import { SearchOutlined } from '@ant-design/icons'
import { Badge, Button, Empty, Image, Input, Layout, Rate, Spin, Table, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import { ChevronUp, ProductDefaultImage, EyeIcon } from '../../constants/media'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
const { Content } = Layout
const { Text, Title } = Typography
const { TextArea, Search } = Input

const publishedProductVersionsAPI = process.env.REACT_APP_PRODUCT_PUBLISH_VERSION_API
const variantDetailsByVersionNumberAPI = process.env.REACT_APP_VARIANT_ATTRIBUTES_BY_VERSION_NUMBER_API
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const baseURL = process.env.REACT_APP_BASE_URL
const approvalRequestAPI = process.env.REACT_APP_APPROVAL_REQUEST_API
const productPublishStatusUpdateAPI = process.env.REACT_APP_PRODUCT_PUBLISHING_STATUS_UPDATE_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH
const maxLengthForToolTip = process.env.REACT_APP_PREFIX_SUFFIX_MAX_SHOW_LENGTH

const ProductsListingNewDesign = () => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal') + ' - ' + String(` ${t('common:my_products')}`))

    const navigate = useNavigate()
    const [tableData, setTableData] = useState([])
    const [searchParams, setSearchParams] = useSearchParams()
    const [totalProducts, setTotalProducts] = useState()
    const [isLoadingProducts, setIsLoadingProducts] = useState(false)
    const [isNetWorkError, setIsNetWorkError] = useState(false)
    const [submitForApprovalModal, setSubmitForApprovalModal] = useState(false)
    const [productId, setProductId] = useState()
    const [submissionReason, setSubmissionReason] = useState('')
    const [isApprovalUploading, setIsApprovalUploading] = useState(false)
    const [unPublishModalVisible, setUnPublishModalVisible] = useState(false)
    const [retiredModalVisible, setRetiredModalVisible] = useState(false)
    const [isVariantsLoading, setIsVariantsLoading] = useState(true)
    const [ExpandedRowKey, setExpandedRowKey] = useState(null)
    const [searchValue, setSearchValue] = useState('')
    const [isSearchTriggered, setIsSearchTriggered] = useState(false)
    const [isTableDataWithoutSearchKeyEmpty, setIsTableDataWithoutSearchKeyEmpty] = useState(true)
    const [langDirection, setLangDirection] = useState(util.getSelectedLanguageDirection() || 'ltr')

    const navigateNext = (
        mode,
        action,
        productTemplateId,
        productTemplateRevisionId,
        storeProductTypeRevisionId,
        store_product_type_id,
        versionStatus,
        versionId,
        variantId,
        versionNumber,
        storeProductTypeName,
        approvalId,
        totalVariantCount,
        variantVersionNumber
    ) => {
        if (variantId) {
            navigate(
                `/dashboard/products/edit-product?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${versionId}&vrsts=${versionStatus}&vaid=${variantId}&vrno=${variantVersionNumber}&m=${mode}&a=${action}&ptrid=${storeProductTypeRevisionId}&ptid=${store_product_type_id}&ptname=${storeProductTypeName}&apprid=${approvalId}&vact=${totalVariantCount}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        } else {
            navigate(
                `/dashboard/products/edit-product?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${versionId}&vrsts=${versionStatus}&vrno=${versionNumber}&m=${mode}&a=${action}&ptrid=${storeProductTypeRevisionId}&ptid=${store_product_type_id}&ptname=${storeProductTypeName}&apprid=${approvalId}&vact=${totalVariantCount}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        }
    }

    const handleVariantNavigation = (
        productTemplateId,
        productTemplateRevisionId,
        productId,
        versionStatus,
        variantId,
        versionNumber,
        storePTRevisionId,
        productTypeId,
        productTypeName,
        approvalId,
        mode,
        action
    ) => {
        navigate(
            `/dashboard/products/variant-attribute-details?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${productId}&vrsts=${versionStatus}&vaid=${variantId}&vrno=${versionNumber}&m=${mode}&a=${action}&ptrid=${storePTRevisionId}&ptid=${productTypeId}&ptname=${productTypeName}&apprid=${approvalId}`
        )
    }

    const editSectionButtonClick = (
        isVariantData,
        productTemplateId,
        productTemplateRevisionId,
        storeProductTypeRevisionId,
        storeProductTypeId,
        productStatus,
        productId,
        variantId,
        versionNumber,
        storeProductTypeName,
        approvalId,
        totalVariants,
        variantVersionNumber
    ) => {
        if (isVariantData === true) {
            if (productStatus === 1) {
                handleVariantNavigation(
                    productTemplateId,
                    productTemplateRevisionId,
                    productId,
                    productStatus,
                    variantId,
                    variantVersionNumber,
                    storeProductTypeRevisionId,
                    storeProductTypeId,
                    storeProductTypeName,
                    approvalId,
                    1,
                    1
                )
            } else {
                handleVariantNavigation(
                    productTemplateId,
                    productTemplateRevisionId,
                    productId,
                    productStatus,
                    variantId,
                    variantVersionNumber,
                    storeProductTypeRevisionId,
                    storeProductTypeId,
                    storeProductTypeName,
                    approvalId,
                    1,
                    0
                )
            }
        } else {
            navigateNext(
                1,
                0,
                productTemplateId,
                productTemplateRevisionId,
                storeProductTypeRevisionId,
                storeProductTypeId,
                productStatus,
                productId,
                variantId,
                versionNumber,
                storeProductTypeName,
                approvalId,
                totalVariants,
                variantVersionNumber
            )
        }
    }

    const productsTabData = [
        {
            key: 0,
            label: <p className={`!mb-0 ${langDirection === 'RTL' ? 'mx-4' : ''}`}>{t('common:all')}</p>,
            value: 0,
        },
        {
            key: 1,
            label: <p className={`!mb-0`}> {t('common:draft')}</p>,
            value: 1,
        },
        {
            key: 2,
            label: <p className=' !mb-0'> {t('products:awaiting_approval')}</p>,
            value: 2,
        },
        {
            key: 3,
            label: <p className=' !mb-0'> {t('products:published')}</p>,
            value: 3,
        },
        {
            key: 4,
            label: <p className='!mb-0'> {t('common:rejected')}</p>,
            value: 4,
        },
        {
            key: 5,
            label: <p className='!mb-0'> {t('products:unpublished')}</p>,
            value: 5,
        },
    ]

    const productsColumns = [
        {
            title: t('products:product_and_version'),
            width: '16%',
            dataIndex: 'name',
            key: 'name',
            fixed: 'left',
            render: (text, record) => {
                return (
                    <>
                        {record.isShowVariantData === false ? (
                            <div className=''>
                                <div className='flex'>
                                    <Image
                                        src={
                                            record.product_image ? baseURL + record.product_image : ProductDefaultImage
                                        }
                                        width={40}
                                        height={40}></Image>
                                    <Content className='w-[80%] ml-2 font-normal'>
                                        {record.product_display_name !== '' ? (
                                            <Text
                                                className='font-notmal !text-[14px] !text-brandGray1'
                                                ellipsis={{
                                                    tooltip: {
                                                        title: record.product_display_name,
                                                        mouseLeaveDelay: 0,
                                                    },
                                                }}>
                                                {record.product_display_name}
                                            </Text>
                                        ) : (
                                            <Text className='font-notmal !text-[14px] !text-brandGray1'>
                                                {t('products:not_available')}
                                            </Text>
                                        )}

                                        <p
                                            className={`font-notmal !text-[14px] !text-brandGray2 ${
                                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                    ? '!px-2'
                                                    : ''
                                            }`}>
                                            (V{Number(record.version_number)?.toFixed(1)})
                                        </p>
                                    </Content>
                                </div>
                            </div>
                        ) : null}
                    </>
                )
            },
        },
        {
            title: t('products:template_and_type'),

            dataIndex: 'productType',
            key: 'productType',
            width: '15%',
            render: (text, record) => {
                return (
                    <div className='flex flex-col'>
                        <Text
                            className='!font-notmal !text-[14px] !text-brandGray1'
                            ellipsis={{
                                tooltip: {
                                    title: record.product_template_display_name,
                                    mouseLeaveDelay: 0,
                                },
                            }}>
                            {record.product_template_display_name}
                        </Text>
                        {record.store_product_type_name ? (
                            <Tag
                                color={
                                    record.store_product_type_name === 'Physical Product'
                                        ? 'green'
                                        : record.store_product_type_name === 'Digital Product'
                                          ? 'blue'
                                          : record.store_product_type_name === 'Service Product'
                                            ? 'magenta'
                                            : record.store_product_type_name === 'Subscription Product'
                                              ? 'gold'
                                              : record.store_product_type_name === 'Bundled Product'
                                                ? 'cyan'
                                                : 'default'
                                }
                                className='w-max !mt-1'>
                                {record.store_product_type_name}
                            </Tag>
                        ) : (
                            ''
                        )}
                    </div>
                )
            },
        },
        {
            title: t('products:managed_by'),

            dataIndex: 'managedBy',
            key: 'managedBy',
            width: '11%',
            render: (text, record) => {
                return (
                    <div>
                        {record.isShowVariantData === false ? (
                            <Text
                                className='!text-[14px] !text-brandGray1 !max-w-[120px]'
                                ellipsis={{
                                    tooltip: {
                                        title: record?.vendor_display_name,
                                        mouseLeaveDelay: 0,
                                    },
                                }}>
                                {record?.vendor_display_name}
                            </Text>
                        ) : null}
                    </div>
                )
            },
        },
        {
            title: t('common:variants'),
            dataIndex: 'variant',
            key: 'variant',
            width: '17%',
            render: (text, record) => {
                return (
                    <div>
                        <Text className='!font-notmal !text-[14px] !text-brandGray1'>
                            {record.isShowVariantData === false ? (
                                <div
                                    className={
                                        util.getSelectedLanguageDirection().toUpperCase() === 'RTL'
                                            ? '-mr-5'
                                            : '-ml-5 '
                                    }>
                                    {record.variant_id == null
                                        ? t('products:no_variants')
                                        : record.total_variants + ' ' + t('common:variants')}
                                </div>
                            ) : (
                                <div className='flex'>
                                    <Image
                                        src={
                                            record.product_variant_image
                                                ? baseURL + record.product_variant_image
                                                : ProductDefaultImage
                                        }
                                        width={40}
                                        height={40}></Image>
                                    <Content className='w-[80%] ml-2 font-medium'>
                                        <Text
                                            className='!font-notmal !text-[14px] !text-brandGray1'
                                            ellipsis={{
                                                tooltip: {
                                                    title: record.variant_name,
                                                    mouseLeaveDelay: 0,
                                                },
                                            }}>
                                            {record.variant_name}
                                        </Text>
                                        <p className='!font-notmal !text-[14px] !text-brandGray1'>
                                            V {record.variant_version_number}
                                        </p>
                                    </Content>
                                </div>
                            )}
                        </Text>
                    </div>
                )
            },
        },
        {
            title: t('common:status'),
            dataIndex: 'statusName',
            key: 'statusName',
            width: '18%',
            render: (text, record) => {
                return (
                    <>
                        {record.total_variants &&
                        record.total_variants.length > 0 &&
                        record.isShowVariantData === false ? (
                            <p>Multiple States</p>
                        ) : (
                            <Badge
                                color={
                                    record.product_status === 1
                                        ? 'blue'
                                        : record.product_status === 2
                                          ? 'orange'
                                          : record.product_status === 3
                                            ? 'green'
                                            : record.product_status === 4
                                              ? 'red'
                                              : record.product_status === 5
                                                ? 'black'
                                                : null
                                }
                                text={record.product_status_display_name}
                            />
                        )}
                    </>
                )
            },
        },
        {
            title: t('products:rating'),
            dataIndex: 'rating',
            key: 'rating',
            width: '13%',
            render: (text, record) => {
                return record.rating > 0 ? <Rate allowHalf defaultValue={record.rating} /> : '-'
            },
        },
        {
            title: <p className=''>{t('common:action')}</p>,
            width: '22%',
            key: 'operation',
            fixed: 'right',
            zIndex: 0,
            render: (text, record) => (
                <div className='w-[100%] '>
                    {parseInt(record.product_status) === 1 || parseInt(record.product_status) === 2 ? (
                        <Content className='flex justify-end '>
                            <Button
                                type='text'
                                className='app-btn-text'
                                onClick={() =>
                                    editSectionButtonClick(
                                        record.isShowVariantData,
                                        record.product_template_id,
                                        record.product_template_revision_id,
                                        record.store_product_type_revision_id,
                                        record.store_product_type_id,
                                        record.product_status,
                                        record.product_id,
                                        record.variant_id,
                                        record.version_number,
                                        record.store_product_type_name,
                                        record.approval_id,
                                        record.total_variants,
                                        record.variant_version_number
                                    )
                                }>
                                {/* <Text ellipsis className='app-primary-color  w-[90px]'>
                                    {t('common:view_details')}
                                </Text> */}
                                <div>
                                    <img src={EyeIcon} />
                                </div>
                            </Button>
                        </Content>
                    ) : parseInt(record.product_status) === 3 ? (
                        <Content className='flex justify-between '>
                            <Button
                                type='text'
                                className='app-btn-text'
                                onClick={() => {
                                    setUnPublishModalVisible(true)
                                    setProductId(record.product_id)
                                }}>
                                <Tooltip title={t('common:make_it_unpublished')} placement='top'>
                                    <Text ellipsis className='app-primary-color font-medium text-[14px]'>
                                        {t('common:make_it_unpublished')}
                                    </Text>
                                </Tooltip>
                            </Button>
                            <Button
                                type='text'
                                className='app-btn-text'
                                onClick={() =>
                                    editSectionButtonClick(
                                        record.isShowVariantData,
                                        record.product_template_id,
                                        record.product_template_revision_id,
                                        record.store_product_type_revision_id,
                                        record.store_product_type_id,
                                        record.product_status,
                                        record.product_id,
                                        record.variant_id,
                                        record.version_number,
                                        record.store_product_type_name,
                                        record.approval_id,
                                        record.total_variants,
                                        record.variant_version_number
                                    )
                                }>
                                {/* <Tooltip
                                    title={
                                        t('common:view_details')?.length > maxLengthForToolTip
                                            ? t('common:view_details')
                                            : undefined
                                    }
                                    placement='top'>
                                    <Text ellipsis className='app-primary-color w-[90px]'>
                                        {t('common:view_details')}
                                    </Text>
                                </Tooltip> */}
                                <div>
                                    <img src={EyeIcon} />
                                </div>
                            </Button>
                        </Content>
                    ) : parseInt(record.product_status) === 5 ? (
                        <Content className='w-[100%] flex justify-between'>
                            <Button
                                type='text'
                                className='app-btn-reject px-0'
                                onClick={() => {
                                    setRetiredModalVisible(true)
                                    setProductId(record.product_id)
                                }}>
                                <Tooltip
                                    title={
                                        t('common:retire')?.length > maxLengthForToolTip
                                            ? t('common:retire')
                                            : undefined
                                    }
                                    placement='top'>
                                    <Text ellipsis className='app-btn-reject font-medium text-[14px] '>
                                        {t('common:retire')}
                                    </Text>
                                </Tooltip>
                            </Button>
                            <Button
                                type='text'
                                className='app-btn-text'
                                onClick={() =>
                                    editSectionButtonClick(
                                        record.isShowVariantData,
                                        record.product_template_id,
                                        record.product_template_revision_id,
                                        record.store_product_type_revision_id,
                                        record.store_product_type_id,
                                        record.product_status,
                                        record.product_id,
                                        record.variant_id,
                                        record.version_number,
                                        record.store_product_type_name,
                                        record.approval_id,
                                        record.total_variants,
                                        record.variant_version_number
                                    )
                                }>
                                {/* <Tooltip
                                    title={
                                        t('common:view_details')?.length > maxLengthForToolTip
                                            ? t('common:view_details')
                                            : undefined
                                    }
                                    placement='top'>
                                    <Text ellipsis className='app-primary-color w-[90px]'>
                                        {t('common:view_details')}
                                    </Text>
                                </Tooltip> */}
                                <div>
                                    <img src={EyeIcon} />
                                </div>
                            </Button>
                        </Content>
                    ) : parseInt(record.product_status) === 6 || parseInt(record.product_status) === 4 ? (
                        <Content className='flex justify-end '>
                            <Button
                                type='text'
                                className='app-btn-text'
                                onClick={() =>
                                    editSectionButtonClick(
                                        record.isShowVariantData,
                                        record.product_template_id,
                                        record.product_template_revision_id,
                                        record.store_product_type_revision_id,
                                        record.store_product_type_id,
                                        record.product_status,
                                        record.product_id,
                                        record.variant_id,
                                        record.version_number,
                                        record.store_product_type_name,
                                        record.approval_id,
                                        record.total_variants,
                                        record.variant_version_number
                                    )
                                }>
                                <div>
                                    <img src={EyeIcon} />
                                </div>
                            </Button>
                        </Content>
                    ) : null}
                </div>
            ),
        },
    ]

    const listProductsDataProcessor = (data) => {
        let temp = []
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                temp.push({
                    key: data[i].id,
                    value: data[i].id,
                    id: data[i].id,
                    version_number: data[i].version_number,
                    store_id: data[i].store_id,
                    product_template_id: data[i].product_template_id,
                    product_template_name: data[i].product_template_name,
                    variant_id: data[i].variant_id,
                    variant_name: data[i].variant_name,
                    variant_display_name: data[i].variant_display_name,
                    vendor_id: data[i].vendor_id,
                    vendor_name: data[i].vendor_name,
                    vendor_display_name: data[i].vendor_display_name,
                    product_template_revision_id: data[i].product_template_revision_id,
                    product_status: data[i].product_status,
                    product_status_display_name: data[i].product_status_display_name,
                    description: data[i].description,
                    store_product_type_revision_id: data[i].store_product_type_revision_id,
                    store_product_type_id: data[i].store_product_type_id,
                    store_product_type_name: data[i].store_product_type_name,
                    total_variants: data[i].total_variants,
                    product_name: data[i].product_name,
                    product_image: data[i].product_image,
                    rating: data[i].rating,
                    num_of_rated_users: data[i].num_of_rated_users,
                    product_template_display_name: data[i].product_template_display_name,
                    product_display_name: data[i].product_display_name,
                    approval_id: data[i].approval_id,
                    approval_reason: data[i].approval_reason,
                    submission_reason: data[i].submission_reason,
                    isRowExpandable: data[i].variant_id !== null ? true : false,
                    isShowVariantData: false,
                    product_id: data[i].id,
                    children: [],
                })
            }
            return temp
        } else {
            return []
        }
    }

    const listVariantDataProcessor = (data) => {
        let temp = []
        if (data && data.length > 0) {
            for (var i = 0; i < data.length; i++) {
                temp.push({
                    key: data[i].variant_id + 'v',
                    value: data[i].product_id,
                    id: data[i].product_id,
                    variant_version_number: data[i].version_number,
                    store_id: data[i].store_id,
                    product_template_id: data[i].product_template_id,
                    product_template_name: data[i].product_template_name,
                    variant_id: data[i].variant_id,
                    variant_name: data[i].variant_name,
                    variant_display_name: data[i].variant_display_name,
                    vendor_id: data[i].vendor_id,
                    vendor_name: data[i].vendor_name,
                    product_template_revision_id: data[i].product_template_revision_id,
                    product_status: data[i].product_status,
                    product_status_display_name: data[i].product_status_display_name,
                    description: data[i].description,
                    store_product_type_revision_id: data[i].store_product_type_revision,
                    store_product_type_id: data[i].store_product_type_id,
                    variant_store_product_type_name: data[i].store_product_type_name,
                    total_variants: data[i].total_variants,
                    product_name: data[i].product_name,
                    product_variant_image: data[i].product_image,
                    rating: data[i].rating,
                    num_of_rated_users: data[i].num_of_rated_users,
                    product_template_display_name: data[i].product_template_display_name,
                    product_display_name: data[i].product_display_name,
                    approval_id: data[i].approval_id,
                    approval_reason: data[i].approval_reason,
                    submission_reason: data[i].submission_reason,
                    attribute: data[i].attribute,
                    attribute_value: data[i].attribute_value,
                    isRowExpandable: false,
                    isShowVariantData: true,
                    product_id: data[i].product_id,
                })
            }
            return temp
        }
    }

    const getAllProductsList = (status, page, limit, searchKey) => {
        let params = {}

        if (searchKey) {
            params['search'] = searchKey
            setIsSearchTriggered(true)
        }
        if (isNaN(status)) {
            setSearchParams({
                tab: 0,
                page: searchParams.get('page'),
                limit: searchParams.get('limit'),
            })
        }
        if (parseInt(status) !== 0) {
            params['product-status'] = parseInt(status)
        }
        setExpandedRowKey(null)
        setIsLoadingProducts(true)
        MarketplaceServices.findByPage(publishedProductVersionsAPI, params, parseInt(page), parseInt(limit), true)
            .then(function (response) {
                console.log('list of products-->', response.data)
                setTotalProducts(response.data.response_body[0].count)
                if (
                    response.data.response_body[0].produt_publish_version &&
                    response.data.response_body[0].produt_publish_version.length > 0
                ) {
                    setTableData(listProductsDataProcessor(response.data.response_body[0].produt_publish_version))
                } else {
                    setTableData([])
                }
                if (
                    (searchKey === '' || searchKey === undefined) &&
                    response?.data?.response_body[0]?.produt_publish_version?.length <= 0
                ) {
                    setIsTableDataWithoutSearchKeyEmpty(true)
                } else {
                    setIsTableDataWithoutSearchKeyEmpty(false)
                }
                setIsLoadingProducts(false)
                setIsNetWorkError(false)
            })
            .catch(function (error) {
                setTableData([])
                setIsLoadingProducts(false)
                setIsNetWorkError(true)
                console.log('error--->', error.response)
            })
    }

    const getVariantDetailsByVersionNumber = (
        vendorId,
        productTemplateId,
        productTemplateRevisionId,
        versionNumber,
        productTypeRevisionId,
        productId
    ) => {
        MarketplaceServices.findAllWithoutPage(
            variantDetailsByVersionNumberAPI,
            {
                'vendor-id': parseInt(vendorId),
                product_template_id: parseInt(productTemplateId),
                product_template_revision_id: parseInt(productTemplateRevisionId),
                version_number: versionNumber,
                store_product_type_revision_id: parseInt(productTypeRevisionId),
            },
            true
        )
            .then(function (response) {
                console.log('variants get call response--->', response.data.response_body.variants)
                if (response.data.response_body.variants && response.data.response_body.variants.length > 0) {
                    let temp = [...tableData]
                    let particularIndex = temp.findIndex((item) => item.id === productId)
                    temp[particularIndex]['children'] = listVariantDataProcessor(response.data.response_body.variants)
                    setIsVariantsLoading(false)
                    setTableData(temp)
                } else {
                    let temp = [...tableData]
                    let particularIndex = temp.findIndex((item) => item.id === productId)
                    temp[particularIndex]['children'] = []
                    setIsVariantsLoading(false)
                    setTableData(temp)
                }
            })
            .catch(function (error) {
                console.log('variants get call error response--->', error)
                setIsVariantsLoading(false)
            })
    }

    const handleTabChange = (value) => {
        setSearchValue('')
        setExpandedRowKey(null)
        setSearchParams({
            tab: value,
            page: 1,
            limit: pageLimit,
        })
    }

    const handlePageNumberChange = (page, pageSize) => {
        setExpandedRowKey(null)
        setSearchParams({
            tab: isNaN(searchParams.get('tab')) ? 0 : searchParams.get('tab'),
            page: page,
            limit: pageSize,
        })
    }

    const postApprovalRequest = () => {
        setIsApprovalUploading(true)
        const postBodyObject = {}
        postBodyObject.type_choice = 'product_publishing'
        postBodyObject.revision_id = [parseInt(productId)]
        postBodyObject.revision_status = 2
        postBodyObject.submission_reason = submissionReason
        MarketplaceServices.save(approvalRequestAPI, postBodyObject, null)
            .then(function (response) {
                console.log('Response from approvals POST---- is:', response.data)
                setIsApprovalUploading(false)
                setSubmitForApprovalModal(false)
                setProductId()
                setSubmissionReason('')
                getAllProductsList(
                    searchParams.get('tab'),
                    parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                    parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit,
                    searchValue
                )
            })
            .catch(function (error) {
                console.log('errorFromApprovalStatus====', error)
                MarketplaceToaster.showToast(error.response)
                setIsApprovalUploading(false)
                setSubmitForApprovalModal(false)
                setSubmissionReason('')
                setProductId()
            })
    }

    const closeRequestApprovalModal = () => {
        setSubmitForApprovalModal(false)
        setSubmissionReason('')
    }

    const updateStatusOfProducts = (status) => {
        let temp = productPublishStatusUpdateAPI.replace('{product_id}', String(productId))
        let dataObject = {
            product_status: status,
        }

        MarketplaceServices.update(temp, dataObject)
            .then(function (response) {
                console.log('Server response from updateStatusOfProducts is', temp, response.data.response_body)
                MarketplaceToaster.showToast(response)

                closeUnPublishModal()
                closeRetiredModal()
                getAllProductsList(
                    searchParams.get('tab'),
                    parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                    parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit
                )
            })
            .catch((error) => {
                console.log('Error Server response from updateStatusOfProducts is', temp, error)
                MarketplaceToaster.showToast(error.response)
                closeUnPublishModal()
                closeRetiredModal()
            })
    }

    const closeUnPublishModal = () => {
        setUnPublishModalVisible(false)
    }

    const closeRetiredModal = () => {
        setRetiredModalVisible(false)
    }
    const handleSearchChange = (searchValue) => {
        setSearchValue(searchValue)
        if (searchValue?.trim()) {
            getAllProductsList(
                searchParams.get('tab'),
                parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit,
                searchValue
            )
        } else {
            if (isSearchTriggered) {
                setIsSearchTriggered(false)
                getAllProductsList(
                    parseInt(searchParams.get('tab')),
                    parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                    parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit
                )
            }
        }
    }
    const handleInputChange = (event) => {
        const trimmed = String(event.target.value)
        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
        setSearchValue(trimmedUpdate)
        if (event.target.value === '') {
            if (isSearchTriggered) {
                setIsSearchTriggered(false)
                getAllProductsList(
                    parseInt(searchParams.get('tab')),
                    parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
                    parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : pageLimit
                )
            }
        }
    }

    const handleExpand = (expanded, record) => {
        setExpandedRowKey(expanded ? record.key : null)
        if (expanded && record.children && record.children.length === 0) {
            setIsVariantsLoading(true)
            if (record.children && record.children.length === 0) {
                setIsVariantsLoading(true)
                getVariantDetailsByVersionNumber(
                    record.vendor_id,
                    record.product_template_id,
                    record.product_template_revision_id,
                    record.version_number,
                    record.store_product_type_revision_id,
                    record.id
                )
            }
        }
    }
    useEffect(() => {
        setIsNetWorkError(false)
        if (searchParams) {
            let pageNumber = searchParams.get('page') != null ? searchParams.get('page') : 1
            let pageLimit1 = searchParams.get('limit') != null ? searchParams.get('limit') : pageLimit
            let status = searchParams.get('tab') != null ? searchParams.get('tab') : 0
            window.scrollTo(0, 0)
            setIsLoadingProducts(true)
            getAllProductsList(status, pageNumber, pageLimit1)
        }
    }, [searchParams])
    const customButton = (
        <Button type='primary' disabled={searchValue?.trim() === '' ? true : false} icon={<SearchOutlined />} />
    )

    return (
        <Content>
            <HeaderForTitle
                title={
                    <>
                        <Content className='flex justify-between'>
                            <Title level={3} className='!font-semibold !text-regal-blue !text-[24px]'>
                                {t('common:products')}
                            </Title>
                        </Content>
                    </>
                }
                headerContent={
                    <Content className='!h-11'>
                        <DmTabAntDesign
                            tabData={productsTabData}
                            handleTabChangeFunction={handleTabChange}
                            defaultSelectedTabKey={3}
                            activeKey={searchParams.get('tab') !== null ? parseInt(searchParams.get('tab')) : 0}
                            tabBarPosition={'top'}
                            tabType={'line'}
                        />
                    </Content>
                }
            />
            <Content className='m-3 bg-white p-3 shadow-brandShadow rounded-lg'>
                {!isNetWorkError ? (
                    <Content className='!flex !justify-between mt-3'>
                        <p className='text-sm !font-semibold'>{productsTabData[searchParams.get('tab')]?.label}</p>
                        {!isTableDataWithoutSearchKeyEmpty ? (
                            <Search
                                placeholder={t('common:please_enter_search_text_here')}
                                onSearch={handleSearchChange}
                                onChange={handleInputChange}
                                value={searchValue}
                                suffix={null}
                                maxLength={searchMaxLength}
                                enterButton={customButton}
                                allowClear
                                className='!justify-end !w-[30%]'
                            />
                        ) : null}
                    </Content>
                ) : null}
                {isLoadingProducts ? (
                    <SkeletonComponent />
                ) : isNetWorkError ? (
                    <Content className='text-center !mb-2 pt-2 bg-white p-3'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : (
                    <Content className='flex flex-col'>
                        <Content className='mt-3'>
                            {tableData && tableData.length === 0 && isSearchTriggered && searchValue?.length > 0 ? (
                                <Content className='text-center font-semibold ml-2 mt-3 bg-white p-3'>
                                    <Text>{t('common:not_able_to_find_searched_details')}</Text>
                                </Content>
                            ) : (
                                <>
                                    {tableData && tableData.length > 0 ? (
                                        <Table
                                            columns={productsColumns}
                                            dataSource={tableData}
                                            pagination={false}
                                            className={isVariantsLoading ? '' : 'product-listing-table'}
                                            scroll={{
                                                x: 1500,
                                            }}
                                            expandable={{
                                                onExpand: handleExpand,
                                                expandedRowRender: (record) => {
                                                    return (
                                                        <>
                                                            <Spin
                                                                spinning={isVariantsLoading}
                                                                className='!my-4 !ml-[50%]'></Spin>
                                                        </>
                                                    )
                                                },
                                                expandIcon: ({ expandable, expanded, onExpand, record }) => (
                                                    <span
                                                        onClick={(e) => {
                                                            onExpand(record, e)
                                                        }}
                                                        className='flex !items-center justify-center cursor-pointer '>
                                                        {expandable && (
                                                            <img
                                                                src={ChevronUp}
                                                                className={`transition-transform transform mt-2 ${
                                                                    expanded ? 'rotate-180' : 'rotate-0'
                                                                }`}
                                                                alt='upIcon'
                                                            />
                                                        )}
                                                    </span>
                                                ),
                                                expandedRowKeys: [ExpandedRowKey],
                                                rowExpandable: (record) => record.isRowExpandable,
                                                expandIconColumnIndex: 3,
                                            }}
                                        />
                                    ) : (
                                        <Content className='!text-center !mb-5'>
                                            <Empty description={t('common:no_data_available_to_display')} />
                                        </Content>
                                    )}
                                </>
                            )}
                        </Content>
                        {totalProducts > pageLimit ? (
                            <Content className='flex flex-row self-end z-10'>
                                <DmPagination
                                    currentPage={
                                        parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1
                                    }
                                    totalItemsCount={totalProducts}
                                    defaultPageSize={pageLimit}
                                    pageSize={
                                        parseInt(searchParams.get('limit'))
                                            ? parseInt(searchParams.get('limit'))
                                            : pageLimit
                                    }
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showTotal={true}
                                    showQuickJumper={true}
                                />
                            </Content>
                        ) : null}
                    </Content>
                )}
            </Content>
            <StoreModal
                isVisible={submitForApprovalModal}
                title={`${t('common:approval_request_submission')}`}
                okCallback={() => postApprovalRequest()}
                cancelCallback={() => closeRequestApprovalModal()}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                isSpin={isApprovalUploading}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('products:submission_reason')}
                    </Title>
                    <TextArea
                        value={submissionReason}
                        autoSize={true}
                        maxLength={225}
                        placeholder={t('messages:please_enter_submission_request_message')}
                        onChange={(e) => setSubmissionReason(e.target.value)}
                        onBlur={() => {
                            setTimeout(() => {
                                const trimmed = submissionReason.trim().replace(/\s+/g, ' ')
                                setSubmissionReason(trimmed)
                            }, 300)
                        }}
                    />
                </Content>
            </StoreModal>
            <StoreModal
                isVisible={unPublishModalVisible}
                title={`${t('products:unpublish_request_submission')}`}
                okCallback={() => updateStatusOfProducts(5)}
                cancelCallback={() => closeUnPublishModal()}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                isSpin={false}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('products:unpublish_message')}
                    </Title>
                </Content>
            </StoreModal>

            <StoreModal
                isVisible={retiredModalVisible}
                title={`${t('products:retire_request_submission')}`}
                okCallback={() => updateStatusOfProducts(6)}
                cancelCallback={() => closeRetiredModal()}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                isSpin={false}>
                <Content className='mb-4'>
                    <Title level={5} className=' mb-2'>
                        {t('products:retire_message')}
                    </Title>
                </Content>
            </StoreModal>
        </Content>
    )
}

export default ProductsListingNewDesign
