import { Button, Col, Input, Layout, Popover, Row, Tag, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'

import { fnRevisionInfo, fnRevisionSelectedInfo } from '../../services/redux/actions/ActionsRevisionInfo'

import { useTranslation } from 'react-i18next'
import { getParentChildrenDataForProductParentTemplate, sortObjectArrayByKey } from '../../util/util'

import StoreModal from '../../components/storeModal/StoreModal'
import ProductsTabData from '../../constants/ProductsTabData'

import { ArrowLeftOutlined, ArrowRightOutlined, QuestionCircleOutlined } from '@ant-design/icons'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'

const itemsPerPage = process.env.REACT_APP_ITEM_PER_PAGE
const productTemplateAPI = process.env.REACT_APP_PRODUCT_API
const productTemplateRevisionAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_API
const approvalRequestAPI = process.env.REACT_APP_APPROVAL_REQUEST_API
const productPublishStatusUpdateAPI = process.env.REACT_APP_PRODUCT_PUBLISHING_STATUS_UPDATE_API
const publishedProductVersionsAPI = process.env.REACT_APP_PRODUCT_PUBLISH_VERSION_API
const variantDetailsByVersionNumberAPI = process.env.REACT_APP_VARIANT_ATTRIBUTES_BY_VERSION_NUMBER_API
const productHierarchyAPI = process.env.REACT_APP_PRODUCT_HIERARCHY_API
const productTemplateActiveAPI = process.env.REACT_APP_ACTIVE_PRODUCT_TEMPLATE_API

//!Ant Design destructing
const { Title, Text } = Typography
const { Content } = Layout
const { TextArea } = Input

function ProductPublishingHeader({
    type,
    viewType,
    isSaveClicked,
    setIsSaveClicked,
    saveButtonDisabled,
    selectedVariant,
    selectedStoreProductTypeRevisionId,
    isFileUploaded,
    setStoreProductTypeId
}) {
    const search = useLocation().search
    let navigate = useNavigate()
    const { t } = useTranslation()
    const dispatch = useDispatch()
    /**
     * !Below are variables got from the URl Search Params.
     */
    let productTemplateId = new URLSearchParams(search).get('ptsid')
    let productTemplateRevisionId = new URLSearchParams(search).get('ptsrid')
    let version_status = new URLSearchParams(search).get('vrsts')
    let product_id = new URLSearchParams(search).get('vrid')
    let version_number = new URLSearchParams(search).get('vrno')
    let variant_id = new URLSearchParams(search).get('vaid')
    let mode = new URLSearchParams(search).get('m')
    let action = new URLSearchParams(search).get('a')
    let storeProductTypeName = new URLSearchParams(search).get('ptname')
    let approvalStatusFromURL = new URLSearchParams(search).get('apSts')
    let approvalPageFromURL = new URLSearchParams(search).get('apPage')
    let approvalLimitFromURL = new URLSearchParams(search).get('apLimit')
    let approval_id_from_URL = new URLSearchParams(search).get('apprid')
    let storePTRevisionId = new URLSearchParams(search).get('ptrid')
    let storeProductTypeIdFromURL = new URLSearchParams(search).get('ptid')
    let totalVariantCountFromURL = new URLSearchParams(search).get('vact')
    const revisionSelectedInfo = useSelector((state) => state.reducerRevisionInfo.revisionSelectedInfo)

    const permissionValue = util.getPermissionData() || []
    const makePublishingAPICallRedux = useSelector((state) => state.reducerAPICalls.makePublishingCall)
    const [searchParams, setSearchParams] = useSearchParams()
    const [selectedProduct, setSelectedProduct] = useState()
    const [rejectionReason, setRejectionReason] = useState()
    const [isModalApproveVisible, setIsModalApproveVisible] = useState(false)
    const [isApprovalUploading, setIsApprovalUploading] = useState(false)
    const [isModalRejectVisible, setIsModalRejectVisible] = useState(false)
    const [isModalMakeItDraftVisible, setIsModalMakeItDraftVisible] = useState(false)
    const [isRejectUploading, setIsRejectUploading] = useState(false)
    const [buttonDisabled, setButtonDisabled] = useState(false)
    const [unPublishModalVisible, setUnPublishModalVisible] = useState(false)
    const [retiredModalVisible, setRetiredModalVisible] = useState(false)
    const [currentSelectedProduct, setCurrentSelectedProduct] = useState()
    const [approvalReason, setApprovalReason] = useState('')
    const [productDetailsLoading, setProductDetailsLoading] = useState(true)
    const [variantsData, setVariantsData] = useState()

    const statusOfTag = () => {
        return (
            <Tag color={'grey'} className='!mt-2 mx-2'>
                {t('common:multiple_states')}
            </Tag>
        )
    }

    //! get product Template
    const getProductById = () => {
        if (
            productTemplateRevisionId &&
            productTemplateRevisionId !== undefined &&
            productTemplateRevisionId !== null &&
            productTemplateId &&
            productTemplateId !== undefined &&
            productTemplateId !== null
        ) {
            MarketplaceServices.findAllWithoutPage(
                productTemplateAPI,
                {
                    'product-template-id': parseInt(productTemplateId),
                },
                true
            )
                .then(function (response) {
                    console.log(
                        'Server response from getProductById API is ',
                        productTemplateAPI,
                        response.data.response_body
                    )
                    setSelectedProduct(response.data.response_body.StoreProductRevision[0])
                    if (response.data.response_body.StoreProductRevision?.length > 0) {
                        let serverData = response.data.response_body.StoreProductRevision

                        setStoreProductTypeId(
                            serverData[0]?.store_product_type.length > 0 &&
                                serverData[0]?.store_product_type[0]?.product_type_id
                        )
                    }
                })
                .catch((error) => {
                    console.log('Server Error Response -> Product By Id : ', productTemplateAPI, error.response)
                })
        }
    }
    //! GET call for Product revision
    const getProductRevision = () => {
        if (productTemplateId && productTemplateId !== undefined && productTemplateId !== null) {
            MarketplaceServices.findAll(
                productTemplateRevisionAPI,
                {
                    'product-template': parseInt(productTemplateId),
                },
                true
            )
                .then(function (response) {
                    console.log(
                        'Server Response from getProductRevision is',
                        productTemplateRevisionAPI,
                        response.data.response_body
                    )
                    let serverResponse = response.data.response_body.data
                    serverResponse = sortObjectArrayByKey('revision_number', 'number', serverResponse, 'desc')
                    let temp = serverResponse && serverResponse.filter((ele) => ele.product_revision_status === 3)
                    dispatch(fnRevisionInfo(temp))

                    let selectedRevisionId = temp.filter((ele) => ele.id === parseInt(productTemplateRevisionId))
                    if (selectedRevisionId && selectedRevisionId.length > 0) {
                        dispatch(fnRevisionSelectedInfo(selectedRevisionId[0]))
                    }
                    dispatch(fnRevisionInfo(serverResponse))
                })

                .catch(function (error) {
                    console.log(
                        'error Server response from  getProductRevision API is',
                        productTemplateRevisionAPI,
                        error.response
                    )
                })
        }
    }
    //!GET call to get the publish version by product template id and product template revision id
    const getProductPublishVersionData = () => {
        if (
            productTemplateRevisionId &&
            productTemplateRevisionId !== undefined &&
            productTemplateRevisionId !== null &&
            productTemplateId &&
            productTemplateId !== undefined &&
            productTemplateId !== null
        ) {
            MarketplaceServices.findAll(
                publishedProductVersionsAPI,
                {
                    'product-template-id': parseInt(productTemplateId),
                    'product-template-revision-id': parseInt(productTemplateRevisionId),
                },
                true
            )
                .then(function (response) {
                    console.log(
                        'server response from getProductPublishVersionData is',
                        publishedProductVersionsAPI,
                        response.data.response_body[0].produt_publish_version
                    )
                    var currentProduct =
                        response.data.response_body[0].produt_publish_version &&
                        response.data.response_body[0].produt_publish_version.length > 0 &&
                        response.data.response_body[0].produt_publish_version.find(
                            (element) => parseInt(element.id) === parseInt(product_id)
                        )
                    setCurrentSelectedProduct(currentProduct)
                    if (currentProduct) {
                        console.log(currentProduct)
                    } else {
                        if (parseInt(mode) === 1 && parseInt(version_status) === 5) {
                            navigate(`/dashboard/products/list-products?tab=5&page=1&limit=${itemsPerPage}`)
                        }
                    }
                    setProductDetailsLoading(false)
                })
                .catch(function (error) {
                    setProductDetailsLoading(false)
                    console.log(
                        'Server Error response from getProductPublishVersionData',
                        publishedProductVersionsAPI,
                        error.response
                    )
                })
        }
    }

    //!GET call to get the variant details by product ID
    const getVariantDetailsByVersionNumber = () => {
        MarketplaceServices.findAllWithoutPage(
            variantDetailsByVersionNumberAPI,
            {
                product_template_id: parseInt(productTemplateId),
                product_template_revision_id: parseInt(productTemplateRevisionId),
                version_number: version_number,
                store_product_type_revision_id: parseInt(storePTRevisionId),
            },
            true
        )
            .then(function (response) {
                console.log(
                    'server response from variants by product id ## :',
                    variantDetailsByVersionNumberAPI,
                    'response',
                    response.data.response_body
                )
                setVariantsData(response?.data?.response_body?.variants)
                if (response.data && response.data.response_body.variants.length > 0) {
                    var currentProduct =
                        response.data.response_body.variants &&
                        response.data.response_body.variants.length > 0 &&
                        response.data.response_body.variants.find(
                            (element) => parseInt(element.product_id) === parseInt(product_id)
                        )
                    setCurrentSelectedProduct(currentProduct)
                    if (currentProduct) {
                        console.log(currentProduct)
                    } else {
                        if (parseInt(mode) === 1 && parseInt(version_status) === 5) {
                            navigate(`/dashboard/products/list-products?tab=5&page=1&limit=${itemsPerPage}`)
                        }
                    }
                } else {
                    if (parseInt(variant_id) && type === 'variant') {
                        getVariantDetailsByVersionNumber()
                    } else {
                        getProductPublishVersionData()
                    }
                }
                setProductDetailsLoading(false)
            })
            .catch(function (error) {
                setVariantsData([])
                setProductDetailsLoading(false)
                console.log('Server Error response from Published product templates:', error.response)
            })
    }
    const closeApproveModal = () => {
        setIsModalApproveVisible(false)
        setApprovalReason('')
    }
    const closeRejectModal = () => {
        setIsModalRejectVisible(false)
        setApprovalReason('')
        setRejectionReason('')
    }
    const closeUnPublishModal = () => {
        setUnPublishModalVisible(false)
    }
    const closeRetiredModal = () => {
        setRetiredModalVisible(false)
    }
    const closeMakeItDraftModal = () => {
        setIsModalMakeItDraftVisible(false)
    }
    const handleNavigation = () => {
        if (parseInt(mode) === 0 && type !== 'variant') {
            navigate('/dashboard/product-template/list-product-templates?' + MarketplaceAppConfig.getStore(''))
        } else if (
            parseInt(mode) === 1 &&
            approval_id_from_URL &&
            approvalStatusFromURL &&
            approvalLimitFromURL &&
            approvalPageFromURL
        ) {
            navigate(
                `/dashboard/approvals/list-approvals?tab=product&status=${approvalStatusFromURL}&page=${approvalPageFromURL}&limit=${approvalLimitFromURL}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        } else if (parseInt(mode) === 1 && type !== 'variant') {
            navigate('/dashboard/products/list-products?' + MarketplaceAppConfig.getStore(''))
        } else if (type === 'variant') {
            navigate(
                `/dashboard/products/edit-product?ptsid=${productTemplateId}&ptsrid=${productTemplateRevisionId}&vrid=${product_id}&vrsts=${version_status}&vaid=${variant_id}&vrno=${version_number}&m=${mode}&a=${action}&ptrid=${storePTRevisionId}&ptid=${storeProductTypeIdFromURL}&ptname=${storeProductTypeName}&vact=${totalVariantCountFromURL}&${MarketplaceAppConfig.getStore(
                    ''
                )}`
            )
        }
    }
    /**
     * !All the useEffects will be here.
     */
    useEffect(() => {
        if (product_id === null && parseInt(mode) === 1 && parseInt(action) === 1) {
            navigate('/dashboard/page-not-available?' + MarketplaceAppConfig.getStore(''))
        }
        if (parseInt(variant_id) && type === 'variant') {
            getVariantDetailsByVersionNumber()
        } else {
            getProductPublishVersionData()
        }
    }, [product_id])

    useEffect(() => {
        if (parseInt(variant_id) && type === 'variant') {
            getVariantDetailsByVersionNumber()
        } else {
            getProductPublishVersionData()
        }
    }, [makePublishingAPICallRedux])
    useEffect(() => {
        if (currentSelectedProduct) {
            if (parseInt(variant_id) && variant_id !== undefined) {
                if (
                    approval_id_from_URL &&
                    approval_id_from_URL != null &&
                    approvalStatusFromURL &&
                    approvalPageFromURL &&
                    approvalLimitFromURL
                ) {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        vaid: variant_id,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                        vact: totalVariantCountFromURL,
                        apprid: approval_id_from_URL,
                        apSts: approvalStatusFromURL,
                        apPage: approvalPageFromURL,
                        apLimit: approvalLimitFromURL,
                    })
                } else if (approval_id_from_URL && approval_id_from_URL != null) {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        vaid: variant_id,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                        vact: totalVariantCountFromURL,
                        apprid: currentSelectedProduct.approval_id,
                    })
                } else {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        vaid: variant_id,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        vact: totalVariantCountFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                    })
                }
            } else {
                if (
                    approval_id_from_URL &&
                    approval_id_from_URL != null &&
                    approvalStatusFromURL &&
                    approvalPageFromURL &&
                    approvalLimitFromURL
                ) {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        vact: totalVariantCountFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                        apprid: approval_id_from_URL,
                        apSts: approvalStatusFromURL,
                        apPage: approvalPageFromURL,
                        apLimit: approvalLimitFromURL,
                    })
                } else if (approval_id_from_URL && approval_id_from_URL != null) {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        vact: totalVariantCountFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                        apprid: currentSelectedProduct.approval_id,
                    })
                } else {
                    setSearchParams({
                        store: MarketplaceAppConfig.getStore('onlyClient'),
                        ptsid: productTemplateId,
                        ptsrid: productTemplateRevisionId,
                        vrsts: currentSelectedProduct.product_status,
                        vrid: product_id,
                        vrno: currentSelectedProduct.version_number,
                        m: 1,
                        a: 0,
                        ptrid: storePTRevisionId,
                        ptid: storeProductTypeIdFromURL,
                        vact: totalVariantCountFromURL,
                        ptname: currentSelectedProduct.store_product_type_name,
                    })
                }
            }
        }
    }, [currentSelectedProduct])

    useEffect(() => {
        getVariantDetailsByVersionNumber()
        getProductById()
        getProductRevision()
        setCurrentSelectedProduct()
        if (parseInt(mode) === 1) {
            if (parseInt(variant_id) && type === 'variant') {
                getVariantDetailsByVersionNumber()
            } else {
                getProductPublishVersionData()
            }
        }
    }, [])

    const updateStatusOfProducts = (status) => {
        setButtonDisabled(true)
        let temp = productPublishStatusUpdateAPI.replace('{product_id}', String(product_id))
        let dataObject = {
            product_status: status,
        }
        MarketplaceServices.update(temp, dataObject)
            .then(function (response) {
                console.log('Server response from updateStatusOfProducts API is', temp, response.data)
                MarketplaceToaster.showToast(response)
                closeUnPublishModal()
                closeApproveModal()
                closeRejectModal()
                closeRetiredModal()
                closeMakeItDraftModal()
                setButtonDisabled(false)
                if (parseInt(status) === 6) {
                    navigate(
                        '/dashboard/products/list-products?tab=5&page=1&limit=20?' + MarketplaceAppConfig.getStore('')
                    )
                }
                if (parseInt(variant_id) && type === 'variant') {
                    getVariantDetailsByVersionNumber()
                } else {
                    getProductPublishVersionData()
                }
            })
            .catch((error) => {
                console.log('error Server Response from updateStatusOfProducts is', temp, error.response)
                setButtonDisabled(false)
                if (error && error.response != undefined) {
                    MarketplaceToaster.showToast(error.response)
                } else {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:something_went_wrong')}`, 'warning'))
                }
                closeUnPublishModal()
                closeRetiredModal()
                closeMakeItDraftModal()
            })
    }
    const makeItDraftForProductApproval = (status) => {
        var putObject = {}
        putObject.type_choice = 'product_publishing'
        putObject.revision_id = parseInt(product_id)
        putObject.revision_status = status
        MarketplaceServices.update(approvalRequestAPI, putObject, {
            _id: parseInt(approval_id_from_URL),
        })
            .then(function (response) {
                console.log('Server Response from makeItDraftForProductApproval API is: ', response.data)
                closeMakeItDraftModal()
                if (parseInt(variant_id) && type === 'variant') {
                    getVariantDetailsByVersionNumber()
                } else {
                    getProductPublishVersionData()
                }
                MarketplaceToaster.showToast(response)
            })
            .catch(function (error) {
                console.log('Server Error Response from makeItDraftForProductApproval is ', error.response)
                closeMakeItDraftModal()
                MarketplaceToaster.showToast(error.response)
            })
    }
    const updateStatusOfProductsForAwaitingApprovals = (status, reason) => {
        setIsApprovalUploading(true)
        setIsRejectUploading(true)
        let dataObject = {
            revision_id: parseInt(product_id),
            revision_status: status,
            type_choice: 'product_publishing',
            approval_reason: reason,
        }
        MarketplaceServices.update(approvalRequestAPI, dataObject, {
            _id: parseInt(approval_id_from_URL),
        })
            .then(function (response) {
                console.log('Server response from updateStatusOfProductsForAwaitingApprovals is', response.data)
                MarketplaceToaster.showToast(response)
                closeApproveModal()
                closeRejectModal()
                setRejectionReason()
                setApprovalReason()
                setIsApprovalUploading(false)
                setIsRejectUploading(false)
                if (parseInt(variant_id) && type === 'variant') {
                    getVariantDetailsByVersionNumber()
                } else {
                    getProductPublishVersionData()
                }
                setIsApprovalUploading(false)
                setIsRejectUploading(false)
            })
            .catch((error) => {
                console.log('Error Server response from updateStatusOfProductsForAwaitingApprovals is', error.response)
                MarketplaceToaster.showToast(error.response)
                setIsApprovalUploading(false)
                closeApproveModal()
                closeRejectModal()
                setRejectionReason()
                setApprovalReason()
                setIsApprovalUploading(false)
                setIsRejectUploading(false)
            })
    }

    return (
        <div>
            {productDetailsLoading ? (
                <div className=' bg-white p-3'>
                    <SkeletonComponent />
                </div>
            ) : (
                <Content>
                    <HeaderForTitle
                        title={
                            <Row className=''>
                                <Col span={14}>
                                    <Row className='flex'>
                                        <Col className='mt-2'>
                                            {viewType === 'modal' ? null : (
                                                <p role={'button'} className='!mb-0' onClick={handleNavigation}>
                                                    {util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? (
                                                        <ArrowRightOutlined
                                                            width={20}
                                                            height={15}
                                                            className='!text-xl  hover:cursor-pointer ml-4'
                                                        />
                                                    ) : (
                                                        <ArrowLeftOutlined
                                                            width={20}
                                                            height={15}
                                                            className='!text-xl  hover:cursor-pointer mr-4'
                                                        />
                                                    )}
                                                </p>
                                            )}
                                        </Col>
                                        <Col>
                                            <Tooltip
                                                overlayStyle={{ zIndex: 11, position: 'fixed' }}
                                                placement='bottom'
                                                title={
                                                    type === 'product' ? (
                                                        <p className='!mb-0'>
                                                            {currentSelectedProduct &&
                                                            currentSelectedProduct.product_display_name !== null &&
                                                            currentSelectedProduct.product_display_name !== ''
                                                                ? currentSelectedProduct.product_display_name !== '' &&
                                                                  currentSelectedProduct.product_display_name !==
                                                                      null &&
                                                                  currentSelectedProduct.product_display_name !==
                                                                      undefined
                                                                    ? currentSelectedProduct.product_display_name
                                                                    : currentSelectedProduct.product_template
                                                                          .product_template_display_name
                                                                : selectedProduct &&
                                                                  selectedProduct.product_template &&
                                                                  selectedProduct.product_template.display_name}
                                                        </p>
                                                    ) : type === 'variant' ? (
                                                        <p className='!mb-0'>
                                                            {selectedVariant && selectedVariant.variant_name}
                                                        </p>
                                                    ) : null
                                                }>
                                                <Title
                                                    level={3}
                                                    ellipsis={true}
                                                    style={{ maxWidth: 350, paddingRight: 5 }}>
                                                    {type === 'product' ? (
                                                        <>
                                                            {parseInt(mode) === 1 ? (
                                                                <p
                                                                    className='mb-0 text-[24px]'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '350px',
                                                                    }}>
                                                                    {currentSelectedProduct &&
                                                                    currentSelectedProduct.product_display_name !==
                                                                        null &&
                                                                    currentSelectedProduct.product_display_name !== ''
                                                                        ? currentSelectedProduct.product_display_name !==
                                                                              '' &&
                                                                          currentSelectedProduct.product_display_name !==
                                                                              null &&
                                                                          currentSelectedProduct.product_display_name !==
                                                                              undefined
                                                                            ? currentSelectedProduct.product_display_name
                                                                            : currentSelectedProduct.product_template
                                                                                  .product_template_display_name
                                                                        : selectedProduct &&
                                                                          selectedProduct.product_template &&
                                                                          selectedProduct.product_template.display_name}
                                                                </p>
                                                            ) : (
                                                                <p
                                                                    className='mb-0 text-[24px]'
                                                                    style={{
                                                                        overflow: 'hidden',
                                                                        textOverflow: 'ellipsis',
                                                                        whiteSpace: 'nowrap',
                                                                        maxWidth: '350px',
                                                                    }}>
                                                                    {currentSelectedProduct &&
                                                                    currentSelectedProduct.product_display_name === null
                                                                        ? selectedProduct &&
                                                                          selectedProduct.product_template &&
                                                                          selectedProduct.product_template.display_name
                                                                        : currentSelectedProduct &&
                                                                          currentSelectedProduct.product_display_name}
                                                                </p>
                                                            )}
                                                        </>
                                                    ) : type === 'variant' ? (
                                                        <p
                                                            className='mb-0'
                                                            style={{
                                                                overflow: 'hidden',
                                                                textOverflow: 'ellipsis',
                                                                whiteSpace: 'nowrap',
                                                                maxWidth: '350px',
                                                            }}>
                                                            {selectedVariant && selectedVariant.variant_name}
                                                        </p>
                                                    ) : null}
                                                </Title>
                                            </Tooltip>
                                        </Col>
                                        <Col>
                                            {parseInt(mode) === 0 && type === 'product' ? (
                                                <Tag color='green' className='mx-2 !h-6'>
                                                    {revisionSelectedInfo &&
                                                    revisionSelectedInfo?.product_revision_status_display_name
                                                        ? revisionSelectedInfo?.product_revision_status_display_name
                                                        : t('common:not_available')}
                                                </Tag>
                                            ) : parseInt(mode) === 1 ? (
                                                <>
                                                    {parseInt(variant_id) && type === 'product' ? (
                                                        statusOfTag()
                                                    ) : (
                                                        <Tag
                                                            color={
                                                                version_status &&
                                                                ProductsTabData &&
                                                                ProductsTabData.length > 0 &&
                                                                ProductsTabData.find(
                                                                    (element) =>
                                                                        parseInt(element.tabId) ===
                                                                        parseInt(version_status)
                                                                ).color
                                                            }
                                                            className='mx-2 !mt-2 !h-6'>
                                                            {currentSelectedProduct &&
                                                                currentSelectedProduct.product_status_display_name}
                                                        </Tag>
                                                    )}
                                                </>
                                            ) : null}
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={10} className='!grid !justify-items-end items-center'>
                                    <Row className={` flex ${viewType === 'modal' ? 'gap-1' : 'gap-2'}`}>
                                        {parseInt(version_status) === 3 &&
                                        ((variant_id === null && type === 'product') ||
                                            (parseInt(variant_id) && type === 'variant')) ? (
                                            <Button
                                                onClick={() => {
                                                    setUnPublishModalVisible(true)
                                                }}
                                                disabled={viewType === 'modal' ? true : buttonDisabled ? true : false}
                                                className='app-btn-primary !h-[32px] '>
                                                {t('products:unpublish')}
                                            </Button>
                                        ) : null}
                                        <>
                                            {permissionValue &&
                                            permissionValue.length > 0 &&
                                            permissionValue.includes('UI-list-approvals') ? (
                                                <>
                                                    {parseInt(version_status) === 2 &&
                                                    approval_id_from_URL &&
                                                    ((variant_id === null && type === 'product') ||
                                                        (parseInt(variant_id) && type === 'variant')) ? (
                                                        <Button
                                                            onClick={() => {
                                                                setIsModalRejectVisible(true)
                                                                setRejectionReason()
                                                            }}
                                                            disabled={viewType === 'modal' ? true : false}
                                                            className={'app-btn-secondary'}>
                                                            {t('common:reject')}
                                                        </Button>
                                                    ) : null}
                                                    {parseInt(version_status) === 2 &&
                                                    approval_id_from_URL &&
                                                    ((variant_id === null && type === 'product') ||
                                                        (parseInt(variant_id) && type === 'variant')) ? (
                                                        <Button
                                                            onClick={() => {
                                                                setIsModalApproveVisible(true)
                                                                setApprovalReason()
                                                            }}
                                                            disabled={viewType === 'modal' ? true : false}
                                                            className='app-btn-primary !h-[32px] '>
                                                            {t('common:approve')}
                                                        </Button>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </>
                                        {parseInt(version_status) === 4 &&
                                        ((variant_id === null && type === 'product') ||
                                            (parseInt(variant_id) && type === 'variant')) ? (
                                            <Button
                                                onClick={() => {
                                                    setIsModalMakeItDraftVisible(true)
                                                }}
                                                disabled={viewType === 'modal' ? true : buttonDisabled ? true : false}
                                                className='app-btn-primary'>
                                                {t('approvals:make_it_draft')}
                                            </Button>
                                        ) : null}
                                        {parseInt(version_status) === 5 &&
                                        ((variant_id === null && type === 'product') ||
                                            (parseInt(variant_id) && type === 'variant')) ? (
                                            <Button
                                                onClick={() => {
                                                    setRetiredModalVisible(true)
                                                }}
                                                disabled={viewType === 'modal' ? true : buttonDisabled ? true : false}
                                                className='app-btn-primary'>
                                                {t('common:retire')}
                                            </Button>
                                        ) : null}
                                    </Row>
                                </Col>
                            </Row>
                        }
                        headerContent={
                            <div className=''>
                                {/* <Col span={14} className='!mt-2'> */}
                                <Content className='!flex justify-between !mt-3  !py-3'>
                                    {/* <div className='!flex !gap-2 !flex-row'>
                                     <p className='!input-label-color  !mb-0'>{t('common:product_type')} </p> :{' '}
                                     {parseInt(mode) === 1 ? <Tag color={'pink'}>{storeProductTypeName}</Tag> : null}
                                 </div> */}
                                    {parseInt(mode) === 1 ? (
                                        <Content className='w-[full] flex gap-x-10'>
                                            <table className='header-table flex-shrink-0'>
                                                <tr>
                                                    <td className='input-label-color whitespace-nowrap'>
                                                        {t('products:product_type_one')}{' '}
                                                    </td>
                                                    <td>:</td>
                                                    <td className='!pl-2'>
                                                        {parseInt(mode) === 1 ? (
                                                            <Tag color={'green'}>{storeProductTypeName}</Tag>
                                                        ) : null}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='input-label-color'>{t('common:description')} </td>
                                                    <td>:</td>
                                                    <td className='!pl-2'>
                                                        {selectedProduct?.product_template?.display_description ? (
                                                            <div className='!w-40'>
                                                                <Text
                                                                    ellipsis={{
                                                                        tooltip:
                                                                            selectedProduct?.product_template
                                                                                ?.display_description,
                                                                    }}
                                                                    className='!font-normal !text-sm'>
                                                                    {
                                                                        selectedProduct?.product_template
                                                                            ?.display_description
                                                                    }
                                                                </Text>
                                                            </div>
                                                        ) : (
                                                            t('products:not_available')
                                                        )}
                                                    </td>
                                                </tr>
                                                {type === 'product' && (
                                                    <tr>
                                                        <td className='input-label-color'>{t('common:variants')} </td>
                                                        <td> : </td>
                                                        <td className='!pl-2'>
                                                            {variantsData != undefined
                                                                ? totalVariantCountFromURL != 'null' &&
                                                                  totalVariantCountFromURL != undefined
                                                                    ? `${totalVariantCountFromURL + ' ' + t('common:variants')}`
                                                                    : `${variantsData.length + ' ' + t('common:variants')}`
                                                                : ''}
                                                        </td>
                                                    </tr>
                                                )}
                                            </table>
                                            <table className='header-table align-self-start'>
                                                <tr>
                                                    <td className='input-label-color whitespace-nowrap'>
                                                        {t('products:parent_template')}{' '}
                                                    </td>
                                                    <td>{' : '}</td>
                                                    <td className='!pl-2'>
                                                        {selectedProduct?.parent_template_display_name ? (
                                                            <div className='!w-40'>
                                                                <Text
                                                                    ellipsis={{
                                                                        tooltip:
                                                                            selectedProduct?.parent_template_display_name,
                                                                    }}
                                                                    className='!font-normal !text-sm'>
                                                                    {selectedProduct?.parent_template_display_name}
                                                                </Text>
                                                            </div>
                                                        ) : (
                                                            t('products:not_available')
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td className='input-label-color whitespace-nowrap'>
                                                        {t('products:copy_attributes_from')}{' '}
                                                    </td>
                                                    <td> : </td>
                                                    <td className='!pl-2'>
                                                        {selectedProduct?.procure_template_displaynames &&
                                                        selectedProduct?.procure_template_displaynames?.length > 0 ? (
                                                            <div className='flex'>
                                                                {selectedProduct?.procure_template_displaynames &&
                                                                    selectedProduct?.procure_template_displaynames
                                                                        ?.length > 0 &&
                                                                    selectedProduct?.procure_template_displaynames
                                                                        .slice(0, 2)
                                                                        .map((ele) => {
                                                                            return (
                                                                                <>
                                                                                    {/* <Tag color={'cyan'} className=''>
                                                                                        {ele?.display_name}
                                                                                    </Tag> */}
                                                                                    <Tag color='cyan'>
                                                                                        {' '}
                                                                                        <Tooltip
                                                                                            title={
                                                                                                ele.display_name
                                                                                                    ?.length > 20
                                                                                                    ? ele.display_name
                                                                                                    : null
                                                                                            }>
                                                                                            {ele.display_name?.length >
                                                                                            20
                                                                                                ? ele.display_name.slice(
                                                                                                      0,
                                                                                                      20
                                                                                                  ) + '...'
                                                                                                : ele.display_name}
                                                                                        </Tooltip>
                                                                                    </Tag>
                                                                                </>
                                                                            )
                                                                        })}
                                                                {selectedProduct?.procure_template_displaynames
                                                                    ?.length > 0 &&
                                                                selectedProduct?.procure_template_displaynames?.length >
                                                                    2 ? (
                                                                    <div>
                                                                        <Popover
                                                                            trigger='click'
                                                                            placement='bottom'
                                                                            content={
                                                                                <div className='!w-72'>
                                                                                    {selectedProduct
                                                                                        ?.procure_template_displaynames
                                                                                        ?.length > 0 &&
                                                                                        selectedProduct?.procure_template_displaynames
                                                                                            ?.slice(2)
                                                                                            .map((ele, index) => {
                                                                                                return (
                                                                                                    <Tag color='cyan'>
                                                                                                        {
                                                                                                            ele.display_name
                                                                                                        }
                                                                                                    </Tag>
                                                                                                )
                                                                                            })}
                                                                                </div>
                                                                            }
                                                                            title=''>
                                                                            <p className='!text-brandPrimaryColor !cursor-pointer'>
                                                                                {`+ ${
                                                                                    selectedProduct
                                                                                        ?.procure_template_displaynames
                                                                                        ?.length > 0
                                                                                        ? selectedProduct
                                                                                              ?.procure_template_displaynames
                                                                                              ?.length - 2
                                                                                        : 0
                                                                                }${t('common:more')}`}
                                                                            </p>
                                                                        </Popover>
                                                                    </div>
                                                                ) : null}
                                                            </div>
                                                        ) : (
                                                            <p className='mt-1 !mb-0'>{t('products:not_available')}</p>
                                                        )}
                                                    </td>
                                                </tr>
                                            </table>
                                        </Content>
                                    ) : null}
                                    <Content className='flex justify-end self-center'>
                                        <>
                                            <div className='!mr-2'>
                                                <Content className='flex gap-2'>
                                                    <Content className='!whitespace-nowrap'>
                                                        <Content>
                                                            <span className='!my-0 text-md input-label-color'>
                                                                {t('products:version')}
                                                            </span>
                                                            {parseInt(mode) === 0 ? (
                                                                <span>
                                                                    <Tooltip
                                                                        overlayStyle={{
                                                                            zIndex: 11,
                                                                            position: 'fixed',
                                                                        }}
                                                                        placement='bottomRight'
                                                                        title={
                                                                            <>
                                                                                <p className='!mb-0'>
                                                                                    <span className='mr-1'>
                                                                                        {revisionSelectedInfo &&
                                                                                        parseInt(
                                                                                            revisionSelectedInfo.product_revision_status
                                                                                        ) === 4 ? (
                                                                                            <>
                                                                                                {t(
                                                                                                    'common:rejection_note'
                                                                                                )}{' '}
                                                                                                :{' '}
                                                                                            </>
                                                                                        ) : (
                                                                                            <>
                                                                                                {t(
                                                                                                    'common:approval_note'
                                                                                                )}{' '}
                                                                                                :{' '}
                                                                                            </>
                                                                                        )}{' '}
                                                                                    </span>
                                                                                    {revisionSelectedInfo &&
                                                                                    revisionSelectedInfo.approval_reason !==
                                                                                        null &&
                                                                                    revisionSelectedInfo.approval_reason !==
                                                                                        undefined &&
                                                                                    revisionSelectedInfo.approval_reason !==
                                                                                        ''
                                                                                        ? revisionSelectedInfo.approval_reason
                                                                                        : t(
                                                                                              'product_types:not_applicable'
                                                                                          )}
                                                                                </p>
                                                                                <p className='!mb-0 gap-1'>
                                                                                    <span className='mr-1 '>
                                                                                        {t('common:submission_note')} :{' '}
                                                                                    </span>
                                                                                    {revisionSelectedInfo &&
                                                                                    revisionSelectedInfo.submission_reason !==
                                                                                        null &&
                                                                                    revisionSelectedInfo.submission_reason !==
                                                                                        undefined &&
                                                                                    revisionSelectedInfo.submission_reason !==
                                                                                        ''
                                                                                        ? revisionSelectedInfo.submission_reason
                                                                                        : t(
                                                                                              'product_types:not_applicable'
                                                                                          )}
                                                                                </p>
                                                                            </>
                                                                        }>
                                                                        {/* <InfoCircleOutlined className='!text-lg !align-[0.2rem] cursor-pointer' /> */}
                                                                        <QuestionCircleOutlined className='!ml-1 !text-sm  input-label-color cursor-pointer' />
                                                                    </Tooltip>
                                                                </span>
                                                            ) : null}
                                                            {parseInt(version_status) >= 2 &&
                                                            parseInt(version_status) !== 5 ? (
                                                                <span>
                                                                    <Tooltip
                                                                        overlayStyle={{ zIndex: 11, position: 'fixed' }}
                                                                        placement='bottomRight'
                                                                        title={
                                                                            <>
                                                                                {parseInt(version_status) === 3 ||
                                                                                parseInt(version_status) === 4 ? (
                                                                                    <p className='!mb-1'>
                                                                                        <span className=''>
                                                                                            {parseInt(
                                                                                                version_status
                                                                                            ) === 3
                                                                                                ? `${t('common:approval_note')} : `
                                                                                                : parseInt(
                                                                                                        version_status
                                                                                                    ) === 4
                                                                                                  ? `${t('common:rejection_note')} : `
                                                                                                  : null}
                                                                                        </span>
                                                                                        {currentSelectedProduct &&
                                                                                        currentSelectedProduct.approval_reason !==
                                                                                            null &&
                                                                                        currentSelectedProduct.approval_reason !==
                                                                                            undefined &&
                                                                                        currentSelectedProduct.approval_reason !==
                                                                                            ''
                                                                                            ? currentSelectedProduct.approval_reason
                                                                                            : t(
                                                                                                  'product_types:not_applicable'
                                                                                              )}
                                                                                    </p>
                                                                                ) : null}
                                                                                <p className='!mb-0  gap-1'>
                                                                                    <span className=''>
                                                                                        {/* {t("common:submission_note : ")}{" "} */}
                                                                                        {t('common:submission_note')} :{' '}
                                                                                    </span>
                                                                                    {currentSelectedProduct &&
                                                                                    currentSelectedProduct.submission_reason !==
                                                                                        null &&
                                                                                    currentSelectedProduct.submission_reason !==
                                                                                        undefined &&
                                                                                    currentSelectedProduct.submission_reason !==
                                                                                        ''
                                                                                        ? currentSelectedProduct.submission_reason
                                                                                        : t(
                                                                                              'product_types:not_applicable'
                                                                                          )}
                                                                                </p>
                                                                            </>
                                                                        }>
                                                                        <QuestionCircleOutlined className='!ml-1 !text-sm  input-label-color cursor-pointer' />
                                                                        {/* <InfoCircleOutlined className='!text-lg !align-[0.2rem] cursor-pointer' /> */}
                                                                    </Tooltip>
                                                                </span>
                                                            ) : null}
                                                        </Content>
                                                        <Content>
                                                            <span className='!my-0 font-bold text-xl'>
                                                                {'V '}
                                                                {parseInt(mode) === 1
                                                                    ? Number(version_number)?.toFixed(1)
                                                                    : revisionSelectedInfo &&
                                                                      Number(
                                                                          revisionSelectedInfo.revision_number
                                                                      )?.toFixed(1)}
                                                            </span>
                                                        </Content>
                                                    </Content>
                                                </Content>
                                            </div>
                                        </>
                                    </Content>
                                </Content>
                            </div>
                        }
                    />
                    <StoreModal
                        isVisible={isModalApproveVisible}
                        title={`${t('approvals:approval_request_submission')}`}
                        okCallback={() => updateStatusOfProductsForAwaitingApprovals(3, approvalReason)}
                        cancelCallback={() => closeApproveModal()}
                        okButtonText={`${t('common:ok')}`}
                        cancelButtonText={`${t('common:cancel')}`}
                        isSpin={isApprovalUploading}>
                        <Content className='mb-4'>
                            <Title level={5} className=' mb-2'>
                                {t('products:approve_message')}
                            </Title>
                            <TextArea
                                showCount
                                maxLength={225}
                                autoSize={true}
                                value={approvalReason}
                                placeholder={t('approvals:enter_approval_req_message')}
                                onChange={(e) => setApprovalReason(e.target.value)}
                                className='!pr-[3px]'
                            />
                        </Content>
                    </StoreModal>
                    <StoreModal
                        isVisible={isModalRejectVisible}
                        title={`${t('approvals:rejection_request_submission')}`}
                        okCallback={() => updateStatusOfProductsForAwaitingApprovals(4, rejectionReason)}
                        cancelCallback={() => closeRejectModal()}
                        okButtonText={`${t('common:ok')}`}
                        cancelButtonText={`${t('common:cancel')}`}
                        isSpin={isRejectUploading}>
                        <Content className='mb-4'>
                            <Title level={5} className=' mb-2'>
                                {t('products:reject_message')}
                            </Title>
                            <TextArea
                                showCount
                                maxLength={225}
                                autoSize={true}
                                value={rejectionReason}
                                placeholder={t('approvals:please_enter_rejection_submission_request_message')}
                                onChange={(e) => setRejectionReason(e.target.value)}
                                className='!pr-[3px]'
                            />
                        </Content>
                    </StoreModal>
                    <StoreModal
                        isVisible={isModalMakeItDraftVisible}
                        title={`${t('products:draft_request_submission')}`}
                        okCallback={() => makeItDraftForProductApproval(1)}
                        cancelCallback={() => closeMakeItDraftModal()}
                        okButtonText={`${t('common:ok')}`}
                        cancelButtonText={`${t('common:cancel')}`}
                        isSpin={false}>
                        <Content className='mb-4'>
                            <Title level={5} className=' mb-2'>
                                {t('products:make_it_draft')}
                            </Title>
                        </Content>
                    </StoreModal>
                    <StoreModal
                        isVisible={unPublishModalVisible}
                        title={`${t('products:unpublish_request_submission')}`}
                        okCallback={() => updateStatusOfProducts(5)}
                        cancelCallback={() => closeUnPublishModal()}
                        okButtonText={`${t('common:ok')}`}
                        cancelButtonText={`${t('common:cancel')}`}
                        isSpin={false}>
                        <Content className='mb-4'>
                            <Title level={5} className=' mb-2'>
                                {t('products:unpublish_message')}
                            </Title>
                        </Content>
                    </StoreModal>
                    <StoreModal
                        isVisible={retiredModalVisible}
                        title={`${t('products:retire_request_submission')}`}
                        okCallback={() => updateStatusOfProducts(6)}
                        cancelCallback={() => closeRetiredModal()}
                        okButtonText={`${t('common:ok')}`}
                        cancelButtonText={`${t('common:cancel')}`}
                        isSpin={false}>
                        <Content className='mb-4'>
                            <Title level={5} className=' mb-2'>
                                {t('products:retire_message')}
                            </Title>
                        </Content>
                    </StoreModal>
                </Content>
            )}
        </div>
    )
}

export default ProductPublishingHeader
