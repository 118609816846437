import { DownOutlined } from '@ant-design/icons'
import { Dropdown, Empty, Skeleton, Space, Typography } from 'antd'
import { Content } from 'antd/lib/layout/layout'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import './dashboard.css'
// import { Column } from "@ant-design/plots";

import { Line } from '@ant-design/plots'

import { useAuth } from 'react-oidc-context'
import util from '../../util/common'

const { Text, Title } = Typography

const TotalConversionsGraph = (props) => {
    const { t } = useTranslation()
    const auth = useAuth()
    const instance = axios.create()

    const [langDirection, setLangDirection] = useState('ltr')
    const [updatedTimes, setUpdatedTimes] = useState({
        Daily: sessionStorage.getItem('updated_time_last24h'),
        Weekly: sessionStorage.getItem('updated_time_last7d'),
        Monthly: sessionStorage.getItem('updated_time_last4w'),
        Yearly: sessionStorage.getItem('updated_time_last12m'),
    })

    const [conversionsData, setConversionsData] = useState([])
    let [timeInterval, setTimeInterval] = useState('daily')
    const [totalConversionsCount, setTotalConversionsCount] = useState(0)
    const [loader, setLoader] = useState(false)

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const dm4sightBaseURL = process.env.REACT_APP_4SIGHT_BASE_URL

    const dm4sightClientID = process.env.REACT_APP_4SIGHT_CLIENT_ID
    const totalOrdersApi = process.env.REACT_APP_4SIGHT_GETTOTALORDERS_API

    const realmName = util.getClient()
    let token = auth?.user?.access_token

    const dm4sightHeaders = {
        token: token,
        realmname: realmName,
        dmClientId: dm4sightClientID,
        client: 'store',
    }

    let reloadPerformance = props.reloadPerformance

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    const getTotalOrders = async (interval) => {
        setLoader(true)

        try {
            if (interval) {
                timeInterval = interval
            }
            const response = await instance({
                url: dm4sightBaseURL + totalOrdersApi,
                method: 'post',
                headers: dm4sightHeaders,
                data: {
                    time_interval: timeInterval,
                },
            })

            let resTotalOrders = response.data.data

            let updatedData = resTotalOrders
                ? resTotalOrders?.map((item, index) => {
                      return {
                          xValue:
                              timeInterval === 'yearly'
                                  ? item.updated_time
                                  : timeInterval === 'monthly'
                                    ? item.updated_time
                                    : timeInterval === 'weekly'
                                      ? item.updated_time?.split(' ')[0]
                                      : timeInterval === 'daily' && item.updated_time,
                          count: item.order_count,
                      }
                  })
                : []
            console.log('updatedData', updatedData)

            if (updatedData.length > 0) {
                if (timeInterval === 'daily') {
                    // compare dates and sort
                    updatedData.sort((a, b) => {
                        return new Date(a.xValue.split(' ')[0]) - new Date(b.xValue.split(' ')[0])
                    })

                    const d = new Date()
                    let currentHour = d.getHours()

                    updatedData = updatedData?.map((item) => ({
                        xValue: item.xValue?.split(' ')[1],
                        count: item.count,
                    }))
                    let less_ress = []
                    let high_ress = []

                    if (currentHour <= 10 && currentHour >= 0) {
                        updatedData?.forEach((ele) => {
                            console.log(ele.xValue, 'day')
                            if (ele.xValue >= 0 && ele.xValue <= 10) {
                                less_ress.push(ele)
                            } else {
                                high_ress.push(ele)
                            }
                            //console.log(ele.xValue, "hii")
                        })

                        less_ress.sort((a, b) => {
                            return parseInt(a.xValue) - parseInt(b.xValue)
                        })
                        high_ress.sort((a, b) => {
                            return parseInt(a.xValue) - parseInt(b.xValue)
                        })
                        console.log(less_ress, 'less')
                        console.log(high_ress, 'high')
                        updatedData = []
                        high_ress?.forEach((ele) => {
                            if (ele.xValue == '23') {
                                ele.xValue = ele.xValue + '-00'
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }

                            updatedData.push(ele)
                        })
                        less_ress?.forEach((ele) => {
                            if (parseInt(ele.xValue) < 9) {
                                ele.xValue = ele.xValue + '-0' + (parseInt(ele.xValue) + 1).toString()
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }

                            updatedData.push(ele)
                        })

                        console.log(updatedData, 'finally')
                    } else {
                        updatedData.sort((a, b) => {
                            return a.xValue - b.xValue
                        })
                        updatedData?.forEach((ele) => {
                            if (parseInt(ele.xValue) < 9) {
                                ele.xValue = ele.xValue + '-0' + (parseInt(ele.xValue) + 1).toString()
                            } else if (ele.xValue == '23') {
                                ele.xValue = ele.xValue + '-00'
                            } else {
                                ele.xValue = ele.xValue + '-' + (parseInt(ele.xValue) + 1).toString()
                            }
                        })
                    }
                    console.log('upddd', updatedData)
                } else if (timeInterval === 'weekly') {
                    updatedData.sort((a, b) => {
                        return new Date(a.xValue) - new Date(b.xValue)
                    })
                }
                if (timeInterval === 'monthly') {
                    updatedData?.forEach((item) => {
                        const dates = item.xValue?.split('&')

                        const startDate = new Date(dates[0])
                            ?.toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })
                            .replace(/,/g, '')
                            ?.split(' ')

                        let startDateFormatted = startDate[1] + ' ' + startDate[0] + ' ' + startDate[2]

                        const endDate = new Date(dates[1])
                            .toLocaleDateString('en-US', {
                                day: '2-digit',
                                month: 'short',
                                year: 'numeric',
                            })
                            .replace(/,/g, '')
                            .split(' ')

                        let endDateFormatted = endDate[1] + ' ' + endDate[0] + ' ' + endDate[2]

                        item.xValue = `${startDateFormatted} - ${endDateFormatted}`
                    })

                    updatedData.sort((a, b) => new Date(a.xValue.split(' - ')[0]) - new Date(b.xValue.split(' - ')[0]))
                } else if (timeInterval === 'yearly') {
                    updatedData.sort((a, b) => {
                        const dateA = new Date(a.xValue)
                        const dateB = new Date(b.xValue)
                        return dateA - dateB
                    })
                }
            }

            if (resTotalOrders == {} || resTotalOrders == [[]] || resTotalOrders?.[0].length == 0) {
                setConversionsData([])
                setTotalConversionsCount(0)
                console.log('totaaa', totalConversionsCount)
            } else {
                let totalCount = 0
                for (const ele of updatedData) {
                    totalCount += ele.count
                }
                console.log('totalCount', totalCount, resTotalOrders.length)
                setTotalConversionsCount(totalCount)
                updatedData = updatedData.map((obj) => {
                    const { count, ...rest } = obj
                    return { [t('dashboard:count')]: count, ...rest }
                })


                setConversionsData(updatedData)
            }
            const currentDate = new Date()
            const currentTime = currentDate.toLocaleTimeString([], {
                hour: '2-digit',
                minute: '2-digit',
            })
            sessionStorage.setItem('updated_time', currentTime)
        } catch (error) {
            console.log('error', error)
            throw new Error('Error fetching top vendor data')
        } finally {
            setLoader(false)
        }
    }

    useEffect(() => {
        getTotalOrders()
    }, [])

    useEffect(() => {
        if (reloadPerformance) {
            getTotalOrders()
        }
    }, [reloadPerformance])

    const config = {
        data: conversionsData,
        padding: 'auto',
        xField: 'xValue',
        yField: t('dashboard:count'),
        legend: {
            position: 'bottom',
            marker: { symbol: 'circle' },
        },

        yAxis: {
            label: {
                formatter: (val) => (Number.isInteger(parseFloat(val)) ? val : ''), // Hide decimal values
            },
            title: {
                text: t('dashboard:number_of_orders'),
            },
            grid: {
                line: {
                    style: {
                        lineDash: [7, 7],
                    },
                },
            },
        },
        xAxis: {
            title: {
                text:
                    timeInterval === 'yearly'
                        ? t('dashboard:months_caps')
                        : timeInterval === 'monthly'
                          ? t('dashboard:weeks_caps')
                          : timeInterval === 'weekly'
                            ? t('dashboard:days_caps')
                            : t('dashboard:hours_caps'),
            },
        },
        lineStyle: {
            stroke: '#13C2C2',
            lineWidth: 2,
        },
    }

    const items = [
        {
            key: '1',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e, t) => {
                        setTimeInterval('daily')
                        getTotalOrders('daily')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 12 {t('dashboard:hours')}
                </a>
            ),
            value: 'daily',
        },
        {
            key: '2',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={() => {
                        setTimeInterval('weekly')
                        getTotalOrders('weekly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 7 {t('dashboard:days')}
                </a>
            ),
            value: 'weekly',
        },
        {
            key: '3',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e) => {
                        setTimeInterval('monthly')
                        getTotalOrders('monthly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 4 {t('dashboard:weeks')}
                </a>
            ),
            value: 'monthly',
        },
        {
            key: '4',
            label: (
                <a
                    style={{ color: '#FB8500' }}
                    onClick={(e) => {
                        setTimeInterval('yearly')
                        getTotalOrders('yearly')
                    }}
                    rel='noopener noreferrer'>
                    {t('dashboard:last')} 12 {t('dashboard:months')}
                </a>
            ),
            value: 'yearly',
        },
    ]

    return (
        <div>
            <Content className='rounded-2xl'>
                <Title level={4} className='!m-0 !pt-0 !mt-0 !text-[#8899A8] flex gap-2 justify-between '>
                    {t('dashboard:total_conversions')}

                    <Title level={5}>
                        <Dropdown
                            menu={{
                                items,
                            }}>
                            <a style={{ color: '#FB8500' }}>
                                <Space>
                                    {items?.filter((ele) => ele.value === timeInterval)?.[0]?.label}
                                    <DownOutlined />
                                </Space>
                            </a>
                        </Dropdown>
                    </Title>
                </Title>

                <Title level={3} className='mt-1 mb-3 !text-[#13C2C2] '>
                    {totalConversionsCount}
                </Title>
                {loader ? (
                    <Skeleton paragraph={{ rows: 4 }} />
                ) : conversionsData.length === 0 ? (
                    <Content className='mt-5'>
                        <Empty description={t('common:no_data')} />
                    </Content>
                ) : (
                    <Line {...config} />
                )}
            </Content>
        </div>
    )
}

export default TotalConversionsGraph
