//! Import libraries
import { LoadingOutlined } from '@ant-design/icons'
import { Affix, Layout, Menu, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { AiOutlineHome } from 'react-icons/ai'
import { useAuth } from 'react-oidc-context'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import {
    Approvals,
    Dashboard,
    Language,
    OpenInNew,
    Orders,
    Policy,
    Product,
    ProductTemplate,
    ProductType,
    Productlayout,
    Profile,
    RelatedProduct,
    Store,
    StoreSettings,
    StoreSettings1,
    Transactions,
    UserAccessControlIcon,
    Vendors,
    attributesettings,
    categories,
    myproducts,
    paymentSettingsIcon,
    StarImg,
    DiscountSideBar,
} from '../../constants/media'

//! Import CSS libraries

//! Import user defined CSS
import NewFooter from '../../components/footer/NewFooter'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnContentSettingInfo } from '../../services/redux/actions/ActionContentSetting'
import { fnStoreSettings } from '../../services/redux/actions/ActionStoreSettings'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import './sidebar.css'
import DashboardIcon from './SidebarIcons/DashboardIcon'
import CheckBoxIcon from './SidebarIcons/CheckBoxIcon'
import ListAltIcon from './SidebarIcons/ListAltIcon'
import ShoppingBagIcon from './SidebarIcons/ShoppingBagIcon'
import BoxIcon from './SidebarIcons/BoxIcon'
import RevenueIcon from './SidebarIcons/RevenueIcon'
import UserStarIcon from './SidebarIcons/UserStarIcon'
import OfferIcon from './SidebarIcons/OfferIcon'
import CogIcon from './SidebarIcons/CogIcon'

//! Destructure the components
const { Sider, Content } = Layout
const { Title, Text } = Typography

const antIcon = <LoadingOutlined className='text-[10px] hidden' spin />

//! Global Variables
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const contentSettingAPI = process.env.REACT_APP_CONTENT_SETTING_API
const sfUrlAPI = process.env.REACT_APP_STORE_FRONT_URL

const Sidebar = ({ permission, collapsed, setCollapsed }) => {
    const { t } = useTranslation()
    const auth = useAuth()
    const dispatch = useDispatch()
    const [currentLanguage, setCurrentLanguage] = useState()
    const [selectedItem, setSelectedItem] = useState([])
    const [openedItem, setOpenedItem] = useState([])
    const { pathname } = useLocation()
    const [isHovering, setIsHovering] = useState(false)
    const [sfUrl, setSFUrl] = useState()

    // get permissions from storage
    const permissionValue = util.getPermissionData() || []

    //! storeSettingsData
    const storeSettingsInfo = useSelector((state) => state.reducerContentSetting.contentSettingInfo)

    const navigate = useNavigate()
    //! get content Setting Data
    const getContentSetting = () => {
        MarketplaceServices.findAll(contentSettingAPI, null, false)
            .then(function (response) {
                console.log(
                    'server Response from getContentSetting API is',
                    contentSettingAPI,
                    response.data.response_body.store_settings_data
                )
                dispatch(fnStoreSettings(response.data.response_body.store_settings_data))
                dispatch(fnContentSettingInfo(response.data.response_body.store_settings_data))
            })
            .catch(function (error) {
                console.log('Error Server Response from getContentSetting is', contentSettingAPI, error.response)
            })
    }

    const storeFrontUrl = () => {
        MarketplaceServices.findAll(sfUrlAPI, null, true)
            .then(function (response) {
                console.log('Response from get SF url', response.data)
                if (response.data.response_body) {
                    setSFUrl(response.data.response_body.url)
                }
            })
            .catch(function (error) {
                console.log('Response from catchBlock of getVendors', error)
            })
    }
    const myData = [
        {
            key: '-1',
            icon: <AiOutlineHome />,
            label: `${t('common:home')}`,
            navigate_to: '/',
            item: null,
            show_in_menu: false,
        },
        {
            key: '1',
            icon: <DashboardIcon />,
            label: `${t('common:dashboard')}`,
            navigate_to: `/dashboard?${MarketplaceAppConfig.getStore('')}`,
            show_in_menu: true,
        },
        {
            key: '4',
            icon: <CheckBoxIcon />,
            label: `${t('common:approvals')}`,
            navigate_to: `/dashboard/approvals/list-approvals?tab=product_template&status=0&page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore(
                ''
            )}`,
            show_in_menu:
                !auth.isAuthenticated ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('UI-list-approvals'))
                    ? true
                    : false,
        },
        {
            key: '112',
            icon: <ListAltIcon />,
            label: `${t('common:categories')}`,
            navigate_to: `/dashboard/categories/list-category?${MarketplaceAppConfig.getStore('')}`,
            show_in_menu:
                !auth.isAuthenticated ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('UI-list-category')) ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('create-store-category'))
                    ? true
                    : false,
        },
        {
            key: '155',
            icon: <ShoppingBagIcon />,
            label: `${t('common:products')}`,
            navigate_to: '',
            show_in_menu:
                !auth.isAuthenticated ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('get-product-publishing-version'))
                    ? true
                    : false,
            childrenKeys: ['113', '133', '144'],
            children: [
                {
                    key: '113',
                    // icon: <img src={ProductTemplate} alt='productTemplate' />,
                    label: `${t('common:product_templates')}`,
                    navigate_to: `/dashboard/product-template/list-product-templates?${MarketplaceAppConfig.getStore(
                        ''
                    )}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('UI-list-product-template')) ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('UI-create-edit-product-template'))
                            ? true
                            : false,
                },
                {
                    key: '133',
                    // icon: <img src={myproducts} alt='product' />,
                    label: `${t('common:my_products')}`,
                    navigate_to: `/dashboard/products/list-products?tab=0&page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore(
                        ''
                    )}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('get-product-publishing-version'))
                            ? true
                            : false,
                },
                {
                    key: '144',
                    // icon: <img src={RelatedProduct} alt='relatedProduct' />,
                    label: `${t('common:related_products')}`,
                    navigate_to: `/dashboard/relative-products/list-relative-products?page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore(
                        ''
                    )}`,
                    show_in_menu: true,
                },
            ],
        },
        {
            key: '7',
            icon: <BoxIcon />,
            label: `${t('common:orders')}`,
            navigate_to: `/dashboard/orders/list-orders?page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore('')}`,
            show_in_menu: true,
        },
        {
            key: '13',
            icon: <RevenueIcon />,
            label: `${t('transactions:settlements')}`,
            navigate_to: `/dashboard/settlement?${MarketplaceAppConfig.getStore('')}`,
            show_in_menu: true,
        },
        {
            key: '8',
            icon: <UserStarIcon />,
            label: `${t('common:vendors')}`,
            navigate_to: `/dashboard/vendors/list-vendors?page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore(
                ''
            )}`,
            show_in_menu: true,
        },
        {
            key: '143',
            icon: <OfferIcon />,
            label: `${t('coupons:discounts')}`,
            navigate_to: `/dashboard/coupons/list-coupons?tab=3&page=1&limit=20${MarketplaceAppConfig.getStore('')}`,
            show_in_menu: true,
        },
        {
            key: '5',
            icon: <CogIcon />,
            label: `${t('store_settings:settings')}`,
            navigate_to: `/dashboard/store-settings?${MarketplaceAppConfig.getStore('')}`,
            show_in_menu:
                !auth.isAuthenticated ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('UI-list-producttype')) ||
                (auth.isAuthenticated &&
                    permissionValue &&
                    permissionValue.length > 0 &&
                    permissionValue.includes('UI-list-language'))
                    ? true
                    : false,
            childrenKeys: ['120', '111', '116', '117', '121', '118', '119', '11', '12'],
            children: [
                {
                    key: '120',
                    // icon: <img src={StoreSettings} alt='StoreSettings' />,
                    label: `${t('common:store_settings')}`,
                    navigate_to: `/dashboard/store-settings?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu: true,
                },
                {
                    key: '111',
                    // icon: <img src={ProductType} alt='ProductType' />,
                    label: `${t('common:product_settings')}`,
                    navigate_to: `/dashboard/settings/product-types?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('UI-list-producttype'))
                            ? true
                            : false,
                },
                {
                    key: '116',
                    // icon: <img src={Language} alt='Language' />,
                    label: `${t('common:language_settings')}`,
                    navigate_to: `/dashboard/languages?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('UI-list-language'))
                            ? true
                            : false,
                },
                {
                    key: '117',
                    // icon: <img src={Productlayout} alt='Productlayout' />,
                    label: `${t('common:product_layout')}`,
                    navigate_to: `/dashboard/list-layout?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu: true,
                },
                {
                    key: '121',
                    // icon: <img src={Policy} alt='Policy' />,
                    label: `${t('common:policies_settings')}`,
                    navigate_to: `/dashboard/policies?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated && permissionValue?.includes('UI-list-approvals')),
                },
                {
                    key: '118',
                    // icon: <img src={attributesettings} alt='AttributeSettings' />,
                    label: `${t('common:product_attribute_settings')}`,
                    navigate_to: `/dashboard/list-attributegroups?page=1&limit=20&${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu: true,
                },
                {
                    key: '119',
                    // icon: <img src={paymentSettingsIcon} alt='paymentIcon' />,
                    label: `${t('payment:payment_settings')}`,
                    navigate_to: `/dashboard/payment?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu: true,
                },
                {
                    key: '12',
                    // icon: (
                    //     <img
                    //         src={UserAccessControlIcon}
                    //         alt='userAccessControl'
                    //         width={'10px'}
                    //         height={'10px'}
                    //         className='!text-[#fff]'
                    //     />
                    // ),
                    label: `${t('common:user_access_control')}`,
                    navigate_to: `/dashboard/user-access-control/list-user-roles?tab=0&page=1&limit=${pageLimit}&${MarketplaceAppConfig.getStore(
                        ''
                    )}`,
                    show_in_menu:
                        !auth.isAuthenticated ||
                        (auth.isAuthenticated &&
                            permissionValue &&
                            permissionValue.length > 0 &&
                            permissionValue.includes('UI-user-access-control'))
                            ? true
                            : false,
                },
                {
                    key: '11',
                    // icon: <img src={Profile} alt='profile' />,
                    label: `${t('common:my_profile')}`,
                    navigate_to: `/dashboard/userprofile?${MarketplaceAppConfig.getStore('')}`,
                    show_in_menu: true,
                },
            ],
        },
    ]

    useEffect(() => {
        switch (pathname.split('/')[2]) {
            case 'categories':
                // setOpenedItem(['2'])
                setSelectedItem('112')
                break
            case 'product-template':
                setOpenedItem(['155'])
                setSelectedItem('113')
                break
            case 'products':
                setOpenedItem(['155'])
                setSelectedItem('133')
                break
            case 'relative-products':
                setOpenedItem(['155'])
                setSelectedItem('144')
                break
            case 'settlement':
                setSelectedItem('13')
                break
            case 'vendor':
                setOpenedItem(['3'])
                setSelectedItem('115')
                break
            case 'coupons':
                setSelectedItem('143')
                break
            case 'published':
                setOpenedItem(['2'])
                setSelectedItem('117')
                break
            case 'approvals':
                setSelectedItem('4')
                break
            case 'store-settings':
                setOpenedItem(['5'])
                setSelectedItem('120')
                break
            case 'settings':
                setOpenedItem(['5'])
                setSelectedItem('111')
                break
            case 'languages':
                setOpenedItem(['5'])
                setSelectedItem('116')
                break
            case 'payment':
                setOpenedItem(['5'])
                setSelectedItem('119')
                break
            case 'list-layout':
                setOpenedItem(['5'])
                setSelectedItem('117')
                break
            case 'edit-layout':
                setOpenedItem(['5'])
                setSelectedItem('117')
                break
            case 'add-layout':
                setOpenedItem(['5'])
                setSelectedItem('117')
                break
            case 'list-attributegroups':
                setOpenedItem(['5'])
                setSelectedItem('118')
                break
            case 'orders':
                setSelectedItem('7')
                break
            case 'vendors':
                setSelectedItem('8')
                break
            case 'customers':
                setSelectedItem('9')
                break
            case 'transactions':
                setSelectedItem('10')
                break
            case 'userprofile':
                setOpenedItem(['5'])
                setSelectedItem('11')
                break
            case 'user-access-control':
                setOpenedItem(['5'])
                setSelectedItem('12')

                break
            case 'policies':
                setOpenedItem(['5'])
                setSelectedItem('121')

                break
            default:
                setSelectedItem('1')
                break
        }
    }, [pathname])

    useEffect(() => {
        getContentSetting()
        storeFrontUrl()
    }, [permissionValue])

    function getCookie(name) {
        const value = `; ${document.cookie}`
        const parts = value.split(`; ${name}=`)
        setCurrentLanguage(parts[1])
    }
    // Function to handle mouse enter event on the sidebar
    const handleMouseEnter = () => {
        setIsHovering(true)
    }

    // Function to handle mouse leave event on the sidebar
    const handleMouseLeave = () => {
        setIsHovering(false)
    }
    const handleMenuClick = (path) => {
        navigate(path)
    }

    useEffect(() => {
        getCookie('dmsmplng')
    }, [])

    return (
        <Layout>
            {/* <div>
                <Affix offsetTop={48}> */}
            <Sider
                trigger={null}
                id='sidebar'
                collapsible
                collapsed={collapsed}
                onCollapse={(value) => setCollapsed(value)}
                width={252}
                onMouseEnter={() => {
                    handleMouseEnter()
                }}
                onMouseLeave={() => {
                    handleMouseLeave()
                }}
                style={{
                    overflow: isHovering ? 'auto' : 'hidden',
                    height: '100vh',
                    backgroundColor: '#0246BB',
                    position: 'fixed',
                    top: 72,
                    left: `${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? null : 0}`,
                    right: `${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 0 : null}`,
                }}>
                <Spin spinning={false} indicator={antIcon} tip='Please Wait...'>
                    <Content className='!h-20  space-y-4 !bg-[var(--mp-sidebar-menu-dark)] !py-2'>
                        {myData && myData.length > 0 ? (
                            <Content>
                                <Content className={` text-white  font-normal mx-7`}>
                                    {collapsed ? (
                                        <Tooltip
                                            title={storeSettingsInfo && storeSettingsInfo[0].store_display_name}
                                            placement={
                                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                    ? 'left'
                                                    : 'right'
                                            }>
                                            <Title
                                                level={5}
                                                className='!text-base !text-[var(--mp-sidebar-menu-not-selected)] '>
                                                {storeSettingsInfo &&
                                                    storeSettingsInfo[0].store_display_name?.slice(0, 2).toUpperCase()}
                                            </Title>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip
                                            title={
                                                storeSettingsInfo &&
                                                storeSettingsInfo.length > 0 &&
                                                storeSettingsInfo[0]?.store_display_name.length > 20
                                                    ? storeSettingsInfo && storeSettingsInfo[0].store_display_name
                                                    : undefined
                                            }
                                            placement={
                                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                    ? 'left'
                                                    : 'right'
                                            }
                                            overlayStyle={{ zIndex: 100, position: 'fixed' }}>
                                            <Title
                                                level={5}
                                                className='!text-[var(--mp-sidebar-menu-not-selected)]    !mb-1 !max-w-[200px]'
                                                ellipsis>
                                                {storeSettingsInfo && storeSettingsInfo[0].store_display_name}
                                            </Title>
                                        </Tooltip>
                                    )}
                                </Content>
                                <Content
                                    className='flex mt-2 cursor-pointer'
                                    onClick={() => window.open(sfUrl, '_blank')}>
                                    {collapsed ? (
                                        <div className='  w-[100%] !flex !items-center !justify-center'>
                                            <Tooltip
                                                title={t('common:view_my_store')}
                                                placement={
                                                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                        ? 'left'
                                                        : 'right'
                                                }>
                                                <img
                                                    src={OpenInNew}
                                                    alt='openInNewIcon'
                                                    width={'20px'}
                                                    height={'20px'}
                                                    // className="!ml-6"
                                                />
                                            </Tooltip>
                                        </div>
                                    ) : (
                                            <div className='!w-[100%]  flex items-center mr-7 my-2'>
                                                <div className='!w-[85%] flex gap-3 items-center'>
                                                    <img
                                                        src={Store}
                                                        alt='StoreIcon'
                                                        width={'20px'}
                                                        height={'20px'}
                                                        className={` ${
                                                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                                ? '!mr-6'
                                                                : '!ml-6'
                                                        }`}
                                                    />
                                                    <Tooltip
                                                        title={t('common:view_my_store')}
                                                        placement={
                                                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                                                                ? 'left'
                                                                : 'right'
                                                        }>
                                                    <Text
                                                        ellipsis
                                                        className='!text-[var(--mp-sidebar-menu-not-selected)] !font-semibold '>
                                                            {t('common:view_my_store')}
                                                        </Text>
                                                    </Tooltip>
                                                </div>
                                                <div>
                                                    <img
                                                        src={OpenInNew}
                                                        alt='openInNewIcon'
                                                        width={'20px'}
                                                        height={'20px'}
                                                    />
                                                </div>
                                            </div>
                                    )}
                                </Content>
                            </Content>
                        ) : null}
                    </Content>
                    <Menu
                        theme={'dark'}
                        mode='inline'
                        className=' !text-base'
                        onOpenChange={(e) => {
                            setOpenedItem(e)
                        }}
                        selectedKeys={selectedItem}
                        openKeys={openedItem}
                        style={{
                            height: 'calc(100vh - 140px)',
                            overflow: isHovering ? 'auto' : 'hidden',
                            backgroundColor: '#0246BB',
                        }}>
                        {myData.map((item) =>
                            item.show_in_menu && item.children ? (
                                <Menu.SubMenu
                                    icon={item.icon}
                                    key={item.key}
                                    className={`${
                                        currentLanguage === 'ar'
                                            ? ' pr-[0px] {styles.ant-menu-submenu-arrow } {styles.ant-menu-title-content} {styles.ant-menu-vertical .ant-menu-submenu-title} !text-base font-semibold '
                                            : '!text-base font-semibold'
                                    }`}
                                    style={{
                                        opacity: item.childrenKeys.includes(selectedItem) ? 1 : 0.8,
                                    }}
                                    title={item.label}>
                                    {item.children.map((child) =>
                                        child.show_in_menu ? (
                                            <Menu.Item
                                                icon={child.icon}
                                                key={child.key}
                                                style={{
                                                    opacity: !item.childrenKeys.includes(selectedItem)
                                                        ? 1
                                                        : selectedItem === child.key
                                                          ? 1
                                                          : 0.8,
                                                }}
                                                onClick={() => {
                                                    handleMenuClick(child.navigate_to)
                                                }}
                                                className={`${
                                                    currentLanguage === 'ar' ? ' {styles.ant-menu-title-content} !text-base !font-semibold' : '!text-base !font-semibold'
                                                }`}>
                                                {selectedItem === child.key ? (
                                                    <span className='font-semibold'>{child.label}</span>
                                                ) : (
                                                    child.label
                                                )}
                                            </Menu.Item>
                                        ) : null
                                    )}
                                </Menu.SubMenu>
                            ) : item.show_in_menu ? (
                                <Menu.Item
                                    icon={item.icon}
                                    key={item.key}
                                    disabled={!item.show_in_menu}
                                    style={{
                                        opacity: selectedItem === item.key ? 1 : 0.7,
                                    }}
                                    onClick={() => {
                                        handleMenuClick(item.navigate_to)
                                    }}
                                    className={`${currentLanguage === 'ar' ? ' {styles.ant-menu-title-content} !text-base font-semibold' : '!text-base font-semibold'}`}>
                                    {selectedItem === item.key ? (
                                        <span className='font-semibold '>{item.label}</span>
                                    ) : (
                                        item.label
                                    )}
                                </Menu.Item>
                            ) : null
                        )}
                    </Menu>
                </Spin>
            </Sider>
            {/* </Affix>
            </div> */}
            <Content
                className={`flex flex-col min-h-screen transition-all ${
                    util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL'
                        ? `${collapsed ? 'mr-[80px]' : 'mr-[253px]'}`
                        : `${collapsed ? 'ml-[80px]' : 'ml-[253px]'}`
                }`}>
                <Content className='!bg-[#F4F4F4] flex-grow mb-2'>
                    <Outlet />
                </Content>
                <Content className='flex-grow-0 !w-[100%]'>
                    <NewFooter />
                </Content>
            </Content>
        </Layout>
    )
}
export default Sidebar
