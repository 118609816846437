import React, { useState, useEffect } from 'react'
import { Layout, Select, Button, Tooltip, Typography, Col, Row, Tabs, Collapse } from 'antd'
import { InboxOutlined, LinkOutlined, SyncOutlined } from '@ant-design/icons'

import { useTranslation } from 'react-i18next'
import util from '../../util/common'
import StoreModal from '../storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { MagnifyIcon } from '../../constants/media'
import SkeletonComponent from '../Skeleton/SkeletonComponent'
import MarketplaceToaster from '../../util/marketplaceToaster'
import {
    createJourneyIcon,
    journeyPlusIcon,
    JourneyRefreshIcon,
    JourneyRefreshIconHover,
    ActiveJourneyButton,
    DisabledJourneyButton,
    HoverJourneyButton,
} from '../../constants/media'

const { Content } = Layout
const { Option } = Select
const { Text } = Typography
const { Panel } = Collapse

const listJourneysAPI = process.env.REACT_APP_LIST_JOURNEYS_API
const canvasIframeURL = process.env.REACT_APP_CANVAS_IFRAME_URL
const productTypeJourneyMappingAPI = process.env.REACT_APP_PRODUCT_TYPE_JOURNEY_MAPPING
const productJourneyMappingAPI = process.env.REACT_APP_PRODUCT_JOURNEY_MAPPING
const createJourneyURL = process.env.REACT_APP_CANVAS_CREATE_JOURNEY_URL
const productTemplateJourneyMappingAPI = process.env.REACT_APP_STORE_PRODUCT_TEMPLATE_JOURNEY_MAPPING_API

const DynamicJourneyComponent = ({ type, id, productTypeId, action }) => {
    console.log('productTypeId------>', productTypeId, 'type----->', type)
    const { t } = useTranslation()

    const journeyEventsForProductTypes = [
        {
            key: 'Physical_Order',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Fulfillment')}`),

            value: 'Physical_Order',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-fullfillment',
        },
        {
            key: 'Physical_Return',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Return')}`),

            value: 'Physical_Return',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-return',
        },
        {
            key: 'Physical_Replacement',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Replacement')}`),

            value: 'Physical_Replacement',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-replacement',
        },
        {
            key: 'Physical_Cancel',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Cancellation')}`),

            value: 'Physical_Cancel',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-customer-cancellation',
        },

        {
            key: 'Subscription_Activation',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Subscription Activation')}`),

            value: 'Subscription_Activation',
            journeyType: 'subscription',
            level: 'order-management',
            packageName: 'subscription-activation',
        },
        {
            key: 'Subscription_Cancellation',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Subscription Cancellation')}`),

            value: 'Subscription_Cancellation',
            journeyType: 'subscription',
            level: 'order-management',
            packageName: 'subscription-cancellation',
        },

        {
            key: 'Digital_Activation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Activation')}`),

            value: 'Digital_Activation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-activation',
        },
        {
            key: 'Digital_Cancellation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Cancellation')}`),

            value: 'Digital_Cancellation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-cancellation',
        },
        {
            key: 'Digital_Content_Creation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Creation')}`),

            value: 'Digital_Content_Creation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-creation',
        },
        {
            key: 'Digital_Content_Update',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Update')}`),

            value: 'Digital_Content_Update',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-updation',
        },
        {
            key: 'Digital_Content_Download',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Download')}`),

            value: 'Digital_Content_Download',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-download',
        },

        {
            key: 'Service_Fulfillment',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Booking fulfillment')}`),

            value: 'Service_Fulfillment',
            journeyType: 'service',
            level: 'order-management',
            packageName: 'service-fulfillment',
        },
        {
            key: 'Service_Cancellation',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Booking Cancellation')}`),

            value: 'Service_Cancellation',
            journeyType: 'service',
            level: 'order-management',
            packageName: 'service-cancellation',
        },
       
        {
            key: 'Packaged_Service_Fulfillment',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Service Package Fulfillment')}`),

            value: 'Packaged_Service_Fulfillment',
            journeyType: 'packaged-service-product',
            level: 'order-management',
            packageName: 'service-package-fulfillment',
        },
        {
            key: 'Packaged_Service_Cancellation',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Service Package Cancellation')}`),

            value: 'Packaged_Service_Cancellation',
            journeyType: 'packaged-service-product',
            level: 'order-management',
            packageName: 'service-package-cancellation',
        },
    ]

    const journeyEventsForProductTemplates = [
        {
            key: 'Physical_Product_Creation',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Physical_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Physical_Product_Update',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Physical_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
        {
            key: 'Subscription_Product_Creation',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Subscription_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Subscription_Product_Update',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Subscription_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
        {
            key: 'Digital_Content_Creation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Creation')}`),

            value: 'Digital_Content_Creation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-creation',
        },
        {
            key: 'Digital_Content_Update',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Update')}`),

            value: 'Digital_Content_Update',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-updation',
        },
        {
            key: 'Digital_Content_Download',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Content Download')}`),

            value: 'Digital_Content_Download',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-content-download',
        },
        {
            key: 'Digital_Product_Creation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Digital_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Digital_Product_Update',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Digital_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
        {
            key: 'Service_Product_Creation',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Service_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Service_Product_Update',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Service_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
        {
            key: 'Bundled_Product_Creation',
            productTypeId: 2,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Bundled_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Bundled_Product_Update',
            productTypeId: 2,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Bundled_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
        {
            key: 'Packaged_Service_Product_Creation',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Creation')}`),

            value: 'Packaged_Service_Product_Creation',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-create',
        },
        {
            key: 'Packaged_Service_Product_Update',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Product Update')}`),

            value: 'Packaged_Service_Product_Update',
            journeyType: '',
            level: 'product-and-services',
            packageName: 'product-update',
        },
    ]

    const journeyEventsForProducts = [
        {
            key: 'Physical_Order',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Fulfillment')}`),

            value: 'Physical_Order',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-fullfillment',
        },
        {
            key: 'Physical_Return',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Return')}`),

            value: 'Physical_Return',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-return',
        },
        {
            key: 'Physical_Replacement',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Replacement')}`),

            value: 'Physical_Replacement',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-replacement',
        },
        {
            key: 'Physical_Cancel',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Order Item Cancellation')}`),

            value: 'Physical_Cancel',
            journeyType: 'physical',
            level: 'order-management',
            packageName: 'physical-customer-cancellation',
        },
        {
            key: 'Physical_Checkout',
            productTypeId: 1,
            label: t(`coupler:${util.getCouplerMultilingualKey('Physical Checkout')}`),

            value: 'Physical_Checkout',
            journeyType: '',
            level: 'order-management',
            packageName: 'physical-checkout',
        },

        {
            key: 'Subscription_Activation',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Subscription Activation')}`),

            value: 'Subscription_Activation',
            journeyType: 'subscription',
            level: 'order-management',
            packageName: 'subscription-activation',
        },
        {
            key: 'Subscription_Cancellation',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Subscription Cancellation')}`),

            value: 'Subscription_Cancellation',
            journeyType: 'subscription',
            level: 'order-management',
            packageName: 'subscription-cancellation',
        },
        {
            key: 'Subscription_Checkout',
            productTypeId: 5,
            label: t(`coupler:${util.getCouplerMultilingualKey('Subscription Checkout')}`),
            value: 'Subscription_Checkout',
            journeyType: '',
            level: 'order-management',
            packageName: 'subscription-checkout',
        },

        {
            key: 'Digital_Activation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Activation')}`),

            value: 'Digital_Activation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-activation',
        },
        {
            key: 'Digital_Cancellation',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Cancellation')}`),

            value: 'Digital_Cancellation',
            journeyType: 'digital',
            level: 'order-management',
            packageName: 'digital-cancellation',
        },
        {
            key: 'Digital_Checkout',
            productTypeId: 4,
            label: t(`coupler:${util.getCouplerMultilingualKey('Digital Checkout')}`),
            value: 'Digital_Checkout',
            journeyType: '',
            level: 'order-management ',
            packageName: 'digital-checkout',
        },

        {
            key: 'Service_Fulfillment',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Booking fulfillment')}`),

            value: 'Service_Fulfillment',
            journeyType: 'service',
            level: 'order-management',
            packageName: 'service-fulfillment',
        },
        {
            key: 'Service_Cancellation',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Booking Cancellation')}`),

            value: 'Service_Cancellation',
            journeyType: 'service',
            level: 'order-management',
            packageName: 'service-cancellation',
        },
        {
            key: 'Service_Checkout',
            productTypeId: 3,
            label: t(`coupler:${util.getCouplerMultilingualKey('Service Checkout')}`),
            value: 'Service_Checkout',
            journeyType: '',
            level: 'order-management ',
            packageName: 'service-checkout',
        },
        {
            key: 'Packaged_Service_Fulfillment',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Service Package Fulfillment')}`),

            value: 'Packaged_Service_Fulfillment',
            journeyType: 'packaged-service-product',
            level: 'order-management',
            packageName: 'service-package-fulfillment',
        },
        {
            key: 'Packaged_Service_Cancellation',
            productTypeId: 7,
            label: t(`coupler:${util.getCouplerMultilingualKey('Service Package Cancellation')}`),

            value: 'Packaged_Service_Cancellation',
            journeyType: 'packaged-service-product',
            level: 'order-management',
            packageName: 'service-package-cancellation',
        },
       
       
    ]

    const [listJourneys, setListJourneys] = useState([])
    const [journeyEvents, setJourneyEvents] = useState([])
    const [selectedJourneyEvent, setSelectedJourneyEvent] = useState()
    const [selectedListJourney, setSelectedListJourney] = useState()
    const [iframeUrl, setIframeUrl] = useState()

    const [mappingId, setMappingId] = useState()
    const [mappedJourneysData, setMappedJourneysData] = useState([])
    const [disableSaveButton, setIsDisableSaveButton] = useState(true)
    const [listJourneyLoading, setListJourneyLoading] = useState(true)
    const [listJourneyNetworkError, setListJourneyNetworkError] = useState(false)
    const [showIframeModal, setShowIframeModal] = useState(false)
    const [refreshIcons, setRefreshIcons] = useState(ActiveJourneyButton)

    let finalCreateJourneyUrl = createJourneyURL
    if (parseInt(productTypeId) === 1) {
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{type}', 'physical')
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{realmName}', util.getClient('lower')?.toLowerCase())
    } else if (parseInt(productTypeId) === 2) {
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{type}', 'bundled')
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{realmName}', util.getClient('lower')?.toLowerCase())
    } else if (parseInt(productTypeId) === 3) {
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{type}', 'service')
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{realmName}', util.getClient('lower')?.toLowerCase())
    } else if (parseInt(productTypeId) === 4) {
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{type}', 'digital')
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{realmName}', util.getClient('lower')?.toLowerCase())
    } else if (parseInt(productTypeId) === 5) {
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{type}', 'subscription')
        finalCreateJourneyUrl = finalCreateJourneyUrl.replace('{realmName}', util.getClient('lower')?.toLowerCase())
    }

    const handleChangeListJourneys = (value, obj) => {
        setSelectedListJourney(value)

        generateIframeUrl(obj.journeyId, obj.journeyFlowName, obj.journeyPackageName)
        if (mappedJourneysData && mappedJourneysData.length > 0) {
            let filterSelectedEventsInMappedJourneysData = mappedJourneysData.filter(
                (ele) => ele.journey_for === selectedJourneyEvent
            )

            if (filterSelectedEventsInMappedJourneysData && filterSelectedEventsInMappedJourneysData.length > 0) {
                let temp = listJourneys.filter(
                    (ele) => ele.path === filterSelectedEventsInMappedJourneysData[0].journey_url
                )
                console.log('temp--->', temp)
                if (temp && temp.length > 0) {
                    if (temp[0].path == value) {
                        setIsDisableSaveButton(true)
                    } else {
                        setIsDisableSaveButton(false)
                    }
                } else {
                    setIsDisableSaveButton(false)
                }
            } else {
                setIsDisableSaveButton(false)
            }
        } else {
            setIsDisableSaveButton(false)
        }
    }

    const generateIframeUrl = (journeyId, journeyFlowName, journeyPackageName) => {
        let canvasURL = canvasIframeURL
        canvasURL = canvasURL.replace('{journeyID}', journeyId)
        canvasURL = canvasURL.replace('{journeyFlowName}', journeyFlowName)
        canvasURL = canvasURL.replace('{journeyPackageName}', journeyPackageName)
        canvasURL = canvasURL.replace('{realmName}', util.getClient('lower')?.toLowerCase())
        setIframeUrl(canvasURL)
    }

    const handleDiscard = () => {
        setIsDisableSaveButton(true)
        if (mappedJourneysData?.length > 0 && listJourneys?.length > 0 && selectedJourneyEvent) {
            let filterAlreadyMappedEvents = mappedJourneysData.filter((ele) => ele.journey_for == selectedJourneyEvent)

            if (filterAlreadyMappedEvents?.length > 0) {
                if (filterAlreadyMappedEvents && filterAlreadyMappedEvents.length > 0) {
                    let filterAlreadyMappedJourney = listJourneys.filter(
                        (ele) => ele.path == filterAlreadyMappedEvents[0].journey_url
                    )

                    if (filterAlreadyMappedJourney && filterAlreadyMappedJourney.length > 0) {
                        setMappingId(filterAlreadyMappedEvents[0].id)
                        setSelectedJourneyEvent(filterAlreadyMappedEvents[0].journey_for)
                        setSelectedListJourney(filterAlreadyMappedEvents[0].journey_url)
                        generateIframeUrl(
                            filterAlreadyMappedJourney[0].id,
                            filterAlreadyMappedJourney[0].journey_flow_name,
                            filterAlreadyMappedJourney[0].journey_package_name
                        )
                    }
                } else {
                    setIframeUrl()
                    setSelectedJourneyEvent()
                    setSelectedListJourney()
                }
            } else {
                setIframeUrl()
                setSelectedListJourney()
                setMappingId()
            }
        } else {
            setIframeUrl()
            setSelectedListJourney()
            setMappingId()
        }
    }

    const getAllJourneys = (level, packageName, journeyType) => {
        let requestBody = {}
        if (level != '') {
            requestBody['level'] = level
        }
        if (packageName != '') {
            requestBody['packageName'] = packageName
        }
        if (journeyType != '') {
            requestBody['journeyType'] = journeyType
        }

        MarketplaceServices.findAllWithoutPage(listJourneysAPI, requestBody, false)
            .then((response) => {
                console.log(
                    'Server Response from getOrderJourneys API ',
                    listJourneysAPI,
                    response?.data?.response_body?.Response
                )
                setListJourneys(response?.data?.response_body?.Response)
                setListJourneyLoading(false)
                setListJourneyNetworkError(false)
            })
            .catch((error) => {
                setListJourneyLoading(false)
                setListJourneyNetworkError(true)
                setListJourneys([])
            })
    }
    const alreadyMappedStoreProductTypesJourney = () => {
        MarketplaceServices.findAll(productTypeJourneyMappingAPI, { 'product-type-id': parseInt(productTypeId) }, false)
            .then(function (response) {
                console.log('alreadyMappedStoreProductTypesJourney---->', response)
                if (
                    response.data.response_body.data.store_product_type_journey_mapping_data &&
                    response.data.response_body.data.store_product_type_journey_mapping_data.length > 0
                ) {
                    setMappedJourneysData(response.data.response_body.data.store_product_type_journey_mapping_data)
                } else {
                    setMappedJourneysData([])
                }
            })
            .catch((error) => {
                setMappedJourneysData([])
                console.log(
                    'Server Error Response alreadyMappedStoreProductTypesJourney',

                    error
                )
            })
    }

    const alreadyMappedProductTemplatesJourney = () => {
        MarketplaceServices.findAll(productTemplateJourneyMappingAPI, { 'product-template-id': parseInt(id) }, false)
            .then(function (response) {
                console.log('alreadyMappedProductTemplatesJourney---->', response)

                if (
                    response.data.response_body.data.product_journey_mapping_data &&
                    response.data.response_body.data.product_journey_mapping_data.length > 0
                ) {
                    setMappedJourneysData(response.data.response_body.data.product_journey_mapping_data)
                } else {
                    setMappedJourneysData([])
                }
            })
            .catch((error) => {
                setMappedJourneysData([])
                console.log(
                    'Server Error Response alreadyMappedProductTemplatesJourney',

                    error
                )
            })
    }

    const alreadyMappedProductsJourney = () => {
        MarketplaceServices.findAll(productJourneyMappingAPI, { 'product-id': parseInt(id) }, false)
            .then(function (response) {
                console.log('alreadyMappedProductsJourney---->', response)

                if (
                    response.data.response_body.data.product_journey_mapping_data &&
                    response.data.response_body.data.product_journey_mapping_data.length > 0
                ) {
                    setMappedJourneysData(response.data.response_body.data.product_journey_mapping_data)
                } else {
                    setMappedJourneysData([])
                }
            })
            .catch((error) => {
                setMappedJourneysData([])
                console.log(
                    'Server Error alreadyMappedProductsJourney',

                    error
                )
            })
    }

    const validationJourneys = () => {
        let count = 2

        if (count == 2) {
            if (type === 'product_type') {
                if (mappingId === undefined) {
                    postCallProductTypeJourneyMapping()
                } else {
                    updateCallProductTypeJourneyMapping()
                }
            } else if (type === 'product_template') {
                if (mappingId === undefined) {
                    postCallProductTemplateJourneyMapping()
                } else {
                    updateCallProductTemplateJourneyMapping()
                }
            } else if (type === 'product') {
                if (mappingId === undefined) {
                    postCallProductJourneyMapping()
                } else {
                    updateCallProductJourneyMapping()
                }
            }
        }
    }

    const postCallProductTypeJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_type_id'] = parseInt(productTypeId)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.save(productTypeJourneyMappingAPI, requestBody, null)
            .then(function (response) {
                console.log(
                    'postCallProductTypeJourneyMapping',
                    productTypeJourneyMappingAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)

                alreadyMappedStoreProductTypesJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log(
                    'Error Server response from saveJourneyMappingOnServer api ',

                    error
                )
                MarketplaceToaster.toast(error.response)

                setIsDisableSaveButton(true)
            })
    }

    const updateCallProductTypeJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_type_id'] = parseInt(productTypeId)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.update(productTypeJourneyMappingAPI, requestBody, {
            _id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Server response from updateCallProductTypeJourneyMapping',
                    productTypeJourneyMappingAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)

                alreadyMappedStoreProductTypesJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                console.log(
                    'ERROR Server response from updateCallProductTypeJourneyMapping',
                    productTypeJourneyMappingAPI,
                    error
                )
                setIsDisableSaveButton(true)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const postCallProductTemplateJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_template'] = parseInt(id)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.save(productTemplateJourneyMappingAPI, requestBody, null)
            .then(function (response) {
                console.log(
                    'postCallProductTemplateJourneyMapping',
                    productTemplateJourneyMappingAPI,
                    response.data.response_body
                )
                MarketplaceToaster.showToast(response)

                alreadyMappedProductTemplatesJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log(
                    'Error Server response from saveJourneyMappingOnServer api ',

                    error
                )
                MarketplaceToaster.toast(error.response)

                setIsDisableSaveButton(true)
            })
    }

    const updateCallProductTemplateJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_template'] = parseInt(id)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.update(productTemplateJourneyMappingAPI, requestBody, {
            _id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Server response from productTemplateJourneyMappingAPI',
                    productTypeJourneyMappingAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)

                alreadyMappedProductTemplatesJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                console.log(
                    'ERROR Server response from productTemplateJourneyMappingAPI',
                    productTemplateJourneyMappingAPI,
                    error
                )
                setIsDisableSaveButton(true)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const postCallProductJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_id'] = parseInt(id)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.save(productJourneyMappingAPI, requestBody, null)
            .then(function (response) {
                console.log('productJourneyMappingAPI', productJourneyMappingAPI, response.data.response_body)
                MarketplaceToaster.showToast(response)

                alreadyMappedProductsJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log(
                    'Error Server response from productJourneyMappingAPI api ',

                    error
                )
                MarketplaceToaster.toast(error.response)

                setIsDisableSaveButton(true)
            })
    }

    const updateCallProductJourneyMapping = () => {
        let requestBody = {}
        requestBody['product_id'] = parseInt(id)
        requestBody['journey_url'] = selectedListJourney
        requestBody['journey_for'] = selectedJourneyEvent
        MarketplaceServices.update(productJourneyMappingAPI, requestBody, {
            _id: mappingId,
        })
            .then(function (response) {
                console.log(
                    'Server response from productTemplateJourneyMappingAPI',
                    productTypeJourneyMappingAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)

                alreadyMappedProductsJourney()
                setIsDisableSaveButton(true)
            })
            .catch((error) => {
                console.log(
                    'ERROR Server response from productTemplateJourneyMappingAPI',
                    productTemplateJourneyMappingAPI,
                    error
                )
                setIsDisableSaveButton(true)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const handleRefreshJourney = () => {
        setIsDisableSaveButton(true)
        MarketplaceToaster.showToast(util.getToastObject(`${t('product_types:refresh_journey')}`, 'success'))
        setListJourneyLoading(true)
        let filterParticularEvents = journeyEvents?.filter((ele) => ele.value == selectedJourneyEvent)
        if (filterParticularEvents?.length > 0) {
            getAllJourneys(
                filterParticularEvents[0].level,
                filterParticularEvents[0].packageName,
                filterParticularEvents[0].journeyType
            )
        } else {
            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }

    const onTabChange = (value) => {
        setIsDisableSaveButton(true)
        setSelectedJourneyEvent(value)
        setListJourneyLoading(true)
        let filterParticularEvents = journeyEvents?.filter((ele) => ele.value == value)
        if (filterParticularEvents?.length > 0) {
            getAllJourneys(
                filterParticularEvents[0].level,
                filterParticularEvents[0].packageName,
                filterParticularEvents[0].journeyType
            )
        } else {
            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }
    const handleMouseEnter = () => setRefreshIcons(HoverJourneyButton)
    const handleMouseLeave = () => setRefreshIcons(ActiveJourneyButton)

    useEffect(() => {
        if (productTypeId != undefined && type != undefined) {
            setSelectedJourneyEvent()
            setSelectedListJourney()
            setIsDisableSaveButton(true)
            if (type === 'product_type') {
                alreadyMappedStoreProductTypesJourney()
                let temp = journeyEventsForProductTypes.filter((ele) => ele.productTypeId === parseInt(productTypeId))
                if (temp && temp.length > 0) {
                    setJourneyEvents(temp)
                } else {
                    setJourneyEvents([])
                }
            } else if (type === 'product_template') {
                alreadyMappedProductTemplatesJourney()
                let temp = journeyEventsForProductTemplates.filter(
                    (ele) => ele.productTypeId === parseInt(productTypeId)
                )
                if (temp && temp.length > 0) {
                    setJourneyEvents(temp)
                } else {
                    setJourneyEvents([])
                }
            } else if (type === 'product') {
                alreadyMappedProductsJourney()
                let temp = journeyEventsForProducts.filter((ele) => ele.productTypeId === parseInt(productTypeId))
                if (temp && temp.length > 0) {
                    setJourneyEvents(temp)
                } else {
                    setJourneyEvents([])
                }
            }
        }
    }, [productTypeId, type, id])

    useEffect(() => {
        if (journeyEvents?.length > 0) {
            setSelectedJourneyEvent(journeyEvents[0].value)

            getAllJourneys(journeyEvents[0].level, journeyEvents[0].packageName, journeyEvents[0].journeyType)
        }
    }, [journeyEvents])

    useEffect(() => {
        if (mappedJourneysData?.length > 0 && listJourneys?.length > 0 && selectedJourneyEvent) {
            let filterAlreadyMappedEvents = mappedJourneysData.filter((ele) => ele.journey_for == selectedJourneyEvent)
            console.log('filterAlreadyMappedEvents-------->', filterAlreadyMappedEvents)

            if (filterAlreadyMappedEvents?.length > 0) {
                if (filterAlreadyMappedEvents && filterAlreadyMappedEvents.length > 0) {
                    let filterAlreadyMappedJourney = listJourneys.filter(
                        (ele) => ele.path == filterAlreadyMappedEvents[0].journey_url
                    )

                    if (filterAlreadyMappedJourney && filterAlreadyMappedJourney.length > 0) {
                        setMappingId(filterAlreadyMappedEvents[0].id)
                        setSelectedJourneyEvent(filterAlreadyMappedEvents[0].journey_for)
                        setSelectedListJourney(filterAlreadyMappedEvents[0].journey_url)
                        generateIframeUrl(
                            filterAlreadyMappedJourney[0].id,
                            filterAlreadyMappedJourney[0].journey_flow_name,
                            filterAlreadyMappedJourney[0].journey_package_name
                        )
                    }
                } else {
                    setIframeUrl()
                    setSelectedJourneyEvent()
                    setSelectedListJourney()
                }
            } else {
                setIframeUrl()
                setSelectedListJourney()
                setMappingId()
            }
        } else {
            setIframeUrl()
            setSelectedListJourney()
            setMappingId()
        }
    }, [mappedJourneysData, listJourneys, selectedJourneyEvent])

    return (
        <>
            {(productTypeId == 2 && type == 'product_type') || (productTypeId == 2 && type == 'product') ? null : (
                <Collapse accordion bordered={true} className='!bg-white mb-3 !pt-4'>
                    <Panel
                        header={
                            <div className='flex flex-row justify-between pb-3'>
                                <p className='!text-regal-blue font-semibold text-lg'>
                                    {t('product_types:product_type_journey_label')}
                                </p>
                                {action !== 'read' ? (
                                    <div
                                        className={`flex flex-row w-max border-[1px] border-[#D9D9D9]  rounded-md py-2 cursor-pointer hover:border-[#fb8500]  ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!pr-5' : '!pl-5'}`}
                                        onClick={() => window.open(finalCreateJourneyUrl, '_blank')}>
                                        <img src={createJourneyIcon} width={14} height={14} />
                                        <p
                                            className={`font-medium text-sm text-regal-blue  mx-2`}>
                                            {t('product_types:create_new_journey')}
                                        </p>
                                    </div>
                                ) : null}
                            </div>
                        }>
                        <section className='bg-white py-1 pr-4 !rounded-md'>
                            <div className='pt-4 flex flex-row'>
                                <Tabs
                                    activeKey={selectedJourneyEvent ? selectedJourneyEvent : journeyEvents?.[0]?.value}
                                    tabPosition={
                                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'right' : 'left'
                                    }
                                    items={journeyEvents}
                                    onTabClick={onTabChange}
                                    className='noVerticalLine'
                                />
                                {listJourneyLoading ? (
                                    <Content className=' bg-white p-3'>
                                        <SkeletonComponent />
                                    </Content>
                                ) : listJourneyNetworkError ? (
                                    <Content className='text-center p-3 !bg-white'>
                                        <h1 level={5}>{t('common:network_error')}</h1>
                                    </Content>
                                ) : (
                                    <div className='w-full'>
                                        <p className='text-sm font-medium text-[#8899A8] inline-block'>
                                            {t('product_types:journey_label_name')}
                                        </p>
                                        <span className={'mandatory-symbol-color text-sm text-center ml-1'}>*</span>
                                        <div className='flex flex-row w-[70%] mt-2'>
                                            <Select
                                                disabled={action !== 'read' ? false : true}
                                                placeholder={t('product_types:journey_not_associated')}
                                                dropdownStyle={{ zIndex: 1 }}
                                                value={selectedListJourney}
                                                onChange={handleChangeListJourneys}
                                                className='!w-[400px] mr-4'>
                                                {listJourneys &&
                                                    listJourneys.length > 0 &&
                                                    listJourneys.map((item) => (
                                                        <Option
                                                            key={item.id}
                                                            value={item.path}
                                                            journeyId={item.id}
                                                            journeyPackageName={item.journey_package_name}
                                                            journeyFlowName={item.journey_flow_name}
                                                            flowInfo={item.flow_info}
                                                            journeyUrl={item.url}>
                                                            {t(
                                                                `coupler:${util.getCouplerMultilingualKey(item.journey_flow_name)}`
                                                            )}
                                                        </Option>
                                                    ))}
                                            </Select>
                                            {action !== 'read' ? (
                                                <Tooltip title={t('dashboard:refresh')}>
                                                    <div
                                                        className='cursor-pointer'
                                                        onClick={() => {
                                                            handleRefreshJourney()
                                                        }}
                                                        onMouseEnter={handleMouseEnter}
                                                        onMouseLeave={handleMouseLeave}>
                                                        <img src={refreshIcons} />
                                                    </div>
                                                </Tooltip>
                                            ) : null}
                                        </div>

                                        <div className='mt-5'>
                                            {iframeUrl ? (
                                                <Content className='h-[300px] overflow-auto relative'>
                                                    <iframe
                                                        id='canvasIframe'
                                                        src={iframeUrl}
                                                        title={t('product_types:journey_canvas')}
                                                        width='100%'
                                                        height='627'
                                                        loading='eager'
                                                    />
                                                    <div className='  absolute top-2  right-2 '>
                                                        <div
                                                            className=' h-8 w-8 rounded-full border-[1px] border-[#8899A8] flex justify-center items-center bg-white cursor-pointer '
                                                            onClick={() => setShowIframeModal(true)}>
                                                            <img src={journeyPlusIcon} width={14} height={14} />
                                                        </div>
                                                    </div>
                                                </Content>
                                            ) : (
                                                <Content className='bg-slate-100 p-10 text-center my-2'>
                                                    <Content>
                                                        <InboxOutlined className='my-2 text-2xl' />
                                                    </Content>
                                                    <h1>{t('product_types:journey_not_yet_mapped')}</h1>
                                                </Content>
                                            )}
                                        </div>
                                        {action !== 'read' && disableSaveButton == false ? (
                                            <div className='pt-4'>
                                                <Button
                                                    disabled={disableSaveButton}
                                                    className='app-btn-primary'
                                                    onClick={() => validationJourneys()}>
                                                    {mappingId?t('common:update'):t('common:save')}
                                                </Button>
                                                <Button
                                                    disabled={disableSaveButton}
                                                    className='app-btn-secondary ml-3'
                                                    onClick={() => handleDiscard()}>
                                                    {t('common:cancel')}
                                                </Button>
                                            </div>
                                        ) : null}
                                    </div>
                                )}
                                <StoreModal
                                    isVisible={showIframeModal}
                                    width={1200}
                                    //okCallback={() => handleSaveImage()}
                                    cancelCallback={() => setShowIframeModal(false)}
                                    okButtonText={null}
                                    cancelButtonText={null}
                                    title={''}
                                    isSpin={false}>
                                    <iframe
                                        id='canvasIframe'
                                        src={iframeUrl}
                                        title={t('product_types:journey_canvas')}
                                        width='100%'
                                        height='627'
                                        loading='eager'
                                        className='mt-9'
                                    />
                                </StoreModal>
                            </div>
                        </section>
                    </Panel>
                </Collapse>
            )}
        </>
    )
}

export default DynamicJourneyComponent
