import { Button, Col, Divider, Input, Layout, Progress, Row, Select, Skeleton, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'

import util from '../../util/common'
import { deepCopy } from '../../util/util'

import StoreModal from '../../components/storeModal/StoreModal'
import { fnAttributeDetailsTranslated, fnAttributeDetailsUpdated } from '../../services/redux/actions/ActionsAPICall'

//! Destructure the components

const { Content } = Layout
const { Title } = Typography

const attributeDisplayNameAPI = process.env.REACT_APP_ATTRIBUTE_DISPLAYNAME_API
const attributeOptionDisplayNameAPI = process.env.REACT_APP_ATTRIBUTE_OPTION_DISPLAYNAME_API

// ! input fields properties
const titleMaxLength = process.env.REACT_APP_TITLE_MAX_LENGTH
const attributeOptionMaxCharacterLength = process.env.REACT_APP_ATTRIBUTE_OPTION_MAX_CHARACTER_LENGTH

const AttributeTranslatorModal = ({
    dataJsonForAttributeName,
    dataJsonForAttributeOption,
    setTranslateModalVisible,
    isCore,
    onChangeDisableFields,
    setOnChangeDisableFields,
    setIsMultilingualCallSuccess,
}) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()
    const displayNameDataJsonForAttributeName =
        dataJsonForAttributeName &&
        dataJsonForAttributeName.length > 0 &&
        dataJsonForAttributeName.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))

    const displayNameDataJsonForAttributeOption =
        dataJsonForAttributeOption &&
        dataJsonForAttributeOption.length > 0 &&
        dataJsonForAttributeOption.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
    const apiCallFor =
        displayNameDataJsonForAttributeName &&
        displayNameDataJsonForAttributeName.length > 0 &&
        displayNameDataJsonForAttributeName[0].value &&
        displayNameDataJsonForAttributeName[0].value.length > 0
            ? 'attributes'
            : 'attributeoption'
    const [loadingSkelton, setLoadingSkelton] = useState(false)
    const [storeLanguages, setStoreLanguages] = useState()
    const [defaultLanguageName, setDefaultLanguageName] = useState()
    const [isLoadingStoreLanguage, setIsLoadingStoreLanguage] = useState(false)
    const [selectedLanguageFromDropDown, setSelectedLanguageFromDropDown] = useState()
    const [allLanguages, setAllLanguages] = useState()
    const [isInputFieldsValidationModalOpen, setIsInputFieldsValidationModalOpen] = useState(false)
    const [commonDataContainingAllObject, setCommonDataContainingAllObject] = useState()
    const [displayNameApiData, setDisplayNameApiData] = useState([])
    const [languagesWithInvalidData, setLanguagesWithInvalidData] = useState()
    const [dataForAttributesDetailsUpdate, setDataForAttributesDetailsUpdate] = useState()
    const [dataForOptionDetailsUpdate, setDataForOptionDetailsUpdate] = useState(deepCopy())
    const [invalidName, setInvalidName] = useState(false)
    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    const [alreadyTranslatedCount, setAlreadyTranslatedCount] = useState(0)
    const getStoreLanguageData = () => {
        const StoreLanguageResponse = storeLanguageFromReduxState

        setStoreLanguages(StoreLanguageResponse)
        const defaultLanguageCode =
            StoreLanguageResponse &&
            StoreLanguageResponse.length > 0 &&
            StoreLanguageResponse.filter((element) => element.is_default)[0].language_code

        displayNameDataJsonForAttributeName &&
            displayNameDataJsonForAttributeName.length > 0 &&
            displayNameDataJsonForAttributeName[0].value &&
            displayNameDataJsonForAttributeName[0].value.length > 0 &&
            getAttributesDisplayNameData(StoreLanguageResponse)

        displayNameDataJsonForAttributeOption &&
            displayNameDataJsonForAttributeOption.length > 0 &&
            displayNameDataJsonForAttributeOption[0].value &&
            displayNameDataJsonForAttributeOption[0].value.length > 0 &&
            getAttributesOptionsDisplayNameData(StoreLanguageResponse)

        setDefaultLanguageName(
            StoreLanguageResponse &&
                StoreLanguageResponse.length > 0 &&
                StoreLanguageResponse.filter((element) => element.is_default)
        )

        setAllLanguages(StoreLanguageResponse)
        setSelectedLanguageFromDropDown(defaultLanguageCode)
        setIsLoadingStoreLanguage(false)
    }

    const getAttributesDisplayNameData = (StoreLanguageResponse) => {
        MarketplaceServices.findAll(
            attributeDisplayNameAPI,
            {
                'attribute-id': parseInt(
                    displayNameDataJsonForAttributeName && displayNameDataJsonForAttributeName[0].value[0].attributeId
                ),
            },
            false
        )

            .then(function (response) {
                console.log('edit selected get displayname response : ', response.data)
                if (response.data) {
                    var tempArray
                    var displayNameData

                    tempArray = deepCopy(response.data.response_body.attribute_displaynames)
                    setDisplayNameApiData(tempArray)
                    displayNameData = response.data.response_body.attribute_displaynames
                    var finalObjectWithAllData = []
                    setCommonDataContainingAllObject(displayNameData)
                    for (let i = 0; i < StoreLanguageResponse.length; i++) {
                        let storeLanguageDataIsAvailableInDisplayNameData =
                            displayNameData &&
                            displayNameData.length > 0 &&
                            displayNameData.filter(
                                (ele) => ele.language_code === StoreLanguageResponse[i].language_code
                            )

                        if (
                            storeLanguageDataIsAvailableInDisplayNameData &&
                            storeLanguageDataIsAvailableInDisplayNameData.length > 0
                        ) {
                        } else {
                            finalObjectWithAllData.push({
                                attribute: parseInt(
                                    displayNameDataJsonForAttributeName &&
                                        displayNameDataJsonForAttributeName[0].value[0].attributeId
                                ),
                                language_code: StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                display_name: '',
                            })
                        }
                    }
                }
                setCommonDataContainingAllObject([...displayNameData, ...finalObjectWithAllData])
                setAlreadyTranslatedCount(response?.data?.response_body?.count)
                setLoadingSkelton(false)
            })
            .catch(function (error) {
                if (error && error.response && error.response.status === 401) {
                }
                console.log('edit selected get displayname error response', error.response)
                setLoadingSkelton(false)
            })
    }

    const getAttributesOptionsDisplayNameData = (StoreLanguageResponse) => {
        MarketplaceServices.findAll(
            attributeOptionDisplayNameAPI,
            {
                'attribute-option-id': parseInt(
                    displayNameDataJsonForAttributeOption &&
                        displayNameDataJsonForAttributeOption[0].value[0].attributeOptionId
                ),
            },
            false
        )
            .then(function (response) {
                console.log('edit selected get option displayname response : ', response.data)
                if (response.data) {
                    var tempArray
                    var displayNameData

                    tempArray = deepCopy(response.data.response_body.attribute_displaynames)
                    setDisplayNameApiData(tempArray)
                    displayNameData = response.data.response_body.attribute_displaynames
                    var finalObjectWithAllData = []
                    setCommonDataContainingAllObject(displayNameData)
                    for (let i = 0; i < StoreLanguageResponse.length; i++) {
                        let storeLanguageDataIsAvailableInDisplayNameData =
                            displayNameData &&
                            displayNameData.length > 0 &&
                            displayNameData.filter(
                                (ele) => ele.language_code === StoreLanguageResponse[i].language_code
                            )

                        if (
                            storeLanguageDataIsAvailableInDisplayNameData &&
                            storeLanguageDataIsAvailableInDisplayNameData.length > 0
                        ) {
                        } else {
                            finalObjectWithAllData.push({
                                attribute_option: parseInt(
                                    displayNameDataJsonForAttributeOption &&
                                        displayNameDataJsonForAttributeOption[0].value[0].attributeOptionId
                                ),
                                language_code: StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                display_name: '',
                                description: 'default description',
                            })
                        }
                    }
                }

                setCommonDataContainingAllObject([...displayNameData, ...finalObjectWithAllData])
                setAlreadyTranslatedCount(response?.data?.response_body?.count)
                setLoadingSkelton(false)
            })
            .catch(function (error) {
                if (error && error.response && error.response.status === 401) {
                }
                console.log('edit selected get displayname error response', error.response)
                setLoadingSkelton(false)
            })
    }
    //! doing post call for category display name
    const postMultilingualDetails = (
        postBody,
        putCallWillHappen,
        finalDataForPutCall,
        changedDataForPutCall,
        callWithDefaultDetails
    ) => {
        let postDisplayNameApiEndPoint
        let finalPostBody
        switch (apiCallFor) {
            case 'attributeoption':
                postDisplayNameApiEndPoint = attributeOptionDisplayNameAPI
                finalPostBody = { option_name: postBody }

                break
            case 'attributes':
                postDisplayNameApiEndPoint = attributeDisplayNameAPI
                finalPostBody = { attribute_languages: postBody }
                break
            default:
                return null
        }
        MarketplaceServices.save(postDisplayNameApiEndPoint, finalPostBody, null)
            .then((response) => {
                console.log('add category postMultilingualDetails', response.data)
                if (!isCore) {
                    setIsMultilingualCallSuccess(true)
                    dispatch(fnAttributeDetailsTranslated(true))
                } else {
                    dispatch(fnAttributeDetailsUpdated(true))
                }

                if (putCallWillHappen) {
                    if (callWithDefaultDetails) {
                        if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                            putMultilingualDetails(finalDataForPutCall, 200)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                        putMultilingualDetails(changedDataForPutCall, 200)
                        setIsLoadingStoreLanguage(true)
                    }
                } else {
                    MarketplaceToaster.showToast(response)
                    setIsLoadingStoreLanguage(false)
                    setTranslateModalVisible(false)
                }
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    setIsLoadingStoreLanguage(false)
                }
                if (putCallWillHappen) {
                    if (callWithDefaultDetails) {
                        if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                            putMultilingualDetails(finalDataForPutCall, 400)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                        putMultilingualDetails(changedDataForPutCall, 400)
                        setIsLoadingStoreLanguage(true)
                    }
                } else {
                    MarketplaceToaster.showToast(error.response)
                    setIsLoadingStoreLanguage(false)
                    setTranslateModalVisible(false)
                }

                if (error.response) {
                    console.log('error in DisplayName Post call', error.response.data.message)
                }
            })
    }

    //! doing put call for  display name
    const putMultilingualDetails = (changedDataForPutCall, postApiStatus) => {
        let putDisplayNameApiEndPoint
        let finalPutBody
        let putParams
        switch (apiCallFor) {
            case 'attributeoption':
                putDisplayNameApiEndPoint = attributeOptionDisplayNameAPI
                finalPutBody = { option_name: changedDataForPutCall }
                putParams = {
                    'option-id': parseInt(
                        displayNameDataJsonForAttributeOption &&
                            displayNameDataJsonForAttributeOption[0].value[0].attributeOptionId
                    ),
                }

                break
            case 'attributes':
                putDisplayNameApiEndPoint = attributeDisplayNameAPI
                finalPutBody = { attribute_languages: changedDataForPutCall }
                putParams = {
                    'attribute-id': parseInt(
                        displayNameDataJsonForAttributeName &&
                            displayNameDataJsonForAttributeName[0].value[0].attributeId
                    ),
                }
                break
            default:
                return null
        }
        MarketplaceServices.update(putDisplayNameApiEndPoint, finalPutBody, putParams)
            .then((response) => {
                console.log('put category postMultilingualDetails', response.data)
                if (!isCore) {
                    setIsMultilingualCallSuccess(true)
                    dispatch(fnAttributeDetailsTranslated(true))
                } else {
                    dispatch(fnAttributeDetailsUpdated(true))
                }
                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(response)
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                }
                setIsLoadingStoreLanguage(false)
                setTranslateModalVisible(false)
            })
            .catch((error) => {
                if (error && error.response && error.response.status === 401) {
                    setIsLoadingStoreLanguage(false)
                }
                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                } else {
                    MarketplaceToaster.showToast(error.response)
                }
            })
    }
    const validationOfDataForPutCall = (tempArrDataWithDisplayNameId) => {
        var finalPutBody = []
        for (let i = 0; i < displayNameApiData.length; i++) {
            const id = displayNameApiData[i].id
            const localData =
                tempArrDataWithDisplayNameId &&
                tempArrDataWithDisplayNameId.find((element) => parseInt(element.id) === parseInt(id))
            let isChanged = false
            let obj = {}
            obj['id'] = displayNameApiData[i].id
            obj['language_code'] = displayNameApiData[i].language_code
            if (displayNameApiData[i].display_name !== localData.display_name) {
                obj['display_name'] = localData.display_name
                obj['description'] = localData.description
                isChanged = true
            }
            if (displayNameApiData[i].description !== localData.description) {
                obj['display_name'] = localData.display_name
                obj['description'] = localData.description
                isChanged = true
            }
            if (isChanged) {
                finalPutBody.push(obj)
            }
        }
        return finalPutBody
    }
    const InputValuesValidationFromAllLanguages = (
        commonDataContainingAllObject,
        callWithDefaultDetails,
        finalDataForPostCall,
        finalDataForPutCall
    ) => {
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        const tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        var postCallWillHappen =
            ((finalDataForPostCall && finalDataForPostCall.length > 0) ||
                (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0)) === true
                ? true
                : false

        var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)

        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            var putCallWillHappen =
                ((changedDataForPutCall && changedDataForPutCall.length > 0) ||
                    (finalDataForPutCall && finalDataForPutCall.length > 0)) === true
                    ? true
                    : false

            if (callWithDefaultDetails) {
                postMultilingualDetails(
                    finalDataForPostCall,
                    putCallWillHappen,
                    finalDataForPutCall,
                    changedDataForPutCall,
                    callWithDefaultDetails
                )
                setIsLoadingStoreLanguage(true)
            } else {
                postMultilingualDetails(
                    tempArrDataWithCategoryId,
                    putCallWillHappen,
                    finalDataForPutCall,
                    changedDataForPutCall,
                    callWithDefaultDetails
                )
                setIsLoadingStoreLanguage(true)
            }
        }
        if (!postCallWillHappen) {
            if (callWithDefaultDetails) {
                if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                    putMultilingualDetails(finalDataForPutCall, 200)
                    setIsLoadingStoreLanguage(true)
                }
            } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                putMultilingualDetails(changedDataForPutCall, 200)
                setIsLoadingStoreLanguage(true)
            }
        }
        var checkPrevOptionName =
            displayNameDataJsonForAttributeOption &&
            displayNameDataJsonForAttributeOption.length > 0 &&
            displayNameDataJsonForAttributeOption[0].value &&
            displayNameDataJsonForAttributeOption[0].value.length > 0 &&
            displayNameDataJsonForAttributeOption[0].value[0].attributeOptionName

        var latestOptionName =
            dataForOptionDetailsUpdate &&
            dataForOptionDetailsUpdate.length > 0 &&
            dataForOptionDetailsUpdate[0].value &&
            dataForOptionDetailsUpdate[0].value.length > 0 &&
            dataForOptionDetailsUpdate[0].value[0].attributeOptionName

        var checkPrevAttrName =
            displayNameDataJsonForAttributeName &&
            displayNameDataJsonForAttributeName.length > 0 &&
            displayNameDataJsonForAttributeName[0].value &&
            displayNameDataJsonForAttributeName[0].value.length > 0 &&
            displayNameDataJsonForAttributeName[0].value[0].name

        var latestAttrName =
            dataForAttributesDetailsUpdate &&
            dataForAttributesDetailsUpdate.length > 0 &&
            dataForAttributesDetailsUpdate[0].value &&
            dataForAttributesDetailsUpdate[0].value.length > 0 &&
            dataForAttributesDetailsUpdate[0].value[0].name

        if (apiCallFor === 'attributeoption') {
            if (checkPrevOptionName !== latestOptionName) {
                //! (commented for left disable fix)
            }
        } else if (apiCallFor === 'attributes') {
            if (checkPrevAttrName !== latestAttrName) {
                //! (commented for left disable fix)
            }
        }
        if (
            (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) ||
            (changedDataForPutCall && changedDataForPutCall.length > 0)
        ) {
        } else {
            //! Now check for Default Details Changes
            if (checkPrevAttrName !== latestAttrName) {
            } else if (checkPrevOptionName !== latestOptionName) {
            } else {
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
        }
    }

    const inputFieldsValidationForApiCalls = (tempArrDataWithDisplayNameId, tempArrDataWithCategoryId) => {
        var temp = []
        tempArrDataWithDisplayNameId &&
            tempArrDataWithDisplayNameId.length > 0 &&
            tempArrDataWithDisplayNameId.forEach((ele) => {
                if (ele.display_name === '' || ele.description === '') {
                    temp.push(ele.language_code)
                }
            })

        tempArrDataWithCategoryId &&
            tempArrDataWithCategoryId.length > 0 &&
            tempArrDataWithCategoryId.forEach((ele) => {
                if (ele.display_name === '' || ele.description === '') {
                    temp.push(ele.language_code)
                }
            })
        setLanguagesWithInvalidData(temp)
        return temp
    }

    const handleLanguageChange = (val) => {
        setSelectedLanguageFromDropDown(val)
    }
    const handleInputFieldsValidationModalClose = () => {
        setIsInputFieldsValidationModalOpen(false)
    }
    const handleTranlaterDiscard = () => {
        setTranslateModalVisible(false)
    }
    const handleAttributeNameChangeFromAllLanguages = (val, byDefault) => {
        setInvalidName(false)
        var temp = deepCopy(commonDataContainingAllObject)
        if (byDefault) {
            let objIndex = temp.findIndex((obj) => obj.language_code === defaultLanguageName[0].language_code)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        } else {
            let objIndex = temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        }
    }

    const handleAttributeOptionChangeFromAllLanguages = (val, byDefault) => {
        setInvalidName(false)

        var temp = deepCopy(commonDataContainingAllObject)
        if (byDefault) {
            let objIndex = temp.findIndex((obj) => obj.language_code === defaultLanguageName[0].language_code)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        } else {
            let objIndex = temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        }
    }
    useEffect(() => {
        setDataForAttributesDetailsUpdate(
            dataJsonForAttributeName &&
                dataJsonForAttributeName.length > 0 &&
                dataJsonForAttributeName.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
        )
        setDataForOptionDetailsUpdate(
            dataJsonForAttributeOption &&
                dataJsonForAttributeOption.length > 0 &&
                dataJsonForAttributeOption.sort((a, b) => parseFloat(a.order) - parseFloat(b.order))
        )
        if (onChangeDisableFields) {
            setLoadingSkelton(true)
            setCommonDataContainingAllObject([])
            getStoreLanguageData()
        }
    }, [displayNameDataJsonForAttributeName, displayNameDataJsonForAttributeOption])
    const handleInputFieldsValidationModalSave = () => {
        setIsInputFieldsValidationModalOpen(false)
        var callWithDefaultDetails = true
        const tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        var finalDataForPostCall = deepCopy(tempArrDataWithCategoryId)
        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = finalDataForPostCall.findIndex((obj) => obj.language_code === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (finalDataForPostCall && finalDataForPostCall[objIndex].description === '') {
                            finalDataForPostCall[objIndex].description =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].description
                        }
                        if (finalDataForPostCall && finalDataForPostCall[objIndex].display_name === '') {
                            finalDataForPostCall[objIndex].display_name =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].display_name
                        }
                    }
                })
        }
        var finalDataForPutCall
        if (tempArrDataWithDisplayNameId && tempArrDataWithDisplayNameId.length > 0) {
            var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)
            finalDataForPutCall = deepCopy(changedDataForPutCall)
        }
        var dataWithDefaultLanguge =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code === defaultLanguageName[0].language_code)
        var dataWithoutDefaultLanguge =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code !== defaultLanguageName[0].language_code)

        if (dataWithoutDefaultLanguge && dataWithoutDefaultLanguge.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = dataWithoutDefaultLanguge.findIndex((obj) => String(obj.language_code) === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (dataWithoutDefaultLanguge[objIndex].description === '') {
                            dataWithoutDefaultLanguge[objIndex].description =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].description
                        }
                        if (dataWithoutDefaultLanguge[objIndex].display_name === '') {
                            dataWithoutDefaultLanguge[objIndex].display_name =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].display_name
                        }
                    }
                })
        }

        if (
            dataWithoutDefaultLanguge &&
            dataWithoutDefaultLanguge.length > 0 &&
            dataWithDefaultLanguge &&
            dataWithDefaultLanguge.length > 0
        ) {
            finalDataForPutCall = [...dataWithoutDefaultLanguge, ...dataWithDefaultLanguge]
        } else if (dataWithoutDefaultLanguge && dataWithoutDefaultLanguge.length > 0) {
            finalDataForPutCall = [...dataWithoutDefaultLanguge]
        } else if (dataWithDefaultLanguge && dataWithDefaultLanguge.length > 0) {
            finalDataForPutCall = [...dataWithDefaultLanguge]
        }
        InputValuesValidationFromAllLanguages(
            commonDataContainingAllObject,
            callWithDefaultDetails,
            finalDataForPostCall,
            finalDataForPutCall
        )
    }
    const handleSaveButton = () => {
        //!  checking commonObject is created or not
        if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
            //! checking weather default language is empty or not
            const defaultLanguageDisplayName =
                commonDataContainingAllObject &&
                commonDataContainingAllObject.length > 0 &&
                commonDataContainingAllObject.filter(
                    (value) => value.language_code === defaultLanguageName[0].language_code
                )[0].display_name

            const defaultLanguageDescription =
                commonDataContainingAllObject &&
                commonDataContainingAllObject.length > 0 &&
                commonDataContainingAllObject.filter(
                    (value) => value.language_code === defaultLanguageName[0].language_code
                )[0].description
            if (defaultLanguageDisplayName !== '' && defaultLanguageDescription !== '') {
                const tempArrDataWithDisplayNameId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return o.hasOwnProperty('id')
                    })

                const tempArrDataWithCategoryId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return !o.hasOwnProperty('id')
                    })
                var resultForinputFieldsValidationForApiCalls = inputFieldsValidationForApiCalls(
                    tempArrDataWithDisplayNameId,
                    tempArrDataWithCategoryId
                )
                if (resultForinputFieldsValidationForApiCalls && resultForinputFieldsValidationForApiCalls.length > 0) {
                    setIsInputFieldsValidationModalOpen(true)
                } else {
                    let callWithDefaultDetails = false
                    InputValuesValidationFromAllLanguages(commonDataContainingAllObject, callWithDefaultDetails)
                }
            } else {
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('languages:provide_details_for_default_language')}`, 'error')
                )
                setInvalidName(true)
                setSelectedLanguageFromDropDown(
                    defaultLanguageName && defaultLanguageName.length > 0 && defaultLanguageName[0].language_code
                )
                if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
                    const getTheInfoOfDefaultLang =
                        commonDataContainingAllObject &&
                        commonDataContainingAllObject.length > 0 &&
                        commonDataContainingAllObject.filter(
                            (ele) => ele.language_code === defaultLanguageName[0].language_code
                        )

                    if (
                        getTheInfoOfDefaultLang &&
                        getTheInfoOfDefaultLang.length > 0 &&
                        getTheInfoOfDefaultLang[0].display_name === ''
                    ) {
                        setInvalidName(true)
                    }
                }
            }
        } else {
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:something_went_wrong')}`, 'error'))
        }
    }

    const handleAttributeNameChange = (val) => {
        let tempObj = deepCopy(dataForAttributesDetailsUpdate)
        if (tempObj && tempObj.length > 0 && tempObj[0].value && tempObj[0].value.length > 0) {
            tempObj[0].value[0].name = val
            setDataForAttributesDetailsUpdate(tempObj)
        }
    }
    const handleAttributeOptionChange = (val) => {
        let tempObj = deepCopy(dataForOptionDetailsUpdate)
        if (tempObj && tempObj.length > 0 && tempObj[0].value && tempObj[0].value.length > 0) {
            tempObj[0].value[0].attributeOptionName = val
            console.log('tempObjTempObj#', tempObj)
            setDataForOptionDetailsUpdate(tempObj)
        }
    }
    return (
        <Content>
            {loadingSkelton ? (
                <Skeleton paragraph={{ rows: 6 }} className='p-3' />
            ) : (
                <Spin tip='Please wait!' size='large' spinning={isLoadingStoreLanguage}>
                    <StoreModal
                        title={t('languages:language_with_invalid_inputs')}
                        isVisible={isInputFieldsValidationModalOpen}
                        okButtonText={t('common:enter_translations')}
                        cancelButtonText={`${t('common:proceed_without_translations')}`}
                        okCallback={() => handleInputFieldsValidationModalClose()}
                        cancelCallback={() => handleInputFieldsValidationModalSave()}
                        isSpin={false}
                        width={700}
                        hideCloseButton={false}>
                        <Content>
                            <div className=' mt-2 space-y-2'>
                                <p className=''>{t('product_template:the_multi_lingual_details_for')}</p>
                                <ul
                                    className={
                                        languagesWithInvalidData && languagesWithInvalidData.length > 5
                                            ? '!overflow-y-auto !h-24'
                                            : ''
                                    }>
                                    {languagesWithInvalidData &&
                                        languagesWithInvalidData.length > 0 &&
                                        languagesWithInvalidData.map((ele, index) => {
                                            return (
                                                <>
                                                    {
                                                        <li className='!font-semibold'>
                                                            {index + 1}
                                                            {'. '}
                                                            {storeLanguages &&
                                                                storeLanguages.length > 0 &&
                                                                storeLanguages.filter(
                                                                    (val) => val.language_code === ele
                                                                )[0].language}
                                                        </li>
                                                    }
                                                </>
                                            )
                                        })}
                                </ul>
                                <p className=''>{t('product_template:click_continue_to_save')}</p>
                            </div>
                        </Content>
                    </StoreModal>
                    {/* <Row className='!space-x-5'>
                        <Col>
                            <Title level={4} className='!font-medium'>
                                {t('attributes:attribute')}
                            </Title>
                        </Col>
                    </Row>

                    <Divider /> */}
                    <div className='flex gap-3 w-full'>
                        <div className='flex-grow-0 my-2 '>
                            {/* <Content className='flex'>
                                <h3 className='!font-medium mt-1 '>{t('attributes:attribute_details')}</h3>{' '}
                                <span> </span>
                            </Content> */}
                            <p className='mb-4'>
                                <span className='font-normal opacity-40'>{t('common:translate_from')} </span>
                                <span className='font-medium'>{t('common:base_language')}</span>
                            </p>
                            {displayNameDataJsonForAttributeName &&
                                displayNameDataJsonForAttributeName.length > 0 &&
                                displayNameDataJsonForAttributeName[0].value &&
                                displayNameDataJsonForAttributeName[0].value.length > 0 &&
                                displayNameDataJsonForAttributeName.map((data) => (
                                    <>
                                        {data.type === 'textbox' ? (
                                            <Content className='my-3' key={data.order}>
                                                <Typography className='input-label-color'>{data.label}</Typography>
                                                <Content className='mt-2'>
                                                    <Tooltip
                                                        placement='bottom'
                                                        title={
                                                            dataForAttributesDetailsUpdate &&
                                                            dataForAttributesDetailsUpdate.length > 0 &&
                                                            dataForAttributesDetailsUpdate[0].value &&
                                                            dataForAttributesDetailsUpdate[0].value.length > 0
                                                                ? dataForAttributesDetailsUpdate[0].value[0].name
                                                                : undefined
                                                        }>
                                                        <Input
                                                            placeholder={t('product_types:enter_title_here')}
                                                            className='w-[100%] border-solid border-[#C6C6C6]'
                                                            disabled={true}
                                                            value={
                                                                dataForAttributesDetailsUpdate &&
                                                                dataForAttributesDetailsUpdate.length > 0 &&
                                                                dataForAttributesDetailsUpdate[0].value &&
                                                                dataForAttributesDetailsUpdate[0].value.length > 0
                                                                    ? dataForAttributesDetailsUpdate[0].value[0].name
                                                                    : null
                                                            }
                                                            onChange={(e) => handleAttributeNameChange(e.target.value)}
                                                            maxLength={100}
                                                        />
                                                    </Tooltip>
                                                </Content>
                                            </Content>
                                        ) : null}
                                    </>
                                ))}

                            {displayNameDataJsonForAttributeOption &&
                                displayNameDataJsonForAttributeOption.length > 0 &&
                                displayNameDataJsonForAttributeOption[0].value &&
                                displayNameDataJsonForAttributeOption[0].value.length > 0 &&
                                displayNameDataJsonForAttributeOption.map((data) => (
                                    <>
                                        {data.type === 'textbox' ? (
                                            <Content className='my-3' key={data.order}>
                                                <Typography className='input-label-color'>{data.label}</Typography>
                                                <Content className='mt-2'>
                                                    <Tooltip
                                                        placement='bottom'
                                                        title={
                                                            dataForOptionDetailsUpdate &&
                                                            dataForOptionDetailsUpdate.length > 0 &&
                                                            dataForOptionDetailsUpdate[0].value &&
                                                            dataForOptionDetailsUpdate[0].value.length > 0
                                                                ? dataForOptionDetailsUpdate[0].value[0]
                                                                      .attributeOptionName
                                                                : undefined
                                                        }>
                                                        <Input
                                                            placeholder={t('attributes:enter_attribute_values')}
                                                            className='w-[100%] border-solid border-[#C6C6C6]'
                                                            disabled={true}
                                                            value={
                                                                dataForOptionDetailsUpdate &&
                                                                dataForOptionDetailsUpdate.length > 0 &&
                                                                dataForOptionDetailsUpdate[0].value &&
                                                                dataForOptionDetailsUpdate[0].value.length > 0
                                                                    ? dataForOptionDetailsUpdate[0].value[0]
                                                                          .attributeOptionName
                                                                    : null
                                                            }
                                                            onChange={(e) =>
                                                                handleAttributeOptionChange(e.target.value)
                                                            }
                                                            maxLength={100}
                                                        />
                                                    </Tooltip>
                                                </Content>
                                            </Content>
                                        ) : null}
                                    </>
                                ))}
                        </div>
                        <div className='border-r !border-brandGray'></div>
                        <div className='flex-grow my-2'>
                            <Content className='flex gap-2 items-center justify-between'>
                                <div className='flex items-center mb-2'>
                                    <p className='font-normal opacity-40'>{t('common:translate_to')}</p>
                                    <Select
                                        value={selectedLanguageFromDropDown}
                                        onChange={handleLanguageChange}
                                        className='border-0 translator-select h-[22px] !mx-2 w-28 '
                                        listHeight={138}>
                                        {allLanguages &&
                                            allLanguages.length > 0 &&
                                            allLanguages.map((e) => (
                                                <Select.Option
                                                    className='translatorSelectOption'
                                                    value={e.language_code}
                                                    title={e.language}
                                                    key={e.language_code}>
                                                    {e.language}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                    {defaultLanguageName &&
                                    defaultLanguageName.length > 0 &&
                                    selectedLanguageFromDropDown === defaultLanguageName[0].language_code ? (
                                        <p className='!font-semibold  !opacity-60'>
                                            {t('attributes:default_language')}
                                        </p>
                                    ) : null}
                                </div>
                                <div className='flex'>
                                    <Progress
                                        className='w-[140px] !m-0 px-2'
                                        percent={(alreadyTranslatedCount / allLanguages?.length) * 100}
                                        showInfo={false}
                                        size={'small'}
                                    />
                                    <p className='font-normal text-sm'>
                                        {alreadyTranslatedCount} {t('common:of')} {allLanguages?.length}{' '}
                                        {t('common:languages_translated')}
                                    </p>
                                </div>
                            </Content>
                            {displayNameDataJsonForAttributeName &&
                                displayNameDataJsonForAttributeName.length > 0 &&
                                displayNameDataJsonForAttributeName[0].value &&
                                displayNameDataJsonForAttributeName[0].value.length > 0 &&
                                displayNameDataJsonForAttributeName.map((data) => (
                                    <>
                                        {data.type === 'textbox' ? (
                                            <Content className='mt-3 mb-1' key={data.order}>
                                                <Typography className='input-label-color'>
                                                    {String(data.label).split(' ')[0]}{' '}
                                                    {String(data.label).split(' ')[1]}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>

                                                <Content className='mt-2'>
                                                    <Input
                                                        placeholder={t('product_types:enter_title_here')}
                                                        className={`w-[100%] ${
                                                            invalidName &&
                                                            defaultLanguageName &&
                                                            defaultLanguageName.length > 0 &&
                                                            selectedLanguageFromDropDown ===
                                                                defaultLanguageName[0].language_code
                                                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                                : ' border-[#C6C6C6] border-solid'
                                                        }`}
                                                        value={
                                                            allLanguages && allLanguages.length > 0
                                                                ? commonDataContainingAllObject &&
                                                                  commonDataContainingAllObject[0] &&
                                                                  commonDataContainingAllObject.length > 0 &&
                                                                  commonDataContainingAllObject.filter(
                                                                      (el) =>
                                                                          el.language_code ===
                                                                          selectedLanguageFromDropDown
                                                                  )[0].display_name
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            if (validator.matches(e.target.value.trim(), /^\S.*$/)) {
                                                                handleAttributeNameChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )
                                                                setOnChangeDisableFields(false)
                                                            } else if (e.target.value === '') {
                                                                handleAttributeNameChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )
                                                                setOnChangeDisableFields(false)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var temp = deepCopy(commonDataContainingAllObject)
                                                            let objIndex = temp.findIndex(
                                                                (obj) =>
                                                                    obj.language_code === selectedLanguageFromDropDown
                                                            )
                                                            temp[objIndex].display_name = e.target.value
                                                                .trim()
                                                                .replace(/\s+/g, ' ')
                                                            setCommonDataContainingAllObject(temp)
                                                        }}
                                                        maxLength={parseInt(titleMaxLength)}
                                                    />
                                                </Content>
                                            </Content>
                                        ) : null}
                                    </>
                                ))}

                            {displayNameDataJsonForAttributeOption &&
                                displayNameDataJsonForAttributeOption.length > 0 &&
                                displayNameDataJsonForAttributeOption[0].value &&
                                displayNameDataJsonForAttributeOption[0].value.length > 0 &&
                                displayNameDataJsonForAttributeOption.map((data) => (
                                    <>
                                        {data.type === 'textbox' ? (
                                            <Content className='my-3' key={data.order}>
                                                <Typography className='input-label-color'>
                                                    {data.label}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>
                                                <Content className=' mt-1'>
                                                    <Input
                                                        placeholder={t('attributes:enter_attribute_values')}
                                                        className={`w-[100%] ${
                                                            invalidName &&
                                                            defaultLanguageName &&
                                                            defaultLanguageName.length > 0 &&
                                                            selectedLanguageFromDropDown ===
                                                                defaultLanguageName[0].language_code
                                                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                                : ' border-[#C6C6C6] border-solid'
                                                        }`}
                                                        value={
                                                            allLanguages && allLanguages.length > 0
                                                                ? commonDataContainingAllObject &&
                                                                  commonDataContainingAllObject[0] &&
                                                                  commonDataContainingAllObject.length > 0 &&
                                                                  commonDataContainingAllObject.filter(
                                                                      (el) =>
                                                                          el.language_code ===
                                                                          selectedLanguageFromDropDown
                                                                  )[0].display_name
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            if (validator.matches(e.target.value.trim(), /^\S.*$/)) {
                                                                handleAttributeOptionChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )

                                                                setOnChangeDisableFields(false)
                                                            } else if (e.target.value === '') {
                                                                handleAttributeOptionChangeFromAllLanguages(
                                                                    e.target.value,
                                                                    false
                                                                )
                                                                setOnChangeDisableFields(false)
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var temp = deepCopy(commonDataContainingAllObject)
                                                            let objIndex = temp.findIndex(
                                                                (obj) =>
                                                                    obj.language_code === selectedLanguageFromDropDown
                                                            )
                                                            temp[objIndex].display_name = e.target.value
                                                                .trim()
                                                                .replace(/\s+/g, ' ')
                                                            setCommonDataContainingAllObject(temp)
                                                        }}
                                                        maxLength={parseInt(attributeOptionMaxCharacterLength)}
                                                    />
                                                </Content>
                                            </Content>
                                        ) : null}
                                    </>
                                ))}
                        </div>
                    </div>
                    <Content
                        className={` -mb-3 w-full  ${
                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'text-left ' : 'text-right'
                        }`}>
                        <Button
                            className={`app-btn-secondary ${
                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!ml-2' : '!mr-2'
                            }`}
                            onClick={() => handleTranlaterDiscard()}>
                            {t('common:discard')}
                        </Button>
                        <Button
                            className='app-btn-primary ml-2'
                            onClick={() => handleSaveButton()}
                            disabled={
                                !onChangeDisableFields
                                    ? allLanguages && allLanguages.length > 0
                                        ? (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_name === null) ||
                                          (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_name === '')
                                            ? true
                                            : false
                                        : true
                                    : true
                            }>
                            {t('common:save')}
                        </Button>
                    </Content>
                </Spin>
            )}
        </Content>
    )
}

export default AttributeTranslatorModal
