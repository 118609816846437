import React, { useEffect } from 'react'
import { Layout, Pagination } from 'antd'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'

const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)

const { Content } = Layout
export default function DmPagination({
    currentPage,
    totalItemsCount,
    handlePageNumberChange,
    pageSize,
    defaultPageSize,
    showSizeChanger,
    showTotal,
    paginationSize,
    showQuickJumper,
}) {
    const { t } = useTranslation()
    const handlePageChange = (page, pageSize) => {
        handlePageNumberChange(page, pageSize)
    }
    // Customize the Keys for Pagination
    const paginationLocaleValues = {
        items_per_page: t('common:page'),
        prev_page: t('common:prev_page'),
        next_page: t('common:next_page'),
        jump_to: t('common:go_to'),
        page: t('common:page_capital'),
    }
    const selectedLanguageFromReduxState = useSelector((state) => state.reducerSelectedLanguage.selectedLanguage)
    useEffect(() => {
        if (String(selectedLanguageFromReduxState?.writing_script_direction).toUpperCase() === 'RTL') {
            document.getElementsByClassName('ant-pagination-prev')[0].style.transform = 'rotate(180deg)'
            document.getElementsByClassName('ant-pagination-next')[0].style.transform = 'rotate(180deg)'
        }
    }, [selectedLanguageFromReduxState])
    return (
        <Content className='mt-3 mb-5'>
            <Pagination
                showSizeChanger={showSizeChanger}
                defaultCurrent={currentPage}
                total={totalItemsCount}
                pageSizeOptions={[20, 50, 100]}
                size={paginationSize === undefined ? '' : paginationSize}
                showTotal={
                    showTotal === true
                        ? (total, range) => `${range[0]}-${range[1]} ${t('common:of')} ${total}  ${t('common:items')}`
                        : false
                }
                onChange={handlePageChange}
                pageSize={pageSize}
                defaultPageSize={pageLimit}
                showQuickJumper={showQuickJumper}
                locale={paginationLocaleValues}
            />
        </Content>
    )
}
