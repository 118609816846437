import { Badge, Button, Divider, Input, Layout, Tag, Typography, Table } from 'antd'
import axios from 'axios'
import Cookies from 'js-cookie'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import { Buffer } from 'buffer'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'
import DynamicTable from '../tablelayoutcomponent/DynamicTable'
import EyeIcon from './Icons/EyeIcon'
import DownloadIcon from './Icons/DownloadIcon'
import getTranslatedDate from '../../util/getTranslatedDate'

//! ant design initialization
const { Content } = Layout
const { Text, Title } = Typography
const { TextArea } = Input

const invoiceDetailsAPI = process.env.REACT_APP_INVOICE_DETAILS
const invoiceTransactionAPI = process.env.REACT_APP_INVOICE_TRANSACTION
const pageLimit = process.env.REACT_APP_ITEM_PER_PAGE
const settlementJourneyAPI = process.env.REACT_APP_SETTLEMENT_JOURNEY_API
const couplerBaseUrl = process.env.REACT_APP_COUPLER_BASE_URL
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const ListPastSettlement = () => {
    const { t } = useTranslation()
    const search = useLocation().search
    const vid = new URLSearchParams(search).get('vid')
    let storeName = window.sessionStorage.getItem('client')

    const [isLoading, setIsLoading] = useState(false)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [selectedTabTableContent, setSelectedTabTableContent] = useState([])
    const [getInvoiceDetailsData, setGetInvoiceDetailsData] = useState([])
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [isSpinLoading, setIsSpinLoading] = useState(false)
    const [tableInvoiceTransactionData, setTableInvoiceTransactionData] = useState([])
    const [filteredInvoiceDetailsData, setFilteredInvoiceDetailsData] = useState([])
    const [invoiceTransactionCount, setInvoiceTransactionCount] = useState()
    const [isSettleAndUpdateModalOpen, setIsSettleAndUpdateModalOpen] = useState(false)
    const [transactionPageNumber, setTransactionPageNumber] = useState(1)
    const [transactionPageLimit, setTransactionPageLimit] = useState(pageLimit)
    const [invoiceDetailsCount, setInvoiceDetailsCount] = useState()
    const [invoiceDetailsPaginationData, setInvoiceDetailsPaginationData] = useState({
        pageNumber: 1,
        pageSize: pageLimit,
    })
    const [updateInvoiceDetailsData, setUpdateInvoiceDetailsData] = useState({})
    const [invoicePaymentInformation, setInvoicePaymentInformation] = useState('')
    const [inValidPaymentInformation, setInvalidPaymentInformation] = useState(false)
    const [invoiceTransactionId, setInvoiceTransactionId] = useState()
    const [mappedJourneys, setMappedJourneys] = useState([])
    const [journeyUrl, setJourneyUrl] = useState()
    const [invoiceId, setInvoiceId] = useState()
    const [settleAndUpdateLoading, setSettleAndUpdateLoading] = useState(false)
    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    //!columns for Invoice details
    const columns = [
        {
            title: t('coupons:start_date'),
            dataIndex: 'beginDate',
            key: 'beginDate',
            width: '8%',
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return (
                    // <>{moment.utc(record.beginDate).format("D MMMM YYYY HH:mm:ss")}</>
                    <>{getTranslatedDate(getGenerateDateAndTime(record.beginDate, 'D MMMM YYYY h:mm:ss A'), t)}</>
                )
            },
        },
        {
            title: t('transactions:end_date'),
            dataIndex: 'endDate',
            key: 'endDate',
            width: '8%',
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return (
                    // <>{moment.utc(record.endDate).format("D MMMM YYYY HH:mm:ss")}</>
                    <>{getTranslatedDate(getGenerateDateAndTime(record.endDate, 'D MMMM YYYY h:mm:ss A'), t)}</>
                )
            },
        },
        {
            title: t('transactions:state'),
            dataIndex: 'State',
            key: 'State',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className='inline-block'>
                        {record?.status === 'Paid' ? (
                            <p>
                                <Badge status='success' /> <Text>{t('transactions:paid')}</Text>
                            </p>
                        ) : (
                            <p>
                                <Badge status='error' /> <Text>{t('transactions:unpaid')}</Text>
                            </p>
                        )}
                    </Content>
                )
            },
        },
        {
            title: t('transactions:amount'),
            dataIndex: 'amount',
            key: 'amount',
            width: '14%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className=''>
                        {record.amount !== null
                            ? currencySymbolFromRedux + ' ' + record.amount
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:net_vendor_income'),
            dataIndex: 'netVendorIncome',
            key: 'netVendorIncome',
            width: '10%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className=''>
                        {record.totalAmount !== null
                            ? currencySymbolFromRedux + ' ' + record.netVendorIncome
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:store_commission'),
            dataIndex: 'storeCommission',
            key: 'storeCommission',
            width: '16%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className=' text-brandGreen !font-normal'>
                        {record.commissionData !== null
                            ? currencySymbolFromRedux + ' ' + record.commissionData
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:store_discount'),
            dataIndex: 'storeDiscount',
            key: 'storeDiscount',
            width: '8%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className=' text-brandRed !font-normal'>
                        {record.totalAmount !== null
                            ? currencySymbolFromRedux + ' ' + record.storeCouponAmount
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:net_store_income'),
            dataIndex: 'netStoreIncome',
            key: 'netStoreIncome',
            width: 130,
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className={`${record.netStoreIncome >= 0 ? 'text-brandGreen' : 'text-brandRed'}`}>
                        {record.totalAmount !== null
                            ? record.netStoreIncome < 0
                                ? '-' + currencySymbolFromRedux + record.netStoreIncome * -1
                                : currencySymbolFromRedux + ' ' + record.netStoreIncome
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:action'),
            dataIndex: 'action',
            key: 'action',
            width: 250,
            fixed: 'right',
            zIndex: 0,
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className='flex flex-row items-center justify-between '>
                        <Button
                            className='app-btn-primary'
                            disabled={record.status === 'Paid' ? true : false}
                            onClick={() => {
                                setIsSettleAndUpdateModalOpen(true)
                                setInvoicePaymentInformation('')
                                setInvalidPaymentInformation(false)
                                setUpdateInvoiceDetailsData({
                                    vendorId: record.vendorId,
                                    paymentStatus: record.paymentStatus,
                                    invoiceId: record.invoiceId,
                                    invoiceUrl: record.invoiceUrl,
                                    invoiceStatus: record.invoiceStatus,
                                    id: record.id,
                                })
                            }}>
                            <Text
                                ellipsis={{ tooltip: t('transactions:settle_and_update') }}
                                className='w-[100px] text-inherit'>
                                {t('transactions:settle_and_update')}
                            </Text>
                        </Button>
                        <div
                            className='!text-xs cursor-pointer svgIcon'
                            onClick={() => saveDownloadPastDetails(record.id)}>
                            <DownloadIcon />
                        </div>
                        <div
                            className='!text-xs cursor-pointer svgIcon'
                            onClick={() => {
                                InvoiceTransactionModal(record.id)
                                setInvoiceTransactionId(record.id)
                            }}>
                            <EyeIcon />
                        </div>
                    </Content>
                )
            },
        },
    ]

    const InvoiceTransactionModal = (id) => {
        setIsModalOpen(true)
        let invoiceDetailsData =
            getInvoiceDetailsData &&
            getInvoiceDetailsData.length > 0 &&
            getInvoiceDetailsData.filter((ele) => ele.id === id)
        console.log('==> filtered data', invoiceDetailsData)
        setFilteredInvoiceDetailsData(invoiceDetailsData)
        setInvoiceId(id)
    }
    //! columns for Invoice Transaction details
    const InvoiceTransactionDetails = [
        {
            title: t('transactions:date'),
            dataIndex: 'Date',
            key: 'Date',
            width: '20%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    // <>{moment.utc(record.orderedDate).format("D MMMM YYYY HH:mm:ss")}</>
                    <>{getTranslatedDate(getGenerateDateAndTime(record.orderedDate, 'D MMMM YYYY'), t)}</>
                )
            },
        },
        {
            title: t('transactions:line_item_id'),
            dataIndex: 'orderItemId',
            key: 'orderItemId',
            width: '10%',
            ellipsis: true,
            render: (text, record) => {
                return <>{'#' + record.orderItemId}</>
            },
        },
        {
            title: t('transactions:order_id'),
            dataIndex: 'orderId',
            key: 'orderId',
            width: '10%',
            ellipsis: true,
            render: (text, record) => {
                return <>{'#' + record.orderId}</>
            },
        },
        {
            title: t('transactions:type'),
            dataIndex: 'state',
            key: 'state',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Tag
                        color={
                            record.statusType === 'Fulfilled'
                                ? 'green'
                                : record.statusType === 'Refund'
                                  ? 'red'
                                  : record.statusType === 'cancel'
                                    ? 'orange'
                                    : null
                        }>
                        {record?.statusTypeDisplayName}
                    </Tag>
                )
            },
        },
        {
            title: t('transactions:amount'),
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        {record.orderAmount !== null
                            ? currencySymbolFromRedux + ' ' + record.orderAmount
                            : currencySymbolFromRedux + ' ' + 0}
                    </>
                )
            },
        },
        {
            title: t('transactions:net_vendor_income'),
            dataIndex: 'netVendorIncome',
            key: 'netVendorIncome',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        {record.orderAmount !== null
                            ? currencySymbolFromRedux + ' ' + record.netVendorIncome
                            : currencySymbolFromRedux + ' ' + 0}
                    </>
                )
            },
        },
        {
            title: t('transactions:store_commission'),
            dataIndex: 'storeCommission',
            key: 'storeCommission',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className='text-brandGreen'>
                        {record.storeCommission !== null
                            ? currencySymbolFromRedux + ' ' + record.storeCommission
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:store_discount'),
            dataIndex: 'storeDiscount',
            key: 'storeDiscount',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className='text-brandRed'>
                        {record.netAmount !== null
                            ? currencySymbolFromRedux + ' ' + record.storeCouponAmount
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
        {
            title: t('transactions:net_store_income'),
            dataIndex: 'netStoreIncome',
            key: 'netStoreIncome',
            width: '15%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Text className={`${record.netStoreIncome < 0 ? 'text-brandRed' : 'text-brandGreen'}`}>
                        {record.netAmount !== null
                            ? record.netStoreIncome > 0
                                ? currencySymbolFromRedux + ' ' + record.netStoreIncome
                                : '-' + currencySymbolFromRedux + ' ' + record.netStoreIncome * -1
                            : currencySymbolFromRedux + ' ' + 0}
                    </Text>
                )
            },
        },
    ]

    //!dynamic table data
    const InvoiceDetailsPropsData = {
        table_header: columns,
        table_content: selectedTabTableContent,
        search_settings: {
            is_enabled: false,
            search_title: 'Search by date',
            search_data: ['date'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: 'filter by',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }

    //!Invoice Transaction table data
    const InvoiceTransactionPropsData = {
        table_header: InvoiceTransactionDetails,
        table_content: tableInvoiceTransactionData,
        search_settings: {
            is_enabled: false,
            search_title: 'Search by date',
            search_data: ['date'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: 'filter by',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }

    //!Invoice details to get the table data
    const tableInvoiceDetailsData = (filteredData) => {
        const tempArray = []
        if (filteredData && filteredData.length > 0) {
            filteredData &&
                filteredData.length > 0 &&
                filteredData.forEach((element, index) => {
                    console.log('element', element)
                    var id = element.id
                    var paymentBeginDate = element.invoice_from_datetime
                    var paymentEndDate = element.invoice_to_datetime
                    var paymentStatus = element.payment_status
                    var payableAmount = element.total_fulfilled_amount
                    var totalStoreCommissionData = element.total_store_commission
                    var totalFulfilledAmount = element.total_fulfilled_amount
                    var invoiceStatus = element.invoice_status
                    var invoiceId = element.invoice_id
                    var invoiceUrl = element.invoice_url
                    var vendorId = element.vendor_id
                    var netStoreIncome = element.net_store_income
                    var storeCouponAmount = element.store_coupon_amount
                    var totalStoreCommission = element.total_store_commission
                    var netVendorIncome = element.total_fulfilled_amount - element.total_store_commission
                    tempArray &&
                        tempArray.push({
                            key: index,
                            id: id,
                            beginDate: paymentBeginDate,
                            endDate: paymentEndDate,
                            status: paymentStatus,
                            amount: payableAmount,
                            commissionData: totalStoreCommissionData,
                            totalAmount: totalFulfilledAmount,
                            invoiceStatus: invoiceStatus,
                            invoiceId: invoiceId,
                            invoiceUrl: invoiceUrl,
                            vendorId: vendorId,
                            netStoreIncome: netStoreIncome,
                            storeCouponAmount: storeCouponAmount,
                            totalStoreCommission: totalStoreCommission,
                            netVendorIncome: netVendorIncome,
                        })
                })
            return tempArray
        } else {
            return tempArray
        }
        // setSelectedTabTableContent(tempArray);
    }

    //!Invoice Transaction to get the table data
    const invoiceTransactionTableData = (filteredData) => {
        console.log('filteredData-------->', filteredData)
        const tempArray = []
        if (filteredData && filteredData.length > 0) {
            filteredData &&
                filteredData.length > 0 &&
                filteredData.forEach((element, index) => {
                    var id = element.id
                    var orderedDate = element.ordered_date
                    var statusType = element.status_type
                    var orderAmount = element.order_item_amount
                    var storeCommission = element.store_commision
                    var orderItemId = element.order_item_id
                    var netAmount = element.net_amount
                    var orderId = element.order_id
                    var netStoreIncome = element.net_store_income
                    var storeCouponAmount = element.store_discounted_amount
                    var totalStoreCommission = element.total_store_commission
                    // To fix the decimal issues, below line has been commented
                    // var netVendorIncome = element.order_item_amount - element.store_commision
                    var netVendorIncome = element.net_amount
                    var statusTypeDisplayName = element.status_type_display_name
                    tempArray &&
                        tempArray.push({
                            key: id,
                            orderedDate: orderedDate,
                            statusType: statusType,
                            orderAmount: orderAmount,
                            storeCommission: storeCommission,
                            orderItemId: orderItemId,
                            netAmount: netAmount,
                            orderId: orderId,
                            netStoreIncome: netStoreIncome,
                            storeCouponAmount: storeCouponAmount,
                            totalStoreCommission: totalStoreCommission,
                            netVendorIncome: netVendorIncome,
                            statusTypeDisplayName: statusTypeDisplayName,
                        })
                })
            return tempArray
        } else {
            return tempArray
        }
        // setTableInvoiceTransactionData(tempArray);
    }

    //!get call for Invoice details
    const findByPageGetInvoiceDetails = (page, limit) => {
        setIsLoading(true)

        MarketplaceServices.findByPage(invoiceDetailsAPI, { vendor_id: vid }, page, limit, false)
            .then((response) => {
                console.log(
                    'Server Response from findByPageGetInvoiceDetails is',
                    invoiceDetailsAPI,
                    response.data.response_body
                )
                setSelectedTabTableContent(tableInvoiceDetailsData(response.data.response_body.invoice_data))
                setGetInvoiceDetailsData(response.data.response_body.invoice_data)
                setInvoiceDetailsCount(response.data.response_body.count)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch((error) => {
                console.log('Error Server response from findByPageGetInvoiceDetails ', invoiceDetailsAPI, error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    //!get call for Invoice Transaction
    const findByPageGetInvoiceTransactionDetails = (page, limit, invoiceId) => {
        setIsSpinLoading(true)
        // setIsNetworkError(true);
        MarketplaceServices.findByPage(
            invoiceTransactionAPI,
            { vendor_id: vid, 'invoice-id': invoiceId },
            page,
            limit,
            false
        )
            .then((response) => {
                console.log(
                    'Server Response from findByPageGetInvoiceTransactionDetails is',
                    invoiceTransactionAPI,
                    response.data.response_body
                )

                if (response.data && response.data.response_body.transaction_data.length > 0) {
                    setTableInvoiceTransactionData(
                        invoiceTransactionTableData(response.data.response_body.transaction_data)
                    )
                } else {
                    setTableInvoiceTransactionData([])
                }

                setInvoiceTransactionCount(response.data.response_body.count)
                // setIsNetworkError(false);
                setIsSpinLoading(false)
            })
            .catch((error) => {
                setTableInvoiceTransactionData([])
                console.log(
                    'Error Server response from findByPageGetInvoiceTransactionDetails ',
                    invoiceTransactionAPI,
                    error
                )
                setIsSpinLoading(false)
                // setIsNetworkError(false);
            })
    }

    const findAllMappedJourney = () => {
        MarketplaceServices.findAll(
            settlementJourneyAPI,
            {
                'vendor-id': parseInt(vid),
                'journey-for': 'Settlement_Invoice_Download',
            },
            false
        )
            .then((response) => {
                console.log(
                    'Server response from findAllMappedJourney API is',
                    settlementJourneyAPI,
                    response.data.response_body.data.settlement_mapping_data
                )
                setMappedJourneys(response.data.response_body.data.settlement_mapping_data)
            })
            .catch((error) => {
                console.log('ERROR Server response from findAllMappedJourney API is', settlementJourneyAPI, error)
                setMappedJourneys([])
            })
    }
    const saveDownloadPastDetails = (invoiceId) => {
        const auth = Cookies.get(storeName + '_' + 'smp_access_token')
        axios({
            method: 'POST',
            url: `${couplerBaseUrl}${journeyUrl}`,
            headers: {
                Authorization: `Bearer ${auth}`,
            },
            data: {
                vendor_id: parseInt(vid),
                'invoice-id': parseInt(invoiceId),
            },
        })
            .then((response) => {
                MarketplaceToaster.showToast(response)
                console.log('response from the download invoice ', response)
                if (response.data.response_body && response.data.response_body.transaction_report_content?.message) {
                    const fileContent = response.data.response_body.transaction_report_content.message
                    const byteCharacters = Buffer.from(fileContent, 'base64')
                    const byteArray = new Uint8Array(byteCharacters)
                    const blob = new Blob([byteArray], {
                        type: 'application/octet-stream',
                    })
                    const url = URL.createObjectURL(blob)
                    const a = document.createElement('a')
                    a.href = url
                    a.download = `Invoice ${parseInt(invoiceId)}.pdf`
                    document.body.appendChild(a)
                    a.click()
                    document.body.removeChild(a)
                    URL.revokeObjectURL(url)
                }
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log('error', error)
            })
    }

    const updateInvoiceDetails = () => {
        setSettleAndUpdateLoading(true)
        let requestBody = {}
        requestBody['payment_status'] = 'Paid'
        requestBody['invoice_status'] = updateInvoiceDetailsData.invoiceStatus
        requestBody['invoice_url'] = updateInvoiceDetailsData.invoiceUrl
        requestBody['payment_information'] = invoicePaymentInformation
        MarketplaceServices.update(invoiceDetailsAPI, requestBody, {
            'invoice-id': updateInvoiceDetailsData.invoiceId,
        })
            .then(function (response) {
                setSettleAndUpdateLoading(false)
                console.log('Server response from updateCallInvoiceDetailsAPI', invoiceDetailsAPI, response.data)
                let temp = [...selectedTabTableContent]
                let index = temp.findIndex((ele) => ele.id === updateInvoiceDetailsData.id)
                console.log('index', updateInvoiceDetailsData.id, index)
                temp[index]['status'] = 'Paid'
                setSelectedTabTableContent(temp)
                if (filteredInvoiceDetailsData && filteredInvoiceDetailsData.length > 0) {
                    let tempStatus = [...filteredInvoiceDetailsData]
                    let indexStatus = tempStatus.findIndex((ele) => ele.id === updateInvoiceDetailsData.id)
                    tempStatus[indexStatus]['payment_status'] = 'Paid'
                    setFilteredInvoiceDetailsData(tempStatus)
                }
                MarketplaceToaster.showToast(response)
                setIsSettleAndUpdateModalOpen(false)
                // alreadyMappedStoreProductTypesJourney();
            })
            .catch((error) => {
                setSettleAndUpdateLoading(false)
                console.log('ERROR Server response from updateCallInvoiceDetailsAPI', invoiceDetailsAPI, error)
                setIsSettleAndUpdateModalOpen(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const validationForUpdateInvoiceDetails = () => {
        if (
            invoicePaymentInformation === '' ||
            invoicePaymentInformation === null ||
            invoicePaymentInformation === undefined
        ) {
            setInvalidPaymentInformation(true)
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('store_settings:please_provide_values_for_the_mandatory_fields')}`, 'error')
            )
        } else {
            updateInvoiceDetails()
        }
    }

    //! close Invoice transaction Modal
    const closeInvoiceTransactionModal = () => {
        setIsModalOpen(false)
    }

    //! close settle and update modal
    const settleAndUpdateModal = () => {
        setIsSettleAndUpdateModalOpen(false)
    }

    //!InvoiceTransaction pagination onchange function
    const handlePageNumberChange = (page, pageSize) => {
        setTransactionPageNumber(page)
        setTransactionPageLimit(pageSize)
    }

    //!InvoiceDetails pagination onchange function
    const handleInvoiceDetailsPageNumberChange = (page, pageSize) => {
        setInvoiceDetailsPaginationData({
            pageNumber: page,
            pageSize: pageSize,
        })
    }

    useEffect(() => {
        findByPageGetInvoiceDetails(invoiceDetailsPaginationData.pageNumber, invoiceDetailsPaginationData.pageSize)
    }, [invoiceDetailsPaginationData, vid])

    useEffect(() => {
        if (invoiceTransactionId !== null && invoiceTransactionId !== undefined) {
            findByPageGetInvoiceTransactionDetails(transactionPageNumber, transactionPageLimit, invoiceTransactionId)
        }
    }, [transactionPageNumber, transactionPageLimit, invoiceTransactionId])

    useEffect(() => {
        findAllMappedJourney()
        if (mappedJourneys && mappedJourneys.length > 0) {
            setJourneyUrl(mappedJourneys[0].journey_url)
        } else {
            setJourneyUrl('/Vendor-Settlement-Download-Invoice-Journey')
        }
    }, [])

    const formatNumber = (num) => {
        // Convert the number to a string
        const numStr = num.toString()

        // Check if the number contains a decimal point
        if (numStr.includes('.')) {
            // If yes, apply toFixed(2)
            return parseFloat(num).toFixed(2)
        } else {
            // If no decimals, return the number as is
            return num
        }
    }

    return (
        <Content>
            {isLoading ? (
                <Content className=' bg-white text-center !p-2'>
                    <SkeletonComponent />
                </Content>
            ) : isNetworkError ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                    <p>{`${t('common:network_error')}`}</p>
                </Content>
            ) : (
                <Content className=''>
                    {/* testt13 */}
                    <Table
                        columns={columns}
                        dataSource={selectedTabTableContent}
                        pagination={false}
                        scroll={{
                            x: 1600,
                        }}
                        locale={{ emptyText: `${t('common:no_data')}` }}
                    />
                    {invoiceDetailsCount && invoiceDetailsCount >= pageLimit ? (
                        <Content
                            className={` grid justify-items-end  ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!ml-8' : '!mr-8'}`}>
                            <DmPagination
                                currentPage={invoiceDetailsPaginationData.pageNumber}
                                totalItemsCount={invoiceDetailsCount}
                                pageSize={invoiceDetailsPaginationData.pageSize}
                                handlePageNumberChange={handleInvoiceDetailsPageNumberChange}
                                showSizeChanger={true}
                                showTotal={true}
                                showQuickJumper={true}
                            />
                        </Content>
                    ) : null}
                </Content>
            )}
            <StoreModal
                isVisible={isModalOpen}
                cancelCallback={() => closeInvoiceTransactionModal()}
                isSpin={isSpinLoading}
                width={1300}
                hideCloseButton={true}>
                {filteredInvoiceDetailsData && filteredInvoiceDetailsData.length > 0 ? (
                    <>
                        <Content className='flex'>
                            <Title level={4}>
                                {t('transactions:settlement_due_Date')} |{' '}
                                {filteredInvoiceDetailsData && filteredInvoiceDetailsData[0].payment_due_date}
                            </Title>
                            <Content className='!ml-3 !mt-1'>
                                {filteredInvoiceDetailsData &&
                                filteredInvoiceDetailsData[0].payment_status === 'Paid' ? (
                                    <p>
                                        <Badge status='success' /> <Text>{t('transactions:paid')}</Text>
                                    </p>
                                ) : (
                                    <p>
                                        <Badge status='error' /> <Text>{t('transactions:unpaid')}</Text>
                                    </p>
                                )}
                            </Content>
                        </Content>
                        <Divider className='!my-2' />
                        <Content className='flex justify-between'>
                            <div>
                                <Text className='text-[#a9a9a9]'>{t('transactions:total_store_income')}</Text>
                                <p className='text-[#52c41a] text-xl'>
                                    <span className='mr-1'>{currencySymbolFromRedux}</span>
                                    {filteredInvoiceDetailsData &&
                                    filteredInvoiceDetailsData[0].net_store_income !== null
                                        ? filteredInvoiceDetailsData &&
                                          formatNumber(filteredInvoiceDetailsData[0].net_store_income)
                                        : 0}
                                </p>
                            </div>
                            <Divider type='vertical' className='h-16 !border-r-1 ' />
                            <div className='flex gap-4'>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row gap-2'>
                                        <Text className='text-[#a9a9a9] '>{t('transactions:total_amount')} : </Text>
                                        <Text>
                                            <span className='mr-1'>{currencySymbolFromRedux}</span>
                                            {filteredInvoiceDetailsData &&
                                            filteredInvoiceDetailsData[0].total_fulfilled_amount !== null
                                                ? filteredInvoiceDetailsData &&
                                                  formatNumber(filteredInvoiceDetailsData[0].total_fulfilled_amount)
                                                : 0}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row gap-2'>
                                        <Text
                                            ellipsis={{ tooltip: t('transactions:total_vendor_income') }}
                                            className='text-[#a9a9a9] w-[140px]'>
                                            {t('transactions:total_vendor_income')} :{' '}
                                        </Text>
                                        <Text>
                                            <span className='mr-1'>{currencySymbolFromRedux}</span>
                                            {filteredInvoiceDetailsData &&
                                            filteredInvoiceDetailsData[0].payable_amount !== null
                                                ? filteredInvoiceDetailsData &&
                                                  formatNumber(filteredInvoiceDetailsData[0].payable_amount)
                                                : 0}
                                        </Text>
                                    </div>
                                </div>
                                <div className='flex flex-col gap-2'>
                                    <div className='flex flex-row gap-2'>
                                        <Text
                                            className='text-[#a9a9a9] w-[155px]'
                                            ellipsis={{ tooltip: t('transactions:total_store_commission') }}>
                                            {t('transactions:total_store_commission')} :{' '}
                                        </Text>
                                        <Text>
                                            <span className='mr-1'>{currencySymbolFromRedux}</span>
                                            {filteredInvoiceDetailsData &&
                                            filteredInvoiceDetailsData[0].total_store_commission !== null
                                                ? filteredInvoiceDetailsData &&
                                                  formatNumber(filteredInvoiceDetailsData[0].total_store_commission)
                                                : 0}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row gap-2'>
                                        <Text
                                            ellipsis={{ tooltip: t('transactions:total_discount') }}
                                            className='text-[#a9a9a9] w-[95px]'>
                                            {t('transactions:total_discount')} :{' '}
                                        </Text>
                                        <Text>
                                            <span className='mr-1'>{currencySymbolFromRedux}</span>
                                            {filteredInvoiceDetailsData &&
                                            filteredInvoiceDetailsData[0].total_store_commission !== null
                                                ? filteredInvoiceDetailsData &&
                                                  formatNumber(
                                                      filteredInvoiceDetailsData[0].total_store_commission -
                                                          filteredInvoiceDetailsData[0].net_store_income
                                                  )
                                                : 0}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                            <Divider type='vertical' className='h-16 !border-r-1 ' />
                            <div className='flex flex-col'>
                                <div className='flex flex-row gap-2 mb-1'>
                                    <Text
                                        ellipsis={{ tooltip: t('transactions:cycle_begin_date') }}
                                        className='text-[#a9a9a9] !w-[115px]'>
                                        {t('transactions:cycle_begin_date')} :{' '}
                                    </Text>
                                    <Text>
                                        {getTranslatedDate(
                                            getGenerateDateAndTime(
                                                filteredInvoiceDetailsData &&
                                                    filteredInvoiceDetailsData[0].invoice_from_datetime,
                                                'D MMMM YYYY h:mm:ss A'
                                            ),
                                            t
                                        )}
                                    </Text>
                                </div>
                                <div className='flex flex-row gap-2'>
                                    <Text
                                        ellipsis={{ tooltip: t('transactions:cycle_end_date') }}
                                        className='text-[#a9a9a9] !w-[100px]'>
                                        {t('transactions:cycle_end_date')} :{' '}
                                    </Text>
                                    <Text>
                                        {getTranslatedDate(
                                            getGenerateDateAndTime(
                                                filteredInvoiceDetailsData &&
                                                    filteredInvoiceDetailsData[0].invoice_to_datetime,
                                                'D MMMM YYYY h:mm:ss A'
                                            ),
                                            t
                                        )}
                                    </Text>
                                </div>
                            </div>
                            <Divider type='vertical' className='h-16 !border-r-1 ' />
                            <div className='flex flex-col'>
                                <Button
                                    className='app-btn-primary mb-2'
                                    disabled={
                                        filteredInvoiceDetailsData &&
                                        filteredInvoiceDetailsData[0].payment_status === 'Paid'
                                            ? true
                                            : false
                                    }
                                    onClick={() => {
                                        setIsSettleAndUpdateModalOpen(true)
                                        setInvalidPaymentInformation(false)
                                        setInvoicePaymentInformation('')
                                        setUpdateInvoiceDetailsData({
                                            paymentStatus:
                                                filteredInvoiceDetailsData &&
                                                filteredInvoiceDetailsData[0].payment_status,
                                            invoiceId:
                                                filteredInvoiceDetailsData && filteredInvoiceDetailsData[0].invoice_id,
                                            invoiceUrl:
                                                filteredInvoiceDetailsData && filteredInvoiceDetailsData[0].invoice_url,
                                            invoiceStatus:
                                                filteredInvoiceDetailsData &&
                                                filteredInvoiceDetailsData[0].invoice_status,
                                            id: filteredInvoiceDetailsData && filteredInvoiceDetailsData[0].id,
                                        })
                                    }}>
                                    <Text
                                        ellipsis={{ tooltip: t('transactions:settle_and_update') }}
                                        className='w-[100px] text-inherit'>
                                        {t('transactions:settle_and_update')}
                                    </Text>{' '}
                                </Button>
                                <div
                                    className='flex flex-row gap-2 cursor-pointer items-center svgIcon app-btn-secondary'
                                    onClick={() => saveDownloadPastDetails(invoiceId)}>
                                    <DownloadIcon />
                                    {t('transactions:download_report')}
                                </div>
                            </div>
                        </Content>
                        <Divider className='!my-2' />
                    </>
                ) : null}
                <Title level={5} className=''>
                    {t('transactions:transactions')}
                </Title>
                <DynamicTable tableComponentData={InvoiceTransactionPropsData} />
                {invoiceTransactionCount && invoiceTransactionCount >= pageLimit ? (
                    <Content className=' grid justify-items-end'>
                        <DmPagination
                            currentPage={transactionPageNumber}
                            totalItemsCount={invoiceTransactionCount}
                            pageSize={transactionPageLimit}
                            handlePageNumberChange={handlePageNumberChange}
                            showSizeChanger={true}
                            showTotal={true}
                            showQuickJumper={true}
                        />
                    </Content>
                ) : null}
            </StoreModal>
            <StoreModal
                hideCloseButton={true}
                title={t('transactions:update_payment_status')}
                okButtonText={t('transactions:mark_as_paid')}
                okCallback={() => validationForUpdateInvoiceDetails()}
                isVisible={isSettleAndUpdateModalOpen}
                cancelCallback={() => {
                    settleAndUpdateModal()
                }}
                isSpin={settleAndUpdateLoading}>
                {
                    <Content className=''>
                        <div className='!mb-2'>
                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                {t('transactions:enter_settlement_details')}
                                <span className='mandatory-symbol-color text-sm text-center '>*</span>
                            </Typography>
                        </div>
                        <TextArea
                            rows={4}
                            autoSize={true}
                            maxLength={255}
                            // className="!w-80"
                            showCount
                            className={`!pr-[3px] !w-[100%] !my-3 ${
                                inValidPaymentInformation
                                    ? 'border-red-400  border-[1px] border-solid !w-[100%]  focus:border-red-400 hover:border-red-400'
                                    : '  border-[#C6C6C6] !w-[100%]'
                            }`}
                            placeholder={t('transactions:enter_payment_information')}
                            value={invoicePaymentInformation}
                            onChange={(e) => {
                                setInvoicePaymentInformation(e.target.value)
                                setInvalidPaymentInformation(false)
                            }}
                        />
                    </Content>
                }
            </StoreModal>
        </Content>
    )
}

export default ListPastSettlement
