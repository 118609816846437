import { GlobalOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import {
    Button,
    Checkbox,
    DatePicker,
    Image,
    Input,
    InputNumber,
    Radio,
    Row,
    Select,
    Switch,
    Tag,
    TimePicker,
    Tooltip,
    Typography,
} from 'antd'
import dayjs from 'dayjs'
import React from 'react'

import { useTranslation } from 'react-i18next'
import 'react-quill/dist/quill.snow.css'
import validator from 'validator'
import DisplayImage from '../media/DisplayImage'
import { generateObjectFromUrlWithFullDocumentPath } from '../../util/util'
import TextRichEditor from '../../components/textRichEditor/TextRichEditor'
import { validatePercentage } from '../../util/validation'

const { Text } = Typography
const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)
const emailMaxLength = parseInt(process.env.REACT_APP_EMAIL_MAX_LENGTH)
const urlMaxLength = parseInt(process.env.REACT_APP_URL_MAX_LENGTH)
const advancedUnitMaxValue = process.env.REACT_APP_ADVANCED_UNIT_MAX_LIMIT

function StaticAttributeControls({
    productOptionValues,
    isEditting,
    datatype,
    productAttributeValues,
    attributeValeuHandler,
    isCore,
    textRichEditorData,
    setTextRichEditorData,
    setOpenImageModel,
    getAttributeUnitValue,
    generateObjectFromUrlWithFullImagePath,
    setImageData,
    generateObjectFromUrl,
    attributeFileType,
    productOptions,
    setProductOptions,
    setIsPhoneCodeNotValid,
    setPhoneCode,
    isPhoneCodeNotValid,
    phoneCode,
    validatePositiveFloatNumber,
    validatePositiveNumber,
    invalidAttributeValue,
    langDirection,
    isAttributeValueSelected,
    getImageUrl,
}) {
    const { t } = useTranslation()
    if (
        productOptionValues &&
        (productOptionValues.dataGroup !== 'Optional' || productOptionValues.dataGroup === 'Upload') &&
        productOptionValues.datatype &&
        isEditting
    ) {
        switch (datatype) {
            //  Text-Url
            case 13:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <Input
                                              prefix={<GlobalOutlined />}
                                              className='w-full mt-2 pl-[6px]'
                                              placeholder={t('attributes:enter_url_here')}
                                              value={obj.attribute_value}
                                              onBlur={(e) => {
                                                  if (e.target.value) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value.trim().replace(/\s+/g, ' '),
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                              }}
                                              onChange={(e) => {
                                                  if (
                                                      e.target.value.trim() !== '' &&
                                                      validator.isLength(e.target.value.trim(), {
                                                          min: 0,
                                                          max: urlMaxLength,
                                                      })
                                                  ) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  } else if (e.target.value.trim() === '') {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                                  // attributeValeuHandler(
                                                  //   "",
                                                  //   e.target.value,
                                                  //   "",
                                                  //   productOptionValues.datatype
                                                  // );
                                              }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              disabled={isEditting && isCore ? true : false}></Input>
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // "Text-Email"
            case 11:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <Input
                                              prefix={<MailOutlined />}
                                              className='w-full'
                                              placeholder={t('attributes:enter_your_email_address')}
                                              value={obj.attribute_value}
                                              onBlur={(e) => {
                                                  if (e.target.value) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value.trim().replace(/\s+/g, ' '),
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                              }}
                                              onChange={(e) => {
                                                  if (
                                                      e.target.value.trim() !== '' &&
                                                      validator.isLength(e.target.value.trim(), {
                                                          min: 0,
                                                          max: emailMaxLength,
                                                      })
                                                  ) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  } else if (e.target.value.trim() === '') {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                                  // attributeValeuHandler(
                                                  //   "",
                                                  //   e.target.value,
                                                  //   "",
                                                  //   productOptionValues.datatype
                                                  // );
                                              }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              disabled={isEditting && isCore ? true : false}></Input>
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Text Rich
            case 10:
                return (
                    <>
                        <TextRichEditor
                            data={textRichEditorData}
                            placeholder={t('attributes:enter_description')}
                            setData={setTextRichEditorData}
                            isTextFieldEmpty={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                            }
                            minHeight={'min-h-[200px]'}
                        />
                    </>
                )

            // File-image
            case 16:
                return (
                    <>
                        <Button
                            type='dashed'
                            className='ant-btn-secondary'
                            danger={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                                    ? true
                                    : false
                            }
                            style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                            block
                            onClick={() => setOpenImageModel(true)}
                            disabled={
                                isEditting && isCore
                                    ? true
                                    : getAttributeUnitValue('ext')[0].value.length > 0
                                      ? false
                                      : true
                            }>
                            {productAttributeValues.length > 0 && productAttributeValues[0].attribute_value !== ''
                                ? t('attributes:change_image')
                                : t('attributes:add_image')}
                        </Button>
                        {/* Multiple Todo */}
                        {/* {(productOptionValues && productOptionValues.attribute_options.length > 0 && productOptionValues.attribute_options[0].option_name != "" ) ? <Row><DisplayImage ImageData={ isEditting ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options) :  productOptionValues.attribute_options.map(({option_name})=> option_name)} setAllImages={setImageData} fileType={"image"} deleteFromServer={false} size={"large"}></DisplayImage></Row> : ""} */}
                        {productAttributeValues &&
                        productAttributeValues.length > 0 &&
                        productAttributeValues[0].attribute_value != '' ? (
                            <Row>
                                <DisplayImage
                                    ImageData={
                                        isEditting && typeof productAttributeValues[0].attribute_value === 'string'
                                            ? generateObjectFromUrlWithFullImagePath(
                                                  productAttributeValues[0].attribute_value
                                              )
                                            : // [
                                              //     generateObjectFromUrl(
                                              //       productAttributeValues[0].attribute_value
                                              //     ),
                                              //   ]
                                              productAttributeValues[0].attribute_value
                                    }
                                    setAllImages={setImageData}
                                    fileType={'image'}
                                    deleteFromServer={false}
                                    size={'large'}
                                    closeButton={false}></DisplayImage>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                )
            case 18:
                return (
                    <>
                        <Button
                            type='dashed'
                            danger={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                                    ? true
                                    : false
                            }
                            style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                            block
                            onClick={() => setOpenImageModel(true)}
                            disabled={
                                isEditting && isCore
                                    ? true
                                    : getAttributeUnitValue('ext')[0].value.length > 0
                                      ? false
                                      : true
                            }>
                            {productAttributeValues.length > 0 && productAttributeValues[0].attribute_value !== ''
                                ? t('attributes:change_document')
                                : t('attributes:add_document')}
                        </Button>
                        {/* Multiple Todo */}
                        {/* {(productOptionValues && productOptionValues.attribute_options.length > 0 && productOptionValues.attribute_options[0].option_name != "" ) ? <Row><DisplayImage ImageData={ isEditting ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options) :  productOptionValues.attribute_options.map(({option_name})=> option_name)} setAllImages={setImageData} fileType={"image"} deleteFromServer={false} size={"large"}></DisplayImage></Row> : ""} */}
                        {productAttributeValues &&
                        productAttributeValues.length > 0 &&
                        productAttributeValues[0].attribute_value != '' ? (
                            <Row>
                                <DisplayImage
                                    ImageData={
                                        isEditting && typeof productAttributeValues[0].attribute_value === 'string'
                                            ? generateObjectFromUrlWithFullDocumentPath(
                                                  productAttributeValues[0].attribute_value
                                              )
                                            : // [
                                              //     generateObjectFromUrl(
                                              //       productAttributeValues[0].attribute_value
                                              //     ),
                                              //   ]
                                              productAttributeValues[0].attribute_value
                                    }
                                    setAllImages={setImageData}
                                    fileType={attributeFileType}
                                    deleteFromServer={false}
                                    size={'large'}></DisplayImage>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                )
            case 19:
                return (
                    <>
                        <Button
                            type='dashed'
                            danger={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                                    ? true
                                    : false
                            }
                            style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                            block
                            onClick={() => setOpenImageModel(true)}
                            disabled={
                                isEditting && isCore
                                    ? true
                                    : getAttributeUnitValue('ext')[0].value.length > 0
                                      ? false
                                      : true
                            }>
                            {productAttributeValues.length > 0 && productAttributeValues[0].attribute_value !== ''
                                ? t('attributes:change_pdf')
                                : t('attributes:add_pdf')}
                        </Button>
                        {/* Multiple Todo */}
                        {/* {(productOptionValues && productOptionValues.attribute_options.length > 0 && productOptionValues.attribute_options[0].option_name != "" ) ? <Row><DisplayImage ImageData={ isEditting ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options) :  productOptionValues.attribute_options.map(({option_name})=> option_name)} setAllImages={setImageData} fileType={"image"} deleteFromServer={false} size={"large"}></DisplayImage></Row> : ""} */}
                        {productAttributeValues &&
                        productAttributeValues.length > 0 &&
                        productAttributeValues[0].attribute_value != '' ? (
                            <Row>
                                <DisplayImage
                                    ImageData={
                                        isEditting && typeof productAttributeValues[0].attribute_value === 'string'
                                            ? generateObjectFromUrlWithFullDocumentPath(
                                                  productAttributeValues[0].attribute_value
                                              )
                                            : // [
                                              //     generateObjectFromUrl(
                                              //       productAttributeValues[0].attribute_value
                                              //     ),
                                              //   ]
                                              productAttributeValues[0].attribute_value
                                    }
                                    setAllImages={setImageData}
                                    fileType={attributeFileType}
                                    deleteFromServer={false}
                                    size={'large'}></DisplayImage>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                )
            // File
            case 15:
                return (
                    <>
                        <Button
                            type='dashed'
                            danger={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                                    ? true
                                    : false
                            }
                            style={{ width: '70%', marginBottom: '18px', fontWeight: 500 }}
                            block
                            onClick={() => setOpenImageModel(true)}
                            disabled={isEditting && isCore ? true : false}>
                            {productAttributeValues.length > 0 && productAttributeValues[0].attribute_value !== ''
                                ? t('attributes:change_file')
                                : t('attributes:add_file')}
                        </Button>
                        {/* Multiple Todo */}
                        {/* {(productOptionValues && productOptionValues.attribute_options.length > 0 && productOptionValues.attribute_options[0].option_name != "" ) ? <Row><DisplayImage ImageData={isEditting ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options) : productOptionValues.attribute_options.map(({ option_name }) => option_name)} setAllImages={setImageData} fileType={attributeFileType} deleteFromServer={false} size={"large"}></DisplayImage></Row> : ""} */}
                        {productAttributeValues &&
                        productAttributeValues.length > 0 &&
                        productAttributeValues[0].attribute_value != '' ? (
                            <Row>
                                <DisplayImage
                                    ImageData={
                                        isEditting && typeof productAttributeValues[0].attribute_value === 'string'
                                            ? generateObjectFromUrlWithFullDocumentPath(
                                                  productAttributeValues[0].attribute_value
                                              )
                                            : // [
                                              //     generateObjectFromUrl(
                                              //       productAttributeValues[0].attribute_value
                                              //     ),
                                              //   ]
                                              productAttributeValues[0].attribute_value
                                    }
                                    setAllImages={setImageData}
                                    fileType={'document'}
                                    deleteFromServer={false}
                                    size={'large'}></DisplayImage>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                )
            // video
            case 17:
                return (
                    <>
                        <Button
                            type='dashed'
                            danger={
                                productAttributeValues &&
                                productAttributeValues.length > 0 &&
                                productAttributeValues[0].isNonEditableFieldEmpty
                                    ? true
                                    : false
                            }
                            style={{ width: '70%', marginBottom: '18px', fontWeight: 500 }}
                            block
                            onClick={() => setOpenImageModel(true)}
                            disabled={isEditting && isCore ? true : false}>
                            {t('product_template:change_video')}
                        </Button>
                        {/* Multiple Todo */}
                        {/* {(productOptionValues && productOptionValues.attribute_options.length > 0 && productOptionValues.attribute_options[0].option_name != "" ) ? <Row><DisplayImage ImageData={isEditting ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options) : productOptionValues.attribute_options.map(({ option_name }) => option_name)} setAllImages={setImageData} fileType={attributeFileType} deleteFromServer={false} size={"large"}></DisplayImage></Row> : ""} */}
                        {productAttributeValues &&
                        productAttributeValues.length > 0 &&
                        productAttributeValues[0].attribute_value != '' ? (
                            <Row>
                                <DisplayImage
                                    ImageData={
                                        isEditting && typeof productAttributeValues[0].attribute_value === 'string'
                                            ? [generateObjectFromUrl(productAttributeValues[0].attribute_value)]
                                            : productAttributeValues[0].attribute_value
                                    }
                                    setAllImages={setImageData}
                                    fileType={'document'}
                                    deleteFromServer={false}
                                    size={'large'}></DisplayImage>
                            </Row>
                        ) : (
                            ''
                        )}
                    </>
                )

            //? "Text-Phonenumber"
            case 12:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj, index) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}_${index}`}>
                                          <Input
                                              className='w-[11%]'
                                              value={phoneCode}
                                              onChange={(e) => {
                                                  let optionsCopy = { ...productOptions }
                                                  optionsCopy.isNonEditableValueEdited = true
                                                  setProductOptions(optionsCopy)
                                                  setIsPhoneCodeNotValid(false)
                                                  // let number = /^[0-9]*$/.test(e.target.value);
                                                  // to allow only 10 digits
                                                  if (/^\+(\d{1}\-)?(\d{1,3})$/.test(e.target.value)) {
                                                      // let phoneNumber = /^(?:00|\\+)[0-9]*$/.test(e.target.value);
                                                      // console.log("numberr...", phoneNumber);
                                                      setPhoneCode(e.target.value)
                                                  } else if (e.target.value === '+') {
                                                      setPhoneCode(e.target.value)
                                                  }
                                              }}
                                              status={isPhoneCodeNotValid ? 'error' : ''}></Input>
                                          <Input
                                              placeholder={t('attributes:enter_phone_number')}
                                              className='w-full rounded-tl-none rounded-bl-none'
                                              value={obj.attribute_value}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              onChange={(e) => {
                                                  // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                                  // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                                  let number = /^[0-9]*$/.test(e.target.value)
                                                  // to allow only 10 digits
                                                  if (number && e.target.value.length <= 10) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  } else if (e.target.value === '') {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Float-positive
            case 20:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              defaultValue={obj.attribute_value}
                                              min='0'
                                              max={advancedUnitMaxValue}
                                              onChange={(value) => {
                                                  attributeValeuHandler('', value, '', productOptionValues.datatype)
                                              }}
                                              stringMode
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              onKeyPress={(e) => {
                                                  validatePositiveFloatNumber(e, /^[0-9.]+$/, advancedUnitMaxValue, 2)
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Float-round off
            case 25:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              step={getAttributeUnitValue('decimal')[0]?.value == 1 ? "0.1" : "0.01"}
                                              defaultValue={obj.attribute_value}
                                              precision={getAttributeUnitValue('decimal')[0]?.value}
                                              onChange={(value) => {
                                                  let formatValue =
                                                      parseFloat(value).toFixed(
                                                          getAttributeUnitValue('decimal')[0]?.value
                                                      ) || value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              onBlur={(e) => {
                                                  let formatValue =
                                                      parseFloat(e.target.value).toFixed(
                                                          getAttributeUnitValue('decimal')[0]?.value
                                                      ) || e.target.value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              max={999999999.99}
                                              stringMode
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              onKeyPress={(e) => {
                                                  validatePositiveFloatNumber(
                                                      e,
                                                      /^[0-9.]+$/,
                                                      999999999.99,
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  )
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // float-round off limit
            case 26:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              defaultValue={obj.attribute_value}
                                              step={getAttributeUnitValue('decimal')[0]?.value == 1 ? "0.1" : "0.01"}
                                              precision={getAttributeUnitValue('decimal')[0]?.value}
                                              min={
                                                  typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('min')[0].value[0])
                                                      : getAttributeUnitValue('min')[0].value[0] || 0
                                              }
                                              max={
                                                  typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('max')[0].value[0])
                                                      : getAttributeUnitValue('max')[0].value[0] || 999999999.99
                                              }
                                              onChange={(value) => {
                                                  let formatValue =
                                                      parseFloat(value).toFixed(
                                                          getAttributeUnitValue('decimal')[0]?.value
                                                      ) || value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              onBlur={(e) => {
                                                  let formatValue =
                                                      parseFloat(e.target.value).toFixed(
                                                          getAttributeUnitValue('decimal')[0]?.value
                                                      ) || e.target.value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              stringMode
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : invalidAttributeValue
                                                        ? 'error'
                                                        : ''
                                              }
                                              onKeyPress={(e) => {
                                                  validatePositiveFloatNumber(
                                                      e,
                                                      /^[0-9.]+$/,
                                                      typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                          ? String(getAttributeUnitValue('max')[0].value[0])
                                                          : getAttributeUnitValue('max')[0].value[0] || 999999999.99,
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  )
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )

            // Number-percentage
            case 21:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              defaultValue={obj.attribute_value}
                                              min={0}
                                              max={100}
                                              suffix={'%'}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              onKeyPress={(e) => {
                                                validatePercentage(e,
                                                    /^[0-9.]+$/,
                                                    2)
                                              }}
                                              onChange={(value) => {
                                                  attributeValeuHandler(
                                                      '',
                                                      `${value}`,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Number-positive
            case 22:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              defaultValue={obj.attribute_value}
                                              min={1}
                                              maxLength={9}
                                              max={999999999}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              onChange={(value) => {
                                                  attributeValeuHandler('', value, '', productOptionValues.datatype)
                                              }}
                                              // onKeyPress={(e) => {
                                              //     let charCode = e.which || e.keyCode;
                                              //     if (isAlphabetOrNot(charCode)) {
                                              //         console.log("Enterd alphabet/specialcharacter, bnnnn", e.target.value)
                                              //         e.preventDefault();
                                              //     } else {
                                              //         console.log("Enterd number, bnnnn", e.target.value)
                                              //     }
                                              // }}
                                              onKeyPress={(e) => {
                                                  validatePositiveNumber(e, /[0-9]/)
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            //  Integer Limit
            case 23:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              value={obj.attribute_value}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : invalidAttributeValue
                                                        ? 'error'
                                                        : ''
                                              }
                                              onChange={(value) => {
                                                  attributeValeuHandler('', value, '', productOptionValues.datatype)
                                              }}
                                              onKeyPress={(e) => {
                                                  validatePositiveNumber(e, /[0-9]/)
                                              }}
                                              min={
                                                  typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('min')[0].value[0])
                                                      : getAttributeUnitValue('min')[0].value[0]
                                              }
                                              max={
                                                  typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('max')[0].value[0])
                                                      : getAttributeUnitValue('max')[0].value[0]
                                              }
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // "float-limit"
            case 24:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <InputNumber
                                              className='w-full'
                                              defaultValue={obj.attribute_value}
                                              min={
                                                  typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('min')[0].value[0])
                                                      : getAttributeUnitValue('min')[0].value[0] || 0
                                              }
                                              max={
                                                  typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('max')[0].value[0])
                                                      : getAttributeUnitValue('max')[0].value[0] || 999999999
                                              }
                                              onChange={(value) => {
                                                  let formatValue = parseFloat(value).toFixed(2) || value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              onBlur={(e) => {
                                                  let formatValue =
                                                      parseFloat(e.target.value).toFixed(2) || e.target.value
                                                  attributeValeuHandler(
                                                      '',
                                                      formatValue,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              stringMode
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : invalidAttributeValue
                                                        ? 'error'
                                                        : ''
                                              }
                                              onKeyPress={(e) => {
                                                  validatePositiveFloatNumber(
                                                      e,
                                                      /^[0-9.]+$/,
                                                      typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                          ? String(getAttributeUnitValue('max')[0].value[0])
                                                          : getAttributeUnitValue('max')[0].value[0] || 999999999,
                                                      2
                                                  )
                                              }}
                                              disabled={isEditting && isCore ? true : false}
                                              placeholder={t('attributes:attribute_value_placeholder')}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // "Text-Name"
            case 27:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <Input
                                              className='w-full'
                                              placeholder={t('attributes:enter_your_name')}
                                              prefix={<UserOutlined />}
                                              value={obj.attribute_value}
                                              onBlur={(e) => {
                                                  if (e.target.value) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value.trim().replace(/\s+/g, ' '),
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                              }}
                                              onChange={(e) => {
                                                  const alphaWithoutSpaces = /^[a-zA-Z]+$/
                                                  if (
                                                      e.target.value.trim() !== '' &&
                                                      validator.isLength(e.target.value.trim(), {
                                                          min: 0,
                                                          max: titleMaxLength,
                                                      })
                                                  ) {
                                                      if (
                                                          e.target.value !== '' &&
                                                          validator.matches(e.target.value, alphaWithoutSpaces)
                                                      ) {
                                                          attributeValeuHandler(
                                                              '',
                                                              e.target.value,
                                                              '',
                                                              productOptionValues.datatype
                                                          )
                                                      }
                                                  } else if (e.target.value === '') {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                                  // attributeValeuHandler(
                                                  //   "",
                                                  //   e.target.value,
                                                  //   "",
                                                  //   productOptionValues.datatype
                                                  // );
                                              }}
                                              // onKeyPress={(e) => {
                                              //   let charCode = e.which || e.keyCode;
                                              //   if (!restrictNumbersAndSpecialCharacter(charCode)) {
                                              //     // will prevent the number and special character, not to be entered.
                                              //     e.preventDefault();
                                              //   }
                                              // }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              // addonAfter={
                                              //   storeLanguageFromReduxState &&
                                              //   storeLanguageFromReduxState.length > 0 &&
                                              //   isEditting ? (
                                              //     <TranslationOutlined className="iconsHoverClass"></TranslationOutlined>
                                              //   ) : (
                                              //     ""
                                              //   )
                                              // }
                                              disabled={isEditting && isCore ? true : false}></Input>
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // "Text"
            case 30:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <Input
                                              className='w-full'
                                              placeholder={t('attributes:enter_attribute_helper_text')}
                                              value={obj.attribute_value}
                                              onBlur={(e) => {
                                                  if (e.target.value) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value.trim().replace(/\s+/g, ' '),
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                              }}
                                              onChange={(e) => {
                                                  if (
                                                      e.target.value.trim() !== '' &&
                                                      validator.isLength(e.target.value.trim(), {
                                                          min: 0,
                                                          max: titleMaxLength,
                                                      })
                                                  ) {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  } else if (e.target.value.trim() === '') {
                                                      attributeValeuHandler(
                                                          '',
                                                          e.target.value,
                                                          '',
                                                          productOptionValues.datatype
                                                      )
                                                  }
                                                  // attributeValeuHandler(
                                                  //   "",
                                                  //   e.target.value,
                                                  //   "",
                                                  //   productOptionValues.datatype
                                                  // );
                                              }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              // addonAfter={
                                              //   storeLanguageFromReduxState &&
                                              //   storeLanguageFromReduxState.length > 0 &&
                                              //   isEditting ? (
                                              //     <TranslationOutlined className="iconsHoverClass"></TranslationOutlined>
                                              //   ) : (
                                              //     ""
                                              //   )
                                              // }
                                              disabled={isEditting && isCore ? true : false}></Input>
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Time
            case 9:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <TimePicker
                                              format={getAttributeUnitValue('format')[0].value[0]}
                                              value={
                                                  obj.attribute_value !== ''
                                                      ? dayjs(
                                                            obj.attribute_value,
                                                            getAttributeUnitValue('format')[0].value[0]
                                                        )
                                                      : null
                                              }
                                              onChange={(time, timeString) => {
                                                  console.log('time bnhbadjbds', time, timeString)
                                                  attributeValeuHandler(
                                                      '',
                                                      timeString,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              className='w-full'
                                              disabled={isEditting && isCore ? true : false}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // Date
            case 5:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <DatePicker
                                              inputReadOnly={true}
                                              size={'small'}
                                              use12Hours
                                              defaultValue={
                                                  obj.attribute_value !== ''
                                                      ? dayjs(
                                                            obj.attribute_value,
                                                            getAttributeUnitValue('format')[0].value[0] !== ''
                                                                ? getAttributeUnitValue('format')[0].value[0]
                                                                : 'YYYY-MM-DD'
                                                        )
                                                      : null
                                              }
                                              format={
                                                  getAttributeUnitValue('format')[0].value[0] != ''
                                                      ? getAttributeUnitValue('format')[0].value[0]
                                                      : 'YYYY-MM-DD'
                                              }
                                              onChange={(value, timestring) => {
                                                  attributeValeuHandler(
                                                      '',
                                                      timestring,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              className='w-full ant-datepicker-small'
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              disabled={isEditting && isCore ? true : false}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            // DateTime
            case 14:
                return (
                    <>
                        {productAttributeValues
                            ? productAttributeValues.length > 0 &&
                              productAttributeValues.map((obj) => {
                                  return (
                                      <div
                                          style={{
                                              display: 'flex',
                                              alignItems: 'center',
                                              marginBottom: '14px',
                                          }}
                                          key={`${obj.attribute_id}`}>
                                          <DatePicker
                                              inputReadOnly={true}
                                              size={'small'}
                                              placeholder={t('attributes:placeholder_date_time')}
                                              format={
                                                  getAttributeUnitValue('format')[0].value[0] !== ''
                                                      ? getAttributeUnitValue('format')[0].value[0]
                                                      : 'YYYY-MM-DD HH:mm:ss'
                                              }
                                              value={
                                                  obj.attribute_value !== ''
                                                      ? dayjs(
                                                            obj.attribute_value,
                                                            dayjs(
                                                                obj.attribute_value,
                                                                getAttributeUnitValue('format')[0].value[0] !== ''
                                                                    ? getAttributeUnitValue('format')[0].value[0]
                                                                    : 'YYYY-MM-DD HH:mm:ss'
                                                            )
                                                        )
                                                      : null
                                              }
                                              showTime
                                              className='w-full ant-datepicker-small'
                                              onChange={(value, timestring) => {
                                                  console.log('darsvadbrtne', timestring)
                                                  attributeValeuHandler(
                                                      '',
                                                      timestring,
                                                      '',
                                                      productOptionValues.datatype
                                                  )
                                              }}
                                              status={
                                                  obj.isAttributeValueFieldInvalid || obj.isNonEditableFieldEmpty
                                                      ? 'error'
                                                      : ''
                                              }
                                              disabled={isEditting && isCore ? true : false}
                                          />
                                      </div>
                                  )
                              })
                            : ''}
                    </>
                )
            default:
                return ''
        }
    } else {
        switch (datatype) {
            // CheckBox
            case 1:
                return (
                    <Row
                        className={`flex items-center p-2 mt-2 mb-3 w-full ${
                            isAttributeValueSelected ? '' : 'p-2 border-2 border-red-500'
                        }`}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0
                            ? productAttributeValues
                                  .filter(({ attribute_value }) => attribute_value !== '')
                                  .map(
                                      ({ attribute_id, attribute_value, attributeValueSelected, attribute_option }) => {
                                          return (
                                              <Tooltip
                                                  title={attribute_value}
                                                  placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}>
                                                  <div
                                                      className={`${
                                                          langDirection == 'rtl' ? 'pl-2' : 'pr-2'
                                                      } truncate`}>
                                                      <Checkbox
                                                          checked={attributeValueSelected}
                                                          className={`${langDirection == 'rtl' ? 'ml-2' : 'mr-2'}`}
                                                          onChange={(e) => {
                                                              attributeValeuHandler(
                                                                  attribute_option,
                                                                  e.target.checked,
                                                                  attribute_value,
                                                                  datatype
                                                              )
                                                          }}
                                                          disabled={isEditting && isCore ? true : false}
                                                      />
                                                      <span>{attribute_value}</span>
                                                  </div>
                                              </Tooltip>
                                          )
                                      }
                                  )
                            : ''}
                    </Row>
                )
            // Radio
            case 2:
                return (
                    <Row
                        className={`flex items-center p-2 mt-2 mb-3 w-full ${
                            isAttributeValueSelected ? '' : 'p-2 border-2 border-red-500'
                        }`}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0
                            ? productAttributeValues
                                  .filter(({ attribute_value }) => attribute_value !== '')
                                  .map(
                                      ({ attribute_id, attribute_value, attributeValueSelected, attribute_option }) => {
                                          return (
                                              <Tooltip
                                                  title={attribute_value}
                                                  placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}>
                                                  <div className='truncate'>
                                                      <Radio
                                                          key={`${attribute_value}_${attribute_option}`}
                                                          checked={attributeValueSelected}
                                                          onChange={(e) => {
                                                              attributeValeuHandler(
                                                                  attribute_option,
                                                                  e.target.checked,
                                                                  attribute_value,
                                                                  datatype
                                                              )
                                                          }}
                                                          disabled={isEditting && isCore ? true : false}>
                                                          {attribute_value}
                                                      </Radio>
                                                  </div>
                                              </Tooltip>
                                          )
                                      }
                                  )
                            : ''}
                    </Row>
                )
            // Choice Chip
            case 3:
                return (
                    <Row
                        className={`flex items-center p-2 mt-3 mb-3 w-[120%] ${
                            isAttributeValueSelected ? '' : 'p-2 border-2 border-red-500'
                        }`}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0
                            ? productAttributeValues
                                  .filter(({ attribute_value }) => attribute_value !== '')
                                  .map(
                                      ({ attribute_id, attribute_value, attributeValueSelected, attribute_option }) => {
                                          return (
                                              <Tooltip
                                                  title={attribute_value}
                                                  placement='top'
                                                  className='!min-w-[270px] my-1'>
                                                  <Tag.CheckableTag
                                                      key={`${attribute_value}_${attribute_option}`}
                                                      checked={attributeValueSelected}
                                                      className='border-2 border-gray-400 truncate'
                                                      onChange={(e) => {
                                                          attributeValeuHandler(
                                                              attribute_option,
                                                              e,
                                                              attribute_value,
                                                              datatype
                                                          )
                                                      }}>
                                                      {attribute_value}
                                                  </Tag.CheckableTag>
                                              </Tooltip>
                                          )
                                      }
                                  )
                            : ''}
                    </Row>
                )
            // Toggle
            case 4:
                const maxLength = 15
                return (
                    <div
                        className={`${
                            isAttributeValueSelected ? 'max-w-[100px]' : 'p-2 border-1 border-red-500 max-w-[100px]'
                        }`}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length >= 2 ? (
                            <Tooltip
                                title={
                                    productAttributeValues.filter(
                                        ({ attributeValueSelected }) => attributeValueSelected
                                    )[0].attribute_value
                                }
                                placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}>
                                <Switch
                                    //   className="!bg-[#808080] mt-2 mb-3"
                                    checkedChildren={
                                        <span
                                            style={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}>
                                            {productAttributeValues[0].attribute_value.length > maxLength
                                                ? `${productAttributeValues[0].attribute_value.slice(0, maxLength)}...`
                                                : productAttributeValues[0].attribute_value}
                                        </span>
                                    }
                                    unCheckedChildren={
                                        <span
                                            style={{
                                                maxWidth: '100%',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                                whiteSpace: 'nowrap',
                                            }}>
                                            {productAttributeValues[1].attribute_value.length > maxLength
                                                ? `${productAttributeValues[1].attribute_value.slice(0, maxLength)}...`
                                                : productAttributeValues[1].attribute_value}
                                        </span>
                                    }
                                    defaultChecked={
                                        productAttributeValues.filter(
                                            ({ attributeValueSelected }) => attributeValueSelected
                                        ).length >= 1
                                            ? productAttributeValues.filter(
                                                  ({ attributeValueSelected }) => attributeValueSelected
                                              )[0].attribute_value ===
                                              productOptionValues.attribute_options[0].option_name
                                            : productAttributeValues[0].attribute_value
                                    }
                                    style={{ backgroundColor: 'gray' }}
                                    onChange={(checked, e) => {
                                        console.log(
                                            'togglenbs',
                                            checked,
                                            e.currentTarget.innerText,
                                            productAttributeValues,
                                            productAttributeValues.filter(
                                                ({ attribute_value }) => attribute_value !== e.currentTarget.innerText
                                            )[0].attribute_id
                                        )
                                        attributeValeuHandler(
                                            productAttributeValues[0].attribute_value,
                                            '',
                                            checked,
                                            datatype
                                        )
                                    }}
                                    disabled={isEditting && isCore ? true : false}
                                />
                            </Tooltip>
                        ) : (
                            ''
                        )}
                    </div>
                )
            //   drop down
            case 7:
                return (
                    <Select
                        className='w-60 mt-2 mb-3'
                        value={
                            productAttributeValues.filter(({ attributeValueSelected }) => attributeValueSelected)[0]
                                ?.attribute_value
                        }
                        // dropdownStyle={{
                        //   maxHeight: "150px",
                        //   overflowY: "auto",
                        // }}
                        listHeight={150}
                        onChange={(value) => {
                            attributeValeuHandler('', '', value, datatype)
                        }}
                        placeholder={t('attributes:select_attribute_value')}
                        status={isAttributeValueSelected ? '' : 'error'}
                        disabled={isEditting && isCore ? true : false}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0
                            ? productAttributeValues
                                  .filter(({ attribute_value }) => attribute_value !== '')
                                  .map(
                                      ({ attribute_id, attribute_value, attributeValueSelected, attribute_option }) => {
                                          return (
                                              <Select.Option
                                                  key={`${attribute_value}_${attribute_option}`}
                                                  value={attribute_value}>
                                                  {attribute_value}
                                              </Select.Option>
                                          )
                                      }
                                  )
                            : ''}
                    </Select>
                )
            // switcher
            case 8:
                return (
                    <Radio.Group
                        className={`w-60 mt-2 mb-3 ${isAttributeValueSelected ? '' : 'p-2 border-2 border-red-500'}`}
                        // buttonStyle="solid"
                        value={
                            productAttributeValues.filter(({ attributeValueSelected }) => attributeValueSelected)[0]
                                ?.attribute_value
                        }
                        onChange={(e) => {
                            attributeValeuHandler('', '', e.target.value, datatype)
                        }}
                        disabled={isEditting && isCore ? true : false}>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0
                            ? productAttributeValues
                                  .filter(({ attribute_value }) => attribute_value !== '')
                                  .map(
                                      ({ attribute_id, attribute_value, attributeValueSelected, attribute_option }) => {
                                          return (
                                              <Radio.Button
                                                  key={`${attribute_value}_${attribute_option}`}
                                                  value={attribute_value}>
                                                  <Tooltip
                                                      title={attribute_value}
                                                      // overlayStyle={{ zIndex: 1 }}
                                                      placement={`${langDirection == 'rtl' ? 'left' : 'right'}`}>
                                                      <Text
                                                          ellipsis={true}
                                                          style={{ maxWidth: 150, marginBottom: '3px' }}
                                                          className={
                                                              productAttributeValues.filter(
                                                                  ({ attributeValueSelected }) => attributeValueSelected
                                                              )[0]?.attribute_value == attribute_value
                                                                  ? '!text-[var(--mp-primary-color)]'
                                                                  : ''
                                                          }>
                                                          {attribute_value}
                                                      </Text>
                                                  </Tooltip>
                                              </Radio.Button>
                                          )
                                      }
                                  )
                            : ''}
                    </Radio.Group>
                )
            case 16:
                return (
                    <div className='mt-3 border-red-600 border-2 w-[6.5rem]'>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0 ? (
                            <>
                                {productAttributeValues
                                    .filter(({ attribute_value }) => attribute_value !== '')
                                    .map(({ attribute_id, attribute_value }) => {
                                        return (
                                            <Image
                                                key={`${attribute_id}`}
                                                width={100}
                                                src={getImageUrl(
                                                    attribute_value[0].image_path,
                                                    attribute_value[0].image_type,
                                                    'image'
                                                )}
                                            />
                                        )
                                    })}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                )
            case 18:
            case 19:
                return (
                    <div className='mt-3'>
                        {productAttributeValues &&
                        productAttributeValues.filter(({ attribute_value }) => attribute_value !== '').length > 0 ? (
                            <>
                                {productAttributeValues
                                    .filter(({ attribute_value }) => attribute_value !== '')
                                    .map(({ attribute_id, attribute_value }) => {
                                        return (
                                            <a
                                                key={`${attribute_id}`}
                                                href={getImageUrl(
                                                    attribute_value[0].document_path,
                                                    attribute_value[0].document_type,
                                                    'document'
                                                )}
                                                className='!underline !text-red-600'>
                                                {t('attributes:download_file')}
                                            </a>
                                        )
                                    })}
                            </>
                        ) : (
                            ''
                        )}
                    </div>
                )
            default:
                return ''
        }
    }
}

export default StaticAttributeControls
