//!React Library Imports
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
//!Ant Design Library Imports
import { Button, Divider, Empty, Layout, Select, Spin, Table, Tooltip, Typography } from 'antd'
import { useSearchParams } from 'react-router-dom'
//!User Defined Components.
import { useSelector } from 'react-redux'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { processAllPublishedProductsDropdown } from '../../constants/dataProcessor/ProductsDropDownDataProcessers'
import useGetActiveProductsList from '../../hooks/useGetActiveProductsList'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'
import { filterSelectedDataAtTop } from './FilterProductDataToTop'
const relativeProductsAPI = process.env.REACT_APP_RELATIVE_PRODUCTS_API
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)

//!Destructing of the Imports.
const { Title, Text, Paragraph } = Typography
const { Content } = Layout

const RelatedProducts = () => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal') + ' - ' + String(` ${t('common:related_products')}`))
    const [mappedRelatedProducts, setMappedRelatedProducts] = useState([])
    const [isLoadingMappedRelatedProducts, setIsLoadingMappedRelatedProducts] = useState(false)
    /**
     * The below state variable is to check for the selected product from the dropdown and set that particular product id
     */
    const [selectedProductId, setSelectedProductId] = useState()
    /**
     * The below state variable is to check for the selected products from the table and set that particular product id's
     */
    const [selectedProductsIds, setSelectedProductsIds] = useState([])
    const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState(true)
    const [isUpdateButtonDisabled, setIsUpdateButtonDisabled] = useState(false)
    const searchInput = useRef(null)

    const [searchParams, setSearchParams] = useSearchParams()
    const [searchKey, setSearchKey] = useState('')
    const { loading, isNetworkError, apiProcessedData, searchKeyTriggered, totalProducts } =
        useGetActiveProductsList(searchKey)
    const [langDirection, setLangDirection] = useState(util.getSelectedLanguageDirection() || 'ltr')

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI
    const findAllRelatedProducts = (product_id, filteredData) => {
        MarketplaceServices.findAll(relativeProductsAPI, { product_id: parseInt(product_id) }, true)
            .then(function (response) {
                console.log(
                    'DM2-> server response from get relative products BY product id:',
                    relativeProductsAPI,
                    response.data.response_body.relative_products_data
                )
                if (
                    response.data.response_body &&
                    response.data.response_body.relative_products_data &&
                    response.data.response_body.relative_products_data.length > 0
                ) {
                    setMappedRelatedProducts(response.data.response_body.relative_products_data[0].relative_products)
                    setSelectedProductsIds(
                        () => response.data.response_body.relative_products_data[0].relative_products
                    )
                } else {
                    setMappedRelatedProducts([])
                    setSelectedProductsIds([])
                }
                setIsLoadingMappedRelatedProducts(false)
            })

            .catch(function (error) {
                console.log('error', error)
                console.log('Server Error response from store product types', relativeProductsAPI, error.response)
                setIsLoadingMappedRelatedProducts(false)
            })
    }

    const handleDropdownProductChanged = (value) => {
        setIsLoadingMappedRelatedProducts(true)
        setSelectedProductId(value)
        setSelectedProductsIds([])
        findAllRelatedProducts(value)
    }

    const handleButtonClick = () => {
        if (selectedProductId) {
            if (selectedProductsIds.length > 0) {
                if (mappedRelatedProducts.length > 0) {
                    updateRelatedProductMapping()
                } else {
                    saveRelatedProductMapping()
                }
            } else {
                if (mappedRelatedProducts.length > 0) {
                    updateRelatedProductMapping()
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(
                            `${t('relative_products:please_select_one_or_more_products_from_table')}`,
                            'error'
                        )
                    )
                }
            }
        } else {
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('relative_products:please_select_product_from_dropdown_menu')}`, 'error')
            )
        }
    }

    const onRowSelectChange = (newSelectedRowKeys) => {
        setSelectedProductsIds(newSelectedRowKeys)
        setIsUpdateButtonDisabled(false)
        if (newSelectedRowKeys && newSelectedRowKeys.length === 0) {
            setIsSaveButtonDisabled(true)
        } else {
            setIsSaveButtonDisabled(false)
        }
    }
    const saveRelatedProductMapping = () => {
        const requestBody = {}
        setIsLoadingMappedRelatedProducts(true)
        requestBody['product'] = selectedProductId
        requestBody['relative_products'] = selectedProductsIds
        MarketplaceServices.save(relativeProductsAPI, requestBody, null)
            .then(function (response) {
                console.log(
                    'DM2-> server response from post relative products : ',
                    relativeProductsAPI,
                    response.data.response_body[0].relative_products
                )
                MarketplaceToaster.showToast(response)
                if (
                    response &&
                    response.data.response_body &&
                    response.data.response_body.length > 0 &&
                    response.data.response_body[0].relative_products &&
                    response.data.response_body[0].relative_products.length > 0
                ) {
                    setIsUpdateButtonDisabled(true)
                    setSelectedProductsIds(() => response.data.response_body[0].relative_products)
                    setMappedRelatedProducts(() => response.data.response_body[0].relative_products)
                    setIsLoadingMappedRelatedProducts(false)
                    let pageOneElement = document.getElementsByClassName('ant-pagination-item-1')
                    console.log('pageOneElement', pageOneElement)
                    if (pageOneElement && pageOneElement.length > 0) {
                        pageOneElement[0].click()
                    }
                } else if (
                    response &&
                    response.data.response_body &&
                    response.data.response_body.length > 0 &&
                    response.data.response_body[0].relative_products &&
                    response.data.response_body[0].relative_products.length === 0
                ) {
                    setIsUpdateButtonDisabled(true)
                    setIsLoadingMappedRelatedProducts(false)
                    let pageOneElement = document.getElementsByClassName('ant-pagination-item-1')
                    console.log('pageOneElement', pageOneElement)
                    if (pageOneElement && pageOneElement.length > 0) {
                        pageOneElement[0].click()
                    }
                }
                findAllRelatedProducts(selectedProductId)
            })
            .catch(function (error) {
                MarketplaceToaster.showToast(error.response)
                console.log('Server Error response from relative products save mapping', relativeProductsAPI, error)
                setIsLoadingMappedRelatedProducts(false)
            })
    }

    const updateRelatedProductMapping = () => {
        const requestBody = { relative_products: selectedProductsIds }
        setIsLoadingMappedRelatedProducts(true)
        MarketplaceServices.update(relativeProductsAPI, requestBody, {
            product_id: selectedProductId,
        })
            .then(function (response) {
                console.log(
                    'DM2-> server response from PUT updateRelatedProductMapping API is',
                    relativeProductsAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)
                if (
                    response &&
                    response.data.response_body &&
                    response.data.response_body.relative_products &&
                    response.data.response_body.relative_products.length > 0
                ) {
                    setIsUpdateButtonDisabled(true)
                    setSelectedProductsIds(() => response.data.response_body.relative_products)
                    setMappedRelatedProducts(() => response.data.response_body.relative_products)
                    setIsLoadingMappedRelatedProducts(false)
                    let pageOneElement = document.getElementsByClassName('ant-pagination-item-1')
                    console.log('pageOneElement', pageOneElement)
                    if (pageOneElement && pageOneElement.length > 0) {
                        pageOneElement[0].click()
                    }
                } else if (
                    response &&
                    response.data.response_body &&
                    response.data.response_body.relative_products &&
                    response.data.response_body.relative_products.length === 0
                ) {
                    setIsUpdateButtonDisabled(true)
                    setIsLoadingMappedRelatedProducts(false)
                    let pageOneElement = document.getElementsByClassName('ant-pagination-item-1')
                    console.log('pageOneElement', pageOneElement)
                    if (pageOneElement && pageOneElement.length > 0) {
                        pageOneElement[0].click()
                    }
                }
                findAllRelatedProducts(selectedProductId)
            })
            .catch(function (error) {
                MarketplaceToaster.showToast(error.response)
                console.log('Server Error from relative products update mapping', relativeProductsAPI, error)
                setIsLoadingMappedRelatedProducts(false)
            })
    }

    const columns = [
        {
            title: `${t('common:product_name')}`,
            dataIndex: 'product_name',
            key: 'product_name',
            render: (text, record) => {
                return (
                    <Tooltip overlayStyle={{ zIndex: 1 }} title={record && record.product_name}>
                        <Text
                            ellipsis={true}
                            style={{ maxWidth: 250 }}
                            className='!text-[14px] font-normal !text-brandGray1'>
                            {record && record.product_name}{' '}
                        </Text>
                    </Tooltip>
                )
            },
        },
        {
            title: `${t('relative_products:stock_quantity')}`,
            dataIndex: 'quantity',
            key: 'quantity',
            render: (text, record) => {
                return (
                    <p className='!mb-0 font-normal !text-brandGray1'>
                        {record.quantity
                            ? parseInt(record.quantity) >= 1
                                ? record.quantity
                                : t('relative_products:out_of_stock')
                            : t('common:not_available')}
                    </p>
                )
            },
        },
        {
            title: `${t('relative_products:product_price')}`,
            dataIndex: 'product_price',
            key: 'product_price',
            render: (text, record) => {
                return (
                    <p className='font-normal !text-brandGray1 !mb-0'>
                        {currencySymbolFromRedux + ' ' + (record && record.product_price)}
                    </p>
                )
            },
        },
        {
            title: `${t('relative_products:selling_price')}`,
            dataIndex: 'selling_price',
            key: 'selling_price',
            render: (text, record) => {
                return (
                    <p className='font-normal !text-brandGray1 !mb-0'>
                        {currencySymbolFromRedux + ' ' + (record && record.selling_price)}
                    </p>
                )
            },
        },
        {
            title: `${t('products:discount')}(%)`,
            dataIndex: 'discount_details',
            key: 'discount_details',
            render: (text, record) => {
                return (
                    <Tooltip overlayStyle={{ zIndex: 1 }} title={record && record.discount_details}>
                        <Text
                            ellipsis={true}
                            style={{ maxWidth: 100 }}
                            className='!text-[14px]font-normal !text-brandGray1'>
                            {record && record.discount_details}{' '}
                        </Text>
                    </Tooltip>
                )
            },
        },
    ]
    const handlePageNumberChange = (page, limit) => {
        window.scrollTo(0, 0)
        setSearchParams({
            status: searchParams.get('status'),
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(limit) ? parseInt(limit) : pageLimit,
        })
    }

    const customPagination = {
        total: apiProcessedData.length,
        showTotal: (total, range) => `${range[0]}-${range[1]} ${t('common:of')} ${total} ${t('common:items')}`,
        defaultPageSize: itemsPerPageFromEnv,
        defaultCurrent: 1,
        onChange: handlePageNumberChange,
        pageSizeOptions: [20, 50, 100],
        showSizeChanger: true,
        showQuickJumper: true,
    }
    useEffect(() => {
        window.scrollTo(0, 0)
        let page = searchParams.get('page')
        let limit = searchParams.get('limit')
        if (parseInt(page) === 1 && parseInt(limit) === parseInt(itemsPerPageFromEnv)) {
            let pageOneElement = document.getElementsByClassName('ant-pagination-item-1')
            console.log('pageOneElement', pageOneElement)
            if (pageOneElement && pageOneElement.length > 0) {
                pageOneElement[0].click()
            }
        }
        let currentSelectedProductsIds = deepCopy(selectedProductsIds)
        setSelectedProductsIds([])
        setSelectedProductsIds(currentSelectedProductsIds)
    }, [searchParams])

    useEffect(() => {
        setSelectedProductId()
        setSelectedProductsIds([])
        setIsSaveButtonDisabled(true)
        util.setStoreNameWhenWeChangeINUrl()
    }, [])

    return (
        <Content>
            <HeaderForTitle
                title={
                    <Content className=''>
                        <Title level={3} className='!font-semibold !text-regal-blue !text-[24px]'>
                            {t('common:related_products')}
                        </Title>
                    </Content>
                }
                headerContent={
                    <Paragraph className='!font-normal !text-[14px] !text-brandGray1 !m-0 pb-3'>
                        {t('common:rel_prod_short_desc')}
                    </Paragraph>
                }
            />
            <Content className='!p-3 !min-h-screen'>
                {/* <Content className="!flex !justify-end mt-3">
          <Search
            placeholder={t("common:please_enter_search_text_here")}
            onSearch={handleSearchChange}
            onChange={handleInputChange}
            suffix={null}
            maxLength={searchMaxLength}
            enterButton
            allowClear
            className="!justify-end !w-[30%]"
          />
        </Content> */}
                {loading ? (
                    <Content className=' bg-white p-3 shadow-brandShadow rounded-lg'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Content className='text-center !mt-10 !mb-2 bg-white p-3  shadow-brandShadow rounded-lg'>
                        <h1 level={5}>{t('common:network_error')}</h1>
                    </Content>
                ) : (
                    <Content className='!bg-white p-3 shadow-brandShadow rounded-lg'>
                        {apiProcessedData && apiProcessedData.length > 0 ? (
                            apiProcessedData && apiProcessedData.length >= 1 ? (
                                <>
                                    <Content className=''>
                                        <div
                                            className={`flex items-center justify-between ${langDirection === 'RTL' ? 'flex-row-reverse' : ''}`}>
                                            {/* Left content which displays go back icon, title and other stuffs */}
                                            <Text className='!font-semibold !text-regal-blue !text-[18px] !flex !justify-start !items-center'>
                                                {t('relative_products:configure_related_products')}
                                            </Text>
                                            <Button
                                                className={`app-btn-primary !h-[32px] ml-auto ${
                                                    isSaveButtonDisabled ? '!h-[32px]' : ''
                                                }`}
                                                disabled={
                                                    (mappedRelatedProducts && mappedRelatedProducts.length === 0
                                                        ? selectedProductsIds.length === 0
                                                        : false) ||
                                                    (isUpdateButtonDisabled ? isUpdateButtonDisabled : false)
                                                }
                                                onClick={() => handleButtonClick()}>
                                                {!selectedProductId ? (
                                                    <>{t('common:save')}</>
                                                ) : mappedRelatedProducts.length === 0 ? (
                                                    <>{t('common:save')}</>
                                                ) : (
                                                    <>{t('common:update')}</>
                                                )}
                                            </Button>
                                        </div>

                                        <Divider className='my-3 w-full' />
                                        <Typography className='input-label-color mb-2'>
                                            {t('relative_products:choose_product')}
                                        </Typography>

                                        {/* Right content to other stuffs */}
                                        <Content className='!flex !justify-between'>
                                            <Select
                                                showSearch
                                                placeholder={`${t('relative_products:select_a_product')}`}
                                                ref={searchInput}
                                                virtual={false}
                                                className='!w-[350px]'
                                                style={{
                                                    whiteSpace: 'nowrap',
                                                    maxWidth: '350px',
                                                }}
                                                dropdownStyle={{
                                                    zIndex: 1,
                                                    maxHeight: '300px',
                                                }}
                                                optionFilterProp='children'
                                                value={selectedProductId}
                                                onChange={handleDropdownProductChanged}
                                                filterOption={(input, option) =>
                                                    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                }
                                                options={processAllPublishedProductsDropdown(apiProcessedData)}
                                            />
                                        </Content>
                                    </Content>
                                    <Paragraph className='mb-1 mt-3'>
                                        <Typography className='input-label-color'>
                                            {t('relative_products:choose_related_products')}
                                        </Typography>
                                    </Paragraph>

                                    <Content className='bg-white !p-3'>
                                        <Spin spinning={isLoadingMappedRelatedProducts} size='large'>
                                            <>
                                                <Table
                                                    rowSelection={{
                                                        selectedRowKeys: selectedProductsIds,
                                                        preserveSelectedRowKeys: true,
                                                        onChange: onRowSelectChange,
                                                    }}
                                                    loading={isLoadingMappedRelatedProducts}
                                                    dataSource={filterSelectedDataAtTop(
                                                        mappedRelatedProducts,
                                                        apiProcessedData,
                                                        selectedProductId
                                                    )}
                                                    columns={columns}
                                                    scroll={{}}
                                                    pagination={
                                                        apiProcessedData &&
                                                        apiProcessedData.length > itemsPerPageFromEnv
                                                            ? customPagination
                                                            : false
                                                    }
                                                    locale={{ emptyText: `${t('common:no_data')}` }}
                                                />
                                            </>
                                        </Spin>
                                    </Content>
                                </>
                            ) : (
                                <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                                    <Empty description={t('common:no_data_for_rel_prod')} />
                                </div>
                            )
                        ) : searchKeyTriggered ? (
                            <Content className='!text-center !mt-6 font-bold'>
                                <Text>{t('common:not_able_to_find_searched_details')}</Text>
                            </Content>
                        ) : (
                            <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                                <Empty description={t('common:no_data_for_rel_prod')} />
                            </div>
                        )}
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default RelatedProducts
