import { DownOutlined, EyeFilled, PlusOutlined, EyeOutlined } from '@ant-design/icons'
import { Alert, Button, Dropdown, Input, Layout, Space, Tooltip, Typography } from 'antd'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { RiInformationFill } from 'react-icons/ri'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import ProgressBar from '../../../components/progressBar/ProgressBar'
import StoreModal from '../../../components/storeModal/StoreModal'
import { Translation, TranslationDisabled, TrashIcon } from '../../../constants/media'
import util from '../../../util/common'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useCreateConsentTranslation from '../hooks/useCreateConsentTranslation'
import useCreateNewUserConsent from '../hooks/useCreateNewUserConsent'
import useCreateNewUserConsentVersion from '../hooks/useCreateNewUserConsentVersion'
import useGetConsentTranslation from '../hooks/useGetConsentTranslation'
import useGetUserConsentVersionDetails from '../hooks/useGetUserConsentVersionDetails'
import useUpdateConsentTranslation from '../hooks/useUpdateConsentTranslation'
import useUpdateStoreConsentStatus from '../hooks/useUpdateStoreConsentStatus'
import useUpdateStoreUserConsentVersion from '../hooks/useUpdateUserConsentVersion'
import '../policies.css'
import PolicyModal from './PolicyModal'
import VersionCreation from './VersionCreation'
const { Text, Title, Paragraph } = Typography
const { Content } = Layout

const Link = Quill.import('formats/link')
Link.sanitize = function (url) {
    const trimmedURL = url?.trim()
    // quill by default creates relative links if scheme is missing.
    if (!trimmedURL.startsWith('http://') && !trimmedURL.startsWith('https://')) {
        return `https://${trimmedURL}`
    }
    return trimmedURL
}

const modules = {
    toolbar: [
        [{ header: [1, 2, 3, 4, 5, 6, false] }],
        // [{ size: ["small", false, "large", "huge"] }], // custom dropdown
        ['bold', 'italic', 'underline', 'strike'], // toggled buttons
        ['blockquote'],
        ['link'],
        // [{ header: 1 }, { header: 2 }], // custom button values
        [{ list: 'ordered' }, { list: 'bullet' }],
        [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
        [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
        // [{ direction: "rtl" }], // text direction
        [{ color: [] }, { background: [] }], // dropdown with defaults from theme
        [{ align: [] }],
        ['clean'],
    ],
}

const formats = [
    'background',
    'bold',
    'color',
    'font',
    'code',
    'italic',
    'link',
    'size',
    'strike',
    'underline',
    'blockquote',
    'header',
    'indent',
    'list',
    'align',
    'direction',
    'script',
]
const CONSENT_NAME_LENGTH = 100

const PolicyCard = ({
    consent,
    refetchUserConsent,
    isNewPolicy,
    setAddNewPolicy,
    selectedTab,
    policyName,
    handelDeletePolicy,
    policyType,
}) => {
    const { t } = useTranslation()
    const { mutate: updateStoreUserConsentVersion, status: updateStoreUserConsentVersionStatus } =
        useUpdateStoreUserConsentVersion()

    const { mutate: createNewUserConsent, status: createNewUserConsentStatus } = useCreateNewUserConsent()
    const { mutate: updateStoreUserConsentUpdateStatus } = useUpdateStoreConsentStatus()
    const { mutate: createNewUserConsentVersion } = useCreateNewUserConsentVersion()
    const { data: versionHistoryData, refetch: refetchVersionHistoryData } = useGetUserConsentVersionDetails({
        storeUserConsentId: consent?.store_user_consent_id,
    })
    const { mutate: createConsentTranslation } = useCreateConsentTranslation()
    const { mutate: updateConsentTranslation } = useUpdateConsentTranslation()

    const [consentName, setConsentName] = useState(consent?.consent_name || policyName)
    const [description, setDescription] = useState(consent?.consent_description)
    const [descriptionText, setDescriptionText] = useState(consent?.consent_display_discription || description)
    const [titleModified, setTitleModified] = useState(false)
    const [descriptionModified, setDescriptionModified] = useState(false)
    const [confirmPublish, setConfirmPublish] = useState(false)
    const [selectVersion, setSelectVersion] = useState(false)
    const [integerCount, setIntegerCount] = useState(0)
    const [floatCount, setFloatCount] = useState(0)
    const [policyVersion, setPolicyVersion] = useState(consent?.version_number)
    const [newVersionFlag, setNewVersionFlag] = useState(false)
    const [versionHistoryFlag, setVersionHistoryFlag] = useState(false)
    const [activeTabVersion, setActiveTabVersion] = useState(0)
    const [translationFlag, setTranslationFlag] = useState(0)
    const [translationLanguage, setTranslationLanguage] = useState('')
    const [translationTitle, setTranslationTitle] = useState(null)
    const [translationDescription, setTranslationDescription] = useState(null)
    const [translationVersion, setTranslationVersion] = useState(consent?.id)
    const [translationVersionName, setTranslationVersionName] = useState(null)
    const [newTranslation, setNewTranslation] = useState(false)
    const [translationId, setTranslationId] = useState(false)
    const [translationButtonFlag, setTranslationButtonFlag] = useState(true)
    const [showTranslationAlert, setShowTranslationAlert] = useState(false)
    const [languageName, setLanguageName] = useState(null)

    const storeLanguages = useSelector((state) => state.reducerStoreLanguage.storeLanguage)

    const { data: consentTranslations, refetch: refetchConsentTranslations } = useGetConsentTranslation({
        versionId: translationVersion,
    })

    useEffect(() => {
        setIntegerCount(parseInt(String(formatVersion(consent?.version_number)).split('.')[0]))
        setFloatCount(parseInt(String(formatVersion(consent?.version_number)).split('.')[1]))
    }, [])

    const languageItems = []
    if (storeLanguages && storeLanguages.length > 0) {
        storeLanguages.forEach((element) => {
            const languageItem = {}
            languageItem['key'] = element.language_code
            languageItem['label'] = element.language
            languageItems.push(languageItem)
        })
    }
    useEffect(() => {
        if (storeLanguages && storeLanguages.length > 0) {
            let defaultLanguage = storeLanguages && storeLanguages.find((element) => element.is_default)
            setLanguageName(defaultLanguage?.language)
            setTranslationLanguage(defaultLanguage?.language_code)
            const filteredData = consentTranslations?.storconsent_version_displaynames?.filter(
                (val) => val.language_code === defaultLanguage.language_code
            )
            if (filteredData && filteredData.length > 0) {
                filteredData.forEach((val) => {
                    setTranslationTitle(val.display_name)
                    setTranslationDescription(val.display_description)
                    setTranslationVersionName(val.version_display_name)
                    setTranslationId(val.id)
                    setNewTranslation(false)
                })
            } else {
                setTranslationTitle(null)
                setTranslationDescription(null)
                setNewTranslation(true)
            }
        }
    }, [storeLanguages, consentTranslations])
    const handelConsentNameChange = (name) => {
        // if (name?.length > CONSENT_NAME_LENGTH) return
        setConsentName(name)
        setTitleModified(true)
    }

    const handelCancelPolicyName = () => {
        if (policyType !== 'CONTACT_POLICY') {
            setConsentName(consent?.consent_name || '')
            setTitleModified(false)
        }
    }

    const handelDescriptionChange = (content, delta, source, editor) => {
        setDescription(content)
        setDescriptionText(editor.getText(content)?.trim())
        if (!descriptionModified) setDescriptionModified(true)
    }

    const handleTranslationTitleChange = (title) => {
        setTranslationTitle(title)
        setTranslationButtonFlag(false)
    }

    const handleTranslationDescriptionChange = (data) => {
        setTranslationDescription(data)
        setTranslationButtonFlag(false)
    }

    const handelSaveConsent = () => {
        const body = {
            consent_description: description,
            consent_name: consentName?.trim(),
        }
        if (isNewPolicy) {
            body.user_type = selectedTab
            createNewUserConsent(
                { body },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        toast(t('policies:policy_saved_successfully'), {
                            type: 'success',
                        })
                        setTimeout(() => {
                            setAddNewPolicy(false)
                        }, [100])
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        } else {
            body.version_number = consent?.version_number
            updateStoreUserConsentVersion(
                { body, consentVersionId: consent?.id },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        refetchVersionHistoryData()
                        toast(t('policies:policy_saved_successfully'), {
                            type: 'success',
                        })
                        setTimeout(() => {
                            setDescriptionModified(false)
                            setTitleModified(false)
                        }, [500])
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
    }

    const handleTranslationLanguageChange = (lang) => {
        setTranslationLanguage(lang.key)
        setLanguageName(getLanguageName(lang.key))

        const filteredData = consentTranslations?.storconsent_version_displaynames?.filter(
            (val) => val.language_code === lang.key
        )

        if (filteredData && filteredData.length > 0) {
            filteredData.forEach((val) => {
                setTranslationTitle(val.display_name)
                setTranslationDescription(val.display_description)
                setTranslationVersionName(val.version_display_name)
                setTranslationId(val.id)
                setNewTranslation(false)
            })
        } else {
            setTranslationTitle(null)
            setTranslationDescription(null)
            setNewTranslation(true)
        }
        // resetting alret
        setShowTranslationAlert(false)
    }

    const getLanguageName = (lang) => {
        const lg = languageItems.find((val) => val.key === lang)
        return lg.label
    }

    const handleConsentTranslationCancel = () => {
        setTranslationFlag(false)
        setTranslationButtonFlag(true)
        setTranslationTitle(null)
        setTranslationDescription(null)
        setTranslationLanguage('')
        setShowTranslationAlert(false)
    }

    const handleConsentTranslationSave = () => {
        const body = {
            store_userconsent_version_displaynames: [
                {
                    version: translationVersion,
                    language_code: translationLanguage,
                    display_name: translationTitle?.trim(),
                    display_description: translationDescription,
                    version_display_name: translationVersionName,
                },
            ],
        }
        if (newTranslation) {
            createConsentTranslation(
                { body },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        setNewTranslation(false)
                        consentTranslations.count = consentTranslations.count + 1
                        refetchConsentTranslations()
                        setShowTranslationAlert(true)
                        setTranslationButtonFlag(true)
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        } else {
            body.store_userconsent_version_displaynames[0].id = translationId
            updateConsentTranslation(
                { body, consentVersionId: consent?.id },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        setNewTranslation(false)
                        refetchConsentTranslations()
                        setShowTranslationAlert(true)
                        setTranslationButtonFlag(true)
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
    }

    const handelSaveAndPublishConsent = () => {
        console.log('Consent : ', consent)
        setConfirmPublish(false)
        const createPolicyBody = {
            consent_description: description,
            consent_name: consentName?.trim(),
        }
        const statusUpdateBody = {
            status: 2,
        }
        if (isNewPolicy) {
            createPolicyBody.user_type = selectedTab
            createNewUserConsent(
                { createPolicyBody },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        updateStoreUserConsentUpdateStatus(
                            { consentVersionId: consent?.id, body: statusUpdateBody },
                            {
                                onSuccess: () => {
                                    refetchUserConsent()
                                    setTimeout(() => {
                                        setDescriptionModified(false)
                                        setTitleModified(false)
                                    }, [500])
                                },
                                onError: (err) => {
                                    toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                                        type: 'error',
                                    })
                                },
                            }
                        )
                        toast(t('policies:policy_saved_successfully'), {
                            type: 'success',
                        })
                        setTimeout(() => {
                            setAddNewPolicy(false)
                        }, [100])
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        } else {
            createPolicyBody.version_number = consent?.version_number
            updateStoreUserConsentVersion(
                { consentVersionId: consent?.id, createPolicyBody },
                {
                    onSuccess: () => {
                        refetchUserConsent()
                        refetchVersionHistoryData()
                        updateStoreUserConsentUpdateStatus(
                            { consentVersionId: consent?.id, body: statusUpdateBody },
                            {
                                onSuccess: (response) => {
                                    refetchUserConsent()
                                    toast(response.response_body.message || t('policies:status_updated_successfully'), {
                                        type: 'success',
                                    })
                                    setTimeout(() => {
                                        setDescriptionModified(false)
                                        setTitleModified(false)
                                    }, [500])
                                },
                                onError: (err) => {
                                    toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                                        type: 'error',
                                    })
                                },
                            }
                        )
                        setTimeout(() => {
                            setDescriptionModified(false)
                            setTitleModified(false)
                        }, [500])
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_saving_policy'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
    }

    const handelCancelDescription = () => {
        setDescriptionModified(false)
        setTitleModified(false)
        setDescription(consent?.description)
        setDescriptionText(consent?.description)
        handelCancelPolicyName()
    }

    const getDate = (date) => {
        try {
            const formattedDate = moment(date).format('D MMM YYYY, HH:mm')
            return formattedDate
        } catch (error) {
            return ''
        }
    }

    const viewVersionHistory = () => {
        setVersionHistoryFlag(true)
    }

    const viewTranslationPopup = (policy) => {
        refetchConsentTranslations()
        setTranslationFlag(true)
        if (storeLanguages && storeLanguages.length > 0) {
            let defaultLanguage = storeLanguages && storeLanguages.find((element) => element.is_default)
            setLanguageName(defaultLanguage?.language)
            setTranslationLanguage(defaultLanguage?.language_code)
            const filteredData = consentTranslations?.storconsent_version_displaynames?.filter(
                (val) => val.language_code === defaultLanguage.language_code
            )
            console.log('filteredData', filteredData)

            if (filteredData && filteredData.length > 0) {
                filteredData.forEach((val) => {
                    setTranslationTitle(val.display_name)
                    setTranslationDescription(val.display_description)
                    setTranslationVersionName(val.version_display_name)
                    setTranslationId(val.id)
                    setNewTranslation(false)
                })
            } else {
                setTranslationTitle(null)
                setTranslationDescription(null)
                setNewTranslation(true)
            }
        }
        setTranslationVersion(policy?.id)
        setTranslationVersionName(policy?.version_name)
    }

    const formatVersion = (version) => {
        if (version) {
            if (Number.isInteger(version)) {
                return version.toString() + '.0'
            } else {
                return version.toString()
            }
        }
    }

    const createNewVersionFields = (policy) => {
        if (integerCount === '' || floatCount === '') {
            MarketplaceToaster.showToast(util.getToastObject(`${t('policies:fill_version_number')}`, 'error'))
            return
        }
        const currentRevisionNum = policy?.version_number
        const updatedRevisionNum = parseFloat(integerCount + '.' + floatCount)
        const createVersionBody = {
            version_number: updatedRevisionNum,
            store_user_consent: policy?.store_user_consent_id,
        }
        if (updatedRevisionNum <= currentRevisionNum) {
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('policies:invalid_revision_num_message')} ${currentRevisionNum}`, 'error')
            )
            return
        } else {
            createNewUserConsentVersion(
                { body: createVersionBody },
                {
                    onSuccess: (response) => {
                        refetchUserConsent()
                        refetchVersionHistoryData()
                        toast(response?.response_body?.message || t('policies:version_created_successfully'), {
                            type: 'success',
                        })
                        setTimeout(() => {
                            setAddNewPolicy(false)
                        }, [100])
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_creating_version'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
        setPolicyVersion(updatedRevisionNum)
        setConsentName(policy?.display_name)
        setDescription(policy?.description)
        setDescriptionText(policy?.description)
        setSelectVersion(false)
        setDescriptionModified(true)
        setTitleModified(true)
        setNewVersionFlag(true)
        setVersionHistoryFlag(false)
        return true
    }
    useEffect(() => {
        refetchVersionHistoryData()
    }, [activeTabVersion])

    return (
        <div key={consent?.id} className=' bg-white  pb-6 policy-card w-full'>
            <div className='w-full border border-gray-200 rounded-lg'>
                <div className='border-b border-gray-200 p-3 flex justify-between items-center'>
                    <div
                        className={`gap-x-2`}
                        style={{
                            maxWidth: '360px',
                            display: 'inline-block',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                        }}>
                        <Text
                            className=' !font-semibold text-sm !mb-0 '
                            ellipsis={{
                                tooltip: {
                                    title: consentName,
                                    mouseLeaveDelay: 0,
                                    mouseEnterDelay: 0.5,
                                    placement: 'right',
                                },
                            }}>
                            {consentName != null && String(consentName) !== ''
                                ? consentName
                                : t('policies:untitled_policy')}
                        </Text>
                    </div>
                    <div className=' flex justify-end'>
                        <div className='flex space-x-2 items-center'>
                            <Dropdown
                                className='w-[105px] cursor-pointer'
                                disabled={!(parseInt(consent?.status) === 2)}
                                menu={{
                                    items: [
                                        {
                                            label: `${t('policies:view_version_history')}`,
                                            key: 'view_version_history',
                                            icon: <EyeOutlined />,
                                        },
                                    ],
                                    onClick: viewVersionHistory,
                                }}
                                placement='bottomRight'
                                arrow>
                                <Text>
                                    {t('policies:version')} {consent ? formatVersion(policyVersion) : '1.0'}
                                    <DownOutlined
                                        className={
                                            !(parseInt(consent?.status) === 2) ? '!text-[#857e7e40] mx-1' : 'mx-1'
                                        }
                                    />
                                </Text>
                            </Dropdown>

                            {/* {parseInt(consent?.version_count) > 1 ? (
                                <Dropdown
                                    menu={{
                                        items: [
                                            {
                                                label: 'View Version History',
                                                key: 'version',
                                                icon: <EyeFilled />,
                                            },
                                        ],
                                        onClick: viewVersionHistory,
                                    }}
                                    placement='bottom'
                                    arrow
                                    trigger={['click']}
                                    className='cursor-pointer header-text-color mr-2'
                                    overlayStyle={{ position: 'fixed', zIndex: 20 }}>
                                    <Paragraph className='inline-block mb-0 relative mr-2'>
                                        <Content className='!flex !items-center gap-2'>
                                            <Text className='header-text-color text-lg'>
                                                {t('policies:version')} {consent ? formatVersion(policyVersion) : '1.0'}
                                            </Text>
                                            <DownOutlined className='header-text-color text-xs' />
                                        </Content>
                                    </Paragraph>
                                </Dropdown>
                            ) : (
                                <Paragraph className='inline-block mb-0 relative'>
                                    <Content className='!flex !items-center gap-2 hover:cursor-pointer'>
                                        <Text className='header-text-color text-lg '>
                                            {t('policies:version')} {consent ? formatVersion(policyVersion) : '1.0'}
                                        </Text>
                                        <DownOutlined className='header-text-color text-xs' />
                                    </Content>
                                </Paragraph>
                            )} */}
                            <Button
                                disabled={parseInt(consent?.status) < 2 || isNewPolicy}
                                onClick={() => setSelectVersion(true)}
                                className='flex gap-x-2 justify-center items-center'>
                                <span className='text-xs'>
                                    <PlusOutlined />
                                </span>
                                {t('policies:add_version')}
                            </Button>
                            <Button
                                // disabled={!consent || parseInt(consent?.status) === 2}
                                onClick={() => viewTranslationPopup(consent)}
                                className='flex gap-x-2 justify-center items-center'>
                                {/* {!consent || parseInt(consent?.status) === 2 ? (
                                    <img src={TranslationDisabled} alt='Translation' className='w-[16px]' />
                                ) : ( */}
                                <img src={Translation} alt='Translation' className='w-[16px]' />
                                {/* )} */}
                                {t('common:translate')}
                            </Button>
                            {policyType !== 'CONTACT_POLICY' && (
                                <Button
                                    danger
                                    onClick={() => handelDeletePolicy(consent?.store_user_consent_id)}
                                    className='hover:!text-[#ff7875] hover:!border-[#ffa39e] flex gap-x-2 justify-center items-center'>
                                    <img src={TrashIcon} alt='Delete' className='w-[16px]' />
                                    {t('policies:delete_policy')}
                                </Button>
                            )}
                        </div>
                    </div>
                </div>
                <div className='p-3'>
                    <div className=' h-[64px] flex justify-between items-center  w-full'>
                        <div className=' flex items-center gap-x-5 max-w-[40%] w-full'>
                            {policyType === 'CONTACT_POLICY' ? (
                                <div className={`flex items-center gap-x-2 max-w-[60%] cursor-default `}>
                                    <Paragraph
                                        className=' !font-medium text-base !mb-0  '
                                        ellipsis={{ tooltip: consentName }}>
                                        {consentName?.substring(0, 50) || t('policies:untitled_policy')}
                                    </Paragraph>
                                </div>
                            ) : (
                                <div>
                                    <label className='flex flex-row text-[14px] mb-2 ml-1 input-label-color'>
                                        {t('policies:policy_title')}
                                        <p className={`mandatory-symbol-color text-sm mx-1 mt-[2px] font-medium`}>*</p>
                                    </label>
                                    <Input
                                        placeholder={t('policies:untitled_policy')}
                                        autoFocus={isNewPolicy}
                                        onChange={(e) => handelConsentNameChange(e.target?.value)}
                                        value={consentName}
                                        maxLength={CONSENT_NAME_LENGTH}
                                        onBlur={(e) => {
                                            if (e.target?.value) {
                                                handelConsentNameChange(e.target.value.trim().replace(/\s+/g, ' '))
                                            }
                                        }}
                                    />
                                </div>
                            )}
                        </div>
                    </div>
                    <div className='mt-3'>
                        <label className='text-[14px] flex mb-2 ml-1 input-label-color'>
                            {t('policies:policy_description')}
                            <p className={`mandatory-symbol-color text-sm mx-1 mt-[2px] font-medium`}>*</p>
                        </label>
                        <div
                            className={`rounded border-[1px] drop-shadow-sm shadow-[#D9D9D9] border-[#D9D9D9] overflow-hidden w-full ${parseInt(consent?.status) === 2 ? '!pointer-events-none !cursor-not-allowed bg-gray-100 !hover:cursor-not-allowed' : ' bg-white'}`}
                            data-text-editor={'policyCard'}>
                            <ReactQuill
                                theme='snow'
                                value={description}
                                onChange={handelDescriptionChange}
                                modules={modules}
                                formats={formats}
                                placeholder={t('policies:enter_policy_description')}
                                bounds={`[data-text-editor=policyCard]`}
                            />
                        </div>
                    </div>
                    <div className='flex items-center space-x-2 mt-2'>
                        <Tooltip
                            title={t('policies:last_updated_desc')}
                            placement='right'
                            className='whitespace-nowrap'>
                            <RiInformationFill className=' text-[#666666] text-[16px] cursor-pointer' />
                        </Tooltip>
                        <Text className='text-[#000000] text-opacity-50'>
                            {t('policies:last_updated')} {':'}{' '}
                            {isNewPolicy || newVersionFlag
                                ? t('policies:not_updated_yet')
                                : getDate(consent?.updated_on)}
                        </Text>
                    </div>
                    {parseInt(consent?.status) !== 2 ? (
                        <div className=' space-x-2 mt-6'>
                            {parseInt(consent?.status) !== 1 || descriptionModified || titleModified ? (
                                <>
                                    {!(consentName?.trim() && descriptionText?.trim()) ? (
                                        <Tooltip title={t('policies:description_to_save')} placement='right'>
                                            <Button className='app-btn-primary ' disabled>
                                                {t('common:save')}
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <Button
                                            className='app-btn-primary '
                                            onClick={handelSaveConsent}
                                            loading={
                                                createNewUserConsentStatus === 'pending' ||
                                                updateStoreUserConsentVersionStatus === 'pending'
                                            }>
                                            {t('common:save')}
                                        </Button>
                                    )}
                                    <Button
                                        onClick={handelCancelDescription}
                                        disabled={
                                            createNewUserConsentStatus === 'pending' ||
                                            updateStoreUserConsentVersionStatus === 'pending' ||
                                            parseInt(consent?.status) === 2
                                        }>
                                        {t('common:cancel')}
                                    </Button>
                                </>
                            ) : (
                                <Button className='app-btn-primary ' onClick={() => setConfirmPublish(true)}>
                                    {t('common:publish')}
                                </Button>
                            )}
                        </div>
                    ) : null}
                </div>
            </div>

            {/* confirm publish popup code goes here */}
            <StoreModal
                isVisible={confirmPublish}
                title={t('policies:publishing_policy_confirmation')}
                okCallback={() => handelSaveAndPublishConsent()}
                cancelCallback={() => setConfirmPublish(false)}
                okButtonText={t('common:publish')}
                cancelButtonText={t('common:cancel')}
                isSpin={false}
                width={'520px'}
                centered={true}
                onCancel={() => setConfirmPublish(false)}>
                <Content>
                    <p className='mt-[6px]'>{t('policies:confirm_publish_policy')}</p>
                    <p className='mt-3'>{t('policies:confirm_publish_policy_desc')}</p>
                </Content>
            </StoreModal>

            {/* Version selection popup code goes here */}
            {selectVersion && (
                <StoreModal
                    isVisible={selectVersion}
                    title={t('policies:add_version')}
                    okCallback={() => createNewVersionFields(consent)}
                    cancelCallback={() => setSelectVersion(false)}
                    okButtonText={t('policies:add_version')}
                    isSpin={false}
                    width={450}>
                    <Content>
                        <VersionCreation
                            consent={consent}
                            integerCount={integerCount}
                            setIntegerCount={setIntegerCount}
                            floatCount={floatCount}
                            setFloatCount={setFloatCount}
                        />
                    </Content>
                </StoreModal>
            )}

            {/* Version history popup codes goes here */}
            <PolicyModal
                isVisible={versionHistoryFlag}
                cancelCallback={() => setVersionHistoryFlag(false)}
                isSpin={false}
                width={980}>
                <Content>
                    <div className='border-b border-gray-200 py-3 px-4 mb-3'>
                        <Title level={5} className='mb-0'>
                            {t('policies:version_history')}
                        </Title>
                    </div>
                    <div className='flex pb-3'>
                        <div className='!min-w-[200px] !max-w-[200px]  border-r border-[#ccc] pl-3'>
                            {versionHistoryData?.store_userconsent_version_details
                                ?.sort((a, b) => b.id - a.id)
                                ?.map((policy, index) => (
                                    <div
                                        key={index}
                                        className={`cursor-pointer px-2 py-3 ${activeTabVersion === index ? 'border-r-4 border-brandPrimaryColor' : ''}`}
                                        onClick={() => setActiveTabVersion(index)}>
                                        <Title level={5} className=' !text-brandPrimaryColor mb-0'>
                                            {policy?.version_number === 1 ? 'V1.0' : 'V' + policy?.version_number}
                                        </Title>
                                        <Text className='text-md text-[#000000] text-opacity-40'>
                                            {getDate(policy?.updated_on)}
                                        </Text>
                                    </div>
                                ))}
                        </div>

                        <div className='flex-grow ml-4 py-2 px-4'>
                            {versionHistoryData?.store_userconsent_version_details?.map((policy, index) => (
                                <div key={index} className={`min-w-full ${activeTabVersion === index ? '' : 'hidden'}`}>
                                    <Title level={5}>{policy?.consent_display_name}</Title>
                                    <div
                                        className='border border-[#ccc] text-justify mt-3 mb-1 h-[450px] overflow-y-auto custom-scrollbar !min-w-[700px] !max-w-[700px]'
                                        style={{ padding: '5px 12px 5px 12px', borderRadius: '6px' }}
                                        dangerouslySetInnerHTML={{ __html: policy?.consent_display_discription }}></div>
                                    <Text className='text-[#000000] text-opacity-50'>
                                        {t('policies:last_updated')} {':'}{' '}
                                        <span className='text-[#000000]'>{getDate(policy?.updated_on)}</span>
                                    </Text>
                                    <div className='flex justify-end mt-3'>
                                        <Button className='app-btn-primary' onClick={() => setSelectVersion(true)}>
                                            {t('policies:create_new_version')}
                                        </Button>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </Content>
            </PolicyModal>

            {/* Translation popup codes goes here */}
            <StoreModal
                isVisible={translationFlag}
                title={t('common:translate')}
                cancelCallback={() => handleConsentTranslationCancel()}
                isSpin={false}
                width={'80vw'}>
                <Content>
                    {showTranslationAlert && (
                        <Alert
                            message={`${t('policies:translation_success_alert')} "${languageName}" ${t('policies:language')}`}
                            type='success'
                            showIcon
                            className='my-3 w-full !rounded-none'
                            closable
                            onClose={() => setShowTranslationAlert(false)}
                        />
                    )}
                    <div className='flex'>
                        <div className='min-w-[400px] max-w-[400px]  border-r border-[#ccc] px-3'>
                            <div className='pr-2'>
                                <Text className='mt-2'>
                                    {t('policies:translate_from')}
                                    {': '}
                                    {/* <span className='font-bold'>{t('policies:base_language')}</span> */}
                                    <span className='font-bold'>
                                        {storeLanguages &&
                                            storeLanguages?.find((element) => element.is_default)?.language}
                                    </span>
                                </Text>
                                <Title level={5} className='my-3'>
                                    {consentName}
                                </Title>
                                <div
                                    className='h-[70vh] overflow-y-auto custom-scrollbar'
                                    dangerouslySetInnerHTML={{ __html: description }}></div>
                            </div>
                        </div>

                        <div className='flex-grow ml-4 py-2 px-4'>
                            <div className='flex justify-between'>
                                <div className='flex whitespace-nowrap items-center'>
                                    <Text className='mt-1'>{t('policies:translate_to') + ' :'} </Text>
                                    <Dropdown
                                        className='w-[90px] ml-2 mt-1'
                                        menu={{
                                            items: languageItems,
                                            onClick: handleTranslationLanguageChange,
                                        }}>
                                        <Space>
                                            <span className='font-bold'>{languageName || 'English'}</span>
                                            <DownOutlined />
                                        </Space>
                                    </Dropdown>
                                </div>
                                <div className='flex items-center'>
                                    <ProgressBar count={consentTranslations?.count} total={languageItems?.length} />
                                    <Text className='text-xs whitespace-nowrap ml-1'>
                                        <span className='mx-2'>
                                            {consentTranslations?.count} of {languageItems?.length}{' '}
                                        </span>
                                        <span>{t('policies:languages_translated')}</span>
                                    </Text>
                                </div>
                            </div>

                            <div className='mt-4'>
                                <div className='h-[64px] flex justify-between items-center  w-full'>
                                    <div className=' flex items-center gap-x-5 max-w-[40%] w-full'>
                                        <div>
                                            <label className='text-[14px] mb-2 ml-1 flex input-label-color'>
                                                {t('policies:policy_title')}
                                                <p
                                                    className={`mandatory-symbol-color text-sm mx-1 mt-[2px] font-medium`}>
                                                    *
                                                </p>
                                            </label>

                                            <Input
                                                placeholder={t('policies:enter_policy_title')}
                                                autoFocus={isNewPolicy}
                                                onChange={(e) => handleTranslationTitleChange(e.target?.value)}
                                                value={translationTitle}
                                                disabled={parseInt(consent?.status) === 2}
                                                maxLength={CONSENT_NAME_LENGTH}
                                                onBlur={(e) => {
                                                    if (e.target?.value) {
                                                        handleTranslationTitleChange(
                                                            e.target.value.trim().replace(/\s+/g, ' ')
                                                        )
                                                    }
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className='mt-3'>
                                    <label className='text-[14px] mb-2 ml-1 flex input-label-color'>
                                        {t('policies:policy_description')}
                                        <p className={`mandatory-symbol-color text-sm mx-1 mt-[2px] font-medium`}>*</p>
                                    </label>
                                    <div
                                        // className=' rounded border-[1px] drop-shadow-sm  shadow-[#D9D9D9] border-[#D9D9D9] overflow-hidden bg-white   w-full'
                                        className={`rounded border-[1px] drop-shadow-sm shadow-[#D9D9D9] border-[#D9D9D9] w-[460px] !max-h-[300px] !h-[270px] !overflow-y-auto overflow-x-hidden ${parseInt(consent?.status) === 2 ? '!pointer-events-none !cursor-not-allowed bg-gray-100 !hover:cursor-not-allowed' : ' bg-white'}`}
                                        data-text-editor={'policyCard'}>
                                        <ReactQuill
                                            theme='snow'
                                            value={translationDescription}
                                            onChange={(e) => handleTranslationDescriptionChange(e)}
                                            modules={modules}
                                            formats={formats}
                                            bounds={`[data-text-editor=policyCard]`}
                                            placeholder={t('policies:enter_policy_description')}
                                            style={{ width: '100%' }}
                                            // className='!min-h-[40vh] !max-h-[40vh] overflow-y-scroll'
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className='flex justify-end space-x-2 mt-3'>
                                <Button onClick={() => handleConsentTranslationCancel()}>{t('common:cancel')}</Button>
                                {!translationTitle?.trim() ||
                                !translationDescription?.trim() ||
                                translationLanguage === '' ||
                                translationButtonFlag ? (
                                    <Tooltip title={t('policies:description_to_save')} placement='right'>
                                        <Button className='app-btn-primary ' disabled>
                                            {t('common:save')}
                                        </Button>
                                    </Tooltip>
                                ) : (
                                    <Button
                                        className='app-btn-primary '
                                        onClick={handleConsentTranslationSave}
                                        loading={
                                            createNewUserConsentStatus === 'pending' ||
                                            updateStoreUserConsentVersionStatus === 'pending'
                                        }>
                                        {t('common:save')}
                                    </Button>
                                )}
                            </div>
                        </div>
                    </div>
                </Content>
            </StoreModal>
        </div>
    )
}
export default PolicyCard
