import { Button, Divider, Layout, Spin, Table, Tag, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import { useTranslation } from 'react-i18next'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'
import getTranslatedDate from '../../util/getTranslatedDate'

//! ant design initialization
const { Content } = Layout
const { Text } = Typography
const invoiceTransactionAPI = process.env.REACT_APP_INVOICE_TRANSACTION
const pageLimit = process.env.REACT_APP_ITEM_PER_PAGE
const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const generateCustomSettlementAPI = process.env.REACT_APP_GENERATE_CUSTOM_SETTLEMENT_API

const ListCurrentSettlement = () => {
    const search = useLocation().search
    const vid = new URLSearchParams(search).get('vid')
    const { t } = useTranslation()
    const [isLoading, setIsLoading] = useState(false)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [invoiceTransactionCount, setInvoiceTransactionCount] = useState()
    const [tableInvoiceTransactionData, setTableInvoiceTransactionData] = useState([])
    const [completeTransactiondata, setCompleteTransactiondata] = useState([])
    const [transactionPageNumber, setTransactionPageNumber] = useState(1)
    const [transactionPageLimit, setTransactionPageLimit] = useState(pageLimit)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [isSpinLoading, setIsSpinLoading] = useState(false)
    const [isModalVisible, setIsModalVisible] = useState(false)
    const [paginationObject, setPaginationObject] = useState({})

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    //to calculate total data
    const totalData = completeTransactiondata.reduce(
        (accumulator, current) => {
            console.log('accumulator', accumulator, 'current', current)
            if (current.statusType === 'Fulfilled') {
                return {
                    orderAmount: accumulator.orderAmount + current.orderAmount,
                    storeCommission: accumulator.storeCommission + current.storeCommission,
                    totalVendorIncome: accumulator.totalVendorIncome + current.netVendorIncome,
                    totalDiscount: accumulator.totalDiscount + current.storeDiscountAmount,
                }
            } else {
                return {
                    orderAmount: accumulator.orderAmount - current.orderAmount,
                    storeCommission: accumulator.storeCommission - current.storeCommission,
                    totalVendorIncome: accumulator.totalVendorIncome - current.netVendorIncome,
                    totalDiscount: accumulator.totalDiscount - current.storeDiscountAmount,
                }
            }
        },
        { orderAmount: 0, storeCommission: 0, totalVendorIncome: 0, totalDiscount: 0 }
    )
    const isStoreIncomeNegative = totalData.storeCommission - totalData.totalDiscount < 0 ? true : false

    //! columns for Invoice Transaction details
    const InvoiceTransactionDetails = [
        {
            title: t('transactions:date'),
            dataIndex: 'Date',
            key: 'Date',
            width: '10%',
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>{getTranslatedDate(getGenerateDateAndTime(record.orderedDate, 'D MMMM YYYY'), t)}</>
                    // <>{moment.utc(record.orderedDate).format("D MMMM YYYY HH:mm:ss")}</>
                )
            },
        },
        {
            title: t('transactions:line_item_id'),
            dataIndex: 'orderItemId',
            key: 'orderItemId',
            className: 'min-w-[150px]',
            fixed: 'left',
            ellipsis: true,
            render: (text, record) => {
                return <>{'#' + record.orderItemId}</>
            },
        },
        {
            title: t('transactions:order_id'),
            dataIndex: 'orderId',
            key: 'orderId',
            className: 'min-w-[200px]',
            ellipsis: true,
            render: (text, record) => {
                return <>{'#' + record.orderId}</>
            },
        },
        {
            title: t('transactions:type'),
            dataIndex: 'state',
            key: 'state',
            className: 'min-w-[200px]',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Tag
                        color={
                            record.statusType === 'Fulfilled'
                                ? 'green'
                                : record.statusType === 'Refund'
                                  ? 'red'
                                  : record.statusType === 'cancel'
                                    ? 'orange'
                                    : null
                        }>
                        {record?.statusTypeDisplayName}
                    </Tag>
                )
            },
        },
        {
            title: t('transactions:amount'),
            dataIndex: 'amount',
            key: 'amount',
            className: 'min-w-[150px]',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        {record.orderAmount !== null
                            ? record.currencySymbol + ' ' + record.orderAmount
                            : record.currencySymbol + ' ' + 0}
                    </>
                )
            },
        },
        {
            title: t('transactions:net_vendor_income'),
            dataIndex: 'vendorIncome',
            key: 'vendorIncome',
            className: 'min-w-[300px]',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <>
                        {record.netVendorIncome !== null
                            ? record.currencySymbol + ' ' + record.netVendorIncome
                            : record.currencySymbol + ' ' + 0}
                    </>
                )
            },
        },
        {
            title: <p className='min-w-min'>{t('transactions:store_commission')}</p>,
            dataIndex: 'storeCommission',
            key: 'storeCommission',
            className: 'min-w-[200px]',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className='text-brandGreen'>
                        {record.storeCommission !== null
                            ? record.currencySymbol + ' ' + record.storeCommission
                            : record.currencySymbol + ' ' + 0}
                    </div>
                )
            },
        },
        {
            title: t('transactions:store_discount'),
            dataIndex: 'storeDiscount',
            key: 'storeDiscount',
            className: 'min-w-[200px]',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div className='text-brandRed'>
                        {record.storeDiscountAmount !== null
                            ? record.currencySymbol + ' ' + record.storeDiscountAmount
                            : record.currencySymbol + ' ' + 0}
                    </div>
                )
            },
        },
        {
            title: t('transactions:net_store_income'),
            dataIndex: 'netStoreIncome',
            key: 'netStoreIncome',
            className: 'min-w-[200px]',
            fixed: 'right',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <div
                        className={`font-normal text-sm ${record.netStoreIncome < 0 ? 'text-brandRed' : 'text-brandGreen'}`}>
                        {record.netAmount !== null
                            ? record.netStoreIncome > 0
                                ? record.currencySymbol + ' ' + record.netStoreIncome
                                : '-' + record.currencySymbol + ' ' + record.netStoreIncome * -1
                            : record.currencySymbol + ' ' + 0}
                    </div>
                )
            },
        },
    ]

    //!Invoice Transaction to get the table data
    const invoiceTransactionTableData = (filteredData) => {
        const tempArray = []
        if (filteredData && filteredData.length > 0) {
            filteredData &&
                filteredData.length > 0 &&
                filteredData.forEach((element, index) => {
                    var id = element.id
                    var orderedDate = element.ordered_date
                    var statusType = element.status_type
                    var orderAmount = element.order_item_amount
                    var storeCommission = element.store_commision
                    var orderItemId = element.order_item_id
                    var netAmount = element.net_amount
                    var orderId = element.order_id
                    // var netVendorIncome = element.order_item_amount - element.store_commision
                    var netVendorIncome = element.net_amount
                    var storeDiscountAmount = element.store_discounted_amount
                    var netStoreIncome = element.net_store_income
                    var currencySymbol = element.currency_symbol
                    var statusTypeDisplayName = element.status_type_display_name
                    tempArray &&
                        tempArray.push({
                            key: id,
                            orderedDate: orderedDate,
                            statusType: statusType,
                            orderAmount: orderAmount,
                            storeCommission: storeCommission,
                            orderItemId: orderItemId,
                            netAmount: netAmount,
                            orderId: orderId,
                            storeDiscountAmount: storeDiscountAmount,
                            netVendorIncome: netVendorIncome,
                            netStoreIncome: netStoreIncome,
                            currencySymbol: currencySymbol,
                            statusTypeDisplayName: statusTypeDisplayName,
                        })
                })
            return tempArray
        } else {
            return tempArray
        }
        // setTableInvoiceTransactionData(tempArray);
    }

    const findAllWithoutPageGetInvoiceTransactionDetails = () => {
        setIsLoading(true)

        MarketplaceServices.findAll(invoiceTransactionAPI, { vendor_id: vid }, null, false)
            .then(function (response) {
                setIsLoading(false)
                console.log(
                    'Server Response from findWithoutPageGetInvoiceTransactionDetails is ',
                    response.data.response_body
                )
                setCompleteTransactiondata(invoiceTransactionTableData(response.data.response_body.transaction_data))
            })
            .catch((error) => {
                setIsLoading(false)

                console.log('Error Server response from findWithoutPageGetInvoiceTransactionDetails', error.response)
            })
    }

    //!get call for Invoice Transaction
    const findByPageGetInvoiceTransactionDetails = (page, limit) => {
        setIsLoading(true)
        // setIsNetworkError(true)
        MarketplaceServices.findByPage(invoiceTransactionAPI, { vendor_id: vid }, page, limit, false)
            .then((response) => {
                console.log(
                    'Server Response from findByPageGetInvoiceTransactionDetails is',
                    invoiceTransactionAPI,
                    response.data.response_body
                )
                setIsNetworkError(false)
                setTableInvoiceTransactionData(
                    invoiceTransactionTableData(response.data.response_body.transaction_data)
                )
                setInvoiceTransactionCount(response.data.response_body.count)
                setIsLoading(false)
            })
            .catch((error) => {
                setIsNetworkError(true)
                setIsLoading(false)
                console.log(
                    'Error Server response from findByPageGetInvoiceTransactionDetails ',
                    invoiceTransactionAPI,
                    error
                )
            })
    }

    //!InvoiceTransaction pagination onchange function
    const handlePageNumberChange = (page, pageSize) => {
        if (selectedRowKeys.length > 0) {
            if (transactionPageNumber !== page) {
                setIsModalVisible(true)
                setPaginationObject({
                    pageNumber: page,
                    pageSize: pageSize,
                })
            } else {
                setTransactionPageLimit(pageSize)
            }
        } else {
            setTransactionPageNumber(page)
            setTransactionPageLimit(pageSize)
        }
    }

    useEffect(() => {
        if (vid !== null && vid !== undefined) {
            findByPageGetInvoiceTransactionDetails(transactionPageNumber, transactionPageLimit)
        }
    }, [transactionPageNumber, transactionPageLimit, vid])

    useEffect(() => {
        if (vid !== null && vid !== undefined) {
            findAllWithoutPageGetInvoiceTransactionDetails()
        }
    }, [vid])

    const formatNumber = (num) => {
        // Convert the number to a string
        const numStr = num.toString()

        // Check if the number contains a decimal point
        if (numStr.includes('.')) {
            // If yes, apply toFixed(2)
            return parseFloat(num).toFixed(2)
        } else {
            // If no decimals, return the number as is
            return num
        }
    }

    const rowSelection = {
        selectedRowKeys,
        onSelect: (record, selected, selectedRows) => {
            let copySelectedRows = [...selectedRowKeys]
            let uniqueCopySelectedRows = [...new Set(copySelectedRows)]

            if (selected === true) {
                uniqueCopySelectedRows.push(record.key)
            } else if (selected === false) {
                let index = uniqueCopySelectedRows.indexOf(record.key)
                if (index !== -1) {
                    uniqueCopySelectedRows.splice(index, 1)
                }
            }
            setSelectedRowKeys(uniqueCopySelectedRows)
        },
        onSelectAll: (selected, selectedRows, changeRows) => {
            console.log('arun-->', selected, selectedRows, changeRows)
            if (selected == true) {
                let temp = []
                for (var i = 0; i < selectedRows.length; i++) {
                    temp.push(selectedRows[i].key)
                }
                setSelectedRowKeys(temp)
            } else {
                setSelectedRowKeys([])
            }
        },
    }

    const postCallGenerateCustomSettlement = () => {
        let requestBody = {}
        requestBody['vendor_id'] = parseInt(vid)
        requestBody['invoice_transaction_id'] = selectedRowKeys
        MarketplaceServices.save(generateCustomSettlementAPI, requestBody, null)
            .then(function (response) {
                console.log(
                    'post call generateCustomSettlementAPI',

                    response.data.response_body
                )
                setIsSpinLoading(false)
                setSelectedRowKeys([])
                findByPageGetInvoiceTransactionDetails(transactionPageNumber, transactionPageLimit)
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                MarketplaceToaster.showToast(error.response)
                console.log(
                    'Error Server response from saveJourneyMappingOnServer api ',

                    error
                )
                setIsSpinLoading(false)
            })
    }

    console.log('paginationObject------->', paginationObject)
    return (
        <Content className=''>
            {isLoading ? (
                <Content className=' bg-white text-center !p-2'>
                    <SkeletonComponent />
                </Content>
            ) : isNetworkError ? (
                <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                    <p>{`${t('common:network_error')}`}</p>
                </Content>
            ) : (
                <Content>
                    <Divider className='!my-2' />
                    <Content className='flex '>
                        <div>
                            <Text className='text-[#a9a9a9]'>{t('transactions:total_net')}</Text>
                            <p
                                className={` text-xl flex gap-2 ${isStoreIncomeNegative ? ' text-brandRed' : 'text-brandGreen'}`}>
                                <span>
                                    {isStoreIncomeNegative ? '-' : null}
                                    {currencySymbolFromRedux}
                                </span>
                                <span>
                                    {isStoreIncomeNegative
                                        ? ((totalData.storeCommission - totalData.totalDiscount) * -1).toFixed(2)
                                        : (totalData.storeCommission - totalData.totalDiscount).toFixed(2)}
                                </span>
                            </p>
                        </div>
                        <Divider type='vertical' className='h-16 !border-r-1 !mx-7' />
                        <div className='flex flex-col justify-evenly'>
                            <div className='flex flex-row gap-2 mb-1'>
                                <Text className='text-brandGray2 '>{t('transactions:total_amount')} : </Text>
                                <Text className='text-brandGray1'>
                                    <span className='mx-1'>{currencySymbolFromRedux}</span>
                                    {totalData && totalData.orderAmount.toFixed(2)}
                                </Text>
                            </div>
                            <div className='flex flex-row gap-2 mb-1'>
                                <Text className='text-brandGray2  '>{t('transactions:total_vendor_income')} : </Text>
                                <Text className='text-brandGray1'>
                                    <span className='mx-1'>{currencySymbolFromRedux}</span>
                                    {totalData && totalData.totalVendorIncome.toFixed(2)}
                                </Text>
                            </div>
                        </div>
                        <Divider type='vertical' className='h-16 !border-r-1 !mx-7' />
                        <div className='flex flex-col justify-evenly'>
                            <div className='flex flex-row gap-2'>
                                <Text className='text-brandGray2 '>{t('transactions:total_store_commission')} : </Text>
                                <Text className='text-brandGray1'>
                                    <span className='mx-1'>{currencySymbolFromRedux}</span>
                                    {totalData.storeCommission.toFixed(2)}
                                </Text>
                            </div>
                            <div className='flex flex-row gap-2'>
                                <Text className='text-brandGray2 '>{t('transactions:total_discount')} : </Text>
                                <Text className='text-brandGray1'>
                                    <span className='mx-1'>{currencySymbolFromRedux}</span>
                                    {totalData.totalDiscount.toFixed(2)}
                                </Text>
                            </div>
                        </div>
                    </Content>
                    <Divider className='!my-2' />
                    {/* <Title level={5} className="">
            {t("transactions:transactions")}
          </Title> */}
                    <Spin tip={t('common:please_wait')} spinning={isSpinLoading}>
                        {selectedRowKeys && selectedRowKeys.length > 0 ? (
                            <div className='!bg-[var(--mp-brand-color-10)] p-3 flex items-center justify-between mt-3'>
                                <Text className='text-white font-bold text-lg'>
                                    {selectedRowKeys.length + ' ' + t('transactions:items_selected')}
                                </Text>
                                <Button
                                    className='app-btn-primary'
                                    onClick={() => {
                                        setIsSpinLoading(true)
                                        postCallGenerateCustomSettlement()
                                    }}>
                                    {t('transactions:generate')}
                                </Button>
                            </div>
                        ) : null}

                        <Table
                            className=''
                            columns={InvoiceTransactionDetails}
                            dataSource={tableInvoiceTransactionData}
                            pagination={false}
                            scroll={{
                                x: 1500,
                            }}
                            rowSelection={{
                                type: 'checkbox',
                                ...rowSelection,
                                hideSelectAll: false,
                            }}
                            locale={{ emptyText: `${t('common:no_data')}` }}
                        />
                        {invoiceTransactionCount && invoiceTransactionCount >= pageLimit ? (
                            <Content className=' grid justify-items-end'>
                                <DmPagination
                                    currentPage={transactionPageNumber}
                                    totalItemsCount={invoiceTransactionCount}
                                    pageSize={transactionPageLimit}
                                    handlePageNumberChange={handlePageNumberChange}
                                    showSizeChanger={true}
                                    showTotal={true}
                                    showQuickJumper={true}
                                />
                            </Content>
                        ) : null}
                    </Spin>
                </Content>
            )}
            <StoreModal
                isVisible={isModalVisible}
                okButtonText={`${t('common:ok')}`}
                cancelButtonText={`${t('common:cancel')}`}
                title={`${t('common:confirmation')}`}
                okCallback={() => {
                    setIsModalVisible(false)
                    setTransactionPageNumber(paginationObject.pageNumber)
                    setTransactionPageLimit(paginationObject.pageSize)
                    setSelectedRowKeys([])
                }}
                cancelCallback={() => setIsModalVisible(false)}
                isSpin={false}>
                {<div>{t('transactions:leaving_page_message')}</div>}
            </StoreModal>
        </Content>
    )
}

export default ListCurrentSettlement
