//! Importing Libraries
import { Button, DatePicker, Layout, Row, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'

//! importing user-defined components
import { useTranslation } from 'react-i18next'
import DynamicJourneyComponent from '../../components/Journey/DynamicJourneyComponent'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import StoreModal from '../../components/storeModal/StoreModal'
import {
    Afternoon,
    AvailableIcon,
    BookedIcon,
    Evening,
    Morning,
    Night,
    PartiallyBookedIcon,
    SlotsMsgIcon,
} from '../../constants/media'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import ChooseLayout from '../SFSettings/ChooseLayout'
import AttributesDetail from './AttributesDetail'
import ProductPublishingHeader from './ProductPublishingHeader'

//! Get all required details from .env file
const storeProductTypeAPI = process.env.REACT_APP_PRODUCT_TYPE_API
const productJourneyMappingAPI = process.env.REACT_APP_PRODUCT_JOURNEY_MAPPING
const deliverySlotsAPI = process.env.REACT_APP_DELIVERY_SLOTS_API

//! de-structuring libraries
const { Content } = Layout
const { Text } = Typography
const ProductPublishingEditNew = ({ viewType, permission }) => {
    const { t } = useTranslation()
    /**
     * !Below are the destructuring of the antd hooks.
     */
    const navigate = useNavigate()
    const search = useLocation().search
    const location = useLocation().pathname
    /**
     * !Below are variables got from the URl Search Params.
     */
    let product_id = new URLSearchParams(search).get('vrid')
    let variant_id = new URLSearchParams(search).get('vaid')
    let version_status = new URLSearchParams(search).get('vrsts')
    let mode = new URLSearchParams(search).get('m')
    let action = new URLSearchParams(search).get('a')
    const storePTRevisionId = new URLSearchParams(search).get('ptrid')
    const storeProductTypeIdFromURL = new URLSearchParams(search).get('ptid')
    useState(false)
    const [selectedTabStoreProductTypeRevisionId, setSelectedTabStoreProductTypeRevisionId] = useState()
    const [storeProductTypeId, setStoreProductTypeId] = useState()
    const [propsType, setPropsType] = useState()
    const [storeProductTypeServerData, setStoreProductTypeServerData] = useState()
    const [isButtonDisabled, setIsButtonDisabled] = useState(false)
    const [isSaveClicked, setIsSaveClicked] = useState(false)
    const [isSlotAttributeValuesPosted, setIsSlotAttributeValuesPosted] = useState(false)
    const [dateForTimeSlots, setDateForTimeSlots] = useState()
    const [customDate, setCustomDate] = useState('')

    const [slotsAPIData, setSlotsAPIData] = useState([])
    const [slotsAPIDataLoading, setSlotsAPIDataLoading] = useState(false)
    const [isSlotPreviewModalOpen, setIsSlotPreviewModalOpen] = useState(false)
    const [listProductAttributeGroupProps, setListProductAttributeGroupProps] = useState()
    const [isFileUploaded, setIsFileUploaded] = useState(false)
    const [dateArraySimplified, setDateArraySimplified] = useState([])
    const [antDate, setAntDate] = useState(null)
    const generateDateArray = (startDate, numDays) => {
        const dateArr = []
        for (let i = 0; i < numDays; i++) {
            const date = new Date(startDate)
            date.setDate(startDate.getDate() + i)
            dateArr.push(date)
        }
        return dateArr
    }
    const dynamicDateGeneration = (selectedDate) => {
        const dateArray = generateDateArray(selectedDate, 4)
        const daysOfWeek = ['sun', 'mon', 'tue', 'wed', 'thu', 'fri', 'sat']

        let temp = []
        for (var i = 0; i < dateArray.length; i++) {
            const date = new Date(dateArray[i])
            const dayName = daysOfWeek[date.getDay()]
            const formattedDate = date.toISOString().split('T')[0]
            const dayOfMonth = date.getDate()
            temp.push({
                day: dayName,
                value: formattedDate,
                date: dayOfMonth,
            })
        }
        return temp
    }

    let typeRendering
    if (location && location.includes('products')) {
        typeRendering = 'Product'
    } else {
        typeRendering = 'Product-Template'
    }
    const productTemplateSelectedInfoReduxData = useSelector(
        (state) => state.reducerProducts.productTemplateSelectedInfo
    )

    //!get call for store product types
    const getStoreProductTypes = () => {
        MarketplaceServices.findAll(storeProductTypeAPI, null, true)
            .then(function (response) {
                console.log(
                    'server response from getStoreProductTypes API is',
                    storeProductTypeAPI,
                    response.data.response_body
                )
                if (response.data.response_body.StoreProductTypeRevision.length > 0) {
                    setStoreProductTypeServerData(response.data.response_body.StoreProductTypeRevision)
                }
            })
            .catch(function (error) {
                console.log(
                    'Error server response from getStoreProductTypes API is',
                    storeProductTypeAPI,
                    error.response
                )
            })
    }

    const handleProductMapping = () => {
        MarketplaceServices.findAll(productJourneyMappingAPI, null, true)
            .then(function (response) {
                console.log(
                    'Server response from handleProductMapping API is,',
                    productJourneyMappingAPI,
                    response.data.response_body.data
                )
                if (
                    response.data.response_body.data.product_journey_mapping_data &&
                    response.data.response_body.data.product_journey_mapping_data.length > 0
                ) {
                    let temp = response.data.response_body.data.product_journey_mapping_data
                    let localTemp = temp.filter((ele) => ele.product_id === parseInt(product_id))
                    if (localTemp && localTemp.length > 0) {
                        setPropsType('product')
                    } else {
                        setPropsType('product-type')
                    }
                } else {
                    setPropsType('product-type')
                }
            })
            .catch(function (error) {
                console.log(
                    'Server Error response from handleProductMapping API is',
                    productJourneyMappingAPI,
                    error.response
                )
            })
    }
    //! Get call for Delivery Slots
    const getCallForDeliverySlots = (dateProps, dayProps) => {
        setSlotsAPIDataLoading(true)
        const pathParams = {
            'product-id': parseInt(product_id),
            date: String(dateProps),
        }
        MarketplaceServices.findAllWithoutPage(deliverySlotsAPI, pathParams, false)
            .then((response) => {
                console.log(
                    'Server response from getCallForDeliverySlots',
                    dateProps,
                    dayProps,
                    response.data.response_body.slot_data
                )
                if (response.data.response_body.slot_data && response.data.response_body.slot_data.length > 0) {
                    const filteredArrayByDay = response.data.response_body.slot_data
                    // response.data.response_body.slot_data.filter(
                    //   (ele) => ele.day === dayProps
                    // );
                    if (filteredArrayByDay && filteredArrayByDay.length > 0) {
                        setSlotsAPIData(filteredArrayByDay[0].slots)
                    } else {
                        setSlotsAPIData([])
                    }
                } else {
                    setSlotsAPIData([])
                }

                setSlotsAPIDataLoading(false)
            })
            .catch((error) => {
                console.log('Error response from getCallForDeliverySlots is', error.response)
                setSlotsAPIDataLoading(false)
            })
    }
    /**
     * !All the useEffects will be here.
     */
    useEffect(() => {
        if (product_id === null && parseInt(mode) === 1 && parseInt(action) === 1) {
            navigate('/dashboard/page-not-available?' + MarketplaceAppConfig.getStore(''))
        }
    }, [product_id])
    useEffect(() => {
        let tempArray = []
        if (viewType === 'modal') {
            if (
                productTemplateSelectedInfoReduxData &&
                productTemplateSelectedInfoReduxData.length > 0 &&
                productTemplateSelectedInfoReduxData[0].storeProductType &&
                productTemplateSelectedInfoReduxData[0].storeProductType.length > 0
            ) {
                productTemplateSelectedInfoReduxData[0].storeProductType &&
                    productTemplateSelectedInfoReduxData[0].storeProductType.forEach((element) => {
                        storeProductTypeServerData &&
                            storeProductTypeServerData.length > 0 &&
                            storeProductTypeServerData.forEach((element1) => {
                                if (
                                    parseInt(element.store_product_type_id) === parseInt(element1.store_product_type.id)
                                ) {
                                    tempArray.push({
                                        key: element.store_product_type_revision_id,
                                        label: `Attributes For ${element1.store_product_type.name}`,
                                        tabStoreProductType: element1.store_product_type.id,
                                        tabRevisionNumber: element1.revision_number,
                                        tabStoreProductTypeId: element1.store_product_type.product_type,
                                    })
                                }
                            })
                    })
            }
        } else if (parseInt(mode) === 1) {
            storeProductTypeServerData &&
                storeProductTypeServerData.length > 0 &&
                storeProductTypeServerData.forEach((element2) => {
                    if (parseInt(storeProductTypeIdFromURL) === parseInt(element2.store_product_type.id)) {
                        tempArray.push({
                            key: storePTRevisionId,
                            label: `Attributes For ${element2.store_product_type.name}`,
                            tabStoreProductType: element2.store_product_type.id,
                            tabRevisionNumber: element2.revision_number,
                            tabStoreProductTypeId: element2.store_product_type.product_type,
                        })
                    }
                })
        }
        if (tempArray && tempArray.length > 0) {
            setStoreProductTypeId(tempArray[0].tabStoreProductTypeId)
            if (parseInt(mode) === 0 || viewType === 'modal') {
                setSelectedTabStoreProductTypeRevisionId(tempArray[0].key)
            }
        }
    }, [storeProductTypeServerData, storePTRevisionId, storeProductTypeIdFromURL, productTemplateSelectedInfoReduxData])
    useEffect(() => {
        window.scrollTo(0, 0)
        util.setStoreNameWhenWeChangeINUrl()
        getStoreProductTypes()
        if (parseInt(mode) === 1) {
            handleProductMapping()
        }
        let presentData = new Date()
        setDateArraySimplified(dynamicDateGeneration(presentData))
    }, [])

    useEffect(() => {
        if (isSlotPreviewModalOpen === true) {
            getCallForDeliverySlots(
                dateArraySimplified && dateArraySimplified[0].value,
                dateArraySimplified && dateArraySimplified[0].day
            )
            setDateForTimeSlots(dateArraySimplified && dateArraySimplified[0].value)
        }
    }, [isSlotPreviewModalOpen])
    const slotDetails =
        listProductAttributeGroupProps &&
        listProductAttributeGroupProps.length > 0 &&
        listProductAttributeGroupProps.filter((ele) => ele.name === 'Slot Details')

    const slotDetailsName =
        slotDetails && slotDetails.length > 0 && slotDetails[0].attribute.filter((ele) => ele.name === 'Slot Name')

    const slotDetailsNameOne = slotDetailsName && slotDetailsName.length > 0 && slotDetailsName[0]?.attribute_values[0]

    const slotDetailsNameTwo = slotDetailsNameOne && slotDetailsNameOne.length > 0 && slotDetailsNameOne[0]?.value

    const slotDetailsDescription =
        slotDetails &&
        slotDetails.length > 0 &&
        slotDetails[0].attribute.filter((ele) => ele.name === 'Slot Description')

    const slotDetailsDescriptionOne =
        slotDetailsDescription && slotDetailsDescription.length > 0 && slotDetailsDescription[0]?.attribute_values[0]

    const slotDetailsDescriptionTwo =
        slotDetailsDescriptionOne && slotDetailsDescriptionOne.length > 0 && slotDetailsDescriptionOne[0]?.value

    const filterSlotsDetailsBasedOnDateTime = (data, dateTimeName) => {
        let temp = []
        if (data.length > 0) {
            let filterData = data.filter((ele) => ele.day_time_name === dateTimeName)
            if (filterData && filterData.length > 0) {
                temp = filterData
                return temp
            } else {
                return temp
            }
        } else {
            return temp
        }
    }

    const convertHoursIn12Format = (data) => {
        const [hours, minutes, seconds] = data.split(':')
        const time12 = new Date(0, 0, 0, hours, minutes, seconds)
        const formattedTime = time12.toLocaleTimeString('en-US', {
            hour: 'numeric',
            minute: 'numeric',
        })

        return formattedTime
    }

    return (
        <Content className=''>
            <ProductPublishingHeader
                type='product'
                isSaveClicked={isSaveClicked}
                setIsSaveClicked={setIsSaveClicked}
                saveButtonDisabled={isButtonDisabled}
                selectedStoreProductTypeRevisionId={selectedTabStoreProductTypeRevisionId}
                setStoreProductTypeId={setStoreProductTypeId}
                isFileUploaded={isFileUploaded}
            />
            <Content
                className={`${
                    parseInt(version_status) === 3
                        ? '!mt-[0.75rem]'
                        : viewType === 'modal'
                          ? 'mt-[16.5%] !w-[100%]'
                          : '!mt-[0.75rem]'
                }`}>
                <AttributesDetail
                    selectedTabStoreProductTypeRevisionId={selectedTabStoreProductTypeRevisionId}
                    storeProductTypeId={storeProductTypeId}
                    setStoreProductTypeId={setStoreProductTypeId}
                    type={parseInt(variant_id) && parseInt(mode) === 1 ? 'non_variant_specific' : 'both'}
                    setButtonDisabled={setIsButtonDisabled}
                    viewType={viewType}
                    showVariantTable={true}
                    isSaveClicked={isSaveClicked}
                    setIsSaveClicked={setIsSaveClicked}
                    setIsSlotPreviewModalOpen={setIsSlotPreviewModalOpen}
                    setListProductAttributeGroupProps={setListProductAttributeGroupProps}
                    typeRendering={typeRendering}
                    setIsSlotAttributeValuesPosted={setIsSlotAttributeValuesPosted}
                />
                <Content
                    className={`!w-[98%] ${
                        util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-3' : 'ml-3'
                    }`}>
                    {parseInt(variant_id) && parseInt(mode) === 1 ? null : (
                        <Content
                            className={`bg-white rounded-lg custom-box-shadow !p-3 !mt-1${
                                (version_status && parseInt(version_status) === 1) ||
                                parseInt(version_status) === 2 ||
                                parseInt(version_status) === 3
                                    ? ''
                                    : ' !opacity-70 !pointer-events-none !cursor-none !hover:pointer-events-none !hover:cursor-none'
                            }`}>
                            <ChooseLayout
                                type={'product'}
                                id={parseInt(product_id)}
                                filterType={'detailspage'}
                                productTypeId={storeProductTypeId}
                            />
                        </Content>
                    )}
                    <Content id='targetJourney'>
                        {parseInt(mode) === 0 ? (
                            <Content className='rounded-lg custom-box-shadow'>
                                <DynamicJourneyComponent
                                    type={'product'}
                                    id={parseInt(product_id)}
                                    productTypeId={parseInt(storeProductTypeId)}
                                    action={'read'}
                                />
                            </Content>
                        ) : parseInt(variant_id) && parseInt(mode) === 1 ? null : (
                            <>
                                {propsType !== undefined ? (
                                    <Content className='!mt-3 rounded-lg custom-box-shadow'>
                                        <DynamicJourneyComponent
                                            type={'product'}
                                            id={parseInt(product_id)}
                                            productTypeId={parseInt(storeProductTypeId)}
                                            action={'read'}
                                        />
                                    </Content>
                                ) : null}
                            </>
                        )}
                    </Content>
                </Content>
            </Content>
            <StoreModal
                isVisible={isSlotPreviewModalOpen}
                cancelCallback={() => {
                    setIsSlotPreviewModalOpen(false)
                    setAntDate(null)
                    let presentData = new Date()
                    setDateArraySimplified(dynamicDateGeneration(presentData))
                }}
                okButtonText={null}
                cancelButtonText={null}
                title={t('products:slot_preview')}
                isSpin={false}>
                <Content>
                    <Row>
                        <Text className='font-semibold mb-1'>{slotDetailsNameTwo}</Text>
                    </Row>
                    <Row>
                        <p
                            dangerouslySetInnerHTML={{
                                __html: slotDetailsDescriptionTwo || '',
                            }}
                        />
                    </Row>
                    <Text className=''>{t('products:choose_day')}</Text>

                    <Row className='my-2 h-auto'>
                        {dateArraySimplified &&
                            dateArraySimplified.length > 0 &&
                            dateArraySimplified.map((element, index) => {
                                return (
                                    <div
                                        onClick={() => {
                                            getCallForDeliverySlots(element.value, element.day)
                                            setDateForTimeSlots(element.value)
                                        }}
                                        key={index}
                                        className={`border w-[15%] cursor-pointer  !border-black rounded-md m-2 p-2 ${
                                            dateForTimeSlots === element.value ? 'app-btn-primary' : ''
                                        }`}>
                                        <Row>
                                            <Text
                                                ellipsis={{ tooltip: t(`products:${element.day?.toLowerCase()}`) }}
                                                className={`${dateForTimeSlots === element.value ? 'text-white' : ''} w-[100%]`}>
                                                {' '}
                                                {t(`products:${element.day?.toLowerCase()}`)}
                                            </Text>
                                        </Row>
                                        <Row>
                                            <Text
                                                className={`${dateForTimeSlots === element.value ? 'text-white' : ''}`}>
                                                {' '}
                                                {element.date}
                                            </Text>
                                        </Row>
                                    </div>
                                )
                            })}
                        <DatePicker
                            placeholder={`${t('products:select_date')}`}
                            placement='bottomRight'
                            format='YYYY-MM-DD'
                            value={antDate}
                            onChange={(date, dateString) => {
                                console.log('date-->', date, 'dateString--->', dateString)
                                setAntDate(date)
                                if (dateString) {
                                    setCustomDate(dateString)
                                    getCallForDeliverySlots(dateString)
                                    setDateForTimeSlots(dateString)
                                    const currentDate = new Date(dateString)
                                    const nextFourDaysArray = []

                                    for (let i = 0; i < 4; i++) {
                                        const nextDay = new Date(currentDate)
                                        nextDay.setDate(currentDate.getDate() + i)
                                        const formattedDate = nextDay.toISOString().split('T')[0]
                                        const dayName = new Intl.DateTimeFormat('en-US', {
                                            weekday: 'short',
                                        }).format(nextDay)

                                        nextFourDaysArray.push({
                                            day: dayName?.toLowerCase(),
                                            value: formattedDate,
                                            date: nextDay.getDate(),
                                        })
                                    }
                                    setDateArraySimplified(nextFourDaysArray)
                                } else {
                                    setAntDate(null)
                                    let presentData = new Date()
                                    setDateArraySimplified(dynamicDateGeneration(presentData))
                                    setCustomDate()
                                    var currentDate = new Date()
                                    var year = currentDate.getFullYear()
                                    var month = String(currentDate.getMonth() + 1).padStart(2, '0')
                                    var day = String(currentDate.getDate()).padStart(2, '0')
                                    var formattedDate = year + '-' + month + '-' + day
                                    getCallForDeliverySlots(formattedDate)
                                    setDateForTimeSlots(formattedDate)
                                }
                            }}
                            className={`border w-[23%] cursor-pointer  !border-black rounded-md m-2 !mr-0 p-2  ${
                                dateForTimeSlots === customDate ? 'app-btn-primary !text-white' : ''
                            }`}
                        />
                    </Row>
                    <Content className='my-2'>
                        {slotsAPIDataLoading ? (
                            <SkeletonComponent />
                        ) : slotsAPIData && slotsAPIData.length > 0 ? (
                            <>
                                <Row className='mb-1'>
                                    <Text className='w-[100%]'>{t('products:available_time')}</Text>
                                </Row>
                                <Content className=''>
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Morning} alt='Morning' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'morning')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'morning'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>

                                                            <div className='flex'>
                                                                <img
                                                                    className=' mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[22%]'>
                                                <img src={Afternoon} alt='Afternoon' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'afternoon')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[78%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'afternoon'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className=' mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening')[0].day_time_slots
                                        .length > 0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Evening} alt='Evening' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'evening')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'evening'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className=' mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                    {filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night').length > 0 &&
                                    filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night')[0].day_time_slots.length >
                                        0 ? (
                                        <Content className='flex mb-2 !border-b-[1px] !border-zinc-500 p-2'>
                                            <Content className='!w-[20%]'>
                                                <img src={Night} alt='Night' />
                                                <Text className='ml-2 opacity-50'>
                                                    {
                                                        filterSlotsDetailsBasedOnDateTime(slotsAPIData, 'night')[0]
                                                            .day_time_display_name
                                                    }
                                                </Text>
                                            </Content>
                                            <Content className='!w-[80%] grid grid-cols-4 gap-2'>
                                                {filterSlotsDetailsBasedOnDateTime(
                                                    slotsAPIData,
                                                    'night'
                                                )[0].day_time_slots.map((ele) => {
                                                    return (
                                                        <div className='flex flex-col'>
                                                            <Button
                                                                shape='round'
                                                                className='!cursor-not-allowed !hover:text-[#b1afb2] border-1 border-[#b1afb2] hover:border-1 hover:!border-[#b1afb2]'>
                                                                {convertHoursIn12Format(ele.slot_start_time)}
                                                            </Button>
                                                            <div className='flex'>
                                                                <img
                                                                    className=' mr-1'
                                                                    src={
                                                                        ele.slot_status === 'Available'
                                                                            ? AvailableIcon
                                                                            : ele.slot_status === 'Partially_booked'
                                                                              ? PartiallyBookedIcon
                                                                              : BookedIcon
                                                                    }
                                                                    alt='Icons'
                                                                />
                                                                <p className=''>
                                                                    {t(`products:${ele.slot_status.toLowerCase()}`)}
                                                                </p>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                            </Content>
                                        </Content>
                                    ) : null}
                                </Content>
                            </>
                        ) : (
                            <Content className='p-2 my-2 flex flex-col items-center'>
                                <img src={SlotsMsgIcon} className='!w-[18%] my-3' alt='SlotsMsgIcon' />
                                <Text className='my-2 opacity-25'>{t('products:no_slots')}</Text>
                            </Content>
                        )}
                    </Content>
                </Content>
            </StoreModal>
        </Content>
    )
}

export default ProductPublishingEditNew
