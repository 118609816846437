import React, { useState, useEffect } from 'react'
import { Table, Button, Layout, Spin, Typography, Select, Tooltip, Image } from 'antd'
import { ArrowLeftOutlined, ArrowRightOutlined, LoadingOutlined } from '@ant-design/icons'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import { useNavigate, useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import OrderJourney from './OrderJourney'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { getGenerateDateAndTime } from '../../util/util'
import { ProductDefaultImage } from '../../constants/media'
import util from '../../util/common'

const { Content } = Layout
const { Text } = Typography

const currencySymbol = process.env.REACT_APP_CURRENCY_SYMBOL
const orderItemAPI = process.env.REACT_APP_ORDER_WITH_ITEMS_FOR_STORE_ADMIN
const retryOrderItemJourneyAPI = process.env.REACT_APP_RETRY_ORDER_ITEM_JOURNEY
const baseURL = process.env.REACT_APP_BASE_URL

const OrderDetails = () => {
    const navigate = useNavigate()
    const { t } = useTranslation()
    const search = useLocation().search
    let orderId = new URLSearchParams(search).get('oid')

    const contentSettingFromRedux = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const currencySymbolFromAPI =
        contentSettingFromRedux &&
        contentSettingFromRedux.length > 0 &&
        contentSettingFromRedux[0].store_currency &&
        contentSettingFromRedux[0].store_currency[0].symbol

    const currencySymbolFromRedux =
        contentSettingFromRedux === undefined ||
        currencySymbolFromAPI === undefined ||
        currencySymbolFromAPI === null ||
        currencySymbolFromAPI.length < 1
            ? currencySymbol
            : currencySymbolFromAPI

    const [spinLoading, setSpinLoading] = useState(false)
    const [orderDataItemById, setOrderDataItemById] = useState([])
    const [orderDetails, setOrderDetails] = useState([])

    const [isOrderItemLoading, setIsOrderItemLoading] = useState(true)
    const [isOrderItemNetworkError, setIsOrderItemNetworkError] = useState(false)
    const [ExpandedRowKey, setExpandedRowKey] = useState(null)

    const handleNavigationBack = () => {
        navigate(-1)
    }

    const retryOrderItemJourney = (order_item_id) => {
        setSpinLoading(true)
        MarketplaceServices.save(
            retryOrderItemJourneyAPI,
            {},
            {
                order_item_id: order_item_id,
            }
        )
            .then(function (response) {
                setSpinLoading(false)
                console.log(
                    'Server Response from post call of retryOrderItemJourney',
                    retryOrderItemJourneyAPI,
                    response.data
                )
                MarketplaceToaster.showToast(response)
                let temp = [...orderDataItemById]
                let index = temp.findIndex((ele) => ele.id == order_item_id)
                temp[index]['is_journey_started'] = true

                setOrderDataItemById(temp)
            })
            .catch(function (error) {
                setSpinLoading(false)
                MarketplaceToaster.showToast(error.response)
                console.log('Error Server Response from retryOrderItemJourney', retryOrderItemJourneyAPI, error)
            })
    }

    const dataProcessorOfOrderDetails = (data) => {
        let sampleData = []
        if (data?.length > 0) {
            for (let i = 0; i < data.length; i++) {
                let copyObject = {}

                copyObject['key'] = data[i].id
                copyObject['id'] = data[i].id
                copyObject['name'] = data[i].name
                copyObject['image'] = data[i].image
                copyObject['quantity'] = data[i].quantity
                copyObject['amount'] = data[i].amount
                copyObject['product_price'] = data[i].product_price
                copyObject['discount_details'] = data[i].discount_details
                copyObject['vendor_name'] = data[i].vendor_name
                copyObject['expected_delivery'] = data[i].expected_delivery
                copyObject['completed_status'] = data[i].completed_status
                copyObject['node_to_initiate'] = data[i].node_to_initiate
                copyObject['order_fulfilment_flow_id'] = data[i].order_fulfilment_flow_id
                copyObject['tax_amount'] = data[i].tax_amount
                copyObject['delivery_amount'] = data[i].delivery_amount
                copyObject['status'] = data[i].status
                copyObject['handled_by'] = data[i].handled_by
                copyObject['journey_url'] = data[i].journey_url
                copyObject['is_journey_started'] = data[i].is_journey_started
                copyObject['order_id'] = data[i].order_id
                copyObject['product_id'] = data[i].product_id
                copyObject['vendor_id'] = data[i].vendor_id
                copyObject['slot_id'] = data[i].slot_id
                copyObject['slot_date'] = data[i].slot_date
                copyObject['return_started'] = data[i].return_started
                copyObject['replacement_started'] = data[i].replacement_started
                copyObject['is_bundled_product'] = data[i].is_bundled_product
                copyObject['created_on'] = data[i].created_on
                copyObject['invoice_number'] = data[i].invoice_number
                copyObject['is_replaced_orderitem'] = data[i].is_replaced_orderitem
                copyObject['replacement_for_id'] = data[i].replacement_for_id
                copyObject['cancellation_charge'] = data[i].cancellation_charge
                copyObject['product_details'] = data[i].product_details
                copyObject['coupon_details'] = data[i].coupon_details
                copyObject['coupon_applied'] = data[i].coupon_applied
                copyObject['discounted_amount'] = data[i].discounted_amount
                copyObject['status_display_name'] = data[i].status_display_name
                copyObject['product_image_path'] = data[i].product_image_path
                copyObject['product_media_full_path'] = data[i].product_media_full_path
                copyObject['product_type_id'] = data[i].product_type_id
                copyObject['product_type_name'] = data[i].product_type_name
                copyObject['product_name'] = data[i].product_name
                copyObject['vendor_display_name'] = data[i].vendor_display_name
                copyObject['product_type_display_name'] = data[i].product_type_display_name
                copyObject['journey_status'] = data[i].journey_status
                copyObject['order_item_total'] = data[i].order_item_total
                copyObject['order_item_coupon_discount'] = data[i].order_item_coupon_discount
                copyObject['order_item_discount'] = data[i].order_item_discount
                copyObject['order_item_final_total'] = data[i].order_item_final_total
                copyObject['order_item_total_discount'] = data[i].order_item_total_discount
                sampleData.push(copyObject)
            }
            return sampleData
        } else {
            return []
        }
    }

    const getOrderItemByID = (order_id) => {
        MarketplaceServices.findAllWithoutPage(orderItemAPI, { order_id: order_id }, true)
            .then(function (response) {
                console.log('Server Response from get call of orderItemByID', orderItemAPI, response.data.response_body)

                setOrderDataItemById(dataProcessorOfOrderDetails(response?.data?.response_body?.Orders[0]?.order_items))
                setOrderDetails(response.data.response_body.Orders)

                setIsOrderItemLoading(false)
                setIsOrderItemNetworkError(false)
            })
            .catch(function (error) {
                console.log('Error server Response from catchBlock of orderItemByID', orderItemAPI, error)
                setIsOrderItemLoading(false)
                setIsOrderItemNetworkError(true)
                setOrderDataItemById([])
                setOrderDetails([])
            })
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 54,
            }}
            spin
        />
    )
    const latestStatusOptions = [
        {
            key: 1,
            value: `${t('common:received')}`,
            label: `${t('common:received')}`,
        },
        {
            key: 2,
            value: `${t('common:in_progress')}`,
            label: `${t('common:in_progress')}`,
        },
        {
            key: 3,
            value: `${t('common:delivered')}`,
            label: `${t('common:delivered')}`,
        },
    ]

    const handleExpand = (expanded, record) => {
        setExpandedRowKey(expanded ? record.id : null)
    }

    const showBadge = (color, title, isBgFilled) => {
        return (
            <div className='flex flex-row'>
                <div
                    className={
                        isBgFilled == true
                            ? `w-[6px] h-[6px] rounded-full mt-2`
                            : `border-2  w-[6px] h-[6px] rounded-full mt-2`
                    }
                    style={{
                        backgroundColor: isBgFilled ? color : '#ffffff',
                        borderColor: !isBgFilled && color,
                    }}></div>
                <p
                    className={`!font-normal !text-sm !text-regal-blue ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-3' : ' ml-3'}`}>
                    {title}
                </p>
            </div>
        )
    }

    const tableColumnForOrderBasedItems = [
        {
            title: `${t('sales_returns:item_name_and_sku')}`,
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (text, record) => {
                return (
                    <div className='flex flex-row'>
                        <Content className={`w-[30%]`}>
                            <Image
                                src={baseURL + record.product_image_path}
                                fallback={ProductDefaultImage}
                                width={40}
                                height={40}></Image>
                        </Content>
                        <Content className='w-[70%]'>
                            <Text
                                className='ml-1 !font-normal !text-sm !text-regal-blue'
                                ellipsis={{ tooltip: record.name }}>
                                {record.name}
                            </Text>
                        </Content>
                    </div>
                )
            },
        },
        ...(orderDataItemById &&
        orderDataItemById.length > 0 &&
        orderDataItemById[0].product_type_name === 'Subscription Product'
            ? []
            : [
                  {
                      title: `${t('sales_returns:quantity')}`,
                      dataIndex: 'quantity',
                      key: 'quantity',
                      width: '10%',
                      render: (text, record) => {
                          return <Content className=''>{record.quantity}</Content>
                      },
                  },
              ]),
        {
            title: <div className='min-w-max'>{t('transactions:line_item_id')}</div>,
            dataIndex: 'id',
            key: 'id',
            width: '20%',
            render: (text, record) => {
                return <Content className=''>{'#' + record.id}</Content>
            },
        },

        {
            title: `${t('sales_orders:amount')}`,
            dataIndex: 'amount',
            key: 'amount',
            width: '15%',
            render: (text, record) => {
                return <Text className='whitespace-nowrap'>{currencySymbolFromRedux + ' ' + record.amount}</Text>
            },
        },
        {
            title: <div className='min-w-max'>{t('sales_returns:vendor_name')}</div>,
            dataIndex: 'vendor_name',
            key: 'amount',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content className='max-w-[200px]'>
                        <Text ellipsis={{ tooltip: { title: record.vendor_name } }}>{record.vendor_name}</Text>
                    </Content>
                )
            },
        },
        {
            title: `${t('sales_returns:status')}`,
            dataIndex: 'status',
            key: 'status',
            width: '25%',
            render: (text, record) => {
                return (
                    <>
                        {record.status == 'InProgress'
                            ? showBadge('#1890FF', record.status_display_name, true)
                            : record.status == 'Cancelled'
                              ? showBadge('#FF4D4F', record.status_display_name, true)
                              : record.status == 'Refund'
                                ? showBadge('#722ED1', record.status_display_name, true)
                                : record.status == 'Fulfilled'
                                  ? showBadge('#52C41A', record.status_display_name, true)
                                  : record.status == 'Received'
                                    ? showBadge('#FAAD14', record.status_display_name, true)
                                    : showBadge('#BFBFBF', record.status_display_name, true)}
                    </>
                )
            },
        },
    ]

    useEffect(() => {
        window.scrollTo(0, 0)
        if (orderId != null && orderId !== undefined) {
            getOrderItemByID(parseInt(orderId))
        }
    }, [orderId])

    console.log('orderDataItemById--->', orderDataItemById)

    return (
        <div>
            <div className='!h-[95px] bg-white fixed !z-10  !px-6 !pb-6 !pt-10 headerWidth'>
                <div className='flex'>
                    {util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? (
                        <ArrowRightOutlined className=' !text-xl !ml-2' onClick={handleNavigationBack} />
                    ) : (
                        <ArrowLeftOutlined className=' !text-xl' onClick={handleNavigationBack} />
                    )}

                    <p className='!text-regal-blue font-semibold text-2xl ml-2'>
                        {t('dashboard:order_id') + ' ' + ':' + ' ' + orderId}
                    </p>
                </div>
            </div>
            <div className='pt-[120px] !px-6 !pb-6'>
                <div className=' w-full  shadow-brandShadow rounded-lg bg-white p-6'>
                    {isOrderItemLoading ? (
                        <div className='text-center bg-white p-3'>
                            <SkeletonComponent />
                        </div>
                    ) : isOrderItemNetworkError ? (
                        <div className='text-center bg-white p-3'>
                            <h5>{t('common:network_error')}</h5>
                        </div>
                    ) : (
                        <>
                            <div className='flex'>
                                <div className='w-[29%]'>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {t('dashboard:order_id')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[50%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <Text
                                                ellipsis={{ tooltip: orderDetails[0]?.id }}
                                                className='!font-normal !text-sm !text-regal-blue'>
                                                {orderDetails[0]?.id}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {t('dashboard:order_status')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[50%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <Text
                                                ellipsis={{ tooltip: orderDetails[0]?.order_status_display_name }}
                                                className='!font-normal !text-sm !text-regal-blue'>
                                                {orderDetails[0]?.order_status_display_name}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {' '}
                                                {t('dashboard:payment_type')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[50%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <p className='!font-normal !text-sm !text-regal-blue'>
                                                {orderDetails[0]?.payment_type_name === ''
                                                    ? t('common:not_available')
                                                    : orderDetails[0]?.payment_type_name}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {t('sales_orders:reference_id')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[48%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : '!ml-5'}`}>
                                            <Text
                                                ellipsis={{
                                                    tooltip: orderDetails[0]?.order_referance_id
                                                        ? orderDetails[0]?.order_referance_id
                                                        : t('common:not_available'),
                                                }}
                                                className='!font-normal !text-sm !text-regal-blue max-w-[100px]'>
                                                {orderDetails[0]?.order_referance_id
                                                    ? orderDetails[0]?.order_referance_id
                                                    : t('common:not_available')}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {' '}
                                                {t('sales_orders:order_date')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[50%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <p className='!font-normal !text-sm !text-regal-blue'>
                                                {getGenerateDateAndTime(
                                                    orderDetails[0]?.date_of_order,
                                                    'ddd MMM DD YYYY'
                                                )}
                                            </p>
                                        </div>
                                    </div>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[48%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {' '}
                                                {t('sales_orders:amount')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[50%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <p className='!font-normal !text-sm !text-regal-blue'>
                                                {' '}
                                                {orderDetails[0]?.currency_symbol + ' ' + orderDetails[0]?.total_amount}
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className='w-[70%]'>
                                    <div className='flex !pb-6'>
                                        <div className='flex justify-between !w-[20%]'>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>
                                                {t('sales_orders:customer_name')}
                                            </p>
                                            <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                        </div>
                                        <div
                                            className={`!w-[74%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                            <Text
                                                ellipsis={{
                                                    tooltip: orderDetails[0]?.customer_name,
                                                }}
                                                className='!font-normal !text-sm !text-regal-blue'>
                                                {orderDetails[0]?.customer_name}
                                            </Text>
                                        </div>
                                    </div>
                                    <div className='flex'>
                                        <div className='flex !pb-6 w-[50%]'>
                                            <div className='flex justify-between !w-[44%]'>
                                                <p className='!font-normal !text-sm text-[#8899A8]'>
                                                    {' '}
                                                    {t('sales_returns:billing_address')}
                                                </p>
                                                <p className='!font-normal !text-sm text-[#8899A8]'>:</p>
                                            </div>
                                            <div
                                                className={`!w-[57%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                                <p className='!font-normal !text-sm !text-regal-blue'>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.house_number,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.billing_address?.house_number}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.street,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.billing_address?.street}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.locality,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.billing_address?.locality}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.city,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.billing_address?.city}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.state,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1 '>
                                                        {orderDetails[0]?.billing_address?.state}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.country,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue'>
                                                        {orderDetails[0]?.billing_address?.country}
                                                    </Text>{' '}
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.billing_address?.zipcode,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue'>
                                                        {orderDetails[0]?.billing_address?.zipcode}
                                                    </Text>
                                                </p>
                                            </div>
                                        </div>
                                        <div
                                            className={`flex !pb-6 w-[53%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-2' : 'ml-2'}`}>
                                            <div className='flex justify-between !w-[44%] mx-1'>
                                                <p className='!font-normal !text-sm text-[#8899A8] '>
                                                    {t('sales_returns:delivery_address')}
                                                </p>
                                                <p className='!font-normal !text-sm text-[#8899A8] ml-2'>:</p>
                                            </div>
                                            <div
                                                className={`!w-[56%] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-5' : 'ml-5'}`}>
                                                <p className='!font-normal !text-sm !text-regal-blue'>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.house_number,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.delivery_address?.house_number}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.street,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.delivery_address?.street}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.locality,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.delivery_address?.locality}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.city,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.delivery_address?.city}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.state,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue pr-1'>
                                                        {orderDetails[0]?.delivery_address?.state}
                                                    </Text>
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.country,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue'>
                                                        {orderDetails[0]?.delivery_address?.country}
                                                    </Text>{' '}
                                                    <Text
                                                        ellipsis={{
                                                            tooltip: orderDetails[0]?.delivery_address?.zipcode,
                                                        }}
                                                        className='!font-normal !text-sm !text-regal-blue'>
                                                        {orderDetails[0]?.delivery_address?.zipcode}
                                                    </Text>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='!pt-6'>
                                <Table
                                    className='!w-[100%] !mb-2'
                                    expandable={{
                                        onExpand: handleExpand,
                                        expandedRowRender: (record) => (
                                            <Content key={record.id}>
                                                {record.is_journey_started === true ? (
                                                    <Content
                                                        key={record.id}
                                                        className='!min-h-[100px] overflow-clip hover:overflow-auto !relative p-3 '>
                                                        <Content className='!absolute'>
                                                            <OrderJourney order_item_id={record.id} />
                                                        </Content>
                                                    </Content>
                                                ) : (
                                                    <Spin spinning={spinLoading} indicator={antIcon}>
                                                        <Content className='flex justify-between'>
                                                            <Content className=''>
                                                                <p className=''>
                                                                    {t('sales_orders:journey_not_started')}
                                                                </p>
                                                            </Content>
                                                            <Content className='!inline-block !text-right'>
                                                                <Button
                                                                    disabled={
                                                                        orderDetails &&
                                                                        orderDetails[0].order_status == 'Placed'
                                                                            ? false
                                                                            : true
                                                                    }
                                                                    onClick={() => retryOrderItemJourney(record.id)}
                                                                    className='app-btn-primary'>
                                                                    {t('sales_orders:start_journey')}
                                                                </Button>
                                                            </Content>
                                                        </Content>
                                                    </Spin>
                                                )}
                                            </Content>
                                        ),

                                        rowExpandable: (record) => record.name !== 'Not Expandable',
                                        expandedRowKeys: [ExpandedRowKey],
                                    }}
                                    columns={tableColumnForOrderBasedItems}
                                    dataSource={orderDataItemById}
                                    pagination={orderDataItemById.length > 20 ? true : false}
                                />
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

export default OrderDetails
