/**
 * Translates Date names in a date string based on the provided translation function.
 *
 * @param {string} dateString - The date string to be processed, e.g., "01 March 2023".
 * @param {function} t - The translation function used to translate Date names. This function takes a translation key and returns the corresponding translated string.
 *
 * @returns {string} - The date string with translated Date names.
 */
export default function getTranslatedDate(dateString, t) {
    const dateParts = dateString.split(' ')

    // Function to check if a string contains only alphabetic characters
    const isAlphabetic = (str) => /^[a-zA-Z]+$/.test(str)

    // Map over each part of the date string to potentially translate Date names
    const translatedDateString = dateParts
        .map((part) => {
            const lowerPart = part.toLocaleLowerCase()
            if (isAlphabetic(lowerPart)) {
                const translationKey = `common:${lowerPart}`

                // Attempt to retrieve the translated value from the translation dictionary
                const translatedValue = t(translationKey, { defaultValue: null })

                // Return the translated value if found; otherwise, return the original part
                return translatedValue ? translatedValue : part
            }

            return part
        })
        .join(' ')
    return translatedDateString
}
