import {
    Button,
    Checkbox,
    DatePicker,
    Input,
    InputNumber,
    Layout,
    Radio,
    Row,
    Select,
    Slider,
    Switch,
    Tag,
    TimePicker,
    Tooltip,
    Typography,
} from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useState } from 'react'
import { generateObjectFromUrlWithFullDocumentPath, getPrefixAndSuffix, sortObjectArrayByKey } from './util'
// import TextRichEditor from "../components/TextRichEditor/TextRichEditor";
import { EyeInvisibleOutlined, EyeOutlined, GlobalOutlined, MailOutlined, UserOutlined } from '@ant-design/icons'
import { useTranslation } from 'react-i18next'
import TextRichEditor from '../components/textRichEditor/TextRichEditor'
import AttributeDataTypes from '../constants/AttributesDataTypes'
import DisplayImage from '../pages/media/DisplayImage'
import './AttributeControls.css'
import util from './common'
import { generateObjectFromUrlWithFullImagePath } from './util'
import CustomPasswordInput from '../components/CustomPasswordInput'

const { CheckableTag } = Tag
const { Content } = Layout
const { Text } = Typography
let prefixSuffixMaxLength = process.env.REACT_APP_PREFIX_SUFFIX_MAX_SHOW_LENGTH
let choicechipsPrefixSuffixMaxLength = process.env.REACT_APP_CHOICE_CHIPS_PREFIX_SUFFIX_MAX_SHOW_LENGTH

// This function will return the different controls along with their values populated for the product option values

export const GetAttributesControls = ({
    optionType,
    attributeType,
    optionValues,
    sensitive,
    isEditable,
    nonEditableValue,
    dataFromAttributeControls,
    setDataFromAttributeControls,
    attribute_unit_values,
    expression,
    prefix,
    suffix,
    placeholder,
    viewTypeForDropDown,
}) => {
    const { t } = useTranslation()
    const [richTextValue, setRichTextValue] = useState('')
    const [processedMultiCheckBoxOptions, setProcessedMultiCheckBoxOptions] = useState()
    const [imageData, setImageData] = useState()
    const [langDirection, setLangDirection] = useState('ltr')
    const [passwordVisible, setPasswordVisible] = useState(false)

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    //!Here we are Destructuring MultiCheckBox option Values to label key value as per antd Group.checkBox options
    const checkBoxData = (param) => {
        const tempArray = []
        param &&
            param.length > 0 &&
            param.forEach((element) => {
                var checkBoxId = element.attribute_option_id
                var value = element.attribute_option_id
                tempArray &&
                    tempArray.push({
                        key: checkBoxId,
                        label: (
                            <>
                                {getPrefixAndSuffix(attribute_unit_values, element.display_name, 'full-name')?.length >
                                prefixSuffixMaxLength ? (
                                    <Tooltip
                                        title={getPrefixAndSuffix(
                                            attribute_unit_values,
                                            element.display_name,
                                            'full-name'
                                        )}
                                        // overlayStyle={{ zIndex: 1 }}
                                        mouseLeaveDelay={0}
                                        className='!min-w-[200px]'
                                        placement='bottom'>
                                        <Text ellipsis={true} className='my-1'>
                                            {getPrefixAndSuffix(attribute_unit_values, element.display_name, 'name')}
                                        </Text>
                                    </Tooltip>
                                ) : (
                                    <Text ellipsis={true} className='my-1 min-w-[150px]'>
                                        {getPrefixAndSuffix(attribute_unit_values, element.display_name, 'full-name')}
                                    </Text>
                                )}
                            </>
                        ),
                        value: value,
                    })
            })
        // console.log("tempArray", tempArray);
        setProcessedMultiCheckBoxOptions(tempArray)
    }

    useEffect(() => {
        if (parseInt(optionType) === 1) {
            checkBoxData(optionValues)
        }
    }, [optionValues, attribute_unit_values])

    const onChangeData = (e) => {
        //setValue(e.target.value);
        setDataFromAttributeControls({
            ...dataFromAttributeControls,
            value: e.target.value,
        })
    }
    useEffect(() => {
        if (dataFromAttributeControls) {
            setDataFromAttributeControls({
                ...dataFromAttributeControls,
                value: richTextValue,
            })
        }
    }, [richTextValue])

    switch (optionType) {
        //Checkbox-Multiselect
        case 1:
            return (
                <div>
                    <Checkbox.Group
                        className='font-sembold !opacity-50'
                        options={processedMultiCheckBoxOptions}
                        value={nonEditableValue}
                        disabled={isEditable}
                    />
                </div>
            )

        //Radio-Singleselect
        case 2:
            return (
                <div>
                    {optionValues.length > 0 && (
                        <Radio.Group
                            name='radiogroup'
                            value={nonEditableValue && nonEditableValue[0] && nonEditableValue[0]}
                            disabled={isEditable}>
                            {sortObjectArrayByKey('attribute_option_id', 'number', optionValues, 'asc').map(
                                (item, index) => {
                                    return (
                                        <>
                                            {getPrefixAndSuffix(attribute_unit_values, item.display_name, 'full-name')
                                                ?.length > prefixSuffixMaxLength ? (
                                                <Tooltip
                                                    title={getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name'
                                                    )}
                                                    overlayStyle={
                                                        viewTypeForDropDown === 'modal'
                                                            ? { zIndex: 5000 }
                                                            : { zIndex: 1 }
                                                    }
                                                    placement={langDirection === 'rtl' ? 'left' : 'right'}
                                                    className='min-w-[200px] opacity-50'>
                                                    <Radio key={index} value={item.attribute_option_id}>
                                                        {getPrefixAndSuffix(
                                                            attribute_unit_values,
                                                            item.display_name,
                                                            'name'
                                                        )}
                                                    </Radio>
                                                </Tooltip>
                                            ) : (
                                                <Radio
                                                    key={index}
                                                    value={item.attribute_option_id}
                                                    className='min-w-[200px] opacity-50'>
                                                    {getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name'
                                                    )}
                                                </Radio>
                                            )}
                                        </>
                                    )
                                }
                            )}
                        </Radio.Group>
                    )}
                </div>
            )

        //ChoiceChips
        case 3:
            return (
                <div>
                    {optionValues.length > 0 &&
                        sortObjectArrayByKey('attribute_option_id', 'number', optionValues, 'asc').map(
                            (item, index) => {
                                return (
                                    <>
                                        {getPrefixAndSuffix(
                                            attribute_unit_values,
                                            item.display_name,
                                            'full-name',
                                            choicechipsPrefixSuffixMaxLength
                                        )?.length > choicechipsPrefixSuffixMaxLength ? (
                                            <Tooltip
                                                title={getPrefixAndSuffix(
                                                    attribute_unit_values,
                                                    item.display_name,
                                                    'full-name',
                                                    choicechipsPrefixSuffixMaxLength
                                                )}
                                                overlayStyle={
                                                    viewTypeForDropDown === 'modal' ? { zIndex: 5000 } : { zIndex: 1 }
                                                }
                                                placement={langDirection === 'rtl' ? 'left' : 'right'}
                                                className='my-2 min-w-[150px]'>
                                                <CheckableTag
                                                    disabled={isEditable}
                                                    checked={
                                                        nonEditableValue &&
                                                        nonEditableValue.includes(item.attribute_option_id)
                                                    }
                                                    className={`${
                                                        isEditable
                                                            ? 'border-2 border-gray-400 opacity-50 hover:!text-black'
                                                            : 'border-2 border-gray-400'
                                                    }`}
                                                    key={index}>
                                                    {getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'name',
                                                        choicechipsPrefixSuffixMaxLength
                                                    )}
                                                </CheckableTag>
                                            </Tooltip>
                                        ) : (
                                            <CheckableTag
                                                disabled={isEditable}
                                                checked={
                                                    nonEditableValue &&
                                                    nonEditableValue.includes(item.attribute_option_id)
                                                }
                                                className={`${
                                                    isEditable
                                                        ? 'border-2 border-gray-400 opacity-50 hover:!text-black my-2 min-w-[150px] !cursor-not-allowed'
                                                        : 'border-2 border-gray-400 my-2 min-w-[150px]'
                                                }`}
                                                key={index}>
                                                {getPrefixAndSuffix(
                                                    attribute_unit_values,
                                                    item.display_name,
                                                    'full-name',
                                                    choicechipsPrefixSuffixMaxLength
                                                )}
                                            </CheckableTag>
                                        )}
                                    </>
                                )
                            }
                        )}
                </div>
            )

        //Toggle
        case 4:
            const maxLength = 15
            const checkedDisplayName = sortObjectArrayByKey('attribute_option_id', 'number', optionValues, 'asc')[0]
                ?.display_name
            const uncheckedDisplayName = sortObjectArrayByKey('attribute_option_id', 'number', optionValues, 'asc')[1]
                ?.display_name
            return (
                <div className='max-w-[100px]'>
                    {optionValues && optionValues.length >= 2 ? (
                        <Tooltip
                            title={
                                optionValues[0]?.attribute_option_id === nonEditableValue[0] && nonEditableValue[0]
                                    ? checkedDisplayName
                                    : uncheckedDisplayName
                            }
                            placement={langDirection === 'rtl' ? 'bottomRight' : 'bottomLeft'}
                            overlayStyle={{ zIndex: 1 }}>
                            <Switch
                                disabled={isEditable}
                                style={{ backgroundColor: 'gray' }}
                                checkedChildren={
                                    <span
                                        style={{
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                        {checkedDisplayName.length > maxLength
                                            ? `${checkedDisplayName.slice(0, maxLength)}...`
                                            : checkedDisplayName}
                                    </span>
                                }
                                unCheckedChildren={
                                    <span
                                        style={{
                                            maxWidth: '100%',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            whiteSpace: 'nowrap',
                                        }}>
                                        {uncheckedDisplayName.length > maxLength
                                            ? `${uncheckedDisplayName.slice(0, maxLength)}...`
                                            : uncheckedDisplayName}
                                    </span>
                                }
                                checked={
                                    optionValues[0]?.attribute_option_id === nonEditableValue[0] && nonEditableValue[0]
                                }
                            />
                        </Tooltip>
                    ) : null}
                </div>
            )

        //Date
        case 5:
            return (
                <div>
                    <DatePicker
                        disabled={isEditable}
                        format={
                            attribute_unit_values && attribute_unit_values.length > 0
                                ? attribute_unit_values &&
                                  attribute_unit_values.length > 0 &&
                                  attribute_unit_values.find((item) => String(item.key) === 'format')
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format').value
                                    : 'YYYY-MM-DD'
                                : 'YYYY-MM-DD'
                        }
                        defaultValue={
                            nonEditableValue !== '' && nonEditableValue !== null && nonEditableValue !== undefined
                                ? dayjs(
                                      nonEditableValue,
                                      attribute_unit_values && attribute_unit_values.length > 0
                                          ? attribute_unit_values &&
                                            attribute_unit_values.length > 0 &&
                                            attribute_unit_values.find((item) => String(item.key) === 'format')
                                              ? attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'format')
                                                    .value
                                              : 'YYYY-MM-DD'
                                          : 'YYYY-MM-DD'
                                  )
                                : null
                        }
                        placeholder={t(
                            `common:${AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 5).placeholder}`
                        )}
                        className='w-[40%]'
                        value={
                            nonEditableValue !== '' && nonEditableValue !== null && nonEditableValue !== undefined
                                ? dayjs(
                                      nonEditableValue,
                                      attribute_unit_values && attribute_unit_values.length > 0
                                          ? attribute_unit_values &&
                                            attribute_unit_values.length > 0 &&
                                            attribute_unit_values.find((item) => String(item.key) === 'format')
                                              ? attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'format')
                                                    .value
                                              : 'YYYY-MM-DD'
                                          : 'YYYY-MM-DD'
                                  )
                                : null
                        }
                    />
                </div>
            )
        //Slider
        case 6:
            return (
                <div>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <>
                            <Slider
                                disabled={isEditable}
                                range={false}
                                value={parseInt(nonEditableValue)}
                                min={
                                    attribute_unit_values && attribute_unit_values.length > 0
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'min_value')
                                            ? parseInt(
                                                  attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'min_value'
                                                      ).value
                                              )
                                            : 0
                                        : 0
                                }
                                max={
                                    attribute_unit_values && attribute_unit_values.length > 0
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'max_value')
                                            ? parseInt(
                                                  attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'max_value'
                                                      ).value
                                              )
                                            : 10000
                                        : 10000
                                }
                            />
                            <Content className='flex justify-between'>
                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                    attribute_unit_values &&
                                    attribute_unit_values.length > 0 &&
                                    attribute_unit_values.find((item) => String(item.key) === 'left') ? (
                                        <Text>
                                            {attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'left').value}
                                        </Text>
                                    ) : null
                                ) : null}
                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                    attribute_unit_values &&
                                    attribute_unit_values.length > 0 &&
                                    attribute_unit_values.find((item) => String(item.key) === 'right') ? (
                                        <Text>
                                            {attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'right')
                                                    .value}
                                        </Text>
                                    ) : null
                                ) : null}
                            </Content>
                        </>
                    ) : (
                        <>
                            <Slider
                                disabled={isEditable}
                                range={false}
                                min={
                                    attribute_unit_values && attribute_unit_values.length > 0
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'min_value')
                                            ? parseInt(
                                                  attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'min_value'
                                                      ).value
                                              )
                                            : 0
                                        : 0
                                }
                                max={
                                    attribute_unit_values && attribute_unit_values.length > 0
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'max_value')
                                            ? parseInt(
                                                  attribute_unit_values &&
                                                      attribute_unit_values.length > 0 &&
                                                      attribute_unit_values.find(
                                                          (item) => String(item.key) === 'max_value'
                                                      ).value
                                              )
                                            : 10000
                                        : 10000
                                }
                            />
                            <Content className='flex justify-between'>
                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                    attribute_unit_values &&
                                    attribute_unit_values.length > 0 &&
                                    attribute_unit_values.find((item) => String(item.key) === 'left') ? (
                                        <Text>
                                            {attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'left').value}
                                        </Text>
                                    ) : null
                                ) : null}
                                {attribute_unit_values && attribute_unit_values.length > 0 ? (
                                    attribute_unit_values &&
                                    attribute_unit_values.length > 0 &&
                                    attribute_unit_values.find((item) => String(item.key) === 'right') ? (
                                        <Text>
                                            {attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'right')
                                                    .value}
                                        </Text>
                                    ) : null
                                ) : null}
                            </Content>
                        </>
                    )}
                </div>
            )

        //Dropdown-SingleSelect
        case 7:
            return (
                <div>
                    <Select
                        value={nonEditableValue && nonEditableValue[0]}
                        style={{
                            width: 200,
                        }}
                        listHeight={150}
                        // dropdownStyle={{
                        //     maxHeight: '150px',
                        //     overflowY: 'auto',
                        // }}
                        placeholder={t(
                            `common:${AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 7).placeholder}`
                        )}>
                        {optionValues &&
                            optionValues.length > 0 &&
                            optionValues.map((item, index) => (
                                <Select.Option
                                    value={item.attribute_option_id}
                                    dropdownStyle={{
                                        zIndex: viewTypeForDropDown === 'page' ? 1 : '',
                                    }}
                                    disabled={true}
                                    key={index}
                                    title={
                                        // Display the selected value as a tooltip
                                        getPrefixAndSuffix(attribute_unit_values, item.display_name, 'full-name')
                                    }>
                                    {getPrefixAndSuffix(attribute_unit_values, item.display_name, 'full-name')}
                                </Select.Option>
                            ))}
                    </Select>
                </div>
            )

        //Switcher
        case 8:
            return (
                <div>
                    {optionValues && optionValues.length > 0 && (
                        <Radio.Group
                            value={nonEditableValue && nonEditableValue.length > 0 && nonEditableValue[0]}
                            buttonStyle='solid'
                            className='!opacity-50'
                            disabled={isEditable}>
                            {sortObjectArrayByKey('attribute_option_id', 'number', optionValues, 'asc').map(
                                (item, index) => {
                                    return (
                                        <>
                                            {getPrefixAndSuffix(attribute_unit_values, item.display_name, 'full-name')
                                                ?.length > prefixSuffixMaxLength ? (
                                                <Tooltip
                                                    title={getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name'
                                                    )}
                                                    overlayStyle={
                                                        viewTypeForDropDown === 'modal'
                                                            ? { zIndex: 5000 }
                                                            : { zIndex: 1 }
                                                    }
                                                    placement={langDirection === 'rtl' ? 'left' : 'right'}>
                                                    <Radio.Button
                                                        key={index}
                                                        value={item.attribute_option_id}
                                                        className='!min-w-[200px]'>
                                                        {getPrefixAndSuffix(
                                                            attribute_unit_values,
                                                            item.display_name,
                                                            'name'
                                                        )}
                                                    </Radio.Button>
                                                </Tooltip>
                                            ) : (
                                                <Radio.Button
                                                    key={index}
                                                    value={item.attribute_option_id}
                                                    className='!min-w-[200px]'>
                                                    {getPrefixAndSuffix(
                                                        attribute_unit_values,
                                                        item.display_name,
                                                        'full-name'
                                                    )}
                                                </Radio.Button>
                                            )}
                                        </>
                                    )
                                }
                            )}
                        </Radio.Group>
                    )}
                </div>
            )

        //Time
        case 9:
            return (
                <div>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <TimePicker
                            className='w-[40%] pl-[6px]'
                            disabled={isEditable}
                            use12Hours
                            placeholder={t(
                                `common:${AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 9).placeholder}`
                            )}
                            format={
                                attribute_unit_values && attribute_unit_values.length > 0
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format')
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'format').value
                                        : 'HH:mm:ss A'
                                    : 'HH:mm:ss A'
                            }
                            value={dayjs(
                                nonEditableValue,
                                attribute_unit_values && attribute_unit_values.length > 0
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format')
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'format').value
                                        : 'HH:mm:ss A'
                                    : 'HH:mm:ss A'
                            )}
                        />
                    ) : (
                        <TimePicker
                            className='w-[40%] pl-[6px]'
                            placeholder={t(
                                `common:${AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 9).placeholder}`
                            )}
                            disabled={isEditable}
                            format={
                                attribute_unit_values && attribute_unit_values.length > 0
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format')
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'format').value
                                        : 'HH:mm:ss A'
                                    : 'HH:mm:ss A'
                            }
                        />
                    )}
                </div>
            )

        // TextRich
        case 10:
            return (
                <>
                    {isEditable ? (
                        <div className=' bg-[#F5F5F5] !cursor-not-allowed'>
                            <TextRichEditor
                                data={nonEditableValue}
                                setData={setRichTextValue}
                                // placeholder={placeholder}
                                editable={true}
                                placeholder={t(
                                    `common:${
                                        AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 10).placeholder
                                    }`
                                )}
                            />
                        </div>
                    ) : (
                        <div className='opacity-40 bg-[#00000014]'>
                            <TextRichEditor data={richTextValue} setData={setRichTextValue} />
                        </div>
                    )}
                </>
            )

        //Text Email
        case 11:
            return (
                <div>
                    {sensitive === false ? (
                        <Input
                            disabled={isEditable}
                            prefix={<MailOutlined />}
                            className='w-[100%] pl-[6px]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 11).placeholder
                                }`
                            )}
                            value={nonEditableValue}
                            onChange={(e) => onChangeData(e)}
                        />
                    ) : (
                        <>
                            <CustomPasswordInput
                                disabled={isEditable}
                                prefix={<MailOutlined />}
                                className='w-[100%] pl-[6px]'
                                placeholder={t(
                                    `common:${
                                        AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 11).placeholder
                                    }`
                                )}
                                value={nonEditableValue}
                                onChange={(e) => onChangeData(e)}
                                visibility={passwordVisible}
                                setVisibility={setPasswordVisible}
                            />
                        </>
                    )}
                </div>
            )

        //text PhoneNumber
        case 12:
            return (
                <div>
                    {sensitive === false ? (
                        <Input
                            disabled={isEditable}
                            addonBefore={nonEditableValue.split('-')[0]}
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 12).placeholder
                                }`
                            )}
                            className='w-[100%] addonbefore-grayout'
                            value={nonEditableValue.split('-')[1]}
                        />
                    ) : (
                        <CustomPasswordInput
                            disabled={isEditable}
                            addonBefore={nonEditableValue.split('-')[0]}
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 12).placeholder
                                }`
                            )}
                            value={nonEditableValue.split('-')[1]}
                            onChange={(e) => onChangeData(e)}
                            visibility={passwordVisible}
                            setVisibility={setPasswordVisible}
                        />
                    )}
                </div>
            )

        //Text url
        case 13:
            return (
                <div>
                    {sensitive === false ? (
                        <Input
                            disabled={isEditable}
                            prefix={<GlobalOutlined />}
                            className='w-[100%] pl-[7px]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 13).placeholder
                                }`
                            )}
                            value={nonEditableValue}
                        />
                    ) : (
                        <CustomPasswordInput
                            disabled={isEditable}
                            prefix={<GlobalOutlined />}
                            className='w-[100%] pl-[7px]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 13).placeholder
                                }`
                            )}
                            value={nonEditableValue}
                            onChange={(e) => onChangeData(e)}
                            visibility={passwordVisible}
                            setVisibility={setPasswordVisible}
                        />
                    )}
                </div>
            )

        //Date and Time
        case 14:
            return (
                <div>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <DatePicker
                            showTime
                            disabled={isEditable}
                            format={
                                attribute_unit_values && attribute_unit_values.length > 0
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format')
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'format').value
                                        : 'YYYY-MM-DD HH:mm:ss'
                                    : 'YYYY-MM-DD HH:mm:ss'
                            }
                            defaultValue={
                                nonEditableValue !== ''
                                    ? dayjs(
                                          nonEditableValue,
                                          attribute_unit_values && attribute_unit_values.length > 0
                                              ? attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'format')
                                                  ? attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find((item) => String(item.key) === 'format')
                                                        .value
                                                  : 'YYYY-MM-DD HH:mm:ss'
                                              : 'YYYY-MM-DD HH:mm:ss'
                                      )
                                    : null
                            }
                            className='w-[40%]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 14).placeholder
                                }`
                            )}
                            value={
                                nonEditableValue !== ''
                                    ? dayjs(
                                          nonEditableValue,
                                          attribute_unit_values && attribute_unit_values.length > 0
                                              ? attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'format')
                                                  ? attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find((item) => String(item.key) === 'format')
                                                        .value
                                                  : 'YYYY-MM-DD HH:mm:ss'
                                              : 'YYYY-MM-DD HH:mm:ss'
                                      )
                                    : null
                            }
                        />
                    ) : (
                        <DatePicker
                            showTime
                            disabled={isEditable}
                            format={
                                attribute_unit_values && attribute_unit_values.length > 0
                                    ? attribute_unit_values &&
                                      attribute_unit_values.length > 0 &&
                                      attribute_unit_values.find((item) => String(item.key) === 'format')
                                        ? attribute_unit_values &&
                                          attribute_unit_values.length > 0 &&
                                          attribute_unit_values.find((item) => String(item.key) === 'format').value
                                        : 'YYYY-MM-DD HH:mm:ss'
                                    : 'YYYY-MM-DD HH:mm:ss'
                            }
                            defaultValue={
                                nonEditableValue !== ''
                                    ? dayjs(
                                          nonEditableValue,
                                          attribute_unit_values && attribute_unit_values.length > 0
                                              ? attribute_unit_values &&
                                                attribute_unit_values.length > 0 &&
                                                attribute_unit_values.find((item) => String(item.key) === 'format')
                                                  ? attribute_unit_values &&
                                                    attribute_unit_values.length > 0 &&
                                                    attribute_unit_values.find((item) => String(item.key) === 'format')
                                                        .value
                                                  : 'YYYY-MM-DD HH:mm:ss'
                                              : 'YYYY-MM-DD HH:mm:ss'
                                      )
                                    : null
                            }
                            className='w-[40%]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 14).placeholder
                                }`
                            )}
                            value={
                                nonEditableValue !== ''
                                    ? dayjs(
                                          nonEditableValue,
                                          attribute_unit_values && attribute_unit_values.length > 0
                                              ? attribute_unit_values?.find((item) => String(item.key) === 'format')
                                                    ?.value || 'YYYY-MM-DD HH:mm:ss'
                                              : 'YYYY-MM-DD HH:mm:ss'
                                      )
                                    : null
                            }
                        />
                    )}
                </div>
            )

        // File
        case 15:
            return (
                <Content className='flex'>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    nonEditableValue !== ''
                                        ? generateObjectFromUrlWithFullDocumentPath(nonEditableValue)
                                        : nonEditableValue
                                }
                                setAllImages={setImageData}
                                fileType={'document'}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        <>
                            <Button
                                type='dashed'
                                style={{
                                    width: '100%',
                                    marginBottom: '18px',
                                    fontWeight: 500,
                                }}
                                block
                                disabled={isEditable}>
                                {t('attributes:add_file')}
                            </Button>
                        </>
                    )}
                </Content>
            )

        //File-Image
        case 16:
            return (
                <Content className='flex'>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={generateObjectFromUrlWithFullImagePath(nonEditableValue)}
                                setAllImages={setImageData}
                                fileType={'image'}
                                deleteFromServer={false}
                                size={'large'}
                                closeButton={false}></DisplayImage>
                        </Row>
                    ) : (
                        <>
                            <Button
                                style={{
                                    width: '100%',
                                    fontWeight: 500,
                                }}
                                block
                                disabled={isEditable}>
                                {t('attributes:add_image')}
                            </Button>
                        </>
                    )}
                </Content>
            )

        //file -video
        case 17:
            return <p>{t('attributes:file_video_uploading_not_yet_done')}</p>

        //file-docs
        case 18:
            return (
                <Content className='flex'>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    nonEditableValue !== ''
                                        ? generateObjectFromUrlWithFullDocumentPath(nonEditableValue)
                                        : nonEditableValue
                                }
                                setAllImages={setImageData}
                                fileType={'document'}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        <>
                            <Button
                                style={{
                                    width: '100%',
                                    fontWeight: 500,
                                }}
                                block
                                disabled={isEditable}>
                                {t('attributes:add_document')}
                            </Button>
                        </>
                    )}
                </Content>
            )

        //file-pdf
        case 19:
            return (
                <Content className='flex'>
                    {nonEditableValue !== undefined && nonEditableValue !== null && nonEditableValue !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    nonEditableValue !== ''
                                        ? generateObjectFromUrlWithFullDocumentPath(nonEditableValue)
                                        : nonEditableValue
                                }
                                setAllImages={setImageData}
                                fileType={'pdf'}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        <>
                            <Button
                                style={{
                                    width: '100%',
                                    fontWeight: 500,
                                }}
                                block
                                disabled={isEditable}>
                                {t('attributes:add_pdf')}
                            </Button>
                        </>
                    )}
                </Content>
            )
        //Float
        case 20:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    className='w-[100%]'
                                    min='0.01'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 20)
                                                .placeholder
                                        }`
                                    )}
                                    disabled={isEditable}
                                    value={nonEditableValue}
                                    step='0.01'
                                    stringMode
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 20)
                                                .placeholder
                                        }`
                                    )}
                                    min='0.01'
                                    step='0.01'
                                    stringMode
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //Percentage
        case 21:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    disabled={isEditable}
                                    min={0}
                                    suffix='%'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 21)
                                                .placeholder
                                        }`
                                    )}
                                    style={{ width: '100%', marginTop: '7px' }}
                                    value={nonEditableValue}
                                />
                            ) : (
                                <CustomPasswordInput
                                    suffix='%'
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    min={0}
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 21)
                                                .placeholder
                                        }`
                                    )}
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //Integer
        case 22:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    className='w-[100%]'
                                    min='1'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 22)
                                                .placeholder
                                        }`
                                    )}
                                    disabled={isEditable}
                                    value={nonEditableValue}
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    min='1'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 22)
                                                .placeholder
                                        }`
                                    )}
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //Integer Limit
        case 23:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 23)
                                                .placeholder
                                        }`
                                    )}
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? parseInt(
                                                      attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'min'
                                                          ).value
                                                  )
                                                : 0
                                            : 0
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? parseInt(
                                                      attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'max'
                                                          ).value
                                                  )
                                                : 1000
                                            : 1000
                                    }
                                    disabled={isEditable}
                                    className='w-[100%]'
                                    value={nonEditableValue}
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 23)
                                                .placeholder
                                        }`
                                    )}
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? parseInt(
                                                      attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'min'
                                                          ).value
                                                  )
                                                : 0
                                            : 0
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? parseInt(
                                                      attribute_unit_values &&
                                                          attribute_unit_values.length > 0 &&
                                                          attribute_unit_values.find(
                                                              (item) => String(item.key) === 'max'
                                                          ).value
                                                  )
                                                : 1000
                                            : 1000
                                    }
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //Float limit
        case 24:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    className='w-[100%]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 24)
                                                .placeholder
                                        }`
                                    )}
                                    value={nonEditableValue}
                                    step='0.01'
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'min').value
                                                : '12.50'
                                            : '12.50'
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'max').value
                                                : 300.9
                                            : 300.9
                                    }
                                    disabled={isEditable}
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 24)
                                                .placeholder
                                        }`
                                    )}
                                    step='0.01'
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'min').value
                                                : '12.50'
                                            : '12.50'
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'max').value
                                                : 300.9
                                            : 300.9
                                    }
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //Float Round off
        case 25:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    className='w-[100%]'
                                    min='0.01'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 25)
                                                .placeholder
                                        }`
                                    )}
                                    disabled={isEditable}
                                    value={nonEditableValue}
                                    step='0.01'
                                    precision={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'decimal')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'decimal')
                                                      .value
                                                : '3'
                                            : '3'
                                    }
                                    stringMode
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 25)
                                                .placeholder
                                        }`
                                    )}
                                    min='0.01'
                                    step='0.01'
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //FloatRoundOffLimit
        case 26:
            return (
                <div>
                    {String(attributeType).toUpperCase() === 'CALCULATIVE' ? (
                        <>
                            {expression !== 'null' && expression !== undefined && expression !== ''
                                ? expression
                                : 'Expression is not available'}
                        </>
                    ) : (
                        <>
                            {sensitive === false ? (
                                <InputNumber
                                    disabled={isEditable}
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 26)
                                                .placeholder
                                        }`
                                    )}
                                    className='w-[100%]'
                                    value={nonEditableValue}
                                    step='0.01'
                                    precision={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'decimal')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'decimal')
                                                      .value
                                                : '3'
                                            : '3'
                                    }
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'min').value
                                                : 12.5
                                            : 12.5
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'max').value
                                                : 300.9
                                            : 300.9
                                    }
                                />
                            ) : (
                                <CustomPasswordInput
                                    disabled={isEditable}
                                    className='w-[100%] pl-[6px]'
                                    placeholder={t(
                                        `common:${
                                            AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 26)
                                                .placeholder
                                        }`
                                    )}
                                    step='0.01'
                                    min={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'min')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'min').value
                                                : 12.5
                                            : 12.5
                                    }
                                    max={
                                        attribute_unit_values && attribute_unit_values.length > 0
                                            ? attribute_unit_values &&
                                              attribute_unit_values.length > 0 &&
                                              attribute_unit_values.find((item) => String(item.key) === 'max')
                                                ? attribute_unit_values &&
                                                  attribute_unit_values.length > 0 &&
                                                  attribute_unit_values.find((item) => String(item.key) === 'max').value
                                                : 300.9
                                            : 300.9
                                    }
                                    value={nonEditableValue}
                                    visibility={passwordVisible}
                                    setVisibility={setPasswordVisible}
                                />
                            )}
                        </>
                    )}
                </div>
            )

        //TextName
        case 27:
            return (
                <div>
                    {sensitive === false ? (
                        <Input
                            disabled={isEditable}
                            className='w-[100%]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).placeholder
                                }`
                            )}
                            prefix={<UserOutlined />}
                            value={nonEditableValue}
                        />
                    ) : (
                        <CustomPasswordInput
                            disabled={isEditable}
                            prefix={<UserOutlined />}
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 27).placeholder
                                }`
                            )}
                            value={nonEditableValue}
                            onChange={(e) => onChangeData(e)}
                            visibility={passwordVisible}
                            setVisibility={setPasswordVisible}
                        />
                    )}
                </div>
            )

        //ListProductImages
        case 28:
            return <p>{t('attributes:list_product_images_not_yet_done')}</p>

        //File audio
        case 29:
            return <p>{t('attributes:file_audio_not_yet_done')}</p>

        //Text
        case 30:
            return (
                <div>
                    {sensitive === false ? (
                        <Input
                            className='w-[100%]'
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).placeholder
                                }`
                            )}
                            disabled={isEditable}
                            value={nonEditableValue}
                        />
                    ) : (
                        <CustomPasswordInput
                            disabled={isEditable}
                            placeholder={t(
                                `common:${
                                    AttributeDataTypes.find((ele) => parseInt(ele.dataTypeId) === 30).placeholder
                                }`
                            )}
                            value={nonEditableValue}
                            onChange={(e) => onChangeData(e)}
                            visibility={passwordVisible}
                            setVisibility={setPasswordVisible}
                        />
                    )}
                </div>
            )
        default:
            return ''
    }
}
