import { Button, Col, Empty, Input, Layout, Radio, Row, Tooltip, Typography } from 'antd'
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Link, useSearchParams, useLocation } from 'react-router-dom'
import { fnMakeAPICall } from '../../services/redux/actions/ActionsAPICall'
/**
 *! Components are imported Here.
 */

import { SearchOutlined } from '@ant-design/icons'
import 'antd/dist/reset.css'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import DmTabAntDesign from '../../components/DmTabAntDesign/DmTabAntDesign'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import DynamicTable from '../tablelayoutcomponent/DynamicTable'
import ApproveModal from './components/ApproveModal'
import MakeItDraftModal from './components/MakeItDraftModal'
import RejectModal from './components/RejectModal'
import useGetApprovals from './hooks/useGetApprovals'
import { fnPageCompAction } from '../../services/redux/actions/ActionsPageComp'

//! ant design initialization
const { Content } = Layout
const { Search } = Input
const { Title, Text, Paragraph } = Typography
const { Group } = Radio

//! Global Variables
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const itemsPerPageFromEnv = process.env.REACT_APP_ITEM_PER_PAGE
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

function ListApprovalsNew() {
    const { t } = useTranslation()
    const [langDirection, setLangDirection] = useState(util.getSelectedLanguageDirection() || 'ltr')
    const search = useLocation().search
    const tab = new URLSearchParams(search).get('tab')
    const tabStatus = new URLSearchParams(search).get('status')
    const dispatch = useDispatch()
    usePageTitle(`${t('common:store_management_portal')} - ${t('common:approvals')}`)
    const mainTabData = [
        {
            key: 0,
            label: <p className=' !mb-0  text-[14px] font-normal'>{t('approvals:templates')}</p>,
            value: 0,
        },
        {
            key: 1,
            label: (
                <p
                    className={`${langDirection === 'RTL' ? '!mx-4' : '!mb-0 text-brandGray1 text-[14px] font-normal '}`}>
                    {t('common:products')}
                </p>
            ),
            value: 1,
        },
    ]
    const optionsForApprovalsToggle = [
        {
            label: <p className='text-[14px] font-normal'>{t('approvals:requested')}</p>,
            value: '0',
        },
        {
            label: <p className='text-[14px] font-normal'>{t('common:approved')}</p>,
            value: '1',
        },
        {
            label: <p className='text-[14px] font-normal'>{t('common:rejected')}</p>,
            value: '2',
        },
    ]
    const [searchParams, setSearchParams] = useSearchParams()
    const [typeChoice, setTypeChoice] = useState()

    const [searchTableValue, setSearchTableValue] = useState('')
    const [apiSearchTableValue, setApiSearchTableValue] = useState('')
    /**
     * The below is the user defined custom hook. for fetching the API data of approvals with various parameters
     */
    const {
        data: approvalAPIData,
        status: approvalAPIStatus,
        refetch: refetchApprovalData,
    } = useGetApprovals({
        typeChoice: searchParams.get('tab') || 'product_template',
        approvalStatus: parseInt(searchParams.get('status')) || 0,
        page: parseInt(searchParams.get('page')) || 1,
        limit: parseInt(searchParams.get('limit')) || itemsPerPageFromEnv,
        searchValue: apiSearchTableValue,
    })
    const [showApprovalModal, setShowApprovalModal] = useState(false)
    const [showRejectModal, setShowRejectModal] = useState(false)
    const [showMakeItDraftModal, setShowMakeItDraftModal] = useState(false)
    const [selectedTabTableContent, setSelectedTabTableContent] = useState([])
    const [revisionId, setRevisionId] = useState()

    const [putApprovalId, setPutApprovalId] = useState()
    const [permissions, setPermissions] = useState(false)

    console.log('approvalAPIStatus', approvalAPIStatus)

    // get permissions from storage
    const permissionValue = util.getPermissionData() || []
    /**
     * To handle the current clicked approval from the table.
     * @param {*} approvals It will have the current approval object.
     * In this function we are setting the approvalId, revisionId, typeChoice for the update status hook.
     * Mainly to update the current status of an approval.
     */
    const setPresentApprovalData = (approvals) => {
        setPutApprovalId(approvals.id)
        setRevisionId(approvals.revision_id)
        setTypeChoice(approvals.type_choice)
    }
    /**
     * To handle the main tab change
     * @param {*} tabId -> It is the tab id for the main tab,
     * Based on which we are updating the URL parameters with product_template or product
     */
    const handleMainTabChange = (tabId) => {
        setSelectedTabTableContent([])

        if (parseInt(tabId) === 0) {
            setSearchParams({
                tab: 'product_template',
                status: 0,
                page: 1,
                limit: itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        } else if (parseInt(tabId) === 1) {
            setSearchParams({
                tab: 'product',
                status: 0,
                page: 1,
                limit: itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        }
    }
    /**
     * To handle the approvalToggle, the radio group.
     * @param {*} here we taking the changed approval toggle, from ant design we are using the Group, which is radio group
     * Based on the radio group change, we are setting all the table data, search value, searchTriggered to the initial state.
     * Then we are setting the current status is the URL with the help of searchParams.
     */
    const onChangeApprovalsToggle = ({ target: { value } }) => {
        setSelectedTabTableContent([])

        setSearchParams({
            tab: searchParams.get('tab'),
            status: value,
            page: parseInt(searchParams.get('page')) ? parseInt(searchParams.get('page')) : 1,
            limit: parseInt(searchParams.get('limit')) ? parseInt(searchParams.get('limit')) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }
    /**
     * The below function is to handle the pageNumber change from the pagination component from antd
     * @param {*} page -> it is to get the current page
     * @param {*} pageSize -> It is to get the current page Limit
     */
    const handlePageNumberChange = (page, pageSize) => {
        window.scrollTo(0, 0)
        setSearchParams({
            tab: searchParams.get('tab'),
            status: searchParams.get('status'),
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : itemsPerPageFromEnv,
            store: MarketplaceAppConfig.getStore('onlyClient'),
        })
    }
    /**
     * The below is the static columns for the product/product-template related approval tab with category column.
     * Because we will get the category when the approval status is '1', which means approved.
     */
    const productApprovalTableColumns = [
        {
            title:
                searchParams.get('tab') === 'product_template'
                    ? `${t('approvals:tempname_and_version')}`
                    : `${t('approvals:productname_and_Version')}`,
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (text, record) => {
                return (
                    <Content key={record.id} className=''>
                        <Content>
                            <Tooltip
                                overlayStyle={{ zIndex: 1 }}
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                title={
                                    String(record.type_choice).toUpperCase() === 'PRODUCT_REVISION'
                                        ? record.product_template_display_name !== ''
                                            ? record.product_template_display_name
                                            : record.product_template_name
                                        : String(record.type_choice).toUpperCase() === 'PRODUCT_PUBLISHING'
                                          ? record.product_display_name !== ''
                                              ? record.product_display_name
                                              : record.product_name
                                          : '-'
                                }>
                                <Text
                                    ellipsis={true}
                                    style={{ maxWidth: 200 }}
                                    className='!text-brandGray1 hover:text-[#4096ff] text-[14px] font-normal'>
                                    {String(record.type_choice).toUpperCase() === 'PRODUCT_REVISION'
                                        ? record.product_template_display_name !== ''
                                            ? record.product_template_display_name
                                            : record.product_template_name
                                        : String(record.type_choice).toUpperCase() === 'PRODUCT_PUBLISHING'
                                          ? record.product_display_name !== ''
                                              ? record.product_display_name
                                              : record.product_name
                                          : '-'}
                                </Text>
                            </Tooltip>
                            <p className=' !text-brandGray1 hover:text-[#4096ff] text-[14px] font-normal'>
                                V{record.revision_number?.toFixed(1)}
                            </p>
                        </Content>
                    </Content>
                )
            },
        },
        {
            title: `${t('approvals:date_and_time')}`,
            dataIndex: 'date_time',
            key: 'date_time',
            width: '21%',
            render: (text, record) => {
                var moment_date_object = moment(record.submitted_date)
                var submittedDate = moment_date_object.format('L')
                var submittedTime = moment_date_object.format('LT')
                return (
                    <>
                        <Text className='text-[14px] font-normal !text-brandGray1'>
                            {submittedDate + ' ' + submittedTime}
                        </Text>
                    </>
                )
            },
        },
        {
            title: `${t('approvals:requested_by')}`,
            dataIndex: 'requested_by',
            key: 'date_time',
            width: '17%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row className='flex flex-col'>
                            <div>
                                <Text
                                    className='text-[14px] font-normal !text-brandGray1 !max-w-[140px]'
                                    ellipsis={{ tooltip: record?.vendor_username }}>
                                    {record.vendor_username ? record?.vendor_username : '-'}
                                </Text>
                            </div>
                            <div>
                                <Text
                                    className='text-[14px] font-semibold !text-brandGray1 !max-w-[140px]'
                                    ellipsis={{ tooltip: record?.submitted_by }}>
                                    {record.submitted_by ? record?.submitted_by : '-'}
                                </Text>
                            </div>
                        </Row>
                    </Content>
                )
            },
        },
        {
            title: `${t('approvals:approved_note')}`,
            dataIndex: 'requested_by',
            key: 'date_time',
            width: '14%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row>
                            <Col>
                                <Tooltip
                                    overlayStyle={{ zIndex: 1 }}
                                    placement='right'
                                    title={record.approval_reason ? record.approval_reason : '-'}>
                                    <Text
                                        ellipsis={true}
                                        style={{ maxWidth: 200 }}
                                        className='text-[14px] font-normal !text-brandGray1'>
                                        {record.approval_reason ? record.approval_reason : '-'}
                                    </Text>
                                </Tooltip>
                            </Col>
                        </Row>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:category')}`,
            dataIndex: 'category_name',
            key: 'category_name',
            width: '18%',
            render: (text, record) => {
                return (
                    <Content>
                        {record.category_id && record.category_id.length === 0 ? (
                            <p className='font-bold'>-</p>
                        ) : (
                            <>
                                {record.category_id &&
                                    record.category_id.length > 0 &&
                                    record.category_id.map((element, index) => (
                                        <Tooltip
                                            overlayStyle={{ zIndex: 1 }}
                                            placement='right'
                                            title={element && element.category_name}>
                                            <Text
                                                ellipsis={true}
                                                style={{ maxWidth: 100 }}
                                                className='!text-[14px] font-normal !text-brandGray1'>
                                                {String(element.category_name) +
                                                    String(
                                                        parseInt(record.category_id.length) - 1 === parseInt(index)
                                                            ? ''
                                                            : ','
                                                    )}
                                            </Text>
                                        </Tooltip>
                                    ))}
                            </>
                        )}
                    </Content>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row>
                            <Col>
                                {record.type_choice === 'product_publishing' &&
                                permissionValue &&
                                permissionValue.length > 0 &&
                                permissionValue.includes('UI-create-edit-product-template') ? (
                                    <>
                                        <Link
                                            to={{
                                                pathname: '/dashboard/products/edit-product',
                                                search: `ptsid=${record.product_template_id}&ptsrid=${
                                                    record.product_template_revision_id
                                                }&vrid=${record.product_id}&vrsts=${
                                                    record.revision_status
                                                }&vrno=${record.revision_number}&m=${1}&a=${0}&ptrid=${
                                                    record.store_product_type_revision_id
                                                }&ptid=${record?.store_product_type_id}&apprid=${record && record.id}&apprid=${
                                                    record && record.id
                                                }&apSts=${searchParams.get('status')}&apPage=${searchParams.get(
                                                    'page'
                                                )}&apLimit=${searchParams.get('limit')}`,
                                            }}
                                            className=''>
                                            <Button
                                                type='text'
                                                className='app-btn-text text-[14px] font-medium  !hover:cursor-pointer'>
                                                {t('approvals:review')}
                                            </Button>
                                        </Link>
                                    </>
                                ) : permissionValue &&
                                  permissionValue.length > 0 &&
                                  permissionValue.includes('UI-create-edit-product-template') ? (
                                    <Link
                                        to={{
                                            pathname: '/dashboard/product-template/edit-product-template',
                                            search: `?ptsid=${record.product_template_id}&ptsrid=${
                                                record.revision_id
                                            }&ptss=${record.revision_status}&apprid=${record && record.id}`,
                                        }}
                                        className=''>
                                        <Button
                                            type='text'
                                            className='app-btn-text text-[14px] font-medium  !hover:cursor-pointer'>
                                            {t('approvals:review')}
                                        </Button>
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col>
                                {record.approval_status === '0' &&
                                permissionValue &&
                                permissionValue.length > 0 &&
                                permissionValue.includes('UI-edit-approvals') ? (
                                    <Content>
                                        <Row>
                                            <Button
                                                type='text'
                                                className='!px-1 !m-0 !text-brandGray1 hover:text-[#4096ff] text-[14px] font-normal !hover:cursor-pointer  !hover:bg-none'
                                                onClick={() => {
                                                    setShowApprovalModal(true)
                                                    setPresentApprovalData(record)
                                                }}>
                                                {t('common:approve')}
                                            </Button>
                                            <Button
                                                type='text'
                                                className='!px-1 !m-0 text-[14px] !text-[##FF4D4F] hover:text-[#ff4d4f] font-medium !hover:cursor-pointer !hover:bg-none'
                                                onClick={() => {
                                                    setShowRejectModal(true)
                                                    setPresentApprovalData(record)
                                                }}>
                                                {t('common:reject')}
                                            </Button>
                                        </Row>
                                    </Content>
                                ) : record.approval_status === '2' ? (
                                    <Content>
                                        <Row>
                                            <Col>
                                                <Button
                                                    type='text'
                                                    className='!p-0 !m-0 text-[14px] !text-[#f5222d] hover:text-[#ff4d4f] font-medium !hover:cursor-pointer !hover:bg-none'
                                                    onClick={() => {
                                                        setShowMakeItDraftModal(true)
                                                        setPresentApprovalData(record)
                                                    }}></Button>
                                            </Col>
                                        </Row>
                                    </Content>
                                ) : null}
                            </Col>
                        </Row>
                    </Content>
                )
            },
        },
    ]
    /**
     * The below is the static columns for the product/product-template related approval tab without category column.
     * Because the categories will be not stable when the approval status is not '1'.
     */
    const productApprovalTableColumnsWithoutCategoryColumn = [
        {
            title:
                searchParams.get('tab') === 'product_template'
                    ? `${t('approvals:tempname_and_version')}`
                    : `${t('approvals:productname_and_Version')}`,
            dataIndex: 'name',
            key: 'name',
            width: '25%',
            render: (text, record) => {
                return (
                    <Content key={record.id} className=''>
                        <Content>
                            <Tooltip
                                overlayStyle={{ zIndex: 1 }}
                                placement={`${langDirection === 'rtl' ? 'left' : 'right'}`}
                                title={
                                    String(record.type_choice).toUpperCase() === 'PRODUCT_REVISION'
                                        ? record.product_template_display_name !== ''
                                            ? record.product_template_display_name
                                            : record.product_template_name
                                        : String(record.type_choice).toUpperCase() === 'PRODUCT_PUBLISHING'
                                          ? record.product_display_name !== ''
                                              ? record.product_display_name
                                              : record.product_name
                                          : '-'
                                }>
                                <Text
                                    ellipsis={true}
                                    style={{ maxWidth: 200 }}
                                    className='!text-brandGray1 hover:text-[#4096ff] text-[14px] font-normal'>
                                    {String(record.type_choice).toUpperCase() === 'PRODUCT_REVISION'
                                        ? record.product_template_display_name !== ''
                                            ? record.product_template_display_name
                                            : record.product_template_name
                                        : String(record.type_choice).toUpperCase() === 'PRODUCT_PUBLISHING'
                                          ? record.product_display_name !== ''
                                              ? record.product_display_name
                                              : record.product_name
                                          : '-'}
                                </Text>
                            </Tooltip>
                            <p className=' !text-brandGray1 hover:text-[#4096ff] text-[14px] font-normal'>
                                V{record.revision_number?.toFixed(1)}
                            </p>
                        </Content>
                    </Content>
                )
            },
        },
        {
            title: `${t('approvals:date_and_time')}`,
            dataIndex: 'date_time',
            key: 'date_time',
            width: '20%',
            render: (text, record) => {
                var moment_date_object = moment(record.submitted_date)
                var submittedDate = moment_date_object.format('L')
                var submittedTime = moment_date_object.format('LT')
                return (
                    <>
                        <Text className='text-[14px] font-normal !text-brandGray1'>
                            {submittedDate + ' ' + submittedTime}
                        </Text>
                    </>
                )
            },
        },
        {
            title: `${t('approvals:requested_by')}`,
            dataIndex: 'requested_by',
            key: 'date_time',
            width: '14%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row>
                            <div>
                                <Text
                                    className='text-[14px] font-normal !text-brandGray1 !max-w-[140px]'
                                    ellipsis={{ tooltip: record?.vendor_username }}>
                                    {record.vendor_username ? record?.vendor_username : '-'}
                                </Text>
                            </div>
                        </Row>
                        <Row>
                            <div>
                                <Text
                                    className='text-[14px] font-semibold !text-brandGray1 !max-w-[140px]'
                                    ellipsis={{ tooltip: record?.submitted_by }}>
                                    {record.submitted_by ? record?.submitted_by : '-'}
                                </Text>
                            </div>
                        </Row>
                    </Content>
                )
            },
        },
        {
            title:
                parseInt(searchParams.get('status')) === 0
                    ? `${t('approvals:requested_note')}`
                    : `${t('approvals:rejected_note')}`,
            dataIndex: 'requested_by',
            key: 'date_time',
            width: '14%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row>
                            <Col>
                                {parseInt(searchParams.get('status')) === 0 ? (
                                    <Tooltip
                                        overlayStyle={{ zIndex: 1 }}
                                        placement='right'
                                        title={record.submission_reason ? record.submission_reason : '-'}>
                                        <Text
                                            ellipsis={true}
                                            style={{ maxWidth: 200 }}
                                            className='text-[14px] font-normal !text-brandGray1'>
                                            {record.submission_reason ? record.submission_reason : '-'}
                                        </Text>
                                    </Tooltip>
                                ) : null}
                                {parseInt(searchParams.get('status')) === 2 ? (
                                    <Tooltip
                                        overlayStyle={{ zIndex: 1 }}
                                        placement='right'
                                        title={record.approval_reason ? record.approval_reason : '-'}>
                                        <Text ellipsis={true} style={{ maxWidth: 200 }} className='text-[14px]'>
                                            {record.approval_reason ? record.approval_reason : '-'}
                                        </Text>
                                    </Tooltip>
                                ) : null}
                            </Col>
                        </Row>
                    </Content>
                )
            },
        },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            width: '20%',
            render: (text, record) => {
                return (
                    <Content>
                        <Row>
                            <Col>
                                {record.type_choice === 'product_publishing' &&
                                permissionValue &&
                                permissionValue.length > 0 &&
                                permissionValue.includes('UI-create-edit-product-template') ? (
                                    <>
                                        <Link
                                            to={{
                                                pathname: '/dashboard/products/edit-product',
                                                search: `ptsid=${record.product_template_id}&ptsrid=${
                                                    record.product_template_revision_id
                                                }&vrid=${record.product_id}&vaid=${record.variant_id}&vrsts=${
                                                    record.revision_status
                                                }&vrno=${record.revision_number}&m=${1}&a=${
                                                    parseInt(searchParams.get('status')) === 0 ? 1 : 0
                                                }&ptrid=${record.store_product_type_revision_id}&ptid=${record?.store_product_type_id}&apprid=${
                                                    record && record.id
                                                }&apSts=${searchParams.get('status')}&apPage=${searchParams.get(
                                                    'page'
                                                )}&apLimit=${searchParams.get('limit')}`,
                                            }}
                                            className=''>
                                            <Button
                                                type='text'
                                                className='app-btn-text !p-[4px] text-[14px] font-medium  !hover:cursor-pointer'>
                                                {t('approvals:review')}
                                            </Button>
                                        </Link>
                                    </>
                                ) : permissionValue &&
                                  permissionValue.length > 0 &&
                                  permissionValue.includes('UI-create-edit-product-template') ? (
                                    <Link
                                        to={{
                                            pathname: '/dashboard/product-template/edit-product-template',
                                            search: `?ptsid=${record.product_template_id}&ptsrid=${
                                                record.revision_id
                                            }&ptss=${record.revision_status}&apprid=${record && record.id}&${MarketplaceAppConfig.getStore(
                                                ''
                                            )}`,
                                        }}
                                        className=''>
                                        <Button
                                            type='text'
                                            className='app-btn-text !p-[4px] text-[14px] font-medium  !hover:cursor-pointer'
                                            // onClick={() =>
                                            //     dispatch(fnPageCompAction({ type: 'product', action: 'edit' }))
                                            // }
                                        >
                                            {t('approvals:review')}
                                        </Button>
                                    </Link>
                                ) : (
                                    ''
                                )}
                            </Col>
                            <Col>
                                {record.approval_status === '0' &&
                                permissionValue &&
                                permissionValue.length > 0 &&
                                permissionValue.includes('UI-edit-approvals') ? (
                                    <Content>
                                        <Row className='flex gap-1 '>
                                            <Button
                                                type='text'
                                                className='app-btn-text text-[14px] font-medium !hover:cursor-pointer  !hover:bg-none'
                                                onClick={() => {
                                                    setShowApprovalModal(true)
                                                    setPresentApprovalData(record)
                                                }}>
                                                {t('common:approve')}
                                            </Button>

                                            <Button
                                                className='text-[14px] !p-[4px] app-btn-reject font-medium !hover:cursor-pointer '
                                                type='text'
                                                onClick={() => {
                                                    setShowRejectModal(true)
                                                    setPresentApprovalData(record)
                                                }}>
                                                {t('common:reject')}
                                            </Button>
                                        </Row>
                                    </Content>
                                ) : null}
                            </Col>
                        </Row>
                    </Content>
                )
            },
        },
    ]
    /**
     * The function is to handle the searchBar functionality, to capture the searchIcon click.
     * The function is from the ant design and we are using the functionality for the search related.
     * @param {*} searchValue Gives the searchValue from the search input box.
     * In the function we have conditional statement to check whether the search value is empty or not.
     * If the search value is not empty it will be set for the searchValueForAPI, which is a dependency for the useGetApprovalsHook, which will call the API.
     * If the search value is empty and already the API is triggered with search, then we are calling the API with empty, which means without any search parameter for the API.Because the user modified the search input and made it empty, to handle that case we have been using the isSearchTriggered state variable.
     */
    const handleSearchChange = (searchValue) => {
        if (searchValue?.trim()) {
            setSearchTableValue(searchValue)
            setApiSearchTableValue(searchValue)
        }
    }
    /**
     *
     * @param {*} event To capture the current search value entered in the search input box.
     * Here we are trimming the event value, so that it will not have any trailing spaces before and after.\
     * Then we are using the trimmed value, in that string we are replacing multiple spaces with one space in the value.
     */
    const handleInputChange = (event) => {
        let searchText = event.target.value
        if (searchText === '') {
            // setDoSearchAPICall(!doSerachApiCall)

            setApiSearchTableValue('')
        }
        let trimmedSearchText = searchText.replace(/\s+/g, ' ')
        console.log(
            'searchText',
            searchText,
            'searchTableValue',
            searchTableValue,
            'trimmedSearchText',
            trimmedSearchText
        )
        setSearchTableValue(trimmedSearchText)
    }
    /**
     * The below useEffect is to set the table data based on the API data.
     */
    useEffect(() => {
        if (approvalAPIData?.approval && approvalAPIData?.approval.length > 0) {
            setSelectedTabTableContent(approvalAPIData?.approval)
        }
    }, [approvalAPIData])
    /**
     * The below useEffect which runs on the first load.
     * It will scroll to the top of the browser.
     * It will set the store name in the URL, if there is no store using a util function.
     * It will compare whether the logged in user has access or not.
     *  If the user has access then it will set the required search Parameters.
     */
    useEffect(() => {
        window.scrollTo(0, 0)
        util.setStoreNameWhenWeChangeINUrl()
        dispatch(fnMakeAPICall({ makeAPICall: false }))
        if (permissionValue && permissionValue.length > 0 && permissionValue.includes('UI-list-approvals')) {
            setPermissions(true)
            setSearchParams({
                tab: searchParams.get('tab') || 'product_template',
                status: searchParams.get('status') || 0,
                page: parseInt(searchParams.get('page')) || 1,
                limit: parseInt(searchParams.get('limit')) || itemsPerPageFromEnv,
                store: MarketplaceAppConfig.getStore('onlyClient'),
            })
        } else {
            setPermissions(false)
        }
    }, [])

    useEffect(() => {
        return () => {
            setSearchTableValue('')
            setApiSearchTableValue('')
        }
    }, [tab, tabStatus])
    /**
     * The below is the required configuration for the ant design table.
     * It will set the table header/ table columns based on the status, to show with category column or not.
     */
    const tablePropsData = {
        table_header:
            parseInt(searchParams.get('status')) === 1
                ? productApprovalTableColumns
                : productApprovalTableColumnsWithoutCategoryColumn,
        table_content: selectedTabTableContent,
        search_settings: {
            is_enabled: false,
            search_title: 'Search by name',
            search_data: ['product_name'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: '',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }
    /**
     * The below is the customButtonComponent for disabling the searchBar related button when the search value is empty.
     */
    const customButton = <Button type='primary' disabled={!searchTableValue.trim()} icon={<SearchOutlined />} />
    return (
        <Content>
            <Content>
                <Content>
                    <HeaderForTitle
                        title={
                            <Title level={3} className='!font-semibold !text-regal-blue !text-[24px] mt-1'>
                                {t('common:approvals')}
                            </Title>
                        }
                        headerContent={
                            <Content>
                                <Content className=''>
                                    <Paragraph className='!font-normal !text-brandGray1 text-[14px] pt-1'>
                                        {t('approvals:note')}
                                    </Paragraph>
                                </Content>
                                <Content>
                                    <Content className='!h-[30px] !mt-2'>
                                        <DmTabAntDesign
                                            tabData={mainTabData}
                                            tabBarPosition={'top'}
                                            defaultSelectedTabKey={'0'}
                                            activeKey={
                                                searchParams.get('tab') === 'product_template'
                                                    ? 0
                                                    : searchParams.get('tab') === 'product'
                                                      ? 1
                                                      : 0
                                            }
                                            handleTabChangeFunction={handleMainTabChange}
                                            tabType='line'
                                        />
                                    </Content>
                                </Content>
                            </Content>
                        }
                    />
                </Content>
            </Content>
            <Content className='p-3 !min-h-screen'>
                {approvalAPIStatus === 'pending' ? (
                    <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2 bg-white shadow-brandShadow rounded-lg'>
                        <SkeletonComponent />
                    </Content>
                ) : approvalAPIStatus === 'error' ? (
                    <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2 bg-white shadow-brandShadow rounded-lg'>
                        <h5>{`${t('common:network_error')}`}</h5>
                    </Content>
                ) : permissions ? (
                    <Content className=''>
                        {approvalAPIData?.count === 0 &&
                        approvalAPIData?.approval &&
                        approvalAPIData?.approval?.length === 0 &&
                        approvalAPIStatus === 'error' &&
                        searchTableValue ? (
                            <Content className='text-center font-semibold ml-2 mt-3 bg-white p-3'>
                                <Text>{t('common:not_able_to_find_searched_details')}</Text>
                            </Content>
                        ) : (
                            <Content className='!bg-white !p-3 shadow-brandShadow rounded-lg'>
                                <Content>
                                    <Row>
                                        <Content className='!grid !justify-items-start'>
                                            {searchParams.get('tab') === 'product_template' ? (
                                                <Title level={4} className='!font-semibold !text-[16px]'>
                                                    {t('approvals:templates')}
                                                </Title>
                                            ) : searchParams.get('tab') === 'product' ? (
                                                <Title level={4} className='!font-semibold !text-[16px]'>
                                                    {t('common:products')}
                                                </Title>
                                            ) : null}
                                        </Content>
                                        <Content className='flex justify-end items-center'>
                                            <div className=''>
                                                <Group
                                                    options={optionsForApprovalsToggle}
                                                    defaultValue={0}
                                                    onChange={onChangeApprovalsToggle}
                                                    value={searchParams.get('status')}
                                                    optionType='button'
                                                    className='mr-4'
                                                />
                                            </div>
                                            <Search
                                                placeholder={t('common:please_enter_search_text_here')}
                                                onSearch={handleSearchChange}
                                                onChange={handleInputChange}
                                                value={searchTableValue}
                                                suffix={null}
                                                maxLength={searchMaxLength}
                                                enterButton={customButton}
                                                allowClear
                                                className={`${langDirection === 'RTL' ? 'w-[35%] mr-5' : 'w-[35%]'}`}
                                            />
                                        </Content>
                                    </Row>
                                </Content>
                                <Content className='pt-3'>
                                    {approvalAPIData?.count === 0 &&
                                    approvalAPIData?.approval &&
                                    approvalAPIData?.approval.length === 0 &&
                                    approvalAPIStatus === 'success' ? (
                                        <Content className='text-center mt-2 ml-2 p-5 w-[100%]'>
                                            <Empty description={t('approvals:currently_no_approvals')} />
                                        </Content>
                                    ) : (
                                        <Content>
                                            {selectedTabTableContent &&
                                            selectedTabTableContent.length > 0 &&
                                            approvalAPIStatus === 'success' ? (
                                                <>
                                                    <Content className='!max-w-[100%]'>
                                                        <DynamicTable tableComponentData={tablePropsData} />
                                                    </Content>
                                                    {approvalAPIData?.count > itemsPerPageFromEnv ? (
                                                        <Content className='!grid !justify-items-end'>
                                                            <DmPagination
                                                                currentPage={
                                                                    parseInt(searchParams.get('page'))
                                                                        ? parseInt(searchParams.get('page'))
                                                                        : 1
                                                                }
                                                                totalItemsCount={approvalAPIData?.count}
                                                                defaultPageSize={itemsPerPageFromEnv}
                                                                pageSize={
                                                                    parseInt(searchParams.get('limit'))
                                                                        ? parseInt(searchParams.get('limit'))
                                                                        : itemsPerPageFromEnv
                                                                }
                                                                handlePageNumberChange={handlePageNumberChange}
                                                                showSizeChanger={true}
                                                                showTotal={true}
                                                                showQuickJumper={true}
                                                            />
                                                        </Content>
                                                    ) : null}
                                                </>
                                            ) : null}
                                        </Content>
                                    )}
                                </Content>
                            </Content>
                        )}
                        {showApprovalModal && (
                            <ApproveModal
                                showApproveModal={showApprovalModal}
                                setShowApproveModal={setShowApprovalModal}
                                typeChoice={typeChoice}
                                revisionId={revisionId}
                                putApprovalId={putApprovalId}
                                refetchApprovalData={refetchApprovalData}
                            />
                        )}
                        {showRejectModal && (
                            <RejectModal
                                showRejectModal={showRejectModal}
                                setShowRejectModal={setShowRejectModal}
                                typeChoice={typeChoice}
                                revisionId={revisionId}
                                putApprovalId={putApprovalId}
                                refetchApprovalData={refetchApprovalData}
                            />
                        )}
                        {showMakeItDraftModal && (
                            <MakeItDraftModal
                                showMakeItDraftModal={showMakeItDraftModal}
                                setShowMakeItDraftModal={setShowMakeItDraftModal}
                                typeChoice={typeChoice}
                                revisionId={revisionId}
                                putApprovalId={putApprovalId}
                                refetchApprovalData={refetchApprovalData}
                            />
                        )}
                    </Content>
                ) : (
                    <Content className='p-0 text-center mt-2 ml-2'>
                        <Text>{t('common:access_permission_required')}</Text>
                    </Content>
                )}
            </Content>
        </Content>
    )
}

export default ListApprovalsNew
