import { Button, Col, Progress, Input, Layout, Select, Skeleton, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import validator from 'validator'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnAttributeGroupDetails } from '../../services/redux/actions/ActionAttributesGroup'
import { fnContentSettingInfo } from '../../services/redux/actions/ActionContentSetting'
import { fnLayoutInfo } from '../../services/redux/actions/ActionLayout'
import { fnCategorySelectedInfo } from '../../services/redux/actions/ActionsCategories'
import { fnProductTypeDetails } from '../../services/redux/actions/ActionsProductType'
import { fnProductTemplateSelectedInfo } from '../../services/redux/actions/ActionsProducts'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import { deepCopy } from '../../util/util'

//! Destructure the components
const { Content } = Layout
const { Title } = Typography
const { TextArea } = Input

//! category
const categoryDisplayNameAPI = process.env.REACT_APP_CATEGORY_DISPLAYNAME_API

//! Product Template
const productTemplateDisplayNameAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_DISPLAYNAME_API

//! Product Type
const productTypeLanguageNamesAPI = process.env.REACT_APP_PRODUCT_TYPE_DISPLAY_NAME_API

//! Onboarding Templates
const onboardingTemplateLanguageNameAPI = process.env.REACT_APP_ONBOARDING_TEMPLATE_LANGUAGE_NAME_API
//! Version
const typeRevisionLanguageNamesAPI = process.env.REACT_APP_ONBOARDING_TEMPLATE_REVISION_LANGUAGE_NAMES_API
const versionDescriptionAPI = process.env.REACT_APP_ONBOARDING_TEMPLATE_REVISION_DESCRIPTION_API

// ! Product Attribute Group
const productAttributeGroupDisplayNameAPI = process.env.REACT_APP_PRODUCT_SEGMENT_DISPLAY_NAME_API

// ! store payment type
const storePaymentTypeLanguageNamesAPI = process.env.REACT_APP_STORE_PAYMENT_TYPE_LANGUAGE_NAMES_API

//!Variant Name
const variantDisplayNameAPI = process.env.REACT_APP_VARIANT_LANGUAGE_NAMES_API
// ! input fields properties
const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)
const descriptionMaxLength = parseInt(process.env.REACT_APP_DESCRIPTION_MAX_LENGTH)
const vendorAPI = process.env.REACT_APP_VENDOR_DISPLAY_LANGUAGE_NAMES

//! store language display name API
const storeDisplayLanguageAPI = process.env.REACT_APP_STORE_DISPLAY_LANGUAGE_NAMES

//!layout displayname API
const layoutDisplayLanguageAPI = process.env.REACT_APP_LAYOUT_DISPLAY_LANGUAGE_NAMES

// product template revision display name API
const productTemplateRevisionDisplayNamesAPI = process.env.REACT_APP_PRODUCT_TEMPLATE_REVISION_DISPLAY_NAME_API

const TranslatorModal = ({
    dataJson,
    componentType,
    respectiveId,
    setTranslateModalVisible,
    onboardingType,
    onChangeDisableFields,
    setOnChangeDisableFields,
    //! below 2 props are getting used bcs to reflect the change in listing page
    //! as there is no separate edit page so we are not using redux
    paymentTypeListData,
    setPaymentTypeListData,
    setSelectedVariantDisplayNameData,
    setVersionDescriptionData,
    vendorData,
    setVendorData,
    loadingSkelton,
    setLoadingSkelton,
    setIsMultilingualCallSuccess,
}) => {
    const dispatch = useDispatch()
    const { t } = useTranslation()

    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)
    //! storeSettingsData
    const storeSettingsInfo = useSelector((state) => state.reducerContentSetting.contentSettingInfo)
    const userSelectedLanguageCode = util.getUserSelectedLngCode()
    //! categoryDataRedux
    const categorySelectedInfo = useSelector((state) => state.reducerCategories.categorySelectedInfo)
    //! attributeGroupDataRedux
    let defaultObjectForAttributeGroup = useSelector(
        (state) =>
            state.reducerAttributeGroupDetails &&
            state.reducerAttributeGroupDetails.attribuetGroupDetails &&
            state.reducerAttributeGroupDetails.attribuetGroupDetails
    )
    //! productTypeDataRedux
    let defaultObjectForProductType = useSelector(
        (state) =>
            state.reducerProductTypeDetails &&
            state.reducerProductTypeDetails.productTypeDetails &&
            state.reducerProductTypeDetails.productTypeDetails
    )

    const productTemplateSelectedInfo = useSelector((state) => state.reducerProducts.productTemplateSelectedInfo)
    const productLayoutData = useSelector((state) => state.reducerLayoutInfo.layoutInfo)
    const [displayNameDataJson, setDisplayNameDataJson] = useState(dataJson)
    const [storeLanguages, setStoreLanguages] = useState()
    const [defaultLanguageName, setDefaultLanguageName] = useState()
    const [allLanguages, setAllLanguages] = useState()
    const [isLoadingStoreLanguage, setIsLoadingStoreLanguage] = useState(true)
    const [selectedLanguageFromDropDown, setSelectedLanguageFromDropDown] = useState()
    const [allDisplayNameData, setAllDisplayNameData] = useState()
    const [commonDataContainingAllObject, setCommonDataContainingAllObject] = useState()
    let [putCallStatus, setPutCallStatus] = useState(1)
    const [languagesWithInvalidData, setLanguagesWithInvalidData] = useState()
    const [isInputFieldsValidationModalOpen, setIsInputFieldsValidationModalOpen] = useState(false)
    const [mainTille, setMainTille] = useState()
    const [title, setTitle] = useState()
    const [label, setLabel] = useState()
    const [invalidName, setInvalidName] = useState(false)
    const [invalidDescription, setInvalidDescription] = useState(false)
    const [objectForDetailsUpdate, setObjectForDetailsUpdate] = useState()
    const [displayNameApiData, setDisplayNameApiData] = useState([])
    const [langDirection, setLangDirection] = useState('ltr')
    const [alreadyTranslatedCount, setAlreadyTranslatedCount] = useState(0)

    const getStoreLanguageData = (getDisplayNameApiEndPoint) => {
        const StoreLanguageResponse = storeLanguageFromReduxState

        setStoreLanguages(StoreLanguageResponse)
        const defaultLanguageCode =
            StoreLanguageResponse &&
            StoreLanguageResponse.length > 0 &&
            StoreLanguageResponse.filter((element) => element.is_default)[0].language_code

        findAllDisplayNameData(StoreLanguageResponse, defaultLanguageCode, getDisplayNameApiEndPoint)
        setDefaultLanguageName(
            StoreLanguageResponse &&
                StoreLanguageResponse.length > 0 &&
                StoreLanguageResponse.filter((element) => element.is_default)
        )
        setAllLanguages(StoreLanguageResponse)
        setSelectedLanguageFromDropDown(defaultLanguageCode)
    }

    //! GET call to get  displayNames
    /*  here we are creating common array of objects.
      if storeLanguages are not added in display name then we are adding object in common array with 
      respective ID (with key as product , category, productType etc) otherwise we are adding same object what we are getting from displayName get call 
      with key as id 
  */
    const findAllDisplayNameData = (StoreLanguageResponse, defaultLanguageCode, getDisplayNameApiEndPoint) => {
        let getParams
        switch (componentType) {
            case 'category':
                getParams = { 'category-id': parseInt(respectiveId) }
                break
            case 'producttemplate':
                getParams = { 'product-id': parseInt(respectiveId) }
                break
            case 'producttype':
                getParams = { 'storeproducttype-id': parseInt(respectiveId) }
                break
            case 'onboardingtemplate':
                getParams = { 'onboarding-template-id': parseInt(respectiveId) }
                break
            case 'onboardingVersion':
                getParams = {
                    'onboarding-template-revision-id': parseInt(respectiveId),
                }
                break
            case 'attributegroup':
                getParams = {
                    'product-attribute-group': parseInt(respectiveId),
                }
                break

            case 'layout':
                getParams = {
                    'layout-id': parseInt(respectiveId),
                }
                break
            case 'paymenttype':
                getParams = { 'store-payment-type-id': parseInt(respectiveId) }
                break
            case 'vendor':
                getParams = { 'vendor-id': parseInt(respectiveId) }
                break
            case 'store':
                getParams = null
                break
            case 'variant_name':
                getParams = {
                    'variant-id': parseInt(respectiveId),
                }
                break
            case 'template_version_description':
                getParams = {
                    'product-revision-id': parseInt(respectiveId),
                }
                break
            default:
                return null
        }

        MarketplaceServices.findAll(getDisplayNameApiEndPoint, getParams, false)
            .then(function (response) {
                setIsLoadingStoreLanguage(false)
                console.log('edit selected get displayname response : ', response.data)
                if (response.data) {
                    var tempArray
                    var displayNameData
                    switch (componentType) {
                        case 'category':
                            tempArray = deepCopy(response.data.response_body.category_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.category_displaynames)
                            displayNameData = response.data.response_body.category_displaynames
                            break
                        case 'producttemplate':
                            tempArray = deepCopy(response.data.response_body.product_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.product_displaynames)
                            displayNameData = response.data.response_body.product_displaynames
                            break
                        case 'producttype':
                            tempArray = deepCopy(response.data.response_body.storeproducttype_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.storeproducttype_displaynames)
                            displayNameData = response.data.response_body.storeproducttype_displaynames

                            break
                        case 'onboardingtemplate':
                            tempArray = deepCopy(response.data.onboarding_template_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.onboarding_template_displaynames)
                            displayNameData = response.data.onboarding_template_displaynames
                            break
                        case 'onboardingVersion':
                            tempArray = deepCopy(response.data.onboarding_template_revision_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.onboarding_template_revision_displaynames)
                            displayNameData = response.data.onboarding_template_revision_displaynames
                            break
                        case 'attributegroup':
                            console.log(
                                'attributegroup get response#',
                                response.data.response_body.data.product_attribute_group_displaynames
                            )
                            tempArray = deepCopy(response.data.response_body.data.product_attribute_group_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.data.product_attribute_group_displaynames)
                            displayNameData = response.data.response_body.data.product_attribute_group_displaynames
                            break
                        case 'layout':
                            tempArray = deepCopy(response.data.response_body.layout_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.layout_displaynames)
                            displayNameData = response.data.response_body.layout_displaynames
                            break
                        case 'paymenttype':
                            tempArray = deepCopy(response.data.response_body.store_payment_type_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.store_payment_type_displaynames)
                            displayNameData = response.data.response_body.store_payment_type_displaynames
                            break
                        case 'vendor':
                            tempArray = deepCopy(response.data.response_body.vendor_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.vendor_displaynames)
                            displayNameData = response.data.response_body.vendor_displaynames
                            break
                        case 'store':
                            tempArray = deepCopy(response.data.response_body.store_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.store_displaynames)
                            displayNameData = response.data.response_body.store_displaynames
                            break
                        case 'variant_name':
                            tempArray = deepCopy(response.data.response_body.variant_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.variant_displaynames)
                            displayNameData = response.data.response_body.variant_displaynames
                            break
                        case 'template_version_description':
                            tempArray = deepCopy(response.data.response_body.product_displaynames)
                            setDisplayNameApiData(tempArray)
                            setAllDisplayNameData(response.data.response_body.product_displaynames)
                            displayNameData = response.data.response_body.product_displaynames
                            break

                        default:
                            return null
                    }
                    var finalObjectWithAllData = []
                    setCommonDataContainingAllObject(displayNameData)
                    for (let i = 0; i < StoreLanguageResponse.length; i++) {
                        let storeLanguageDataIsAvailableInDisplayNameData =
                            displayNameData &&
                            displayNameData.length > 0 &&
                            displayNameData.filter(
                                (ele) => ele.language_code === StoreLanguageResponse[i].language_code
                            )

                        if (
                            storeLanguageDataIsAvailableInDisplayNameData &&
                            storeLanguageDataIsAvailableInDisplayNameData.length > 0
                        ) {
                        } else {
                            switch (componentType) {
                                case 'category':
                                    finalObjectWithAllData.push({
                                        category: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })
                                    break
                                case 'producttemplate':
                                    finalObjectWithAllData.push({
                                        product: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })
                                    break
                                case 'producttype':
                                    finalObjectWithAllData.push({
                                        storeproducttype: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })

                                    break
                                case 'onboardingtemplate':
                                    finalObjectWithAllData.push({
                                        onboarding_template: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })

                                    break
                                case 'onboardingVersion':
                                    finalObjectWithAllData.push({
                                        onboarding_template_revision: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        description: '',
                                    })
                                    break

                                case 'attributegroup':
                                    console.log('insideThis')
                                    finalObjectWithAllData.push({
                                        product_attribute_group: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })
                                    break
                                case 'layout':
                                    finalObjectWithAllData.push({
                                        layout: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })
                                    break
                                case 'paymenttype':
                                    finalObjectWithAllData.push({
                                        store_payment_type: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: 'description',
                                    })
                                    break
                                case 'vendor':
                                    finalObjectWithAllData.push({
                                        vendor: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: 'description',
                                    })
                                    break
                                case 'store':
                                    finalObjectWithAllData.push({
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: 'description',
                                    })
                                    break
                                case 'variant_name':
                                    finalObjectWithAllData.push({
                                        variant: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        display_name: '',
                                        description: '',
                                    })
                                    break
                                case 'template_version_description':
                                    finalObjectWithAllData.push({
                                        product_revision: parseInt(respectiveId),
                                        language_code:
                                            StoreLanguageResponse[i] && StoreLanguageResponse[i].language_code,
                                        description: '',
                                    })
                                    break
                                default:
                                    return null
                            }
                        }
                    }

                    setCommonDataContainingAllObject([...displayNameData, ...finalObjectWithAllData])
                }
                setLoadingSkelton(false)
                setAlreadyTranslatedCount(response?.data?.response_body?.count)
            })
            .catch(function (error) {
                console.log('edit selected get displayname error response', error.response)
                setLoadingSkelton(false)
            })
        setIsLoadingStoreLanguage(false)
    }

    //! doing post call for category display name
    const saveMultilingualDetails = (
        postDisplayNameApiEndPoint,
        postBody,
        putCallWillHappen,
        callWithDefaultDetails,
        putDisplayNameApiEndPoint,
        changedDataForPutCall,
        finalDataForPutCall
    ) => {
        var finalPostBody
        let defaultLangCode =
            defaultLanguageName && defaultLanguageName.length > 0 && defaultLanguageName[0].language_code
        var updatedPostDataBasedOnDefaultLangOrderAtFirstIndex =
            postBody && postBody.length > 0 && postBody.filter((ele) => ele.language_code !== defaultLangCode)
        let checkDefaultLangPresent =
            postBody && postBody.length > 0 && postBody.filter((ele) => ele.language_code === defaultLangCode)
        if (checkDefaultLangPresent && checkDefaultLangPresent.length > 0) {
            updatedPostDataBasedOnDefaultLangOrderAtFirstIndex = [
                ...checkDefaultLangPresent,
                ...updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
            ]
        }
        switch (componentType) {
            case 'category':
                finalPostBody = {
                    category_displayname: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'producttemplate':
                finalPostBody = {
                    product_displaynames: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'producttype':
                finalPostBody = {
                    storeproducttype_displaynames: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'onboardingtemplate':
                finalPostBody = {
                    onboarding_template_name: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'onboardingVersion':
                finalPostBody = {
                    onboarding_template_revision_languages: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'attributegroup':
                finalPostBody = {
                    product_attribute_group_names: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'layout':
                finalPostBody = {
                    layout_displayname: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'paymenttype':
                finalPostBody = {
                    store_payment_type_displaynames: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'vendor':
                finalPostBody = {
                    vendor_displayname: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'store':
                finalPostBody = {
                    store_displaynames: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            case 'variant_name':
                finalPostBody = {
                    variant_displayname: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break

            case 'template_version_description':
                finalPostBody = {
                    product_revision_displaynames: updatedPostDataBasedOnDefaultLangOrderAtFirstIndex,
                }
                break
            default:
                return null
        }
        MarketplaceServices.save(postDisplayNameApiEndPoint, finalPostBody, null)
            .then((response) => {
                switch (componentType) {
                    case 'category':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let reduxDataCopy = deepCopy(categorySelectedInfo)
                                reduxDataCopy[1].name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                reduxDataCopy[1].description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnCategorySelectedInfo(reduxDataCopy))
                            }
                        }
                        break
                    case 'producttemplate':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let reduxDataCopy = deepCopy(productTemplateSelectedInfo)
                                reduxDataCopy[1].name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                reduxDataCopy[1].description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnProductTemplateSelectedInfo(reduxDataCopy))
                            }
                        }
                        break
                    case 'producttype':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                dispatch(
                                    fnProductTypeDetails([
                                        {
                                            productTypeName:
                                                defaultObjectForProductType &&
                                                defaultObjectForProductType.length > 0 &&
                                                defaultObjectForProductType[0].productTypeName,
                                            productTypeDescription:
                                                defaultObjectForProductType &&
                                                defaultObjectForProductType.length > 0 &&
                                                defaultObjectForProductType[0].productTypeDescription,
                                        },
                                        {
                                            productTypeName:
                                                selectedLangDisplayAdded[0].display_name &&
                                                selectedLangDisplayAdded[0].display_name.trim(),
                                            productTypeDescription:
                                                selectedLangDisplayAdded[0].description &&
                                                selectedLangDisplayAdded[0].description.trim(),
                                        },
                                    ])
                                )
                            }
                        }
                        break
                    case 'onboardingtemplate':
                        break
                    case 'onboardingVersion':
                        break
                    case 'attributegroup':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data.response_body[0].product_attribute_group_names &&
                                response.data.response_body[0].product_attribute_group_names.length > 0 &&
                                response.data.response_body[0].product_attribute_group_names.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                dispatch(
                                    fnAttributeGroupDetails([
                                        {
                                            groupName:
                                                defaultObjectForAttributeGroup &&
                                                defaultObjectForAttributeGroup.length > 0 &&
                                                defaultObjectForAttributeGroup[0].groupName,
                                            groupDescription:
                                                defaultObjectForAttributeGroup &&
                                                defaultObjectForAttributeGroup.length > 0 &&
                                                defaultObjectForAttributeGroup[0].groupDescription,
                                        },
                                        {
                                            groupName:
                                                selectedLangDisplayAdded[0].display_name &&
                                                selectedLangDisplayAdded[0].display_name.trim(),
                                            groupDescription:
                                                selectedLangDisplayAdded[0].description &&
                                                selectedLangDisplayAdded[0].description.trim(),
                                        },
                                    ])
                                )
                            }
                        }
                        break
                    case 'paymenttype':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let copyOfPaymentTypeAPIData = [...paymentTypeListData]
                                copyOfPaymentTypeAPIData.forEach((obj) => {
                                    if (
                                        parseInt(obj.id) === parseInt(selectedLangDisplayAdded[0]?.store_payment_type)
                                    ) {
                                        obj.display_name =
                                            selectedLangDisplayAdded[0].display_name &&
                                            selectedLangDisplayAdded[0].display_name.trim()
                                    }
                                    setPaymentTypeListData(copyOfPaymentTypeAPIData)
                                })
                            }
                        }
                        break
                    case 'vendor':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                var copyOfVendorAPIData = deepCopy(vendorData)
                                copyOfVendorAPIData[0].display_name = selectedLangDisplayAdded[0].display_name
                                setVendorData(copyOfVendorAPIData)
                            }
                        }
                        break
                    case 'layout':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let layoutDataCopy = deepCopy(productLayoutData)
                                layoutDataCopy.layout_display_name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                layoutDataCopy.layout_display_description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnLayoutInfo(layoutDataCopy))
                            }
                        }
                        break
                    case 'store':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let copyStoreSettingsDataData = deepCopy(storeSettingsInfo)
                                copyStoreSettingsDataData[0].store_display_name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                dispatch(fnContentSettingInfo(copyStoreSettingsDataData))
                            }
                        }
                        break
                    case 'variant_name':
                        if (response.data && response.data.response_body.length > 0) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded##', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                setSelectedVariantDisplayNameData(selectedLangDisplayAdded[0]?.display_name)
                            }
                        }
                        break

                    case 'template_version_description':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body &&
                                response.data.response_body?.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                setVersionDescriptionData(selectedLangDisplayAdded[0]?.description)
                            }
                            setIsMultilingualCallSuccess(true)
                        }

                        break
                    default:
                }
                if (putCallWillHappen) {
                    if (callWithDefaultDetails) {
                        if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                            updateMultilingualDetails(putDisplayNameApiEndPoint, finalDataForPutCall, 200)
                            setPutCallStatus(2)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                        updateMultilingualDetails(putDisplayNameApiEndPoint, changedDataForPutCall, 200)
                        setPutCallStatus(2)
                        setIsLoadingStoreLanguage(true)
                    }
                } else {
                    MarketplaceToaster.showToast(response)
                    setIsLoadingStoreLanguage(false)
                    setTranslateModalVisible(false)
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log('error in DisplayName Post call', error.response.data.message)
                    if (putCallWillHappen) {
                        if (callWithDefaultDetails) {
                            if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                                updateMultilingualDetails(putDisplayNameApiEndPoint, finalDataForPutCall, 400)
                                setPutCallStatus(2)
                                setIsLoadingStoreLanguage(true)
                            }
                        } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                            updateMultilingualDetails(putDisplayNameApiEndPoint, changedDataForPutCall, 400)
                            setPutCallStatus(2)
                            setIsLoadingStoreLanguage(true)
                        }
                    } else {
                        MarketplaceToaster.showToast(error.response)
                        setIsLoadingStoreLanguage(false)
                    }
                }
            })
    }
    //! version Description Update Put Call
    const updateVersionDescription = () => {
        let dataObject = {
            description:
                objectForDetailsUpdate &&
                objectForDetailsUpdate.description &&
                objectForDetailsUpdate.description.trim(),
        }
        MarketplaceServices.update(versionDescriptionAPI, dataObject, {
            onboarding_template_revision: parseInt(respectiveId),
        })
            .then(function (response) {
                console.log('response of boarding version description  edit call--->', response.data)
                if (response && response.data) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(
                            `${t('vendors:Onboarding-version-description-updated-successfully')}`,
                            'success'
                        )
                    )
                }
                setTranslateModalVisible(false)
            })
            .catch((error) => {
                console.log('error-->', error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //! doing put call for  display name
    const updateMultilingualDetails = (putDisplayNameApiEndPoint, changedDataForPutCall, postApiStatus) => {
        let finalPutBody
        console.log('changedDataaaa##', changedDataForPutCall)
        switch (componentType) {
            case 'category':
                finalPutBody = { category_displayname: changedDataForPutCall }
                break
            case 'producttemplate':
                finalPutBody = { product_displaynames: changedDataForPutCall }
                break
            case 'producttype':
                finalPutBody = { display_name: changedDataForPutCall }
                break
            case 'onboardingtemplate':
                finalPutBody = { onboarding_template_name: changedDataForPutCall }
                break
            case 'onboardingVersion':
                finalPutBody = {
                    onboarding_template_revision_languages: changedDataForPutCall,
                }
                break

            case 'attributegroup':
                const updatedPutCallData =
                    changedDataForPutCall &&
                    changedDataForPutCall.length > 0 &&
                    changedDataForPutCall.map((ele) => ({
                        ...ele,
                        product_attribute_group: parseInt(respectiveId),
                    }))
                finalPutBody = { product_attribute_group_names: updatedPutCallData }
                break
            case 'paymenttype':
                finalPutBody = {
                    store_payment_type_displaynames: changedDataForPutCall,
                }
                break
            case 'layout':
                finalPutBody = {
                    layout_displayname: changedDataForPutCall,
                }
                break
            case 'vendor':
                finalPutBody = {
                    vendor_displayname: changedDataForPutCall,
                }
                break
            case 'store':
                finalPutBody = {
                    store_displaynames: changedDataForPutCall,
                }
                break
            case 'variant_name':
                finalPutBody = { variant_displayname: changedDataForPutCall }
                break
            case 'template_version_description':
                finalPutBody = { product_revision_displaynames: changedDataForPutCall }
                break
            default:
                return null
        }

        let putParams
        switch (componentType) {
            case 'category':
                putParams = { category_id: parseInt(respectiveId) }
                break
            case 'producttemplate':
                putParams = { product_id: parseInt(respectiveId) }
                break
            case 'producttype':
                putParams = { storeproducttype_id: parseInt(respectiveId) }
                break
            case 'onboardingtemplate':
                putParams = { 'onboarding-template-id': parseInt(respectiveId) }
                break
            case 'onboardingVersion':
                putParams = {
                    'onboarding-template-revision-id': parseInt(respectiveId),
                }
                break
            case 'attributegroup':
                putParams = { product_attribute_group_id: parseInt(respectiveId) }
                break
            case 'layout':
                putParams = { layout_id: parseInt(respectiveId) }
                break
            case 'paymenttype':
                putParams = {
                    store_payment_type_id: parseInt(respectiveId),
                }
                break
            case 'vendor':
                putParams = {
                    vendor_id: parseInt(respectiveId),
                }
                break
            case 'store':
                putParams = null
                break
            case 'variant_name':
                putParams = { variant_id: parseInt(respectiveId) }
                break
            case 'template_version_description':
                putParams = { product_revision_id: parseInt(respectiveId) }
                break
            default:
                return null
        }
        MarketplaceServices.update(putDisplayNameApiEndPoint, finalPutBody, putParams)
            .then((response) => {
                console.log('put call for updateMultilingualDetails', response.data)
                setPutCallStatus(++putCallStatus)
                switch (componentType) {
                    case 'category':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.category_displaynames &&
                                response.data.response_body.category_displaynames.length > 0 &&
                                response.data.response_body.category_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let reduxDataCopy = deepCopy(categorySelectedInfo)
                                reduxDataCopy[1].name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                reduxDataCopy[1].description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnCategorySelectedInfo(reduxDataCopy))
                            }
                        }
                        break
                    case 'producttemplate':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.product_displaynames &&
                                response.data.response_body.product_displaynames.length > 0 &&
                                response.data.response_body.product_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let reduxDataCopy = deepCopy(productTemplateSelectedInfo)
                                reduxDataCopy[1].name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                reduxDataCopy[1].description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnProductTemplateSelectedInfo(reduxDataCopy))
                            }
                        }
                        break
                    case 'producttype':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data.response_body.display_name &&
                                response.data.response_body.display_name.length > 0 &&
                                response.data.response_body.display_name.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                dispatch(
                                    fnProductTypeDetails([
                                        {
                                            productTypeName:
                                                defaultObjectForProductType &&
                                                defaultObjectForProductType.length > 0 &&
                                                defaultObjectForProductType[0].productTypeName,
                                            productTypeDescription:
                                                defaultObjectForProductType &&
                                                defaultObjectForProductType.length > 0 &&
                                                defaultObjectForProductType[0].productTypeDescription,
                                        },
                                        {
                                            productTypeName:
                                                selectedLangDisplayAdded[0].display_name &&
                                                selectedLangDisplayAdded[0].display_name.trim(),
                                            productTypeDescription:
                                                selectedLangDisplayAdded[0].description &&
                                                selectedLangDisplayAdded[0].description.trim(),
                                        },
                                    ])
                                )
                            }
                        }
                        break
                    case 'onboardingtemplate':
                        break
                    case 'onboardingVersion':
                        break
                    case 'attributegroup':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data.response_body.product_attribute_group_languages &&
                                response.data.response_body.product_attribute_group_languages.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                dispatch(
                                    fnAttributeGroupDetails([
                                        {
                                            groupName:
                                                defaultObjectForAttributeGroup &&
                                                defaultObjectForAttributeGroup.length > 0 &&
                                                defaultObjectForAttributeGroup[0].groupName,
                                            groupDescription:
                                                defaultObjectForAttributeGroup &&
                                                defaultObjectForAttributeGroup.length > 0 &&
                                                defaultObjectForAttributeGroup[0].groupDescription,
                                        },
                                        {
                                            groupName:
                                                selectedLangDisplayAdded[0].display_name &&
                                                selectedLangDisplayAdded[0].display_name.trim(),
                                            groupDescription:
                                                selectedLangDisplayAdded[0].description &&
                                                selectedLangDisplayAdded[0].description.trim(),
                                        },
                                    ])
                                )
                            }
                        }
                        break

                    case 'layout':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.layout_displaynames &&
                                response.data.response_body.layout_displaynames.length > 0 &&
                                response.data.response_body.layout_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let layoutDataCopy = deepCopy(productLayoutData)
                                layoutDataCopy.layout_display_name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                layoutDataCopy.layout_display_description =
                                    selectedLangDisplayAdded[0].description &&
                                    selectedLangDisplayAdded[0].description.trim()
                                dispatch(fnLayoutInfo(layoutDataCopy))
                            }
                        }
                        break
                    case 'paymenttype':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.store_payment_type_displaynames &&
                                response.data.response_body.store_payment_type_displaynames.length > 0 &&
                                response.data.response_body.store_payment_type_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let copyOfPaymentTypeAPIData = [...paymentTypeListData]
                                copyOfPaymentTypeAPIData.forEach((obj) => {
                                    if (
                                        parseInt(obj.id) === parseInt(response.data.response_body.store_payment_type_id)
                                    ) {
                                        obj.display_name =
                                            selectedLangDisplayAdded[0].display_name &&
                                            selectedLangDisplayAdded[0].display_name.trim()
                                    }
                                    setPaymentTypeListData(copyOfPaymentTypeAPIData)
                                })
                            }
                        }
                        break
                    case 'vendor':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.vendor_displaynames &&
                                response.data.response_body.vendor_displaynames.length > 0 &&
                                response.data.response_body.vendor_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                var copyOfVendorAPIData = deepCopy(vendorData)
                                copyOfVendorAPIData[0].display_name = selectedLangDisplayAdded[0].display_name
                                setVendorData(copyOfVendorAPIData)
                            }
                        }
                        break
                    case 'store':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body &&
                                response.data.response_body.length > 0 &&
                                response.data.response_body.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                let copyOfStoreSettingsAPIData = deepCopy(storeSettingsInfo)
                                copyOfStoreSettingsAPIData[0].store_display_name =
                                    selectedLangDisplayAdded[0].display_name &&
                                    selectedLangDisplayAdded[0].display_name.trim()
                                dispatch(fnContentSettingInfo(copyOfStoreSettingsAPIData))
                            }
                        }
                        break
                    case 'variant_name':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.variant_displaynames &&
                                response.data.response_body.variant_displaynames.length > 0 &&
                                response.data.response_body.variant_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )
                            console.log('selectedLangDisplayAdded', selectedLangDisplayAdded)
                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                setSelectedVariantDisplayNameData(selectedLangDisplayAdded[0]?.display_name)
                            }
                        }

                        break
                    case 'template_version_description':
                        if (response.data) {
                            const selectedLangDisplayAdded =
                                response.data &&
                                response.data.response_body.product_revision_displaynames &&
                                response.data.response_body.product_revision_displaynames.length > 0 &&
                                response.data.response_body.product_revision_displaynames.filter(
                                    (val) => val.language_code === userSelectedLanguageCode
                                )

                            if (selectedLangDisplayAdded && selectedLangDisplayAdded.length > 0) {
                                setVersionDescriptionData(selectedLangDisplayAdded[0]?.description)
                            }
                            setIsMultilingualCallSuccess(true)
                        }

                        break
                    default:
                    // return null;
                }
                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(response)
                } else {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                }
                setIsLoadingStoreLanguage(false)
                setTranslateModalVisible(false)
            })
            .catch((error) => {
                console.log('error in Display Put call', error.response.data)

                if (postApiStatus === 200) {
                    MarketplaceToaster.showToast(
                        util.getToastObject(`${t('common:multilingual_details_partially_saved')}`, 'warning')
                    )
                } else {
                    MarketplaceToaster.showToast(error.response)
                }
                setIsLoadingStoreLanguage(false)
            })
    }

    const handleLanguageChange = (val) => {
        setSelectedLanguageFromDropDown(val)
    }
    const validationOfDataForPutCall = (tempArrDataWithDisplayNameId) => {
        var finalPutBody = []
        for (let i = 0; i < displayNameApiData.length; i++) {
            const id = displayNameApiData[i].id
            const localData =
                tempArrDataWithDisplayNameId &&
                tempArrDataWithDisplayNameId.find((element) => parseInt(element.id) === parseInt(id))
            let isChanged = false
            let obj = {}
            obj['id'] = displayNameApiData[i].id
            obj['language_code'] = displayNameApiData[i].language_code
            if (displayNameApiData[i].display_name !== localData.display_name) {
                obj['display_name'] = localData.display_name && localData.display_name.trim()
                obj['description'] = localData.description && localData.description.trim()
                isChanged = true
            }
            if (displayNameApiData[i].description !== localData.description) {
                obj['display_name'] = localData.display_name && localData.display_name.trim()
                obj['description'] = localData.description && localData.description.trim()
                isChanged = true
            }
            if (isChanged) {
                finalPutBody.push(obj)
            }
        }
        return finalPutBody
    }
    const InputValuesValidationFromAllLanguages = (
        commonDataContainingAllObject,
        callWithDefaultDetails,
        finalDataForPostCall,
        finalDataForPutCall
    ) => {
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        const tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)
        var postCallWillHappen =
            ((finalDataForPostCall && finalDataForPostCall.length > 0) ||
                (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0)) === true
                ? true
                : false
        var putDisplayNameApiEndPoint
        switch (componentType) {
            case 'category':
                putDisplayNameApiEndPoint = categoryDisplayNameAPI
                break
            case 'producttemplate':
                putDisplayNameApiEndPoint = productTemplateDisplayNameAPI
                break
            case 'producttype':
                putDisplayNameApiEndPoint = productTypeLanguageNamesAPI
                break
            case 'onboardingtemplate':
                putDisplayNameApiEndPoint = onboardingTemplateLanguageNameAPI
                break
            case 'onboardingVersion':
                putDisplayNameApiEndPoint = typeRevisionLanguageNamesAPI
                break
            case 'attributegroup':
                putDisplayNameApiEndPoint = productAttributeGroupDisplayNameAPI
                break
            case 'layout':
                putDisplayNameApiEndPoint = layoutDisplayLanguageAPI
                break
            case 'paymenttype':
                putDisplayNameApiEndPoint = storePaymentTypeLanguageNamesAPI
                break
            case 'vendor':
                putDisplayNameApiEndPoint = vendorAPI
                break
            case 'store':
                putDisplayNameApiEndPoint = storeDisplayLanguageAPI
                break
            case 'variant_name':
                putDisplayNameApiEndPoint = variantDisplayNameAPI
                break
            case 'template_version_description':
                putDisplayNameApiEndPoint = productTemplateRevisionDisplayNamesAPI
                break
            default:
                return null
        }

        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            let postDisplayNameApiEndPoint
            switch (componentType) {
                case 'category':
                    postDisplayNameApiEndPoint = categoryDisplayNameAPI
                    break
                case 'producttemplate':
                    postDisplayNameApiEndPoint = productTemplateDisplayNameAPI
                    break
                case 'producttype':
                    postDisplayNameApiEndPoint = productTypeLanguageNamesAPI
                    break
                case 'onboardingtemplate':
                    postDisplayNameApiEndPoint = onboardingTemplateLanguageNameAPI
                    break
                case 'onboardingVersion':
                    postDisplayNameApiEndPoint = typeRevisionLanguageNamesAPI
                    break
                case 'attributegroup':
                    postDisplayNameApiEndPoint = productAttributeGroupDisplayNameAPI
                    break
                case 'layout':
                    postDisplayNameApiEndPoint = layoutDisplayLanguageAPI
                    break
                case 'paymenttype':
                    postDisplayNameApiEndPoint = storePaymentTypeLanguageNamesAPI
                    break
                case 'vendor':
                    postDisplayNameApiEndPoint = vendorAPI
                    break
                case 'store':
                    postDisplayNameApiEndPoint = storeDisplayLanguageAPI
                    break
                case 'variant_name':
                    postDisplayNameApiEndPoint = variantDisplayNameAPI
                    break
                case 'template_version_description':
                    postDisplayNameApiEndPoint = productTemplateRevisionDisplayNamesAPI
                    break

                default:
                    return null
            }
            //! decide how many api call will happen
            let putCallWillHappen =
                ((changedDataForPutCall && changedDataForPutCall.length > 0) ||
                    (finalDataForPutCall && finalDataForPutCall.length > 0)) === true
                    ? true
                    : false

            if (callWithDefaultDetails) {
                saveMultilingualDetails(
                    postDisplayNameApiEndPoint,
                    finalDataForPostCall,
                    putCallWillHappen,
                    callWithDefaultDetails,
                    putDisplayNameApiEndPoint,
                    changedDataForPutCall,
                    finalDataForPutCall
                )
                setIsLoadingStoreLanguage(true)
            } else {
                saveMultilingualDetails(
                    postDisplayNameApiEndPoint,
                    tempArrDataWithCategoryId,
                    putCallWillHappen,
                    callWithDefaultDetails,
                    putDisplayNameApiEndPoint,
                    changedDataForPutCall,
                    finalDataForPutCall
                )
                setIsLoadingStoreLanguage(true)
            }
        }

        if (!postCallWillHappen) {
            if (callWithDefaultDetails) {
                if (finalDataForPutCall && finalDataForPutCall.length > 0) {
                    updateMultilingualDetails(putDisplayNameApiEndPoint, finalDataForPutCall, 200)
                    setPutCallStatus(2)
                    setIsLoadingStoreLanguage(true)
                }
            } else if (changedDataForPutCall && changedDataForPutCall.length > 0) {
                updateMultilingualDetails(putDisplayNameApiEndPoint, changedDataForPutCall, 200)
                setPutCallStatus(2)
                setIsLoadingStoreLanguage(true)
            }
        }
        if (componentType === 'onboardingVersion') {
            if (objectForDetailsUpdate.description !== displayNameDataJson[0].value) {
                updateVersionDescription()
            }
        } else if (componentType === 'paymenttype') {
            // function to update the paymenttype name details (core name)
        } else if (componentType === 'vendor') {
            // function to update the vendor name details (core name)
        } else if (componentType === 'layout') {
            // function to update the vendor name details (core name)
        } else if (componentType === 'store') {
            // function to update the store name details (core name)
        }

        if (
            (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) ||
            (changedDataForPutCall && changedDataForPutCall.length > 0)
        ) {
        } else {
            //! Now check for Default Details Changes
            if (componentType === 'onboardingVersion') {
                if (objectForDetailsUpdate.description === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
            } else if (componentType === 'paymenttype') {
                if (objectForDetailsUpdate && objectForDetailsUpdate.name === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
            } else if (componentType === 'vendor') {
                if (objectForDetailsUpdate && objectForDetailsUpdate.name === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
            } else if (componentType === 'layout') {
                if (objectForDetailsUpdate && objectForDetailsUpdate.name === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
            } else if (componentType === 'store') {
                if (objectForDetailsUpdate && objectForDetailsUpdate.name === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
                //
            } else if (componentType === 'template_version_description') {
                if (objectForDetailsUpdate && objectForDetailsUpdate.description === displayNameDataJson[0].value) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
                // template_version_description
            } else {
                if (
                    objectForDetailsUpdate &&
                    objectForDetailsUpdate.name === displayNameDataJson[0].value &&
                    objectForDetailsUpdate &&
                    objectForDetailsUpdate.description === displayNameDataJson[1].value
                ) {
                    MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
                }
            }
        }
    }
    const handleNameChangeFromAllLanguages = (val, byDefault) => {
        setInvalidName(false)
        var temp = deepCopy(commonDataContainingAllObject)
        if (byDefault) {
            //   setDefaultDisplayName(val);

            let objIndex = temp.findIndex((obj) => obj.language_code === defaultLanguageName[0].language_code)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        } else {
            //   setCategoryNameForAllLanguage(val);
            let objIndex = temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
            temp[objIndex].display_name = val
            setCommonDataContainingAllObject(temp)
        }
    }

    //! to check language codes which have empty fields
    const inputFieldsValidationForApiCalls = (tempArrDataWithDisplayNameId, tempArrDataWithCategoryId) => {
        var temp = []
        tempArrDataWithDisplayNameId &&
            tempArrDataWithDisplayNameId.length > 0 &&
            tempArrDataWithDisplayNameId.forEach((ele) => {
                if (ele.display_name === '' || ele.description === '') {
                    temp.push(ele.language_code)
                }
            })

        tempArrDataWithCategoryId &&
            tempArrDataWithCategoryId.length > 0 &&
            tempArrDataWithCategoryId.forEach((ele) => {
                if (ele.display_name === '' || ele.description === '') {
                    temp.push(ele.language_code)
                }
            })
        setLanguagesWithInvalidData(temp)
        return temp
    }
    const handleDescriptionChangeFromAllLanguages = (val, byDefault) => {
        setInvalidDescription(false)
        var temp = deepCopy(commonDataContainingAllObject)
        if (byDefault) {
            let objIndex = temp.findIndex((obj) => obj.language_code === defaultLanguageName[0].language_code)
            temp[objIndex].description = val
            setCommonDataContainingAllObject(temp)
        } else {
            let objIndex = temp.findIndex((obj) => obj.language_code === selectedLanguageFromDropDown)
            temp[objIndex].description = val
            setCommonDataContainingAllObject(temp)
        }
    }
    const handleObjectForDetailsUpdateName = (val) => {
        let tempObj = deepCopy(objectForDetailsUpdate)
        const updatedObj = { ...tempObj, name: val }
        setObjectForDetailsUpdate(updatedObj)
    }

    const handleObjectForDetailsUpdateDescription = (val) => {
        let tempObj = deepCopy(objectForDetailsUpdate)
        const updatedObj = { ...tempObj, description: val }
        setObjectForDetailsUpdate(updatedObj)
    }
    useEffect(() => {
        let getDisplayNameApiEndPoint
        switch (componentType) {
            case 'category':
                getDisplayNameApiEndPoint = categoryDisplayNameAPI
                setMainTille(`${t('categories:category')}`)
                setTitle(`${t('categories:category')}`)
                setLabel(`${t('categories:category')}`)
                break
            case 'producttemplate':
                getDisplayNameApiEndPoint = productTemplateDisplayNameAPI
                setMainTille(`${t('product_template:product_template')}`)
                setTitle(`${t('product_template:template')}`)
                setLabel(`${t('product_template:template')}`)
                break
            case 'producttype':
                getDisplayNameApiEndPoint = productTypeLanguageNamesAPI
                setMainTille(`${t('common:product_type')}`)
                setTitle(`${t('common:product_type')}`)
                setLabel(`${t('common:product_type')}`)
                break
            case 'onboardingtemplate':
                getDisplayNameApiEndPoint = onboardingTemplateLanguageNameAPI
                setMainTille(`${t('vendors:onboarding_template')}`)
                setObjectForDetailsUpdate({
                    name: displayNameDataJson[0].value,
                    description: displayNameDataJson[0].value,
                    type: '',
                })
                break
            case 'onboardingVersion':
                getDisplayNameApiEndPoint = typeRevisionLanguageNamesAPI
                setMainTille(`${t('vendors:version_description')}`)
                setObjectForDetailsUpdate({
                    description: displayNameDataJson[0].value,
                })
                break
            case 'attributegroup':
                getDisplayNameApiEndPoint = productAttributeGroupDisplayNameAPI
                setMainTille(`${t('attribute_groups:attribute_groups')}`)
                setTitle(`${t('attribute_groups:group')}`)
                setLabel(`${t('attribute_groups:group')}`)
                break
            case 'layout':
                getDisplayNameApiEndPoint = layoutDisplayLanguageAPI
                setMainTille(`${t('layouts:layout')}`)
                setTitle(`${t('layouts:layout')}`)
                setLabel(`${t('layouts:layout')}`)
                break
            case 'paymenttype':
                setMainTille(`${t('payment:payment_type')}`)
                setTitle(`${t('payment:payment_type')}`)
                setLabel(`${t('payment:payment_type')}`)
                getDisplayNameApiEndPoint = storePaymentTypeLanguageNamesAPI
                break
            case 'vendor':
                setMainTille(`${t('vendors:vendor')}`)
                setTitle(`${t('vendors:vendor')}`)
                setLabel(`${t('vendors:vendor')}`)
                getDisplayNameApiEndPoint = vendorAPI
                break
            case 'store':
                setMainTille(`${t('store_settings:store')}`)
                setTitle(`${t('store_settings:store')}`)
                setLabel(`${t('store_settings:store')}`)
                getDisplayNameApiEndPoint = storeDisplayLanguageAPI
                break
            case 'variant_name':
                getDisplayNameApiEndPoint = variantDisplayNameAPI
                setMainTille(`${t('products:variant')}`)
                setTitle(`${t('products:variant')}`)
                setLabel(`${t('products:variant')}`)
                setObjectForDetailsUpdate({
                    name: displayNameDataJson[0].value,
                    description: displayNameDataJson[1].value,
                })
                break
            case 'template_version_description':
                getDisplayNameApiEndPoint = productTemplateRevisionDisplayNamesAPI
                setMainTille(`${t('product_types:version_description')}`)
                setTitle(`${t('product_types:version_description')}`)
                setLabel(`${t('product_types:version_description')}`)
                setObjectForDetailsUpdate({
                    description: displayNameDataJson[0].value,
                })
                break

            default:
                return null
        }
        if (onChangeDisableFields && loadingSkelton) {
            setCommonDataContainingAllObject([])
            setTimeout(function () {
                getStoreLanguageData(getDisplayNameApiEndPoint)
            }, 500)
        }
    }, [dataJson])

    //! getting the Details Values From Redux

    useEffect(() => {
        if (componentType === 'producttemplate') {
            if (productTemplateSelectedInfo && productTemplateSelectedInfo.length > 0) {
                setObjectForDetailsUpdate({
                    name: productTemplateSelectedInfo[0].name,
                    description: productTemplateSelectedInfo[0].description,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('categories:category_title')}`,
                        type: 'textbox',
                        value: productTemplateSelectedInfo[0].name,
                    },
                    {
                        order: 2,
                        label: `${t('categories:category_description')}`,
                        type: 'textarea',
                        value: productTemplateSelectedInfo[0].description,
                    },
                ])
            }
        }
    }, [productTemplateSelectedInfo])

    useEffect(() => {
        if (componentType === 'category') {
            if (categorySelectedInfo && categorySelectedInfo.length > 0) {
                setObjectForDetailsUpdate({
                    name: categorySelectedInfo[0].name,
                    description: categorySelectedInfo[0].description,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('categories:category_title')}`,
                        type: 'textbox',
                        value: categorySelectedInfo[0].name,
                    },
                    {
                        order: 2,
                        label: `${t('categories:category_description')}`,
                        type: 'textarea',
                        value: categorySelectedInfo[0].description,
                    },
                ])
            }
        }
    }, [categorySelectedInfo])
    useEffect(() => {
        if (componentType === 'attributegroup') {
            if (defaultObjectForAttributeGroup && defaultObjectForAttributeGroup.length > 0) {
                setObjectForDetailsUpdate({
                    name: defaultObjectForAttributeGroup[0].groupName,
                    description: defaultObjectForAttributeGroup[0].groupDescription,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: t('attribute_groups:enter_attribute_title'),
                        type: 'textbox',
                        value: defaultObjectForAttributeGroup[0].groupName,
                    },
                    {
                        order: 2,
                        label: t('common:description'),
                        type: 'textarea',
                        value: defaultObjectForAttributeGroup[0].groupDescription,
                    },
                ])
            }
        }
    }, [defaultObjectForAttributeGroup])

    useEffect(() => {
        if (componentType === 'producttype') {
            if (defaultObjectForProductType && defaultObjectForProductType.length > 0) {
                setObjectForDetailsUpdate({
                    name: dataJson[0].value,
                    description: dataJson[1].value,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('common:product_type')}`,
                        type: 'textbox',
                        value: dataJson[0].value,
                    },
                    {
                        order: 2,
                        label: `${t('common:description')}`,
                        type: 'textarea',
                        value: dataJson[1].value,
                    },
                ])
            }
        }
    }, [defaultObjectForProductType])
    useEffect(() => {
        if (componentType === 'paymenttype') {
            if (dataJson && dataJson.length > 0) {
                setObjectForDetailsUpdate({
                    name: dataJson[0].value,
                    // description: categorySelectedInfo[0].description,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('categories:category_title')}`,
                        type: 'textbox',
                        value: dataJson[0].value,
                    },
                ])
            }
        }
    }, [dataJson])

    useEffect(() => {
        if (componentType === 'vendor') {
            if (dataJson && dataJson.length > 0) {
                setObjectForDetailsUpdate({
                    name: dataJson[0].value,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('vendors:vendor_title')}`,
                        type: 'textbox',
                        value: dataJson[0].value,
                    },
                ])
            }
        }
    }, [dataJson])

    useEffect(() => {
        if (componentType === 'layout') {
            if (dataJson && dataJson.length > 0) {
                setObjectForDetailsUpdate({
                    name: dataJson[0].value,
                    description: dataJson[1].value,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('vendors:vendor_title')}`,
                        type: 'textbox',
                        value: dataJson[0].value,
                    },
                    {
                        order: 2,
                        label: `${t('common:description')}`,
                        type: 'textarea',
                        value: dataJson[1].value,
                    },
                ])
            }
        }
    }, [dataJson])

    useEffect(() => {
        if (componentType === 'store') {
            if (storeSettingsInfo && storeSettingsInfo.length > 0) {
                setObjectForDetailsUpdate({
                    name: storeSettingsInfo && storeSettingsInfo[0].store_name,
                })
                setDisplayNameDataJson([
                    {
                        order: 1,
                        label: `${t('store_settings:store_title')}`,
                        type: 'textbox',
                        value: storeSettingsInfo && storeSettingsInfo[0].store_name,
                    },
                ])
            }
        }
    }, [storeSettingsInfo])
    // description: displayNameDataJson[0].value,
    useEffect(() => {
        if (componentType === 'template_version_description') {
            if (dataJson && dataJson.length > 0) {
                setObjectForDetailsUpdate({
                    description: dataJson[0].value,
                })
                setDisplayNameDataJson([
                    {
                        order: 2,
                        label: `${t('common:description')}`,
                        type: 'textarea',
                        value: dataJson[0].value,
                    },
                ])
            }
        }
    }, [dataJson])

    const handleSaveButton = () => {
        //!  checking commonObject is created or not
        if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
            //! checking weather default language is empty or not
            const defaultLanguageDisplayName =
                commonDataContainingAllObject &&
                commonDataContainingAllObject.length > 0 &&
                commonDataContainingAllObject.filter(
                    (value) => value.language_code === defaultLanguageName[0].language_code
                )[0].display_name

            const defaultLanguageDescription =
                commonDataContainingAllObject &&
                commonDataContainingAllObject.length > 0 &&
                commonDataContainingAllObject.filter(
                    (value) => value.language_code === defaultLanguageName[0].language_code
                )[0].description

            if (defaultLanguageDisplayName !== '' && defaultLanguageDescription !== '') {
                const tempArrDataWithDisplayNameId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return o.hasOwnProperty('id')
                    })

                const tempArrDataWithCategoryId =
                    commonDataContainingAllObject &&
                    commonDataContainingAllObject.filter(function (o) {
                        return !o.hasOwnProperty('id')
                    })
                var resultForinputFieldsValidationForApiCalls = inputFieldsValidationForApiCalls(
                    tempArrDataWithDisplayNameId,
                    tempArrDataWithCategoryId
                )
                //! below section  to  byDefault lang addition to empty fields
                if (resultForinputFieldsValidationForApiCalls && resultForinputFieldsValidationForApiCalls.length > 0) {
                    setIsInputFieldsValidationModalOpen(true)
                } else {
                    let callWithDefaultDetails = false
                    InputValuesValidationFromAllLanguages(commonDataContainingAllObject, callWithDefaultDetails)
                }
            } else {
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('languages:provide_details_for_default_language')}`, 'error')
                )
                if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
                    if (defaultLanguageName && defaultLanguageName.length) {
                        const displayNameForDefaultLanguage =
                            commonDataContainingAllObject.length > 0 &&
                            commonDataContainingAllObject.filter(
                                (el) => el.language_code === defaultLanguageName[0].language_code
                            )[0]?.display_name

                        const displayDescriptionForDefaultLanguage =
                            commonDataContainingAllObject.length > 0 &&
                            commonDataContainingAllObject.filter(
                                (el) => el.language_code === defaultLanguageName[0].language_code
                            )[0]?.description

                        if (displayNameForDefaultLanguage === '') {
                            setInvalidName(true)
                        }
                        if (displayDescriptionForDefaultLanguage === '') {
                            setInvalidDescription(true)
                        }
                    }
                }
                setSelectedLanguageFromDropDown(
                    defaultLanguageName && defaultLanguageName.length > 0 && defaultLanguageName[0].language_code
                )
                if (commonDataContainingAllObject && commonDataContainingAllObject.length > 0) {
                    const getTheInfoOfDefaultLang =
                        commonDataContainingAllObject &&
                        commonDataContainingAllObject.length > 0 &&
                        commonDataContainingAllObject.filter(
                            (ele) => ele.language_code === defaultLanguageName[0].language_code
                        )

                    if (
                        getTheInfoOfDefaultLang &&
                        getTheInfoOfDefaultLang.length > 0 &&
                        getTheInfoOfDefaultLang[0].display_name === ''
                    ) {
                        setInvalidName(true)
                    }
                    if (
                        getTheInfoOfDefaultLang &&
                        getTheInfoOfDefaultLang.length > 0 &&
                        getTheInfoOfDefaultLang[0].description === ''
                    ) {
                        setInvalidDescription(true)
                    }
                }
            }
        } else {
            MarketplaceToaster.showToast(util.getToastObject(`${t('common:something_went_wrong')}`, 'error'))
        }
    }

    const handleInputFieldsValidationModalSave = () => {
        setIsInputFieldsValidationModalOpen(false)
        var callWithDefaultDetails = true
        const tempArrDataWithCategoryId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return !o.hasOwnProperty('id')
            })
        const tempArrDataWithDisplayNameId =
            commonDataContainingAllObject &&
            commonDataContainingAllObject.filter(function (o) {
                return o.hasOwnProperty('id')
            })
        var finalDataForPostCall = deepCopy(tempArrDataWithCategoryId)
        if (tempArrDataWithCategoryId && tempArrDataWithCategoryId.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = finalDataForPostCall.findIndex((obj) => obj.language_code === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (finalDataForPostCall && finalDataForPostCall[objIndex].description === '') {
                            finalDataForPostCall[objIndex].description =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject
                                    .filter((value) => value.language_code === defaultLanguageName[0].language_code)[0]
                                    .description.trim()
                        }
                        if (finalDataForPostCall && finalDataForPostCall[objIndex].display_name === '') {
                            finalDataForPostCall[objIndex].display_name =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject
                                    .filter((value) => value.language_code === defaultLanguageName[0].language_code)[0]
                                    .display_name.trim()
                        }
                    }
                })
        }
        var finalDataForPutCall
        if (tempArrDataWithDisplayNameId && tempArrDataWithDisplayNameId.length > 0) {
            var changedDataForPutCall = validationOfDataForPutCall(tempArrDataWithDisplayNameId)
            finalDataForPutCall = deepCopy(changedDataForPutCall)
        }
        var dataWithDefaultLanguage =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code === defaultLanguageName[0].language_code)
        var dataWithoutDefaultLanguage =
            finalDataForPutCall &&
            finalDataForPutCall.length > 0 &&
            finalDataForPutCall.filter((ele) => ele.language_code !== defaultLanguageName[0].language_code)

        if (dataWithoutDefaultLanguage && dataWithoutDefaultLanguage.length > 0) {
            languagesWithInvalidData &&
                languagesWithInvalidData.length > 0 &&
                languagesWithInvalidData.forEach((element) => {
                    let objIndex = dataWithoutDefaultLanguage.findIndex((obj) => String(obj.language_code) === element)
                    if (element !== defaultLanguageName[0].language_code) {
                        if (dataWithoutDefaultLanguage[objIndex].description === '') {
                            dataWithoutDefaultLanguage[objIndex].description =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].description
                        }
                        if (dataWithoutDefaultLanguage[objIndex].display_name === '') {
                            dataWithoutDefaultLanguage[objIndex].display_name =
                                commonDataContainingAllObject &&
                                commonDataContainingAllObject.length > 0 &&
                                commonDataContainingAllObject.filter(
                                    (value) => value.language_code === defaultLanguageName[0].language_code
                                )[0].display_name
                        }
                    }
                })
        }

        if (
            dataWithoutDefaultLanguage &&
            dataWithoutDefaultLanguage.length > 0 &&
            dataWithDefaultLanguage &&
            dataWithDefaultLanguage.length > 0
        ) {
            finalDataForPutCall = [...dataWithoutDefaultLanguage, ...dataWithDefaultLanguage]
        } else if (dataWithoutDefaultLanguage && dataWithoutDefaultLanguage.length > 0) {
            finalDataForPutCall = [...dataWithoutDefaultLanguage]
        } else if (dataWithDefaultLanguage && dataWithDefaultLanguage.length > 0) {
            finalDataForPutCall = [...dataWithDefaultLanguage]
        }
        InputValuesValidationFromAllLanguages(
            commonDataContainingAllObject,
            callWithDefaultDetails,
            finalDataForPostCall,
            finalDataForPutCall
        )
    }

    const handleInputFieldsValidationModalClose = () => {
        setIsInputFieldsValidationModalOpen(false)
    }

    const handleTranslatorDiscard = () => {
        setTranslateModalVisible(false)
        setInvalidDescription(false)
        setInvalidName(false)
    }
    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])

    return (
        <Content>
            {loadingSkelton ? (
                <Skeleton paragraph={{ rows: 6 }} className='p-3' />
            ) : (
                <Spin tip={t('common:please_wait')} size='large' spinning={isLoadingStoreLanguage}>
                    <StoreModal
                        title={t('languages:language_with_invalid_inputs')}
                        isVisible={isInputFieldsValidationModalOpen}
                        okButtonText={t('common:enter_translations')}
                        cancelButtonText={`${t('common:proceed_without_translations')}`}
                        okCallback={() => handleInputFieldsValidationModalClose()}
                        cancelCallback={() => handleInputFieldsValidationModalSave()}
                        isSpin={false}
                        width={700}
                        hideCloseButton={false}>
                        <Content>
                            <div className=' mt-2 space-y-2'>
                                <p className=''>{t('product_template:the_multi_lingual_details_for')}</p>
                                <ul
                                    className={
                                        languagesWithInvalidData && languagesWithInvalidData.length > 5
                                            ? '!overflow-y-auto !h-24'
                                            : ''
                                    }>
                                    {languagesWithInvalidData &&
                                        languagesWithInvalidData.length > 0 &&
                                        languagesWithInvalidData.map((ele, index) => {
                                            return (
                                                <>
                                                    {
                                                        <li className='!font-semibold'>
                                                            {index + 1}
                                                            {'. '}
                                                            {storeLanguages &&
                                                                storeLanguages.length > 0 &&
                                                                storeLanguages.filter(
                                                                    (val) => val.language_code === ele
                                                                )[0].language}
                                                        </li>
                                                    }
                                                </>
                                            )
                                        })}
                                </ul>
                                <p className=''>{t('product_template:click_continue_to_save')}</p>
                            </div>
                        </Content>
                    </StoreModal>
                    {/* <Row className='!space-x-5'>
                        <Col>
                            <Title level={4} className='!font-medium'>
                                {mainTille} {t('product_types:details')}{' '}
                            </Title>
                        </Col>
                    </Row>

                    <Divider /> */}
                    <div className=' flex gap-3 w-full'>
                        <div className='flex-grow-0 my-2'>
                            {/* <Content className='flex'>
                                <h3 className='!font-medium mt-1'>
                                    {title} {t('product_types:details')}
                                </h3>{' '}
                                <span> </span>
                            </Content> */}
                            <p>
                                <span className='font-normal opacity-40'>{t('common:translate_from')}</span>{' '}
                                <span className='font-medium'>{t('common:base_language')}</span>
                            </p>
                            {displayNameDataJson &&
                                displayNameDataJson.length > 0 &&
                                displayNameDataJson.map((data) => (
                                    <Content key={data.order}>
                                        {data.type === 'textbox' ? (
                                            <Content className='my-3'>
                                                <Typography className='input-label-color'>
                                                    {componentType === 'vendor' ? (
                                                        t('sales_returns:vendor_account_name')
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {label} {t('common:name')}
                                                        </>
                                                    )}
                                                </Typography>
                                                <Tooltip
                                                    placement='bottom'
                                                    title={objectForDetailsUpdate && objectForDetailsUpdate?.name}>
                                                    <Input
                                                        disabled={true}
                                                        // disabled={editPermissions}
                                                        placeholder={t('product_types:enter_title_here')}
                                                        className='w-[100%] mt-2 border-solid border-[#C6C6C6]'
                                                        value={objectForDetailsUpdate && objectForDetailsUpdate?.name}
                                                        onChange={(e) =>
                                                            handleObjectForDetailsUpdateName(e.target.value)
                                                        }
                                                        maxLength={100}
                                                    />
                                                </Tooltip>
                                            </Content>
                                        ) : null}
                                        {data.type === 'textarea' ? (
                                            <Content className='my-3'>
                                                <Typography className='input-label-color'>{data.label}</Typography>

                                                <TextArea
                                                    disabled={true}
                                                    placeholder={t('product_types:enter_description_here')}
                                                    rows={1}
                                                    autoSize={true}
                                                    showCount
                                                    className='custom-textarea w-[100%] !pr-[3px] mt-2 border-solid border-[#C6C6C6]'
                                                    value={objectForDetailsUpdate && objectForDetailsUpdate.description}
                                                    onChange={(e) =>
                                                        handleObjectForDetailsUpdateDescription(e.target.value)
                                                    }
                                                    maxLength={descriptionMaxLength}
                                                />
                                            </Content>
                                        ) : null}
                                    </Content>
                                ))}
                        </div>
                        <div className='border-r !border-brandGray'></div>
                        <div className='flex-grow my-2'>
                            <Content className='flex gap-2 items-center justify-between'>
                                <div className='flex items-center mb-2'>
                                    <p className='font-normal opacity-40'>{t('common:translate_to')}</p>
                                    <Select
                                        value={selectedLanguageFromDropDown}
                                        onChange={handleLanguageChange}
                                        dropdownStyle={{ zIndex: 500000 }}
                                        className=' border-0 translator-select h-[22px] !mx-2 w-28'
                                        listHeight={138}>
                                        {allLanguages &&
                                            allLanguages.length > 0 &&
                                            allLanguages.map((e) => (
                                                <Select.Option
                                                    className='translatorSelectOption'
                                                    value={e.language_code}
                                                    title={e.language}
                                                    key={e.language_code}>
                                                    {e.language}
                                                </Select.Option>
                                            ))}
                                    </Select>
                                    {defaultLanguageName &&
                                    defaultLanguageName.length > 0 &&
                                    selectedLanguageFromDropDown === defaultLanguageName[0].language_code ? (
                                        <p className='!font-semibold !text-brandGray2'>
                                            {t('attributes:default_language')}
                                        </p>
                                    ) : null}
                                </div>
                                <div className='flex'>
                                    <Progress
                                        className='w-[140px] !m-0 px-2'
                                        percent={(alreadyTranslatedCount / allLanguages?.length) * 100}
                                        showInfo={false}
                                        size={'small'}
                                    />
                                    <p className='font-normal text-sm'>
                                        {alreadyTranslatedCount} {t('common:of')} {allLanguages?.length}{' '}
                                        {t('common:languages_translated')}
                                    </p>
                                </div>
                            </Content>

                            {displayNameDataJson &&
                                displayNameDataJson.length > 0 &&
                                displayNameDataJson.map((data) => (
                                    <>
                                        {data.type === 'textbox' ? (
                                            <Content className='my-2' key={data.order}>
                                                <Typography className='input-label-color'>
                                                    {componentType === 'vendor' ? (
                                                        t('sales_returns:vendor_account_name')
                                                    ) : (
                                                        <>
                                                            {' '}
                                                            {label} {t('common:name')}
                                                        </>
                                                    )}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>
                                                <Content className='mt-2'>
                                                    <Input
                                                        placeholder={t('product_types:enter_title_here')}
                                                        className={`w-[100%] ${
                                                            invalidName &&
                                                            defaultLanguageName &&
                                                            defaultLanguageName.length > 0 &&
                                                            selectedLanguageFromDropDown ===
                                                                defaultLanguageName[0].language_code
                                                                ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                                : ' border-[#C6C6C6] border-solid'
                                                        }`}
                                                        value={
                                                            allLanguages && allLanguages.length > 0
                                                                ? commonDataContainingAllObject &&
                                                                  commonDataContainingAllObject[0] &&
                                                                  commonDataContainingAllObject.length > 0 &&
                                                                  commonDataContainingAllObject.filter(
                                                                      (el) =>
                                                                          el.language_code ===
                                                                          selectedLanguageFromDropDown
                                                                  )[0].display_name
                                                                : null
                                                        }
                                                        onChange={(e) => {
                                                            if (validator.matches(e.target.value.trim(), /^\S.*$/)) {
                                                                handleNameChangeFromAllLanguages(e.target.value, false)

                                                                if (
                                                                    (commonDataContainingAllObject &&
                                                                        commonDataContainingAllObject[0] &&
                                                                        commonDataContainingAllObject.length > 0 &&
                                                                        commonDataContainingAllObject.filter(
                                                                            (el) =>
                                                                                el.language_code ===
                                                                                selectedLanguageFromDropDown
                                                                        )[0].display_name !== null) ||
                                                                    (commonDataContainingAllObject &&
                                                                        commonDataContainingAllObject[0] &&
                                                                        commonDataContainingAllObject.length > 0 &&
                                                                        commonDataContainingAllObject.filter(
                                                                            (el) =>
                                                                                el.language_code ===
                                                                                selectedLanguageFromDropDown
                                                                        )[0].display_name !== '')
                                                                ) {
                                                                    setOnChangeDisableFields(false)
                                                                }
                                                            } else if (e.target.value === '') {
                                                                handleNameChangeFromAllLanguages(e.target.value, false)

                                                                if (
                                                                    (commonDataContainingAllObject &&
                                                                        commonDataContainingAllObject[0] &&
                                                                        commonDataContainingAllObject.length > 0 &&
                                                                        commonDataContainingAllObject.filter(
                                                                            (el) =>
                                                                                el.language_code ===
                                                                                selectedLanguageFromDropDown
                                                                        )[0].display_name !== null) ||
                                                                    (commonDataContainingAllObject &&
                                                                        commonDataContainingAllObject[0] &&
                                                                        commonDataContainingAllObject.length > 0 &&
                                                                        commonDataContainingAllObject.filter(
                                                                            (el) =>
                                                                                el.language_code ===
                                                                                selectedLanguageFromDropDown
                                                                        )[0].display_name !== '')
                                                                ) {
                                                                    setOnChangeDisableFields(false)
                                                                }
                                                            }
                                                        }}
                                                        onBlur={(e) => {
                                                            var temp = deepCopy(commonDataContainingAllObject)
                                                            let objIndex = temp.findIndex(
                                                                (obj) =>
                                                                    obj.language_code === selectedLanguageFromDropDown
                                                            )
                                                            temp[objIndex].display_name = e.target.value
                                                                .trim()
                                                                .replace(/\s+/g, ' ')
                                                            setCommonDataContainingAllObject(temp)
                                                        }}
                                                        maxLength={parseInt(titleMaxLength)}
                                                    />
                                                </Content>
                                            </Content>
                                        ) : null}
                                        {data.type === 'textarea' ? (
                                            <Content className='my-3'>
                                                <Typography className='input-label-color'>
                                                    {data.label}
                                                    {defaultLanguageName &&
                                                    defaultLanguageName.length > 0 &&
                                                    selectedLanguageFromDropDown ===
                                                        defaultLanguageName[0].language_code ? (
                                                        <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                    ) : null}
                                                </Typography>
                                                <TextArea
                                                    placeholder={t('product_types:enter_description_here')}
                                                    rows={1}
                                                    autoSize={true}
                                                    showCount
                                                    className={`w-[100%] !pr-[3px] my-2 ${
                                                        invalidDescription &&
                                                        defaultLanguageName &&
                                                        defaultLanguageName.length > 0 &&
                                                        selectedLanguageFromDropDown ===
                                                            defaultLanguageName[0].language_code
                                                            ? 'border-red-400  border-[1px] border-solid focus:border-red-400 hover:border-red-400 '
                                                            : ' border-[#C6C6C6] border-solid'
                                                    }`}
                                                    value={
                                                        allLanguages && allLanguages.length > 0
                                                            ? commonDataContainingAllObject &&
                                                              commonDataContainingAllObject.length > 0 &&
                                                              commonDataContainingAllObject.filter(
                                                                  (el) =>
                                                                      el.language_code === selectedLanguageFromDropDown
                                                              )[0].description
                                                            : null
                                                    }
                                                    onChange={(e) => {
                                                        if (validator.matches(e.target.value.trim(), /^(?!\s*$).+/)) {
                                                            handleDescriptionChangeFromAllLanguages(
                                                                e.target.value,
                                                                false
                                                            )
                                                            setOnChangeDisableFields(false)
                                                        } else if (e.target.value === '') {
                                                            handleDescriptionChangeFromAllLanguages(
                                                                e.target.value,
                                                                false
                                                            )
                                                            setOnChangeDisableFields(false)
                                                        }
                                                    }}
                                                    onBlur={(e) => {
                                                        var temp = deepCopy(commonDataContainingAllObject)
                                                        let objIndex = temp.findIndex(
                                                            (obj) => obj.language_code === selectedLanguageFromDropDown
                                                        )
                                                        temp[objIndex].description = e.target.value
                                                            ?.split('\n') // Split by newline characters
                                                            .map((line) => line.trim().replace(/\s+/g, ' ')) // Remove leading whitespace and replace multiple spaces on each line
                                                            .join('\n') // Join lines back together
                                                        setCommonDataContainingAllObject(temp)
                                                    }}
                                                    maxLength={parseInt(descriptionMaxLength)}
                                                />
                                            </Content>
                                        ) : null}
                                    </>
                                ))}
                        </div>
                    </div>

                    {/* buttons */}

                    <Content
                        className={` -mb-3 w-full ${
                            util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'text-left' : 'text-right'
                        }`}>
                        <Button
                            className={`app-btn-secondary ${
                                util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? '!ml-2' : '!mr-2'
                            }`}
                            onClick={() => handleTranslatorDiscard()}
                            size={'middle'}>
                            {t('common:discard')}
                        </Button>
                        <Button
                            className='app-btn-primary '
                            disabled={
                                !onChangeDisableFields
                                    ? allLanguages && allLanguages.length > 0
                                        ? (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_name === null) ||
                                          (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].display_name === '') ||
                                          (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].description === null) ||
                                          (commonDataContainingAllObject &&
                                              commonDataContainingAllObject[0] &&
                                              commonDataContainingAllObject.length > 0 &&
                                              commonDataContainingAllObject.filter(
                                                  (el) => el.language_code === selectedLanguageFromDropDown
                                              )[0].description === '')
                                            ? true
                                            : false
                                        : true
                                    : true
                            }
                            onClick={() => handleSaveButton()}
                            size={'middle'}>
                            {t('common:save')}
                        </Button>
                    </Content>
                </Spin>
            )}
        </Content>
    )
}

export default TranslatorModal
