const floatPositiveDecimalLength = process.env.REACT_APP_FLOAT_POSITIVE_DECIMAL_LENGTH
const floatPositiveMaxValue = process.env.REACT_APP_FLOAT_POSITIVE_MAX_VALUE
// validate only for number 0-9, only one dot(.) & not allowing more than 10 number
export const validatePositiveNumber = (e, regex) => {
    const key = e.keyCode || e.which
    const keyChar = String.fromCharCode(key)
    // const regex = /[0-9]|\./;
    if (!regex.test(keyChar) || e.target.value.length >= 10 || (keyChar === '.' && e.target.value.includes('.'))) {
        e.preventDefault()
    }
}

export const validatePositiveFloatNumber = (e, regex, decimal) => {
    const key = e.keyCode || e.which
    const keyChar = String.fromCharCode(key)
    const input = e.target
    const cursorPosition = input.selectionStart

    const beforeCursor = input.value.substring(0, cursorPosition)
    const afterCursor = input.value.substring(cursorPosition)

    const value = beforeCursor + keyChar + afterCursor
    const decimalValue = decimal ? decimal : floatPositiveDecimalLength
    console.log('alpha', !regex.test(value))
    if (
        (!regex.test(value) && key !== 8 && key !== 46) ||
        keyChar === ' ' ||
        (input.value.indexOf('.') !== -1 && keyChar === '.') ||
        (input.value.indexOf('.') !== -1 && value.split('.')[1].length > decimalValue) ||
        parseFloat(value) > parseFloat(floatPositiveMaxValue)
    ) {
        e.preventDefault()
    }
}

export const validatePercentage = (e, regex, decimal, maxValueParams) => {
    const key = e.keyCode || e.which
    const keyChar = String.fromCharCode(key)
    const input = e.target
    const cursorPosition = input.selectionStart

    const beforeCursor = input.value.substring(0, cursorPosition)
    const afterCursor = input.value.substring(cursorPosition)

    const value = beforeCursor + keyChar + afterCursor
    const decimalValue = decimal ? decimal : floatPositiveDecimalLength
    const maxValue = maxValueParams ? maxValueParams : 100
    if (
        (!regex.test(value) && key !== 8 && key !== 46) ||
        keyChar === ' ' ||
        (input.value.indexOf('.') !== -1 && keyChar === '.') ||
        (value.split('.')[0]?.length > 1 && parseInt(value) === 0) ||
        (input.value.indexOf('.') !== -1 && value.split('.')[1].length > decimalValue) ||
        parseFloat(value) > parseFloat(maxValue)
    ) {
        e.preventDefault()
    }
}
