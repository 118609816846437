import { Button, Empty, Input, Layout, Row, Skeleton, Table, Tag, Typography } from 'antd'
import React, { useState } from 'react'

import { PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import HeaderForTitle from '../../components/header/HeaderForTitle'
import StoreModal from '../../components/storeModal/StoreModal'
import ProductTemplateStatus from '../../constants/2.0/ProductTemplateStatus'
import { Prodimg, DownArrow } from '../../constants/media'
import { usePageTitle } from '../../hooks/usePageTitle'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import { fnMakeAPICall,fnAttributeDetailsUpdated } from '../../services/redux/actions/ActionsAPICall'
import {
    fnAlreadyCreatedAttributeInfo,
    fnAlreadyCreatedStaticAttributeInfo,
} from '../../services/redux/actions/ActionsAttributes'
import { fnPageCompAction } from '../../services/redux/actions/ActionsPageComp'
import { fnProductTemplateSelectedInfo } from '../../services/redux/actions/ActionsProducts'
import MarketplaceAppConfig from '../../util/MarketplaceMutlitenancy'
import util from '../../util/common'
import { getParentChildrenDataForProductParentTemplate } from '../../util/util'
import AddProductTemplate from './AddProductTemplate'
import './AddProductTemplate.css'
import { fnRevisionInfo,fnRevisionSelectedInfo } from '../../services/redux/actions/ActionsRevisionInfo'

const { Content } = Layout
const { Paragraph, Title, Text } = Typography
const { Search } = Input

//! Get all required details from .env file
const productTemplateActiveAPI = process.env.REACT_APP_ACTIVE_PRODUCT_TEMPLATE_API
const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
const searchMaxLength = process.env.REACT_APP_SEARCH_MAX_LENGTH

const ProductTemplates = ({ permission }) => {
    const { t } = useTranslation()
    usePageTitle(t('common:store_management_portal')+ ' - ' + String(`${t('common:product_templates')}`))
    const dispatch = useDispatch()
    const [listProductTemplatesCopy, setListProductTemplatesCopy] = useState([])
    const [productCount, setProductCount] = useState()
    const [isLoadingListProductTemplates, setIsLoadingListProductTemplates] = useState(true)
    const [isNetworkErrorListProductTemplates, setIsNetworkErrorListProductTemplates] = useState(false)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [knowMoreInfoModal, setKnowMoreInfoModal] = useState(false)

    //! this useStates are related to Add Product Form component
    const [invalidProductTemplateName, setInvalidProductTemplateName] = useState(false)
    const [invalidProductTemplateDescription, setInvalidProductTemplateDescription] = useState(false)
    const [inValidSelectedStoreProductTypes, setInValidSelectedStoreProductTypes] = useState(false)
    const [isSearchTriggered, setIsSearchTriggered] = useState(false)
    const [searchValue, setSearchValue] = useState('')

    const [permissions, setPermissions] = useState(false)
    const permissionValue = util.getPermissionData() || []
    const [langDirection, setLangDirection] = useState('ltr')

    const showBadge = (color, title, isBgFilled) => {
        console.log('showBadge called with:', { color, title, isBgFilled })
        return (
            <div className='flex flex-row'>
                <div
                    className={
                        isBgFilled ? `w-[6px] h-[6px] rounded-full mt-2` : `border-2 w-[6px] h-[6px] rounded-full mt-2`
                    }
                    style={{
                        backgroundColor: isBgFilled ? color : '#ffffff',
                        borderColor: !isBgFilled ? color : 'transparent',
                    }}></div>
                <p
                    className={`!font-normal !text-sm !text-[#637381] ${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-1' : 'ml-1'}`}>
                    {title}
                </p>
            </div>
        )
    }
    //! columns of list product template table
    const columns = [
        {
            title: `${t('common:product_template')}`,
            dataIndex: `${t('common:name')}`,
            key: `${t('common:name')}`,
            ellipsis: true,
            width: '70%',

            render: (text, record) => {
                return (
                    <Content>
                        <Text
                            ellipsis={{
                                tooltip: { title: record.display_name, mouseLeaveDelay: 0, mouseEnterDelay: 0.5 },
                            }}
                            className={`text-brandGray1 text-[14px] font-normal !mb-0 w-[600px] ${record.children ? null : langDirection === 'rtl' ? 'mr-[31px]' : 'ml-[31px]'}`}>
                            {record.display_name}
                        </Text>
                        <br />
                        <div
                            className={`${record.children ? null : langDirection === 'rtl' ? 'mr-[31px]' : 'ml-[31px]'}`}>
                            {ProductTemplateStatus[record.product_status - 1].value}
                        </div>
                    </Content>
                )
            },
        },
        // {
        //   title: <Content className="">Product Type</Content>,
        //   dataIndex: "storeProductType",
        //   key: "storeProductType",
        //   width: "30%",
        //   render: (text, record) => {
        //     return (
        //       <>
        //         <Row>
        //           {console.log("record##---", record)}

        //           {/* {record.storeProductType && record.storeProductType.map((val)=>  )} */}
        //           {record.storeProductType &&
        //             record.storeProductType.map((ele) => (
        //               <>
        //                 <Col className="mr-2 bg-black text-white rounded-xl px-2 py-[2px] mb-[2px]">
        //                   {listProductTypes &&
        //                     listProductTypes.length > 0 &&
        //                     listProductTypes.filter(
        //                       (value) => value.store_product_type.id === ele
        //                     )[0].store_product_type.name}
        //                 </Col>
        //               </>
        //             ))}
        //         </Row>
        //       </>
        //     );
        //   },
        // },
        {
            title: ` ${t('product_template:latest_version')}`,
            dataIndex: 'version',
            key: 'version',

            render: (text, record) => {
                return (
                    <div>
                        <p className='font-normal text-[14px] text-[#637381] leading-6'>
                            V{Number(record.product_template_revision_details.revision_number)?.toFixed(1)}
                        </p>
                        <p>
                            {record.product_template_revision_details.product_revision_status === 1
                                ? showBadge(
                                      '#2f54eb',
                                      record.product_template_revision_details.product_revision_status_display_name,
                                      true
                                  )
                                : record.product_template_revision_details.product_revision_status === 2
                                  ? showBadge(
                                        '#52c41a',
                                        record.product_template_revision_details.product_revision_status_display_name,
                                        true
                                    )
                                  : record.product_template_revision_details.product_revision_status === 3
                                    ? showBadge(
                                          '#52c41a',
                                          record.product_template_revision_details.product_revision_status_display_name,
                                          true
                                      )
                                    : record.product_template_revision_details.product_revision_status === 4
                                      ? showBadge(
                                            '#FF4D4F',
                                            record.product_template_revision_details
                                                .product_revision_status_display_name,
                                            true
                                        )
                                      : record.product_template_revision_details.product_revision_status === 5
                                        ? showBadge(
                                              '#023047',
                                              record.product_template_revision_details
                                                  .product_revision_status_display_name,
                                              true
                                          )
                                        : showBadge(
                                              '#BFBFBF',
                                              record.product_template_revision_details
                                                  .product_revision_status_display_name,
                                              true
                                          )}
                        </p>
                    </div>
                )
            },
        },
        // {
        //     title: ` ${t('product_template:version_status')}`,
        //     dataIndex: 'product_version_status',
        //     key: 'product_version_status',
        //     render: (text, record) => {
        //         return (
        //             // <p className='!font-normal text-[14px] text-[#637381] leading-6'>
        //             //     {record.product_template_revision_details.product_revision_status_display_name}
        //             // </p>
        //             <p>
        //                 {record.product_status == 1
        //                     ? showBadge(
        //                           '#2f54eb',
        //                           record.product_template_revision_details.product_revision_status_display_name,
        //                           true
        //                       )
        //                     : record.product_status == 2
        //                       ? showBadge(
        //                             '#52c41a',
        //                             record.product_template_revision_details.product_revision_status_display_name,
        //                             true
        //                         )
        //                       : record.product_status == 3
        //                         ? showBadge(
        //                               '#52c41a',
        //                               record.product_template_revision_details.product_revision_status_display_name,
        //                               true
        //                           )
        //                         : record.product_status == 4
        //                           ? showBadge(
        //                                 '#FF4D4F',
        //                                 record.product_template_revision_details.product_revision_status_display_name,
        //                                 true
        //                             )
        //                           : record.product_status == 5
        //                             ? showBadge(
        //                                   '#023047',
        //                                   record.product_template_revision_details.product_revision_status_display_name,
        //                                   true
        //                               )
        //                             : showBadge(
        //                                   '#BFBFBF',
        //                                   record.product_template_revision_details.product_revision_status_display_name,
        //                                   true
        //                               )}
        //             </p>
        //         )
        //     },
        // },
        // {
        //     title: `${t('product_template:template_status')}`,
        //     dataIndex: 'name',
        //     key: 'name',

        //     render: (text, record) => {
        //         return (
        //             <Row>
        //                 <div className='flex'>
        //                     <Tag className='!font-normal text-[14px]'>
        //                         {
        //                             ProductTemplateStatus.filter(
        //                                 (ele) => parseInt(ele.id) === parseInt(record.product_status)
        //                             )[0].value
        //                         }
        //                     </Tag>
        //                 </div>
        //             </Row>
        //         )
        //     },
        // },
        {
            title: `${t('common:action')}`,
            dataIndex: 'action',
            key: 'action',
            render: (text, record) => {
                return (
                    <Link
                        to={{
                            pathname: `/dashboard/product-template/edit-product-template`,
                            search: `?${MarketplaceAppConfig.getStore(
                                ''
                            )}&ptsid=${record.value}&ptsrid=${record.revisionId}&ptss=${record.product_status}}`,
                        }}
                        className='font-semibold app-btn-link'>
                        {t('common:edit')}
                    </Link>
                )
            },
        },
    ]

    //! opening the add product template modal
    const openModal = () => {
        setIsModalOpen(true)
        setInvalidProductTemplateName(false)
        setInvalidProductTemplateDescription(false)
        setInValidSelectedStoreProductTypes(false)
    }

    //!closing the add product template modal
    const closeModal = () => {
        setIsModalOpen(false)
    }

    //! get call of product templates
    const findAllProductTemplates = (searchValue) => {
        let params = {}

        params['product-template-status'] = 'all'
        if (searchValue) {
            params['search'] = searchValue
            setIsSearchTriggered(true)
        }
        setIsLoadingListProductTemplates(true)
        MarketplaceServices.findAll(productTemplateActiveAPI, params, true)
            .then(function (response) {
                console.log(
                    'Server response from findAllProductTemplates API is',
                    productTemplateActiveAPI,
                    response.data.response_body
                )
                setProductCount(
                    response.data.response_body.active_product_templates +
                        response.data.response_body.active_product_templates
                )
                let data = getParentChildrenDataForProductParentTemplate(
                    response.data.response_body.product_template_data
                )
                setListProductTemplatesCopy(data[0].localProductTemplateData)
                setIsLoadingListProductTemplates(false)
            })
            .catch(function (error) {
                console.log(
                    'Error Server response from findAllProductTemplates API is',
                    productTemplateActiveAPI,
                    error.response
                )
                setIsLoadingListProductTemplates(false)
                setIsNetworkErrorListProductTemplates(true)
            })
    }

    const handleSearchChange = (searchValue) => {
        setSearchValue(searchValue)
        if (searchValue?.trim()) {
            findAllProductTemplates(searchValue)
        } else {
            if (isSearchTriggered) {
                findAllProductTemplates()
                setIsSearchTriggered(false)
            }
            // MarketplaceToaster.showToast(
            //   util.getToastObject(
            //     `${t("common:please_enter_something_in_search_bar")}`,
            //     "error"
            //   )
            // );
        }
    }
    const handleInputChange = (event) => {
        const trimmed = String(event.target.value)
        const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
        setSearchValue(trimmedUpdate)
        if (event.target.value == '') {
            if (isSearchTriggered) {
                setIsSearchTriggered(false)
                findAllProductTemplates()
            }
        }
    }

    //! here we are calling the findAllProductTemplate get call
    useEffect(() => {
        window.scrollTo(0, 0)
        if (permissionValue && permissionValue.length > 0 && permissionValue.includes('UI-list-product-template')) {
            setPermissions(true)
            findAllProductTemplates()
            dispatch(fnProductTemplateSelectedInfo())
            dispatch(fnMakeAPICall({ makeAPICall: false }))
            dispatch(fnAlreadyCreatedAttributeInfo())
            dispatch(fnAlreadyCreatedStaticAttributeInfo())
            dispatch(fnAttributeDetailsUpdated())
            dispatch(fnRevisionInfo());
            dispatch(fnRevisionSelectedInfo());
            dispatch(fnPageCompAction({ type: 'product', action: 'edit' }))
        }
    }, [permission])

    useEffect(() => {
        util.setStoreNameWhenWeChangeINUrl()
        setSearchValue('')
        setIsSearchTriggered(false)
    }, [])

    useEffect(() => {
        if (util.getSelectedLanguageDirection()) {
            setLangDirection(util.getSelectedLanguageDirection()?.toLowerCase())
        }
    }, [util.getSelectedLanguageDirection()])
    const customButton = (
        <Button type='primary' disabled={searchValue?.trim() === '' ? true : false} icon={<SearchOutlined />} />
    )

    return (
        <Content className='pt-1'>
            <Content>
                <Content>
                    <HeaderForTitle
                        title={
                            <Content className='flex justify-between items-center'>
                                <Content>
                                    <Title level={3} className='!font-semibold mt-1 !text-regal-blue !text-[24px]'>
                                        {t('common:product_templates')}
                                    </Title>
                                </Content>
                                <div>
                                    {permissionValue &&
                                    permissionValue.length > 0 &&
                                    permissionValue.includes('UI-create-edit-product-template') ? (
                                        <Button
                                            className='app-btn-primary !h-[32px] flex items-center'
                                            onClick={() => {
                                                openModal()
                                            }}>
                                            <PlusOutlined />
                                            {t('product_template:add_product_template')}
                                        </Button>
                                    ) : (
                                        ''
                                    )}
                                </div>
                                <StoreModal
                                    isVisible={isModalOpen}
                                    okButtonText={null}
                                    title={t('product_template:add_product_template')}
                                    cancelButtonText={null}
                                    cancelCallback={() => closeModal()}
                                    width={700}
                                    isSpin={false}
                                    hideCloseButton={false}>
                                    <AddProductTemplate setIsModalOpen={setIsModalOpen} action={'add'} />
                                </StoreModal>
                            </Content>
                        }
                        headerContent={
                            <Paragraph className='!font-normal text-[14px] text-brandGray1 !m-0 pb-2'>
                                {t('common:prod_temp_short_desc')}
                            </Paragraph>
                        }
                    />
                </Content>
            </Content>
            <Content className='p-3 !min-h-screen'>
                {!permissions ? (
                    <Content className='text-center bg-white p-3'>
                        <Text strong>{t('common:access_permission_required')}</Text>
                    </Content>
                ) : (
                    <div>
                        <Content className='p-3 bg-white rounded-lg shadow-brandShadow'>
                            <>
                                {isLoadingListProductTemplates ? (
                                    <div className='flex justify-end mt-3'>
                                        <Skeleton
                                            active
                                            title={false}
                                            paragraph={{ rows: 1, width: '100%' }}
                                            size='small'
                                            className='w-[30%] h-[30%]'
                                        />
                                    </div>
                                ) : !isNetworkErrorListProductTemplates ? (
                                    <Content className='!flex !justify-end mt-3'>
                                        <Search
                                            placeholder={t('common:please_enter_search_text_here')}
                                            onSearch={handleSearchChange}
                                            onChange={handleInputChange}
                                            // value={searchKey}
                                            value={searchValue}
                                            suffix={null}
                                            maxLength={searchMaxLength}
                                            enterButton={customButton}
                                            allowClear
                                            className='!justify-end !w-[30%]'
                                        />
                                    </Content>
                                ) : null}
                                <Content className='mt-3'>
                                    {isLoadingListProductTemplates ? (
                                        <Content className=' bg-white p-3'>
                                            <SkeletonComponent />
                                        </Content>
                                    ) : isNetworkErrorListProductTemplates ? (
                                        <Content className='text-center bg-white p-3'>
                                            <h1 level={5}>{t('common:network_error')}</h1>
                                        </Content>
                                    ) : listProductTemplatesCopy && listProductTemplatesCopy.length > 0 ? (
                                        <Content>
                                            <Table
                                                dataSource={listProductTemplatesCopy}
                                                columns={columns}
                                                scroll={{}}
                                                expandable={{
                                                    childrenColumnName: 'children',
                                                    expandIcon: ({ expanded, record, onExpand }) =>
                                                        record.children ? (
                                                            langDirection === 'rtl' ? (
                                                                <img
                                                                    src={DownArrow}
                                                                    className={`transition-transform transform !ml-5 cursor-pointer ${
                                                                        expanded ? 'rotate-270' : 'rotate-90'
                                                                    }`}
                                                                    alt='expandIcon'
                                                                    onClick={(e) => onExpand(record, e)}
                                                                />
                                                            ) : (
                                                                <img
                                                                    src={DownArrow}
                                                                    className={`transition-transform transform mr-5 cursor-pointer ${
                                                                        expanded ? 'rotate-270' : '-rotate-90'
                                                                    }`}
                                                                    alt='expandIcon'
                                                                    onClick={(e) => onExpand(record, e)}
                                                                />
                                                            )
                                                        ) : null,
                                                }}
                                                pagination={false}></Table>
                                        </Content>
                                    ) : listProductTemplatesCopy &&
                                      listProductTemplatesCopy.length === 0 &&
                                      isSearchTriggered ? (
                                        <Content className='text-center font-semibold ml-2 mt-3 bg-white p-3'>
                                            <Text>{t('common:not_able_to_find_searched_details')}</Text>
                                        </Content>
                                    ) : productCount && productCount === 0 ? (
                                        <Content className='grid justify-items-center !mt-[5px] bg-white p-3'>
                                            <Content className=''>
                                                <Text strong>
                                                    {' '}
                                                    {t('product_template:add_your_first_product_to_the_store')}
                                                </Text>
                                            </Content>
                                            <Content className='mb-4'>
                                                <Text strong>{t('product_template:can_be_anything_ranging')}</Text>
                                            </Content>
                                            <img
                                                className=''
                                                alt={t('product_template:product_image')}
                                                src={Prodimg}></img>
                                        </Content>
                                    ) : (
                                        <Content className='text-center  ml-2 mt-3 bg-white p-3'>
                                            <Empty description={t('common:no_data_available_to_display')} />
                                        </Content>
                                    )}
                                </Content>
                            </>
                        </Content>
                    </div>
                )}
                <StoreModal
                    isVisible={knowMoreInfoModal}
                    okButtonText={null}
                    title={t('common:information')}
                    cancelButtonText={null}
                    cancelCallback={() => setKnowMoreInfoModal(false)}
                    width={300}
                    isSpin={false}
                    hideCloseButton={true}>
                    <Content>{t('product_template:additional_details_available')}</Content>
                    {/* </Content> */}
                </StoreModal>
            </Content>
        </Content>
    )
}

export default ProductTemplates
