import { GlobalOutlined, MailOutlined, TranslationOutlined, UserOutlined } from '@ant-design/icons'
import { Button, DatePicker, Input, InputNumber, Row, TimePicker, Tooltip } from 'antd'
import dayjs from 'dayjs'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import validator from 'validator'
import DisplayImage from '../media/DisplayImage'
import TextRichEditor from '../../components/textRichEditor/TextRichEditor'
import { validatePercentage } from '../../util/validation'

const advancedUnitMaxValue = process.env.REACT_APP_ADVANCED_UNIT_MAX_LIMIT
const attributeOptionMaxCharacterLength = process.env.REACT_APP_ATTRIBUTE_OPTION_MAX_CHARACTER_LENGTH
const urlMaxLength = parseInt(process.env.REACT_APP_URL_MAX_LENGTH)
const emailMaxLength = parseInt(process.env.REACT_APP_EMAIL_MAX_LENGTH)
const titleMaxLength = parseInt(process.env.REACT_APP_TITLE_MAX_LENGTH)

function AttributeOptionControls({
    selectedDataType,
    productOptionValues,
    getAttributeUnitValue,
    setOptionValue,
    enableAndDisableDeleteButton,
    validatePositiveNumber,
    validatePositiveFloatNumber,
    invalidAttributeValue,
    setOpenImageModel,
    isExtensionRequired,
    isEditting,
    isCore,
    setImageData,
    convertUrlToObjectForFileUpload,
    attributeFileType,
    textRichEditorData,
    setTextRichEditorData,
    phoneCode,
    setIsPhoneCodeNotValid,
    setPhoneCode,
    isPhoneCodeNotValid,
    langDirection,
    handleOptionTranslaterModal,
    setOnChangeDisableFields,
    productOptions,
}) {
    const { t } = useTranslation()
    const storeLanguageFromReduxState = useSelector((state) => state.reducerStoreLanguage.storeLanguage)

    switch (selectedDataType) {
        // Integer Limit
        case 23:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          value={obj.option_name}
                                          status={obj.isInValidField ? 'error' : invalidAttributeValue ? 'error' : ''}
                                          onChange={(value) => {
                                              setOptionValue(value, obj.id)
                                          }}
                                          onKeyPress={(e) => {
                                              validatePositiveNumber(e, /[0-9]/)
                                          }}
                                          min={
                                              typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                  ? String(getAttributeUnitValue('min')[0].value[0])
                                                  : getAttributeUnitValue('min')[0].value[0]
                                          }
                                          //   max={
                                          //       typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                          //           ? String(getAttributeUnitValue('max')[0].value[0])
                                          //           : getAttributeUnitValue('max')[0].value[0]
                                          //   }
                                          max={999999999}
                                          maxLength={9}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Number-percentage
        case 21:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          defaultValue={obj.option_name}
                                          min={0}
                                          max={100}
                                          suffix={'%'}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onKeyPress={(e) => {
                                              validatePercentage(e, /^[0-9.]+$/, 2)
                                          }}
                                          onChange={(value) => {
                                              setOptionValue(`${value}`, obj.id)
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Number-positive
        case 22:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          defaultValue={obj.option_name}
                                          min={1}
                                          maxLength={9}
                                          max={999999999}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onChange={(value) => {
                                              setOptionValue(value, obj.id)
                                          }}
                                          onKeyPress={(e) => {
                                              validatePositiveNumber(e, /[0-9]/)
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Float-positive
        case 20:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          defaultValue={obj.option_name}
                                          min='0'
                                          onChange={(value) => {
                                              setOptionValue(value, obj.id)
                                          }}
                                          max={advancedUnitMaxValue}
                                          stringMode
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onKeyPress={(e) => {
                                              validatePositiveFloatNumber(e, /^[0-9.]+$/, advancedUnitMaxValue, 2)
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "float-limit"
        case 24:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          defaultValue={obj.option_name}
                                          min={
                                              typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                  ? String(getAttributeUnitValue('min')[0].value[0])
                                                  : getAttributeUnitValue('min')[0].value[0] || 0
                                          }
                                          max={
                                              typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                  ? String(getAttributeUnitValue('max')[0].value[0])
                                                  : getAttributeUnitValue('max')[0].value[0] || 999999999
                                          }
                                          onChange={(value) => {
                                              let formatValue = parseFloat(value).toFixed(2) || value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          onBlur={(e) => {
                                              let formatValue = parseFloat(e.target.value).toFixed(2) || e.target.value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          stringMode
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty
                                                  ? 'error'
                                                  : invalidAttributeValue
                                                    ? 'error'
                                                    : ''
                                          }
                                          onKeyPress={(e) => {
                                              validatePositiveFloatNumber(
                                                  e,
                                                  /^[0-9.]+$/,
                                                  typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('max')[0].value[0])
                                                      : getAttributeUnitValue('max')[0].value[0] || 999999999,
                                                  2
                                              )
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "float-roundoff-limit"
        case 26:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          defaultValue={obj.option_name}
                                          step={getAttributeUnitValue('decimal')[0]?.value == 1 ? '0.1' : '0.01'}
                                          precision={getAttributeUnitValue('decimal')[0]?.value}
                                          min={
                                              typeof getAttributeUnitValue('min')[0].value[0] === 'number'
                                                  ? String(getAttributeUnitValue('min')[0].value[0])
                                                  : getAttributeUnitValue('min')[0].value[0] || 0
                                          }
                                          max={
                                              typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                  ? String(getAttributeUnitValue('max')[0].value[0])
                                                  : getAttributeUnitValue('max')[0].value[0] || 999999999.99
                                          }
                                          onChange={(value) => {
                                              let formatValue =
                                                  parseFloat(value).toFixed(
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  ) || value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          onBlur={(e) => {
                                              let formatValue =
                                                  parseFloat(e.target.value).toFixed(
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  ) || e.target.value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          stringMode
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty
                                                  ? 'error'
                                                  : invalidAttributeValue
                                                    ? 'error'
                                                    : ''
                                          }
                                          onKeyPress={(e) => {
                                              validatePositiveFloatNumber(
                                                  e,
                                                  /^[0-9.]+$/,
                                                  typeof getAttributeUnitValue('max')[0].value[0] === 'number'
                                                      ? String(getAttributeUnitValue('max')[0].value[0])
                                                      : getAttributeUnitValue('max')[0].value[0] || 999999999.99,
                                                  getAttributeUnitValue('decimal')[0]?.value
                                              )
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "float-roundoff"
        case 25:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <InputNumber
                                          className='w-full'
                                          step={getAttributeUnitValue('decimal')[0]?.value == 1 ? '0.1' : '0.01'}
                                          defaultValue={obj.option_name}
                                          precision={getAttributeUnitValue('decimal')[0]?.value}
                                          onChange={(value) => {
                                              let formatValue =
                                                  parseFloat(value).toFixed(
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  ) || value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          onBlur={(e) => {
                                              let formatValue =
                                                  parseFloat(e.target.value).toFixed(
                                                      getAttributeUnitValue('decimal')[0]?.value
                                                  ) || e.target.value
                                              setOptionValue(formatValue, obj.id)
                                          }}
                                          max={999999999.99}
                                          stringMode
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onKeyPress={(e) => {
                                              validatePositiveFloatNumber(
                                                  e,
                                                  /^[0-9.]+$/,
                                                  999999999.99,
                                                  getAttributeUnitValue('decimal')[0]?.value
                                              )
                                          }}
                                          placeholder={t('attributes:attribute_value_placeholder')}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // File-image
        case 16:
            return (
                <>
                    <Button
                        type='dashed'
                        className='ant-btn-secondary'
                        danger={
                            productOptionValues &&
                            productOptionValues.attribute_options[0].isAttributeOptionValueFieldEmpty
                                ? true
                                : false
                        }
                        style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                        block
                        onClick={() => setOpenImageModel(true)}
                        disabled={isEditting && isCore ? true : isExtensionRequired ? true : false}>
                        {productOptionValues.attribute_options.length > 0 &&
                        productOptionValues.attribute_options[0].option_name !== ''
                            ? t('attributes:change_image')
                            : t('attributes:add_image')}
                    </Button>
                    {productOptionValues &&
                    productOptionValues.attribute_options.length > 0 &&
                    productOptionValues.attribute_options[0].option_name !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    isEditting &&
                                    typeof productOptionValues.attribute_options[0].option_name === 'string'
                                        ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options)
                                        : productOptionValues.attribute_options[0].option_name
                                }
                                setAllImages={setImageData}
                                fileType={'image'}
                                deleteFromServer={false}
                                size={'large'}
                                closeButton={false}></DisplayImage>
                        </Row>
                    ) : (
                        ''
                    )}
                </>
            )
        // File-doc
        case 18:
            return (
                <>
                    <Button
                        type='dashed'
                        danger={
                            productOptionValues &&
                            productOptionValues.attribute_options[0].isAttributeOptionValueFieldEmpty
                                ? true
                                : false
                        }
                        style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                        block
                        onClick={() => setOpenImageModel(true)}
                        disabled={isEditting && isCore ? true : isExtensionRequired ? true : false}>
                        {productOptionValues.attribute_options.length > 0 &&
                        productOptionValues.attribute_options[0].option_name !== ''
                            ? t('attributes:change_document')
                            : t('attributes:add_document')}
                    </Button>
                    {productOptionValues &&
                    productOptionValues.attribute_options.length > 0 &&
                    productOptionValues.attribute_options[0].option_name !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    isEditting &&
                                    typeof productOptionValues.attribute_options[0].option_name === 'string'
                                        ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options)
                                        : productOptionValues.attribute_options[0].option_name
                                }
                                setAllImages={setImageData}
                                fileType={attributeFileType}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        ''
                    )}
                </>
            )
        // File
        case 15:
            return (
                <>
                    <Button
                        type='dashed'
                        danger={
                            productOptionValues &&
                            productOptionValues.attribute_options[0].isAttributeOptionValueFieldEmpty
                                ? true
                                : false
                        }
                        style={{ width: '70%', marginBottom: '18px', fontWeight: 500 }}
                        block
                        onClick={() => setOpenImageModel(true)}
                        disabled={isEditting && isCore ? true : false}>
                        {productOptionValues.attribute_options.length > 0 &&
                        productOptionValues.attribute_options[0].option_name !== ''
                            ? t('attributes:change_file')
                            : t('attributes:add_file')}
                    </Button>
                    {productOptionValues &&
                    productOptionValues.attribute_options.length > 0 &&
                    productOptionValues.attribute_options[0].option_name !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    isEditting &&
                                    typeof productOptionValues.attribute_options[0].option_name === 'string'
                                        ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options)
                                        : productOptionValues.attribute_options[0].option_name
                                }
                                setAllImages={setImageData}
                                fileType={attributeFileType}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        ''
                    )}
                </>
            )
        // File-pdf
        case 19:
            return (
                <>
                    <Button
                        type='dashed'
                        danger={
                            productOptionValues &&
                            productOptionValues.attribute_options[0].isAttributeOptionValueFieldEmpty
                                ? true
                                : false
                        }
                        style={{ width: '100%', marginBottom: '18px', fontWeight: 500 }}
                        block
                        onClick={() => setOpenImageModel(true)}
                        disabled={isEditting && isCore ? true : isExtensionRequired ? true : false}>
                        {productOptionValues.attribute_options.length > 0 &&
                        productOptionValues.attribute_options[0].option_name !== ''
                            ? t('attributes:change_pdf')
                            : t('attributes:add_pdf')}
                    </Button>
                    {productOptionValues &&
                    productOptionValues.attribute_options.length > 0 &&
                    productOptionValues.attribute_options[0].option_name !== '' ? (
                        <Row>
                            <DisplayImage
                                ImageData={
                                    isEditting &&
                                    typeof productOptionValues.attribute_options[0].option_name === 'string'
                                        ? convertUrlToObjectForFileUpload(productOptionValues.attribute_options)
                                        : productOptionValues.attribute_options[0].option_name
                                }
                                setAllImages={setImageData}
                                fileType={attributeFileType}
                                deleteFromServer={false}
                                size={'large'}></DisplayImage>
                        </Row>
                    ) : (
                        ''
                    )}
                </>
            )
        // Text Rich
        case 10:
            return (
                <>
                    <TextRichEditor
                        data={textRichEditorData}
                        setData={setTextRichEditorData}
                        placeholder={t('attributes:enter_description')}
                        isTextFieldEmpty={
                            productOptionValues &&
                            productOptionValues.attribute_options[0].isAttributeOptionValueFieldEmpty
                        }
                        minHeight={'min-h-[200px]'}
                    />
                </>
            )

        // "Text-Email"
        case 11:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <Input
                                          prefix={<MailOutlined />}
                                          className='w-full'
                                          placeholder={t('attributes:enter_your_email_address')}
                                          value={obj.option_name}
                                          onBlur={(e) => {
                                              if (e.target.value) {
                                                  setOptionValue(e.target.value.trim().replace(/\s+/g, ' '), obj.id)
                                              }
                                          }}
                                          onChange={(e) => {
                                              if (
                                                  e.target.value.trim() !== '' &&
                                                  validator.isLength(e.target.value.trim(), {
                                                      min: 0,
                                                      max: emailMaxLength,
                                                  })
                                              ) {
                                                  setOptionValue(e.target.value, obj.id)
                                              } else if (e.target.value.trim() === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }></Input>
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "Text-Phonenumber"
        case 12:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}_${index}`}>
                                      <Input
                                          className='w-[11%] rounded-tr-none rounded-br-none'
                                          value={phoneCode}
                                          onChange={(e) => {
                                              setIsPhoneCodeNotValid(false)
                                              if (/^\+(\d{1}\-)?(\d{1,3})$/.test(e.target.value)) {
                                                  setPhoneCode(e.target.value)
                                              } else if (e.target.value === '+') {
                                                  setPhoneCode(e.target.value)
                                              }
                                          }}
                                          status={isPhoneCodeNotValid ? 'error' : ''}></Input>
                                      <Input
                                          placeholder={t('attributes:enter_phone_number')}
                                          className='w-full rounded-tl-none rounded-bl-none'
                                          value={obj.option_name}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onChange={(e) => {
                                              // this below pattern will also allow you to enter +91 country code, will entering phone number ie +9101234567897,
                                              // const phoneNumberRegPattern = /^([+]\d{2})?\d{10}$/
                                              let number = /^[0-9]*$/.test(e.target.value)
                                              // to allow only 10 digits
                                              if (number && e.target.value.length <= 10) {
                                                  setOptionValue(e.target.value, obj.id)
                                              } else if (e.target.value === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Text-Name
        case 27:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <Input
                                          className='w-full'
                                          placeholder={t('attributes:enter_your_name')}
                                          prefix={<UserOutlined />}
                                          value={obj.option_name}
                                          onBlur={(e) => {
                                              if (e.target.value) {
                                                  setOptionValue(e.target.value.trim().replace(/\s+/g, ' '), obj.id)
                                              }
                                          }}
                                          onChange={(e) => {
                                              const alphaWithoutSpaces = /^[a-zA-Z]+$/
                                              if (
                                                  e.target.value.trim() !== '' &&
                                                  validator.isLength(e.target.value.trim(), {
                                                      min: 0,
                                                      max: 250,
                                                  })
                                              ) {
                                                  if (
                                                      e.target.value !== '' &&
                                                      validator.matches(e.target.value, alphaWithoutSpaces)
                                                  ) {
                                                      setOptionValue(e.target.value, obj.id)
                                                  }
                                              } else if (e.target.value === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }></Input>
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "Text"
        case 30:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <Input
                                          className='w-full'
                                          placeholder={t('attributes:enter_your_text')}
                                          value={obj.option_name}
                                          onBlur={(e) => {
                                              if (e.target.value) {
                                                  setOptionValue(e.target.value.trim().replace(/\s+/g, ' '), obj.id)
                                              }
                                          }}
                                          onChange={(e) => {
                                              if (
                                                  e.target.value.trim() !== '' &&
                                                  validator.isLength(e.target.value.trim(), {
                                                      min: 0,
                                                      max: titleMaxLength,
                                                  })
                                              ) {
                                                  setOptionValue(e.target.value, obj.id)
                                              } else if (e.target.value.trim() === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }></Input>
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // "Text-url"
        case 13:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <Input
                                          prefix={<GlobalOutlined />}
                                          className='w-full'
                                          placeholder={t('attributes:enter_url_here')}
                                          value={obj.option_name}
                                          onBlur={(e) => {
                                              if (e.target.value) {
                                                  setOptionValue(e.target.value.trim().replace(/\s+/g, ' '), obj.id)
                                              }
                                          }}
                                          onChange={(e) => {
                                              if (
                                                  e.target.value.trim() !== '' &&
                                                  validator.isLength(e.target.value.trim(), {
                                                      min: 0,
                                                      max: urlMaxLength,
                                                  })
                                              ) {
                                                  setOptionValue(e.target.value, obj.id)
                                              } else if (e.target.value.trim() === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }></Input>
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Time
        case 9:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <TimePicker
                                          format={getAttributeUnitValue('format')[0].value[0]}
                                          value={
                                              obj.option_name !== ''
                                                  ? dayjs(obj.option_name, getAttributeUnitValue('format')[0].value[0])
                                                  : null
                                          }
                                          onChange={(time, timeString) => {
                                              console.log('time bnhbadjbds', time, timeString)
                                              setOptionValue(timeString, obj.id)
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          className='w-full'
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // Date
        case 5:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <DatePicker
                                          inputReadOnly={true}
                                          size={'small'}
                                          use12Hours
                                          defaultValue={
                                              obj.option_name !== '' ? dayjs(obj.option_name, 'YYYY-MM-DD') : null
                                          }
                                          format={
                                              getAttributeUnitValue('format')[0].value[0] != ''
                                                  ? getAttributeUnitValue('format')[0].value[0]
                                                  : 'YYYY-MM-DD'
                                          }
                                          onChange={(value, timestring) => {
                                              setOptionValue(timestring, obj.id)
                                          }}
                                          className='w-full ant-datepicker-small'
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
        // DateTime
        case 14:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div
                                      style={{
                                          display: 'flex',
                                          alignItems: 'center',
                                          marginBottom: '14px',
                                      }}
                                      key={`${obj.id}`}>
                                      <DatePicker
                                          inputReadOnly={true}
                                          placeholder={t('attributes:placeholder_date_time')}
                                          size={'small'}
                                          format={
                                              getAttributeUnitValue('format')[0].value[0] !== ''
                                                  ? getAttributeUnitValue('format')[0].value[0]
                                                  : 'YYYY-MM-DD HH:mm:ss'
                                          }
                                          value={
                                              obj.option_name !== ''
                                                  ? dayjs(
                                                        obj.option_name,
                                                        getAttributeUnitValue('format')[0].value[0] !== ''
                                                            ? getAttributeUnitValue('format')[0].value[0]
                                                            : 'YYYY-MM-DD HH:mm:ss'
                                                    )
                                                  : null
                                          }
                                          showTime
                                          className='w-full ant-datepicker-small'
                                          onChange={(value, timestring) => {
                                              console.log('darsvadbrtne', timestring)
                                              setOptionValue(timestring, obj.id)
                                          }}
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )

        default:
            return (
                <>
                    {productOptionValues
                        ? productOptionValues.attribute_options.length > 0 &&
                          productOptionValues.attribute_options.map((obj, index) => {
                              return (
                                  <div className='flex items-center mb-[14px]' key={`${obj.id}`}>
                                      <Input
                                          placeholder={t('attributes:enter_attribute_values')}
                                          addonBefore={
                                              productOptions.attributeValuePrefix &&
                                              getAttributeUnitValue('prefix_displayname')[0]?.value != '' ? (
                                                  <div className='whitespace-nowrap px-2 font-bold'>
                                                      {getAttributeUnitValue('prefix_displayname')[0]?.value ? (
                                                          getAttributeUnitValue('prefix_displayname')[0].value[0]
                                                              .length > 4 ? (
                                                              <>
                                                                  {
                                                                      <span>
                                                                          {getAttributeUnitValue(
                                                                              'prefix_displayname'
                                                                          )[0].value[0].slice(0, 4)}
                                                                          <Tooltip
                                                                              title={
                                                                                  getAttributeUnitValue(
                                                                                      'prefix_displayname'
                                                                                  )[0]?.value
                                                                              }
                                                                              placement={`${
                                                                                  langDirection == 'rtl'
                                                                                      ? 'left'
                                                                                      : 'right'
                                                                              }`}>
                                                                              ...
                                                                          </Tooltip>
                                                                      </span>
                                                                  }
                                                              </>
                                                          ) : (
                                                              getAttributeUnitValue('prefix_displayname')[0].value
                                                          )
                                                      ) : (
                                                          ''
                                                      )}
                                                  </div>
                                              ) : (
                                                  ''
                                              )
                                          }
                                          className='w-full'
                                          value={
                                              isEditting && typeof obj.id != 'string'
                                                  ? obj.option_displayname
                                                  : obj.option_name
                                          }
                                          status={
                                              obj.isInValidField || obj.isAttributeOptionValueFieldEmpty ? 'error' : ''
                                          }
                                          onBlur={(e) => {
                                              if (e.target.value) {
                                                  setOptionValue(e.target.value.trim().replace(/\s+/g, ' '), obj.id)
                                              }
                                          }}
                                          onChange={(e) => {
                                              if (
                                                  e.target.value.trim() !== '' &&
                                                  validator.isLength(e.target.value.trim(), {
                                                      min: 0,
                                                      max: titleMaxLength,
                                                  })
                                              ) {
                                                  setOptionValue(e.target.value, obj.id)
                                              } else if (e.target.value.trim() === '') {
                                                  setOptionValue(e.target.value, obj.id)
                                              }
                                          }}
                                          addonAfter={
                                              (storeLanguageFromReduxState &&
                                                  storeLanguageFromReduxState.length > 0 &&
                                                  isEditting &&
                                                  typeof obj.id != 'string') ||
                                              (productOptions.attributeValueSuffix &&
                                                  getAttributeUnitValue('suffix_displayname')[0]?.value != '') ? (
                                                  <div className='flex items-center'>
                                                      {storeLanguageFromReduxState &&
                                                      storeLanguageFromReduxState.length > 0 &&
                                                      isEditting &&
                                                      typeof obj.id != 'string' ? (
                                                          <TranslationOutlined
                                                              onClick={() => {
                                                                  handleOptionTranslaterModal(obj.id)
                                                                  setOnChangeDisableFields(true)
                                                              }}
                                                              className='iconsHoverClass'></TranslationOutlined>
                                                      ) : (
                                                          ''
                                                      )}
                                                      {/* Suffix label */}
                                                      {productOptions.attributeValueSuffix &&
                                                      getAttributeUnitValue('suffix_displayname')[0]?.value != '' ? (
                                                          <div className='whitespace-nowrap text-center font-bold ml-2'>
                                                              {getAttributeUnitValue('suffix_displayname')[0]?.value ? (
                                                                  getAttributeUnitValue('suffix_displayname')[0]
                                                                      .value[0].length > 4 ? (
                                                                      <>
                                                                          {
                                                                              <span>
                                                                                  {getAttributeUnitValue(
                                                                                      'suffix_displayname'
                                                                                  )[0].value[0].slice(0, 4)}
                                                                                  <Tooltip
                                                                                      title={
                                                                                          getAttributeUnitValue(
                                                                                              'suffix_displayname'
                                                                                          )[0]?.value
                                                                                      }
                                                                                      placement={`${
                                                                                          langDirection == 'rtl'
                                                                                              ? 'left'
                                                                                              : 'right'
                                                                                      }`}>
                                                                                      ...
                                                                                  </Tooltip>
                                                                              </span>
                                                                          }
                                                                      </>
                                                                  ) : (
                                                                      getAttributeUnitValue('suffix_displayname')[0]
                                                                          .value
                                                                  )
                                                              ) : (
                                                                  ''
                                                              )}
                                                          </div>
                                                      ) : null}
                                                  </div>
                                              ) : (
                                                  ''
                                              )
                                          }
                                          disabled={isEditting && (isCore || typeof obj.id !== 'string') ? true : false}
                                          maxLength={attributeOptionMaxCharacterLength}
                                      />
                                      {enableAndDisableDeleteButton(
                                          productOptionValues.attribute_options.length,
                                          obj.id,
                                          obj.option_name
                                      )}
                                  </div>
                              )
                          })
                        : ''}
                </>
            )
    }
}

export default AttributeOptionControls
