import { Button, Layout, Skeleton, Tabs, Typography } from 'antd'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import util from '../../../util/common'
import { USER_TYPE } from '../constants'
import useGetStoreUserConsent from '../hooks/useGetStoreUserConsent'

import { toast } from 'react-toastify'
import StoreModal from '../../../components/storeModal/StoreModal'
import { usePageTitle } from '../../../hooks/usePageTitle'
import MarketplaceToaster from '../../../util/marketplaceToaster'
import useCreateNewUserConsentVersion from '../hooks/useCreateNewUserConsentVersion'
import useGetUserConsentVersionDetails from '../hooks/useGetUserConsentVersionDetails'
import '../policies.css'
import VersionCreation from './VersionCreation'
import { NoDataImage } from '../../../constants/media'

const { Title, Text } = Typography
const { Content } = Layout
const { TabPane } = Tabs

const portalInfo = JSON.parse(process.env.REACT_APP_PORTAL_INFO)
function PolicyHistory() {
    const { t } = useTranslation()
    usePageTitle(`${t('common:store_management_portal')} - ${t('common:policies_settings')}`)
    const [selectVersion, setSelectVersion] = useState(false)
    const [selectedTab, setSelectedTab] = useState(USER_TYPE.CUSTOMER)
    const { data: userConsents, status: userConsentStatus } = useGetStoreUserConsent({
        userType: selectedTab,
    })
    const [consent, setConsent] = useState(userConsents?.store_userconsent_data?.[0]?.version_details?.[0])
    const [activeTabPolicy, setActiveTabPolicy] = useState(userConsents?.store_userconsent_data?.[0]?.id)
    const {
        data: versionHistoryData,
        status: versionHistoryDataStatus,
        refetch: refetchVersionHistoryData,
    } = useGetUserConsentVersionDetails({ storeUserConsentId: activeTabPolicy })
    const [selectedVersionIndex, setSelectedVersionIndex] = useState(
        versionHistoryData?.store_userconsent_version_details?.[0]?.id
    )
    const [versionHistoryFilteredDescription, setVersionHistoryFilteredDescription] = useState(
        versionHistoryData?.store_userconsent_version_details.find(
            (element) => parseInt(element.id) === parseInt(selectedVersionIndex)
        )?.consent_display_discription
    )
    const { mutate: createNewUserConsentVersion } = useCreateNewUserConsentVersion()
    const [integerCount, setIntegerCount] = useState(0)
    const [floatCount, setFloatCount] = useState(0)
    const [newVersionCreated, setNewVersionCreated] = useState(false)
    useEffect(() => {
        setActiveTabPolicy(userConsents?.store_userconsent_data?.[0]?.id)
        setNewVersionCreated(false)
    }, [selectedTab, userConsents])
    useEffect(() => {
        let consentMainObject = userConsents?.store_userconsent_data?.find(
            (element) => parseInt(element.id) === parseInt(activeTabPolicy)
        )
        setConsent(consentMainObject?.version_details?.[0])

        setNewVersionCreated(false)
    }, [activeTabPolicy, userConsents])
    useEffect(() => {
        if (!newVersionCreated) {
            setSelectedVersionIndex(versionHistoryData?.store_userconsent_version_details?.[0]?.id)
        } else {
            let versionsLength = versionHistoryData?.store_userconsent_version_details.length
            setSelectedVersionIndex(versionHistoryData?.store_userconsent_version_details?.[versionsLength - 1]?.id)
        }
    }, [versionHistoryData, newVersionCreated])
    useEffect(() => {
        setVersionHistoryFilteredDescription(
            versionHistoryData?.store_userconsent_version_details.find(
                (element) => parseInt(element.id) === parseInt(selectedVersionIndex)
            )?.consent_display_discription
        )
    }, [selectedVersionIndex, versionHistoryData])
    const policiesTabData = [
        {
            key: USER_TYPE.CUSTOMER,
            label: `${t('common:customers')}`,
            value: 0,
        },
        {
            key: USER_TYPE.VENDOR,
            label: (
                <span className={`${util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'mr-4' : ''}`}>
                    {t('common:vendors')}
                </span>
            ),
            value: 1,
        },
    ]
    const getDate = (date) => {
        try {
            const formattedDate = moment(date).format('D MMM YYYY')
            return formattedDate
        } catch (error) {
            return ''
        }
    }
    const createNewVersionFields = (policy) => {
        if (integerCount === '' || floatCount === '') {
            MarketplaceToaster.showToast(util.getToastObject(`${t('policies:fill_version_number')}`, 'error'))
            return
        }
        const currentRevisionNum = policy?.version_number
        const updatedRevisionNum = parseFloat(integerCount + '.' + floatCount)
        const createVersionBody = {
            version_number: updatedRevisionNum,
            store_user_consent: policy?.store_user_consent_id,
        }
        if (updatedRevisionNum <= currentRevisionNum) {
            MarketplaceToaster.showToast(
                util.getToastObject(`${t('policies:invalid_revision_num_message')} ${currentRevisionNum}`, 'error')
            )
            return
        } else {
            createNewUserConsentVersion(
                { body: createVersionBody },
                {
                    onSuccess: (response) => {
                        refetchVersionHistoryData()
                        setNewVersionCreated(true)
                        toast(response?.response_body?.message || t('policies:version_created_successfully'), {
                            type: 'success',
                        })
                    },
                    onError: (err) => {
                        toast(err?.response?.data?.response_message || t('policies:error_creating_version'), {
                            type: 'error',
                        })
                    },
                }
            )
        }
        setSelectVersion(false)
        return true
    }
    return (
        <div>
            <Content>
                <div className='border-b border-gray-200 py-3 px-4 mb-3'>
                    <Title level={5} className='mb-0'>
                        {t('policies:policy_history')}
                    </Title>
                </div>

                <div>
                    <Tabs
                        className='px-4'
                        activeKey={selectedTab}
                        onChange={setSelectedTab}
                        tabBarStyle={{ marginBottom: '1rem' }}>
                        {policiesTabData?.map((userType, index) => (
                            <TabPane tab={userType.label} key={userType.key}></TabPane>
                        ))}
                    </Tabs>
                    <Skeleton loading={userConsentStatus === 'pending'} active className='p-3' />
                    {userConsentStatus === 'success' && (
                        <div className='flex pb-3'>
                            <div className=' mt-3 ml-3'>
                                <Skeleton loading={versionHistoryDataStatus === 'pending'} active className='p-3' />
                            </div>
                            {versionHistoryDataStatus === 'success' && (
                                <>
                                    {userConsents?.store_userconsent_data?.length == 0 ? (
                                        <Content className='text-center p-6'>
                                            <img src={NoDataImage} />
                                            <p className='pt-2 text-[#00000040] !font-bold !text-base'>
                                                {t('common:looks_like_there_nothing_here_yet')}
                                            </p>
                                        </Content>
                                    ) : (
                                        <>
                                            <div className='min-w-[230px] max-w-[200px]  border-r border-[#ccc] pl-3'>
                                                {userConsents?.store_userconsent_data?.map(
                                                    (policy, idx) =>
                                                        policy?.user_type === selectedTab && (
                                                            <div
                                                                key={idx}
                                                                className={`cursor-pointer px-2 py-3 ${activeTabPolicy === policy?.id ? 'border-r-4 border-brandPrimaryColor' : ''}`}
                                                                onClick={() => setActiveTabPolicy(policy?.id)}>
                                                                <Title level={5} className=' mb-0 truncate'>
                                                                    {policy?.version_details?.[0]?.consent_name}
                                                                </Title>
                                                            </div>
                                                        )
                                                )}
                                            </div>

                                            <div className='flex-grow ml-4 py-2 px-4'>
                                                {userConsents?.store_userconsent_data?.map((policy, idx) => (
                                                    <div
                                                        key={idx}
                                                        className={`min-w-full ${parseInt(activeTabPolicy) === parseInt(policy?.id) ? '' : 'hidden'}`}>
                                                        <select
                                                            value={selectedVersionIndex}
                                                            onChange={(e) => setSelectedVersionIndex(e.target.value)}
                                                            className='mb-2 font-bold'>
                                                            {versionHistoryData?.store_userconsent_version_details.map(
                                                                (version, versionIdx) => (
                                                                    <option key={versionIdx} value={version?.id}>
                                                                        {'V'}
                                                                        {version.version_number === 1
                                                                            ? '1.0'
                                                                            : version.version_number}
                                                                    </option>
                                                                )
                                                            )}
                                                        </select>
                                                        <div
                                                            className='border border-[#ccc] text-justify mt-3 mb-1 !h-[400px] overflow-y-auto custom-scrollbar !max-w-[700px] !min-w-[700px]'
                                                            style={{
                                                                padding: '5px 12px 5px 12px',
                                                                borderRadius: '6px',
                                                            }}>
                                                            {versionHistoryFilteredDescription && (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html: versionHistoryFilteredDescription,
                                                                    }}></div>
                                                            )}
                                                        </div>
                                                        <Text className='text-[#000000] text-opacity-50'>
                                                            Last updated:{' '}
                                                            <span className='text-[#000000]'>
                                                                {getDate(
                                                                    versionHistoryData
                                                                        ?.store_userconsent_version_details[
                                                                        selectedVersionIndex
                                                                    ]?.updated_on
                                                                )}
                                                            </span>
                                                        </Text>
                                                        <div className='flex justify-end mt-3'>
                                                            <Button
                                                                className='app-btn-primary'
                                                                onClick={() => setSelectVersion(true)}>
                                                                {t('policies:create_new_version')}
                                                            </Button>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </>
                                    )}
                                </>
                            )}
                            {versionHistoryDataStatus === 'error' && (
                                <div className=' text-center mt-5'>
                                    <Text>{t('common:network_error')}</Text>
                                </div>
                            )}
                        </div>
                    )}
                    {userConsentStatus === 'error' && (
                        <div className=' text-center mt-5'>
                            <Text>{t('common:network_error')}</Text>
                        </div>
                    )}
                </div>
            </Content>
            {/* Version selection popup code goes here */}
            {consent && selectVersion && (
                <StoreModal
                    isVisible={selectVersion}
                    title={t('policies:add_version')}
                    okCallback={() => createNewVersionFields(consent)}
                    cancelCallback={() => setSelectVersion(false)}
                    okButtonText={t('policies:add_version')}
                    isSpin={false}
                    width={450}>
                    <Content>
                        <VersionCreation
                            consent={consent}
                            integerCount={integerCount}
                            setIntegerCount={setIntegerCount}
                            floatCount={floatCount}
                            setFloatCount={setFloatCount}
                        />
                    </Content>
                </StoreModal>
            )}
        </div>
    )
}

export default PolicyHistory
