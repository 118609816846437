import { Alert, Button, Col, Drawer, Empty, Input, Layout, Radio, Select, Spin, Tooltip, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { MdInfo } from 'react-icons/md'
import { useSearchParams } from 'react-router-dom'
/**
 *! Components are imported Here.
 */
import { MdEdit } from 'react-icons/md'
import SkeletonComponent from '../../components/Skeleton/SkeletonComponent'
import DmPagination from '../../components/dmpagination/DmPagination'
import StoreModal from '../../components/storeModal/StoreModal'
import MarketplaceServices from '../../services/axios/MarketplaceServices'
import util from '../../util/common'
import MarketplaceToaster from '../../util/marketplaceToaster'
import DynamicTable from '../tablelayoutcomponent/DynamicTable'

//! ant design initialization
const { Text } = Typography
const { Content } = Layout

//! Global variables
const pageLimit = parseInt(process.env.REACT_APP_ITEM_PER_PAGE)
const paymentTypeMaxLength = process.env.REACT_APP_PAYMENT_TYPE_MAX_LENGTH
const storePaymentTypeAPI = process.env.REACT_APP_STORE_PAYMENT_TYPE_API
const storePaymentMethodAPI = process.env.REACT_APP_STORE_PAYMENT_METHOD_API
const PaymentMethod = () => {
    const { t } = useTranslation()
    const [searchParams, setSearchParams] = useSearchParams()
    const [isLoading, setIsLoading] = useState(true)
    const [isNetworkError, setIsNetworkError] = useState(false)
    const [selectedTabTableContent, setSelectedTabTableContent] = useState([])
    const [paymentTypeListData, setPaymentTypeListData] = useState([])
    const [paymentMethodListData, setPaymentMethodListData] = useState([])
    const [paymentTypeDrawerOpen, setPaymentTypeDrawerOpen] = useState(false)
    const [paymentTypeDrawerAction, setPaymentTypeDrawerAction] = useState()
    const [isUpLoading, setIsUpLoading] = useState(false)

    const [paymentGateway, setPaymentGateway] = useState('1')
    const [stripePublicKey, setStripePublicKey] = useState('')
    const [stripeSecretKey, setStripeSecretKey] = useState('')
    const [razerKeyId, setRazerKeyId] = useState('')
    const [razerKeySecret, setRazerKeySecret] = useState('')

    const [editPaymentGateway, setEditPaymentGateway] = useState('1')
    const [editStripePublicKey, setEditStripePublicKey] = useState('')
    const [editStripeSecretKey, setEditStripeSecretKey] = useState('')
    const [editRazerKeyId, setEditRazerKeyId] = useState('')
    const [editCashFreeKeyId, setEditCashFreeKeyId] = useState('')
    const [editRazerKeySecret, setEditRazerKeySecret] = useState('')
    const [editPaymentType, setEditPaymentType] = useState('')

    const [defaultEditPaymentGateway, setDefaultEditPaymentGateway] = useState('')
    const [defaultEditStripePublicKey, setDefaultEditStripePublicKey] = useState('')
    const [defaultEditStripeSecretKey, setDefaultEditStripeSecretKey] = useState('')
    const [defaultEditRazerKeyId, setDefaultEditRazerKeyId] = useState('')
    const [defaultEditRazerKeySecret, setDefaultEditRazerKeySecret] = useState('')

    const [storePaymentType, setStorePaymentType] = useState('Select store payment type')
    const [inValidStripePublicKey, setInValidStripePublicKey] = useState(false)
    const [inValidStripeSecretKey, setInValidStripeSecretKey] = useState(false)
    const [inValidRazerKeyId, setInValidRazerKeyId] = useState(false)
    const [inValidRazerKeySecret, setInValidRazerKeySecret] = useState(false)
    const [inValidStorePaymentType, setInValidStorePaymentType] = useState(false)

    const [fieldChangeValues, setFieldChangeValues] = useState(false)
    const [inValidEditStripePublicKey, setInValidEditStripePublicKey] = useState(false)
    const [inValidEditStripeSecretKey, setInValidEditStripeSecretKey] = useState(false)
    const [inValidEditRazerKeyId, setInValidEditRazerKeyId] = useState(false)
    const [inValidEditRazerKeySecret, setInValidEditRazerKeySecret] = useState(false)
    const [paymentTypeId, setPaymentTypeId] = useState()
    const [isDeletePaymentTypeModalOpen, setIsDeletePaymentTypeModalOpen] = useState(false)
    const [isDeleteModalLoading, setIsDeleteModalLoading] = useState(false)
    const [paymentMethodCount, setPaymentMethodCount] = useState()
    const [stripePublicKeyPasswordVisible, setStripePublicKeyPasswordVisible] = useState(false)
    const [stripeSecretKeyPasswordVisible, setStripeSecretKeyPasswordVisible] = useState(false)
    const [razorPayPublicKeyPasswordVisible, setRazorPayPublicKeyPasswordVisible] = useState(false)
    const [razorPaySecretKeyPasswordVisible, setRazorPaySecretKeyPasswordVisible] = useState(false)
    const [editPaymentMethodRazorPayId, setEditPaymentMethodRazorPayId] = useState('')
    const [editPaymentMethodRazorPaySecretId, setEditPaymentMethodRazorPaySecretId] = useState('')
    const [editPaymentMethodStripePublicKey, setEditPaymentMethodStripePublicKey] = useState('')
    const [editPaymentMethodStripeSecretKey, setEditPaymentMethodStripeSecretKey] = useState('')
    const [cashFreeKeyPasswordVisible, setCashFreeKeyPasswordVisible] = useState(false)
    const [inValidEditCashFreeId, setInValidEditCashFreeId] = useState(false)
    const [cashFreeSecretKeyPasswordVisible, setCashFreeSecretKeyPasswordVisible] = useState(false)
    const [editCashFreeSecret, setEditCashFreeSecret] = useState('')
    const [inValidEditCashFreeSecret, setInValidEditCashFreeSecret] = useState(false)
    const [editPaymentMethodCashFreeId, setEditPaymentMethodCashFreeId] = useState('')
    const [editPaymentMethodCashFreeSecret, setEditPaymentMethodCashFreeSecretKey] = useState('')
    const getPaymentTypeName = (paymentTypeId) => {
        const filteredPaymentTypeData =
            paymentTypeListData &&
            paymentTypeListData.length > 0 &&
            paymentTypeListData.filter((element) => parseInt(element.id) === parseInt(paymentTypeId))
        let paymentType = ''
        if (filteredPaymentTypeData.length > 0) {
            paymentType = filteredPaymentTypeData[0].display_name
        }
        return paymentType
    }

    const columns = [
        {
            title: `${t('payment:payment_gateway_name')}`,
            dataIndex: 'payment_gateway_name',
            key: 'payment_gateway_name',
            width: '20%',
            ellipsis: true,
            render: (text, record) => {
                return (
                    <Content className='inline-block'>
                        <Tooltip overlayStyle={{ zIndex: 1 }} title={record.payment_gateway_name} placement='bottom'>
                            <Text className='!max-w-[290px]' ellipsis={true}>
                                {record.payment_gateway_name}
                            </Text>
                        </Tooltip>
                    </Content>
                )
            },
        },
        {
            title: `${t('payment:secret_key')}`,
            dataIndex: 'stripe_secret_key',
            key: 'stripe_secret_key',
            width: '20%',
            render: (text, record) => {
                return (
                    <>
                        <Input.Password
                            disabled
                            value={
                                record.razor_key_secret !== undefined
                                    ? record.razor_key_secret
                                    : record.stripe_secret_key !== undefined
                                      ? record.stripe_secret_key
                                      : record.cashfree_client_secret
                            }
                            iconRender={() => null}
                        />
                    </>
                )
            },
        },
        {
            title: `${t('payment:public_key')}`,
            dataIndex: 'stripe_public_key',
            key: 'stripe_public_key',
            width: '20%',
            render: (text, record) => {
                return (
                    <>
                        <Input.Password
                            disabled
                            value={
                                record.razor_key_id !== undefined
                                    ? record.razor_key_id
                                    : record.stripe_public_key !== undefined
                                      ? record.stripe_public_key
                                      : record.cashfree_client_id
                            }
                            iconRender={() => null}
                        />
                    </>
                )
            },
        },
        {
            title: `${t('payment:payment_type_name')}`,
            dataIndex: 'store_payment_type_id',
            key: 'store_payment_type_id',
            width: '15%',
            render: (text, record) => {
                return (
                    <>
                        <Tooltip
                            overlayStyle={{ zIndex: 1 }}
                            title={getPaymentTypeName(record.store_payment_type_id)}
                            placement={util.getSelectedLanguageDirection()?.toUpperCase() === 'RTL' ? 'left' : 'right'}>
                            <Text className='!max-w-[180px]' ellipsis={true}>
                                {getPaymentTypeName(record.store_payment_type_id)}
                            </Text>
                        </Tooltip>
                    </>
                )
            },
        },
        {
            title: `${t('payment:action')}`,
            dataIndex: '',
            key: '',
            width: '8%',
            render: (text, record) => {
                return (
                    <Col className='whitespace-nowrap !text-center flex gap-3'>
                        <Tooltip
                            overlayStyle={{ zIndex: 1 }}
                            title={String(`${t('common:edit')}`) + ' ' + String(`${record.payment_gateway_name}`)}>
                            <Text
                                type='text'
                                className='app-btn-link text-sm'
                                onClick={() => {
                                    showEditPaymentMethodDrawer(record.id, record.store_payment_type_id)
                                }}>
                                {t('common:edit')}
                            </Text>
                        </Tooltip>
                    </Col>
                )
            },
        },
    ]

    //!dynamic table data
    const paymentPropsData = {
        table_header: columns,
        table_content: selectedTabTableContent,
        search_settings: {
            is_enabled: false,
            search_title: 'Search by payment',
            search_data: ['payment'],
        },
        filter_settings: {
            is_enabled: false,
            filter_title: 'filter by',
            filter_data: [],
        },
        sorting_settings: {
            is_enabled: false,
            sorting_title: 'Sorting by',
            sorting_data: [],
        },
    }

    //!storeData to get the table data
    const tablePaymentMethodData = (filteredData) => {
        const tempArray = []
        filteredData &&
            filteredData.length > 0 &&
            filteredData.forEach((element, index) => {
                var id = element.id
                var storePaymentTypeId = element.store_payment_type_id
                var paymentGateWay = element.payment_gateway
                var paymentGateWayName = element.payment_gateway_name
                var stripePublicKey = element && element.payment_config && element.payment_config.stripe_public_key
                var stripeSecretKey = element && element.payment_config && element.payment_config.stripe_secret_key
                var razerKeyID = element && element.payment_config && element.payment_config.razor_key_id
                var razorSecretKey = element && element.payment_config && element.payment_config.razor_key_secret
                var cashFreeClientId = element?.payment_config?.cashfree_client_id
                var cashFreeClientSecret = element?.payment_config?.cashfree_client_secret
                tempArray &&
                    tempArray.push({
                        key: index,
                        payment_gateway_name: paymentGateWayName,
                        id: id,
                        store_payment_type_id: storePaymentTypeId,
                        payment_gateway: paymentGateWay,
                        stripe_public_key: stripePublicKey,
                        stripe_secret_key: stripeSecretKey,
                        razor_key_id: razerKeyID,
                        razor_key_secret: razorSecretKey,
                        cashfree_client_id: cashFreeClientId,
                        cashfree_client_secret: cashFreeClientSecret,
                    })
            })
        setSelectedTabTableContent(tempArray)
    }

    //!this useEffect for tab(initial rendering)
    useEffect(() => {
        if (paymentMethodListData && paymentMethodListData.length > 0 && !isLoading) {
            setIsLoading(false)
            tablePaymentMethodData(paymentMethodListData)
        }
    }, [paymentMethodListData])

    //!get call of store payment method
    const findByPagePaymentMethodData = (page, limit) => {
        // enabling spinner
        setIsLoading(true)
        setIsNetworkError(false)
        MarketplaceServices.findAll(storePaymentMethodAPI, null, page, limit, false)
            .then(function (response) {
                setIsNetworkError(false)
                setIsLoading(false)
                console.log(
                    'server Success response from findByPagePaymentMethodData is',
                    storePaymentMethodAPI,
                    response.data.response_body.store_payment_data
                )
                setPaymentMethodCount(response.data.response_body.count)
                setPaymentMethodListData(response.data.response_body.store_payment_data)
                let paymentConfig = response && response.data.response_body.store_payment_data[0].payment_config
                if (paymentConfig) {
                    setEditPaymentMethodRazorPayId(paymentConfig.razor_key_id)
                    setEditPaymentMethodRazorPaySecretId(paymentConfig.razor_key_secret)
                    setEditPaymentMethodStripePublicKey(paymentConfig.stripe_public_key)
                    setEditPaymentMethodStripeSecretKey(paymentConfig.stripe_secret_key)
                }
                setEditPaymentMethodCashFreeId(
                    response?.data?.response_body?.store_payment_data[1]?.payment_config?.cashfree_client_id
                )
                setEditPaymentMethodCashFreeSecretKey(
                    response?.data?.response_body?.store_payment_data[1]?.payment_config?.cashfree_client_secret
                )
            })
            .catch((error) => {
                console.log('server error response from findByPagePaymentMethodData', storePaymentMethodAPI, error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    //! post call for store payment type
    const saveStorePaymentMethod = () => {
        let postBody = {}
        if (paymentGateway === '1') {
            postBody = {
                payment_gateway: paymentGateway,
                store_payment_type: storePaymentType,
                payment_keys: {
                    razor_key_id: razerKeyId,
                    razor_key_secret: razerKeySecret,
                },
            }
        } else {
            postBody = {
                payment_gateway: paymentGateway,
                store_payment_type: storePaymentType,
                payment_keys: {
                    stripe_public_key: stripePublicKey,
                    stripe_secret_key: stripeSecretKey,
                },
            }
        }
        setIsUpLoading(true)
        MarketplaceServices.save(storePaymentMethodAPI, postBody)
            .then((response) => {
                console.log('Server Success Response From store payment method', storePaymentMethodAPI, response.data)
                findByPagePaymentMethodData(
                    searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
                    searchParams.get('limit') ? parseInt(searchParams.get('limit')) : pageLimit
                )
                setIsUpLoading(false)
                onDrawerClose()
                setPaymentGateway('RazerPay')
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                console.log('Error server Response From saveStorePaymentMethod', storePaymentMethodAPI, error)
                setIsUpLoading(false)
                MarketplaceToaster.showToast(error.response)
            })
    }

    //!put call for store payment type
    const updatePaymentMethodData = () => {
        let putObject = {}
        if (editPaymentGateway === '1') {
            putObject = {
                payment_gateway: editPaymentGateway,
                payment_keys: {
                    razor_key_id: editRazerKeyId,
                    razor_key_secret: editRazerKeySecret,
                },
            }
        } else if (editPaymentGateway === '2') {
            putObject = {
                payment_gateway: editPaymentGateway,
                payment_keys: {
                    stripe_public_key: editStripePublicKey,
                    stripe_secret_key: editStripeSecretKey,
                },
            }
        } else {
            putObject = {
                payment_gateway: editPaymentGateway,
                payment_keys: {
                    cashfree_client_id: editCashFreeKeyId,
                    cashfree_client_secret: editCashFreeSecret,
                },
            }
        }
        setIsUpLoading(true)
        MarketplaceServices.update(storePaymentMethodAPI, putObject, {
            store_gateway_id: paymentTypeId,
        })
            .then((response) => {
                setFieldChangeValues(false)
                setIsUpLoading(false)
                console.log(
                    'Server response from updatePaymentMethodData',
                    storePaymentMethodAPI,
                    response.data.response_body
                )
                let copyOfPaymentTypeAPIData = [...paymentMethodListData]
                setEditPaymentMethodRazorPayId(response.data.response_body.payment_config.razor_key_id)
                setEditPaymentMethodRazorPaySecretId(response.data.response_body.payment_config.razor_key_secret)
                setEditPaymentMethodStripePublicKey(response.data.response_body.payment_config.stripe_public_key)
                setEditPaymentMethodStripeSecretKey(response.data.response_body.payment_config.stripe_secret_key)
                setEditPaymentMethodCashFreeId(response.data.response_body.payment_config.cashfree_client_id)
                setEditPaymentMethodCashFreeSecretKey(response.data.response_body.payment_config.cashfree_client_secret)
                copyOfPaymentTypeAPIData.forEach((obj) => {
                    if (obj.id === response.data.response_body.id) {
                        obj.payment_gateway = response.data.response_body.payment_gateway
                        obj.payment_gateway_name =
                            response.data.response_body.payment_gateway === '2'
                                ? 'Stripe'
                                : response.data.response_body.payment_gateway === '1'
                                  ? 'Razorpay'
                                  : 'Cashfree'
                        if (obj.payment_gateway_name === 'Razorpay') {
                            if (obj.payment_config == null) {
                                obj.payment_config = {
                                    razor_key_id: response.data.response_body.payment_config.razor_key_id,
                                    razor_key_secret: response.data.response_body.payment_config.razor_key_secret,
                                }
                            } else {
                                obj.payment_config.razor_key_id =
                                    response.data.response_body.payment_config.razor_key_id
                                obj.payment_config.razor_key_secret =
                                    response.data.response_body.payment_config.razor_key_secret
                            }
                        } else if (obj.payment_gateway_name === 'Stripe') {
                            if (obj.payment_config == null) {
                                obj.payment_config = {
                                    stripe_public_key: response.data.response_body.payment_config.stripe_public_key,
                                    stripe_secret_key: response.data.response_body.payment_config.stripe_secret_key,
                                }
                            } else {
                                obj.payment_config.stripe_public_key =
                                    response.data.response_body.payment_config.stripe_public_key
                                obj.payment_config.stripe_secret_key =
                                    response.data.response_body.payment_config.stripe_secret_key
                            }
                        } else {
                            if (obj.payment_config == null) {
                                obj.payment_config = {
                                    cashfree_client_id: response.data.response_body.payment_config.cashfree_client_id,
                                    cashfree_client_secret:
                                        response.data.response_body.payment_config.cashfree_client_secret,
                                }
                            } else {
                                obj.payment_config.cashfree_client_id =
                                    response.data.response_body.payment_config.cashfree_client_id
                                obj.payment_config.cashfree_client_secret =
                                    response.data.response_body.payment_config.cashfree_client_secret
                            }
                        }
                    }
                })
                setPaymentMethodListData(copyOfPaymentTypeAPIData)
                onDrawerClose()
                MarketplaceToaster.showToast(response)
            })
            .catch((error) => {
                setIsUpLoading(false)
                console.log('error Server response from updatePaymentMethodData', storePaymentMethodAPI, error)
                MarketplaceToaster.showToast(error.response)
            })
    }

    const putPaymentTypeValidation = () => {
        let validValues = 2
        let stripeValues = 2
        let cashFreeValues = 2
        if (editPaymentGateway === '1') {
            if (
                editRazerKeyId === '' ||
                editRazerKeyId === null ||
                editRazerKeyId === undefined ||
                editRazerKeySecret === '' ||
                editRazerKeySecret === null ||
                editRazerKeySecret === undefined
            ) {
                validValues--

                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('payment:please_enter_the_values_for_mandatory_fields')}`, 'error')
                )
            }
            if (editRazerKeyId === '' || editRazerKeyId === undefined || editRazerKeyId === null) {
                validValues--
                setInValidEditRazerKeyId(true)
            }
            if (editRazerKeySecret === '' || editRazerKeySecret === undefined || editRazerKeySecret === null) {
                validValues--
                setInValidEditRazerKeySecret(true)
            }
            if (
                editRazerKeyId === editPaymentMethodRazorPayId &&
                editRazerKeySecret === editPaymentMethodRazorPaySecretId
            ) {
                validValues--
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
            if (validValues === 2) {
                updatePaymentMethodData()
            }
        } else if (editPaymentGateway === '2') {
            if (
                editStripePublicKey === '' ||
                editStripePublicKey === null ||
                editStripePublicKey === undefined ||
                editStripeSecretKey === '' ||
                editStripeSecretKey === null ||
                editStripeSecretKey === undefined
            ) {
                stripeValues--

                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('payment:please_enter_the_values_for_mandatory_fields')}`, 'error')
                )
            }
            if (editStripePublicKey === '' || editStripePublicKey === undefined || editStripePublicKey === null) {
                stripeValues--
                setInValidEditStripePublicKey(true)
            }
            if (editStripeSecretKey === '' || editStripeSecretKey === undefined || editStripeSecretKey === null) {
                stripeValues--
                setInValidEditStripeSecretKey(true)
            }
            if (
                editStripePublicKey === editPaymentMethodStripePublicKey &&
                editStripeSecretKey === editPaymentMethodStripeSecretKey
            ) {
                stripeValues--
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
            if (stripeValues === 2) {
                updatePaymentMethodData()
            }
        } else {
            if (
                editCashFreeKeyId === '' ||
                editCashFreeKeyId === null ||
                editCashFreeKeyId === undefined ||
                editCashFreeSecret === '' ||
                editCashFreeSecret === null ||
                editCashFreeSecret === undefined
            ) {
                cashFreeValues--

                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('payment:please_enter_the_values_for_mandatory_fields')}`, 'error')
                )
            }
            if (editCashFreeKeyId === '' || editCashFreeKeyId === undefined || editCashFreeKeyId === null) {
                cashFreeValues--
                setInValidEditCashFreeId(true)
            }
            if (editCashFreeSecret === '' || editCashFreeSecret === undefined || editCashFreeSecret === null) {
                cashFreeValues--
                setInValidEditCashFreeSecret(true)
            }
            if (
                editCashFreeKeyId === editPaymentMethodCashFreeId &&
                editCashFreeSecret === editPaymentMethodCashFreeSecret
            ) {
                cashFreeValues--
                MarketplaceToaster.showToast(util.getToastObject(`${t('common:no_change_detected')}`, 'info'))
            }
            if (cashFreeValues === 2) {
                updatePaymentMethodData()
            }
        }
    }

    //!validation of post payment type
    const postPaymentMethodValidation = () => {
        let validValues = 3
        let stripeValues = 3
        if (paymentGateway === '1') {
            if (
                razerKeyId === '' ||
                razerKeyId === null ||
                razerKeyId === undefined ||
                razerKeySecret === '' ||
                razerKeySecret === null ||
                razerKeySecret === undefined ||
                storePaymentType === 'Select store payment type'
            ) {
                validValues--
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('payment:please_enter_the_values_for_mandatory_fields')}`, 'error')
                )
            }
            if (razerKeyId === '' || razerKeyId === undefined || razerKeyId === null) {
                validValues--
                setInValidRazerKeyId(true)
            }
            if (razerKeySecret === '' || razerKeySecret === undefined || razerKeySecret === null) {
                validValues--
                setInValidRazerKeySecret(true)
            }
            if (storePaymentType === 'Select store payment type') {
                setInValidStorePaymentType(true)
                validValues--
            }
            if (validValues === 3) {
                saveStorePaymentMethod()
            }
        } else {
            if (
                stripePublicKey === '' ||
                stripePublicKey === null ||
                stripePublicKey === undefined ||
                stripeSecretKey === '' ||
                stripeSecretKey === null ||
                stripeSecretKey === undefined ||
                storePaymentType === 'Select store payment type'
            ) {
                stripeValues--
                MarketplaceToaster.showToast(
                    util.getToastObject(`${t('payment:please_enter_the_values_for_mandatory_fields')}`, 'error')
                )
            }
            if (stripePublicKey === '' || stripePublicKey === undefined || stripePublicKey === null) {
                stripeValues--
                setInValidStripePublicKey(true)
            }
            if (stripeSecretKey === '' || stripeSecretKey === undefined || stripeSecretKey === null) {
                stripeValues--
                setInValidStripeSecretKey(true)
            }
            if (storePaymentType === 'Select store payment type') {
                setInValidStorePaymentType(true)
                stripeValues--
            }
            if (stripeValues === 3) {
                saveStorePaymentMethod()
            }
        }
    }

    //! Drawer close function
    const onDrawerClose = () => {
        setPaymentTypeDrawerOpen(false)
        setStripePublicKeyPasswordVisible(false)
        setStripeSecretKeyPasswordVisible(false)
        setRazorPayPublicKeyPasswordVisible(false)
        setRazorPaySecretKeyPasswordVisible(false)
        setCashFreeSecretKeyPasswordVisible(false)
        setCashFreeKeyPasswordVisible(false)
        setPaymentGateway('1')
        setStripePublicKey('')
        setStripeSecretKey('')
        setRazerKeyId('')
        setRazerKeySecret('')
        setFieldChangeValues(false)
        setStorePaymentType('Select store payment type')
    }
    //!payment Method edit drawer
    const showEditPaymentMethodDrawer = (id, paytypeId) => {
        setPaymentTypeId(id)
        setPaymentTypeDrawerOpen(true)
        setPaymentTypeDrawerAction('put')
        setInValidEditRazerKeySecret(false)
        setInValidEditStripePublicKey(false)
        setInValidEditRazerKeyId(false)
        setInValidEditCashFreeId(false)
        setInValidEditCashFreeSecret(false)
        setInValidEditStripeSecretKey(false)
        const filteredPaymentTypeData =
            paymentTypeListData &&
            paymentTypeListData.length > 0 &&
            paymentTypeListData.filter((element) => element.id === paytypeId)
        setEditPaymentType(filteredPaymentTypeData[0].payment_type)
        const filteredPaymentMethodData =
            paymentMethodListData &&
            paymentMethodListData.length > 0 &&
            paymentMethodListData.filter((element) => element.id === id)
        setEditPaymentGateway(filteredPaymentMethodData[0].payment_gateway)
        setDefaultEditPaymentGateway(filteredPaymentMethodData[0].payment_gateway)
        setEditRazerKeySecret(filteredPaymentMethodData[0].payment_config.razor_key_secret)
        setDefaultEditRazerKeySecret(filteredPaymentMethodData[0].payment_config.razor_key_secret)
        setEditRazerKeyId(filteredPaymentMethodData[0].payment_config.razor_key_id)
        setDefaultEditRazerKeyId(filteredPaymentMethodData[0].payment_config.razor_key_id)
        setEditStripeSecretKey(filteredPaymentMethodData[0].payment_config.stripe_secret_key)
        setDefaultEditStripeSecretKey(filteredPaymentMethodData[0].payment_config.stripe_secret_key)
        setEditStripePublicKey(filteredPaymentMethodData[0].payment_config.stripe_public_key)
        setDefaultEditStripePublicKey(filteredPaymentMethodData[0].payment_config.stripe_public_key)
        setEditCashFreeKeyId(filteredPaymentMethodData[0].payment_config.cashfree_client_id)
        setEditCashFreeSecret(filteredPaymentMethodData[0].payment_config.cashfree_client_secret)
    }

    //!get call of store payment type
    const findByPagePaymentTypeData = () => {
        // enabling spinner
        setIsLoading(true)
        MarketplaceServices.findAll(storePaymentTypeAPI, null, true)
            .then(function (response) {
                console.log(
                    'server Success response from findByPagePaymentTypeData is 703',
                    storePaymentTypeAPI,
                    response.data.response_body
                )
                setPaymentTypeListData(response.data.response_body.data)
                setIsLoading(false)
                setIsNetworkError(false)
            })
            .catch((error) => {
                console.log('server error response from findByPagePaymentTypeData', storePaymentTypeAPI, error)
                setIsLoading(false)
                setIsNetworkError(true)
            })
    }

    //! closing the delete popup model
    const closeDeleteModal = () => {
        setIsDeletePaymentTypeModalOpen(false)
    }

    useEffect(() => {
        findByPagePaymentMethodData(
            searchParams.get('page') ? parseInt(searchParams.get('page')) : 1,
            searchParams.get('limit') ? parseInt(searchParams.get('limit')) : pageLimit
        )
        window.scrollTo(0, 0)
    }, [searchParams])

    const handlePaymentGatewayChange = (value) => {
        setPaymentGateway(value)
        setFieldChangeValues(true)
        setRazerKeyId('')
        setRazerKeySecret('')
        setStripePublicKey('')
        setStripeSecretKey('')
        setEditCashFreeKeyId('')
        setEditCashFreeSecret('')
        setStorePaymentType('Select store payment type')
        setInValidRazerKeyId(false)
        setInValidRazerKeySecret(false)
        setInValidStripePublicKey(false)
        setInValidStorePaymentType(false)
        setInValidStripeSecretKey(false)
        setInValidEditCashFreeId(false)
        setInValidEditCashFreeSecret(false)
    }

    const handleEditPaymentGatewayChange = (value) => {
        setEditPaymentGateway(value)
        setFieldChangeValues(true)
        setInValidEditRazerKeySecret(false)
        setInValidEditRazerKeyId(false)
        setInValidEditCashFreeId(false)
        setInValidEditStripePublicKey(false)
        setInValidEditStripeSecretKey(false)
        setInValidEditCashFreeSecret(false)
        setEditRazerKeyId(defaultEditRazerKeyId)
        setEditRazerKeySecret(defaultEditRazerKeySecret)
        setEditStripePublicKey(defaultEditStripePublicKey)
        setEditStripeSecretKey(defaultEditStripeSecretKey)
    }

    const handleStorePaymentType = (value) => {
        setStorePaymentType(value)
        setFieldChangeValues(true)
        setInValidStorePaymentType(false)
    }

    const handlePageNumberChange = (page, pageSize) => {
        setSearchParams({
            page: parseInt(page) ? parseInt(page) : 1,
            limit: parseInt(pageSize) ? parseInt(pageSize) : pageLimit,
        })
    }

    useEffect(() => {
        findByPagePaymentTypeData()
    }, [searchParams])

    return (
        <Content>
            <Content className='!min-h-screen '>
                {isLoading ? (
                    <Content className=' bg-white text-center !p-4'>
                        <SkeletonComponent />
                    </Content>
                ) : isNetworkError ? (
                    <Content className='pt-[2.3rem] px-3 pb-3 text-center ml-2'>
                        <p>{`${t('common:network_error')}`}</p>
                    </Content>
                ) : (
                    <>
                        <Content className=' !p-2  bg-white shadow-brandShadow rounded-lg'>
                            {paymentMethodListData && paymentMethodListData.length > 0 ? (
                                <DynamicTable tableComponentData={paymentPropsData} />
                            ) : (
                                <div className='w-[100%] p-5 flex items-center justify-center !bg-white'>
                                    <Empty description={t('common:no_data_available_to_display')} />
                                </div>
                            )}
                            {paymentMethodCount && paymentMethodCount >= pageLimit ? (
                                <Content className=' grid justify-items-end'>
                                    <DmPagination
                                        currentPage={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
                                        presentPage={searchParams.get('page') ? parseInt(searchParams.get('page')) : 1}
                                        totalItemsCount={paymentMethodCount}
                                        pageLimit={pageLimit}
                                        pageSize={
                                            searchParams.get('limit') ? parseInt(searchParams.get('limit')) : pageLimit
                                        }
                                        handlePageNumberChange={handlePageNumberChange}
                                        showSizeChanger={true}
                                        showTotal={true}
                                        showQuickJumper={true}
                                    />
                                </Content>
                            ) : null}
                        </Content>
                        <Content>
                            <StoreModal
                                isVisible={paymentTypeDrawerOpen}
                                title={
                                    paymentTypeDrawerAction === 'post'
                                        ? t('payment:add_payment_method')
                                        : t('payment:edit_payment_method')
                                }
                                cancelCallback={() => onDrawerClose()}
                                width={'50%'}
                                isSpin={isUpLoading}>
                                {paymentTypeDrawerAction === 'post' ? (
                                    <Content className='p-4'>
                                        <Typography className='mb-2 flex gap-1  input-label-color'>
                                            {t('payment:payment_gateway')}
                                            <span className='mandatory-symbol-color text-sm '>*</span>
                                        </Typography>
                                        <Radio.Group
                                            optionType='button'
                                            style={{ display: 'flex' }}
                                            className='mb-3'
                                            value={paymentGateway}
                                            onChange={(e) => {
                                                handlePaymentGatewayChange(e.target.value)
                                            }}>
                                            <Radio.Button value='1'>{t('payment:razorpay')}</Radio.Button>
                                            <Radio.Button value='2'>{t('payment:stripe')}</Radio.Button>
                                        </Radio.Group>
                                        <Typography className='mb-2 flex gap-1 input-label-color'>
                                            {t('payment:store_payment_type')}
                                            <span className='mandatory-symbol-color text-sm '>*</span>
                                        </Typography>

                                        <Select
                                            className={`${
                                                inValidStorePaymentType
                                                    ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 border-1 rounded-md mb-3'
                                                    : 'mb-3'
                                            }`}
                                            placeholder={t('payment:select_store_payment_type')}
                                            style={{ display: 'flex' }}
                                            onChange={(e) => {
                                                handleStorePaymentType(e)
                                            }}
                                            value={storePaymentType}>
                                            {paymentTypeListData &&
                                                paymentTypeListData.length > 0 &&
                                                paymentTypeListData.map((item, index) => (
                                                    <Select.Option key={index} value={item.id}>
                                                        {item.payment_type}
                                                    </Select.Option>
                                                ))}
                                        </Select>
                                        {paymentGateway === '2' ? (
                                            <>
                                                <Content>
                                                    <Typography className='mb-2 flex gap-1 input-label-color'>
                                                        {t('payment:stripe_public_key')}
                                                        <span className='mandatory-symbol-color text-sm'>*</span>
                                                    </Typography>
                                                    <Input.Password
                                                        visibilityToggle={{
                                                            visible: stripePublicKeyPasswordVisible,
                                                            onVisibleChange: setStripePublicKeyPasswordVisible,
                                                        }}
                                                        value={stripePublicKey}
                                                        placeholder={t('payment:enter_the_stripe_public_key')}
                                                        className={`${
                                                            inValidStripePublicKey
                                                                ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-3'
                                                                : 'mb-3'
                                                        }`}
                                                        maxLength={paymentTypeMaxLength}
                                                        onChange={(e) => {
                                                            setStripePublicKey(e.target.value)
                                                            setFieldChangeValues(true)
                                                            setInValidStripePublicKey(false)
                                                        }}
                                                        onBlur={() => {
                                                            const trimmed = stripePublicKey.trim()
                                                            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                            setStripePublicKey(trimmedUpdate)
                                                        }}
                                                    />
                                                </Content>
                                                <Content>
                                                    <Typography className='mb-2 flex gap-1 input-label-color'>
                                                        {t('payment:stripe_secret_key')}
                                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                                    </Typography>

                                                    <Input.Password
                                                        visibilityToggle={{
                                                            visible: stripeSecretKeyPasswordVisible,
                                                            onVisibleChange: setStripeSecretKeyPasswordVisible,
                                                        }}
                                                        value={stripeSecretKey}
                                                        placeholder={t('payment:enter_the_stripe_secret_key')}
                                                        className={`${
                                                            inValidStripeSecretKey
                                                                ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-6'
                                                                : 'mb-6'
                                                        }`}
                                                        maxLength={paymentTypeMaxLength}
                                                        onChange={(e) => {
                                                            setStripeSecretKey(e.target.value)
                                                            setFieldChangeValues(true)
                                                            setInValidStripeSecretKey(false)
                                                        }}
                                                        onBlur={() => {
                                                            const trimmed = stripeSecretKey.trim()
                                                            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                            setStripeSecretKey(trimmedUpdate)
                                                        }}
                                                    />
                                                </Content>
                                            </>
                                        ) : (
                                            <>
                                                <Content>
                                                    <Typography className='mb-2 flex gap-1 input-label-color'>
                                                        {t('payment:razorpay_key_id')}
                                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                                    </Typography>
                                                    <Input.Password
                                                        visibilityToggle={{
                                                            visible: razorPayPublicKeyPasswordVisible,
                                                            onVisibleChange: setRazorPayPublicKeyPasswordVisible,
                                                        }}
                                                        value={razerKeyId}
                                                        placeholder={t('payment:enter_the_razorpay_key_id')}
                                                        className={`${
                                                            inValidRazerKeyId
                                                                ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-3'
                                                                : 'mb-3'
                                                        }`}
                                                        maxLength={paymentTypeMaxLength}
                                                        onChange={(e) => {
                                                            setRazerKeyId(e.target.value)
                                                            setFieldChangeValues(true)
                                                            setInValidRazerKeyId(false)
                                                        }}
                                                        onBlur={() => {
                                                            const trimmed = razerKeyId.trim()
                                                            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                            setRazerKeyId(trimmedUpdate)
                                                        }}
                                                    />
                                                </Content>
                                                <Content>
                                                    <Typography className='mb-2 flex gap-1 input-label-color'>
                                                        {t('payment:razorpay_key_secret')}
                                                        <span className='mandatory-symbol-color text-sm '>*</span>
                                                    </Typography>
                                                    <Input.Password
                                                        visibilityToggle={{
                                                            visible: razorPaySecretKeyPasswordVisible,
                                                            onVisibleChange: setRazorPaySecretKeyPasswordVisible,
                                                        }}
                                                        value={razerKeySecret}
                                                        placeholder={t('payment:enter_the_razorpay_key_secret')}
                                                        className={`${
                                                            inValidRazerKeySecret
                                                                ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-6'
                                                                : 'mb-6'
                                                        }`}
                                                        maxLength={paymentTypeMaxLength}
                                                        onChange={(e) => {
                                                            setRazerKeySecret(e.target.value)
                                                            setFieldChangeValues(true)
                                                            setInValidRazerKeySecret(false)
                                                        }}
                                                        onBlur={() => {
                                                            const trimmed = razerKeySecret.trim()
                                                            const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                            setRazerKeySecret(trimmedUpdate)
                                                        }}
                                                    />
                                                </Content>
                                            </>
                                        )}

                                        <Button
                                            className={fieldChangeValues ? 'app-btn-primary' : '!opacity-75'}
                                            disabled={!fieldChangeValues}
                                            onClick={() => {
                                                postPaymentMethodValidation()
                                            }}>
                                            {t('payment:save')}
                                        </Button>
                                    </Content>
                                ) : (
                                    <>
                                        <div className='pt-3 !mb-0'>
                                            <Alert
                                                icon={<MdInfo className='font-bold !text-center' />}
                                                message={t('payment:payment_alert_message')}
                                                type='info'
                                                showIcon
                                            />
                                        </div>
                                        <Spin tip={t('languages:please_wait')} size='large' spinning={isUpLoading}>
                                            <Content className='pt-4'>
                                                <Typography className='mb-2 flex gap-1 input-label-color'>
                                                    {t('payment:payment_gateway')}
                                                    <span className='mandatory-symbol-color text-sm '>*</span>
                                                </Typography>
                                                <Radio.Group
                                                    optionType='button'
                                                    className='mb-3'
                                                    style={{ display: 'flex' }}
                                                    value={editPaymentGateway}
                                                    onChange={(e) => {
                                                        handleEditPaymentGatewayChange(e.target.value)
                                                    }}>
                                                    {editPaymentGateway === '1' ? (
                                                        <Radio.Button value='1' disabled={true}>
                                                            {t('payment:razorpay')}
                                                        </Radio.Button>
                                                    ) : editPaymentGateway === '2' ? (
                                                        <Radio.Button value='2' disabled={true}>
                                                            {t('payment:stripe')}
                                                        </Radio.Button>
                                                    ) : (
                                                        <Radio.Button value='3' disabled={true}>
                                                            {t('payment:cash_free')}
                                                        </Radio.Button>
                                                    )}
                                                </Radio.Group>
                                                <Typography className='mb-2 ml-1 input-label-color'>
                                                    {t('payment:store_payment_type_id')}
                                                    <span className='mandatory-symbol-color text-sm ml-1'>*</span>
                                                </Typography>

                                                <Select
                                                    placeholder={t('payment:select_store_payment_type')}
                                                    style={{ display: 'flex' }}
                                                    disabled={true}
                                                    className='mb-3'
                                                    onChange={(e) => {
                                                        handleStorePaymentType(e)
                                                    }}
                                                    value={editPaymentType}>
                                                    {paymentTypeListData &&
                                                        paymentTypeListData.length > 0 &&
                                                        paymentTypeListData.map((item, index) => (
                                                            <Select.Option key={index} value={item.id}>
                                                                {item.payment_type}
                                                            </Select.Option>
                                                        ))}
                                                </Select>
                                                {editPaymentGateway === '2' ? (
                                                    <>
                                                        <Content>
                                                            <Typography className='mb-2 input-label-color flex gap-1'>
                                                                {t('payment:stripe_public_key')}
                                                                <span className='mandatory-symbol-color text-sm '>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: stripePublicKeyPasswordVisible,
                                                                    onVisibleChange: setStripePublicKeyPasswordVisible,
                                                                }}
                                                                value={editStripePublicKey}
                                                                placeholder={t('payment:enter_the_stripe_public_key')}
                                                                className={`${
                                                                    inValidEditStripePublicKey
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-3'
                                                                        : 'mb-3'
                                                                }`}
                                                                maxLength={paymentTypeMaxLength}
                                                                onChange={(e) => {
                                                                    setEditStripePublicKey(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidStripePublicKey(false)
                                                                }}
                                                                onBlur={() => {
                                                                    const trimmed = editStripePublicKey.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditStripePublicKey(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                        <Content>
                                                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                                                {t('payment:stripe_secret_key')}
                                                                <span className='mandatory-symbol-color text-sm '>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: stripeSecretKeyPasswordVisible,
                                                                    onVisibleChange: setStripeSecretKeyPasswordVisible,
                                                                }}
                                                                value={editStripeSecretKey}
                                                                placeholder={t('payment:enter_the_stripe_secret_key')}
                                                                className={`${
                                                                    inValidEditStripeSecretKey
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-6'
                                                                        : 'mb-6'
                                                                }`}
                                                                maxLength={paymentTypeMaxLength}
                                                                onChange={(e) => {
                                                                    setEditStripeSecretKey(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidEditStripeSecretKey(false)
                                                                }}
                                                                onBlur={() => {
                                                                    const trimmed = editStripeSecretKey.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditStripeSecretKey(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                    </>
                                                ) : editPaymentGateway === '1' ? (
                                                    <>
                                                        <Content>
                                                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                                                {t('payment:razorpay_key_id')}
                                                                <span className='mandatory-symbol-color text-sm '>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: razorPayPublicKeyPasswordVisible,
                                                                    onVisibleChange:
                                                                        setRazorPayPublicKeyPasswordVisible,
                                                                }}
                                                                value={editRazerKeyId}
                                                                placeholder={t('payment:enter_the_razorpay_key_id')}
                                                                onChange={(e) => {
                                                                    setEditRazerKeyId(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidEditRazerKeyId(false)
                                                                }}
                                                                maxLength={paymentTypeMaxLength}
                                                                className={`${
                                                                    inValidEditRazerKeyId
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-3'
                                                                        : 'mb-3'
                                                                }`}
                                                                onBlur={() => {
                                                                    const trimmed = editRazerKeyId.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditRazerKeyId(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                        <Content>
                                                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                                                {t('payment:razorpay_key_secret')}
                                                                <span className='mandatory-symbol-color text-sm'>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: razorPaySecretKeyPasswordVisible,
                                                                    onVisibleChange:
                                                                        setRazorPaySecretKeyPasswordVisible,
                                                                }}
                                                                value={editRazerKeySecret}
                                                                iconRender={() => null}
                                                                placeholder={t('payment:enter_the_razorpay_key_secret')}
                                                                className={`${
                                                                    inValidEditRazerKeySecret
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-6'
                                                                        : 'mb-6'
                                                                }`}
                                                                maxLength={paymentTypeMaxLength}
                                                                onChange={(e) => {
                                                                    setEditRazerKeySecret(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidEditRazerKeySecret(false)
                                                                }}
                                                                onBlur={() => {
                                                                    const trimmed = editRazerKeySecret.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditRazerKeySecret(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                    </>
                                                ) : (
                                                    <>
                                                        <Content>
                                                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                                                {t('payment:cash_free_key_id')}
                                                                <span className='mandatory-symbol-color text-sm '>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: cashFreeKeyPasswordVisible,
                                                                    onVisibleChange: setCashFreeKeyPasswordVisible,
                                                                }}
                                                                value={editCashFreeKeyId}
                                                                placeholder={t('payment:enter_the_cashfree_key_id')}
                                                                onChange={(e) => {
                                                                    setEditCashFreeKeyId(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidEditCashFreeId(false)
                                                                }}
                                                                maxLength={paymentTypeMaxLength}
                                                                className={`${
                                                                    inValidEditCashFreeId
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-3'
                                                                        : 'mb-3'
                                                                }`}
                                                                onBlur={() => {
                                                                    const trimmed = editCashFreeKeyId.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditCashFreeKeyId(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                        <Content>
                                                            <Typography className='mb-2 flex gap-1 input-label-color'>
                                                                {t('payment:cashfree_secret_key')}
                                                                <span className='mandatory-symbol-color text-sm'>
                                                                    *
                                                                </span>
                                                            </Typography>
                                                            <Input.Password
                                                                visibilityToggle={{
                                                                    visible: cashFreeSecretKeyPasswordVisible,
                                                                    onVisibleChange:
                                                                        setCashFreeSecretKeyPasswordVisible,
                                                                }}
                                                                value={editCashFreeSecret}
                                                                iconRender={() => null}
                                                                placeholder={t('payment:enter_the_cashfree_key_secret')}
                                                                className={`${
                                                                    inValidEditCashFreeSecret
                                                                        ? 'border-red-400 border-solid focus:border-red-400 hover:border-red-400 mb-6'
                                                                        : 'mb-6'
                                                                }`}
                                                                maxLength={paymentTypeMaxLength}
                                                                onChange={(e) => {
                                                                    setEditCashFreeSecret(e.target.value)
                                                                    setFieldChangeValues(true)
                                                                    setInValidEditCashFreeSecret(false)
                                                                }}
                                                                onBlur={() => {
                                                                    const trimmed = editCashFreeSecret.trim()
                                                                    const trimmedUpdate = trimmed.replace(/\s+/g, ' ')
                                                                    setEditCashFreeSecret(trimmedUpdate)
                                                                }}
                                                            />
                                                        </Content>
                                                    </>
                                                )}
                                                <Content className='flex justify-end gap-2'>
                                                    <Button
                                                        className={fieldChangeValues ? 'mt-3' : '!opacity-75 mt-3'}
                                                        disabled={!fieldChangeValues}
                                                        onClick={() => {
                                                            onDrawerClose()
                                                        }}>
                                                        {t('common:cancel')}
                                                    </Button>
                                                    <Button
                                                        className={
                                                            fieldChangeValues
                                                                ? 'app-btn-primary mt-3'
                                                                : '!opacity-75 mt-3'
                                                        }
                                                        disabled={!fieldChangeValues}
                                                        onClick={() => {
                                                            putPaymentTypeValidation()
                                                        }}>
                                                        {t('payment:update')}
                                                    </Button>
                                                </Content>
                                            </Content>
                                        </Spin>
                                    </>
                                )}
                            </StoreModal>
                        </Content>
                    </>
                )}
            </Content>
            <StoreModal
                isVisible={isDeletePaymentTypeModalOpen}
                okButtonText={t('common:yes')}
                cancelButtonText={t('common:cancel')}
                title={t('common:warning')}
                // okCallback={() => removePaymentMethod()}
                cancelCallback={() => closeDeleteModal()}
                isSpin={isDeleteModalLoading}
                hideCloseButton={false}>
                {
                    <div>
                        <p>{t('payment:confirm_product_method_deletion')}</p>
                        <p>{t('payment:want_to_delete_the_product_method')}</p>
                    </div>
                }
            </StoreModal>
        </Content>
    )
}

export default PaymentMethod
